import {useEffect, useState} from "react"
import Avatar from "@components/avatar"
import {Link, useParams, useHistory} from "react-router-dom"
import {useSelector, useDispatch} from 'react-redux'
import BlankProfile from "@src/assets/images/avatars/avatar-blank.png"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Button,
    Spinner,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Alert
} from "reactstrap"
import {
    getOpportunityDetail,
    participateToOpportunity,
    participateToOpportunityReset
} from '@src/views/brands/myCampaigns/store/actions'
import Linkify from "react-linkify"
import {ArrowRightCircle, CheckCircle, CheckSquare, Delete, Download, File, GitPullRequest, Send} from "react-feather"
import {
    FaCalendarAlt,
    FaDollarSign,
    FaHandshake,
    FaMapMarkerAlt, FaSms, FaUser, FaUserCircle,
    FaUserFriends,
    FaUsers,
    FaWind
} from "react-icons/fa"

const OppoDetailBrands = () => {
    const {id} = useParams()

    const history = useHistory()

    const [participateModal, setParticipateModal] = useState(false)
    const [successRes, setSuccessRes] = useState(null)

    const dispatch = useDispatch()

    const {
        brandOppDetailLoading,
        brandOppDetailFullFill,
        brandOppDetailError,

        brandOppParticipateLoading,
        brandOppParticipateFullFill,
        brandOppParticipateError
    } = useSelector(store => store?.brandsOppoprunities)

    useEffect(() => {
        dispatch(getOpportunityDetail(id))
    }, [])

    useEffect(() => {
        if (brandOppParticipateFullFill?.success) {
            setParticipateModal(false)
            setSuccessRes({
                show: true,
                msg: brandOppParticipateFullFill?.msg
            })
            dispatch(participateToOpportunityReset())
            dispatch(getOpportunityDetail(id))
            setTimeout(() => setSuccessRes(null), 10000)
        }
    }, [brandOppParticipateFullFill])

    const doParticipate = () => {
        dispatch(participateToOpportunity({
            event_id: id
        }))
    }

    const handleRedirectMessage = (user) => {
        localStorage.setItem('selectedChatRd', JSON.stringify(user))
        history.push(`/brands/apps/chat/chatBrands/${user.user_id}`)
    }

    return (
        <>
            {brandOppDetailLoading ? (
                <div className="w-100 my-4 text-center">
                    <Spinner color='primary'/>
                </div>
            ) : (
                brandOppDetailFullFill?.data?.length === 0 ? (
                    <Row className="match-height">
                        <Col md="12" lg="12" sm="12">
                            <Alert color={'danger'} isOpen={true}>
                                <div className='alert-body'>
                                    Sorry! The opportunity is no longer available.
                                </div>
                            </Alert>
                        </Col>
                    </Row>
                ) : (
                    <Row className="match-height">

                        <Col md="12" lg="12" sm="12">
                            <h2 className="mb-2">Opportunity Details</h2>
                        </Col>

                        <Col md="5" lg="3" sm="12">
                            <Card className="p-1 d-flex">
                                <div className="justify-content-sm-center">
                                    <CardBody>
                                        {(brandOppDetailFullFill?.data?.city || brandOppDetailFullFill?.data?.state) && (
                                            <CardText className="justify-content-sm-center">
                                                <div className="p-25">
                                                    <h6 className="font-weight-bolder">
                                                        <FaMapMarkerAlt size={12}/> Location
                                                    </h6>
                                                    <h6 className="ml-1">
                                                        {brandOppDetailFullFill?.data?.city}, {brandOppDetailFullFill?.data?.state}
                                                    </h6>
                                                </div>
                                            </CardText>
                                        )}

                                        {brandOppDetailFullFill?.data?.start_datetime && (
                                            <CardText>
                                                <div className="p-25">
                                                    <h6 className="font-weight-bolder">
                                                        <FaCalendarAlt size={12}/> Date
                                                    </h6>
                                                    <h6 className="ml-1">
                                                        {brandOppDetailFullFill?.data?.start_datetime}
                                                    </h6>
                                                </div>
                                            </CardText>
                                        )}

                                        {brandOppDetailFullFill?.data?.ongoing && (
                                            <CardText>
                                                <div className="p-25">
                                                    <h6 className="font-weight-bolder">
                                                        <FaWind size={12}/> Ongoing
                                                    </h6>
                                                    <h6 className="ml-1">
                                                        Yes
                                                    </h6>
                                                </div>
                                            </CardText>
                                        )}

                                        {brandOppDetailFullFill?.data?.event_type && (
                                            <CardText>
                                                <div className="p-25">
                                                    <h6 className="font-weight-bolder">
                                                        <FaHandshake size={12}/> Type
                                                    </h6>
                                                    <h6 className="ml-1">
                                                        {brandOppDetailFullFill?.data?.event_type}
                                                    </h6>
                                                </div>
                                            </CardText>
                                        )}

                                        {brandOppDetailFullFill?.data?.expected_reach && (
                                            <CardText>
                                                <div className="p-25">
                                                    <h6 className="font-weight-bolder">
                                                        <FaUserFriends size={12}/> Expected Reach
                                                    </h6>
                                                    <h6 className="ml-1">
                                                        {brandOppDetailFullFill?.data?.expected_reach}
                                                    </h6>
                                                </div>
                                            </CardText>
                                        )}

                                        {brandOppDetailFullFill?.data?.audience_type && (
                                            <CardText>
                                                <div className="p-25">
                                                    <h6 className="font-weight-bolder">
                                                        <FaUsers size={12}/> Type of Audience
                                                    </h6>
                                                    <h6 className="ml-1">
                                                        {brandOppDetailFullFill?.data?.audience_type}
                                                    </h6>
                                                </div>
                                            </CardText>
                                        )}

                                        {brandOppDetailFullFill?.data?.cost_of_partnership && (
                                            <CardText>
                                                <div className="p-25">
                                                    <h6 className="font-weight-bolder">
                                                        <FaDollarSign size={12}/> Cost of Partnership
                                                    </h6>
                                                    <h6 className="ml-1">
                                                        ${brandOppDetailFullFill?.data?.cost_of_partnership}
                                                    </h6>
                                                </div>
                                            </CardText>
                                        )}

                                        {brandOppDetailFullFill?.data?.no_of_follower && (
                                            <CardText className="oppo-detail">
                                                <div className="p-25">
                                                    <h6 className="font-weight-bolder">
                                                        <FaUserCircle size={12}/> Followers
                                                    </h6>
                                                    <h6 className="ml-1">
                                                        {brandOppDetailFullFill?.data?.no_of_follower}
                                                    </h6>
                                                </div>
                                            </CardText>
                                        )}
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>

                        <Col md="7" lg="9" sm="12">
                            <Card className="p-2">

                                {successRes?.show && (
                                    <Alert color='success'>
                                        <div className='alert-body'>
                                            <span>{successRes?.msg}</span>
                                        </div>
                                    </Alert>
                                )}

                                <CardTitle>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="font-weight-bolder">Opportunity Title</h4>
                                        {brandOppDetailFullFill?.data?.request_status === "" && (
                                            <Button.Ripple
                                                color="secondary"
                                                className="m-25 float-right"
                                                onClick={() => setParticipateModal(true)}
                                            >
                                              <span className="align-middle ml-50">
                                                  <ArrowRightCircle style={{stroke: '#FFF'}} size="16"/> Participate
                                              </span>
                                            </Button.Ripple>
                                        )}

                                        {brandOppDetailFullFill?.data?.request_status === "Requested" && (
                                            <Button.Ripple
                                                color="warning"
                                                className="m-25 float-right"
                                            >
                                              <span className="align-middle ml-50">
                                                  <GitPullRequest style={{stroke: '#FFF'}}
                                                                  size="16"/> {brandOppDetailFullFill?.data?.request_status}
                                              </span>
                                            </Button.Ripple>
                                        )}

                                        {brandOppDetailFullFill?.data?.request_status === "Accepted" && (
                                            <Button.Ripple
                                                color="info"
                                                className="m-25 float-right"
                                            >
                                              <span className="align-middle ml-50">
                                                  <CheckSquare style={{stroke: '#FFF'}}
                                                               size="16"/> {brandOppDetailFullFill?.data?.request_status}
                                              </span>
                                            </Button.Ripple>
                                        )}

                                        {brandOppDetailFullFill?.data?.request_status === "Invoice Sent" && (
                                            <Button.Ripple
                                                color="primary"
                                                className="m-25 float-right"
                                            >
                                              <span className="align-middle ml-50">
                                                  <Send style={{stroke: '#FFF'}}
                                                        size="16"/> {brandOppDetailFullFill?.data?.request_status}
                                              </span>
                                            </Button.Ripple>
                                        )}

                                        {brandOppDetailFullFill?.data?.request_status === "Rejected" && (
                                            <Button.Ripple
                                                color="danger"
                                                className="m-25 float-right"
                                            >
                                              <span className="align-middle ml-50">
                                                  <Delete style={{stroke: '#FFF'}}
                                                          size="16"/> {brandOppDetailFullFill?.data?.request_status}
                                              </span>
                                            </Button.Ripple>
                                        )}

                                        {brandOppDetailFullFill?.data?.request_status === "Paid" && (
                                            <Button.Ripple
                                                color="success"
                                                className="m-25 float-right"
                                            >
                                              <span className="align-middle ml-50">
                                                  <CheckCircle style={{stroke: '#FFF'}}
                                                               size="16"/> {brandOppDetailFullFill?.data?.request_status}
                                              </span>
                                            </Button.Ripple>
                                        )}
                                    </div>
                                </CardTitle>
                                <CardText className="text-break mb-3"
                                          style={{whiteSpace: "break-spaces", textAlign: "justify"}}>
                                    {brandOppDetailFullFill?.data?.title}
                                </CardText>
                                <CardTitle>
                                    <h4 className="font-weight-bolder mb-0">Opportunity Details</h4>
                                </CardTitle>
                                <CardText className="text-break"
                                          style={{whiteSpace: "break-spaces", textAlign: "justify"}}
                                          dangerouslySetInnerHTML={{__html: brandOppDetailFullFill?.data?.description}}
                                >
                                </CardText>
                                <hr/>
                                <CardTitle>
                                    <div className="my-2 justify-content-md-center justify-content-sm-center">
                                        <Avatar
                                            img={brandOppDetailFullFill?.data?.profile_img || BlankProfile}
                                            imgHeight="50"
                                            imgWidth="50"
                                            className="align-items-sm-center"
                                        />
                                        <div className="d-inline-block ml-2">
                                            <h5 className="mb-0">
                                                {brandOppDetailFullFill?.data?.rd_name}
                                            </h5>
                                            {brandOppDetailFullFill?.data?.speciality &&
                                                brandOppDetailFullFill?.data?.speciality.map((data, index) => (
                                                    <h6 key={index}>
                                                        {data.length && `${data} |`}
                                                    </h6>
                                                ))}
                                        </div>
                                    </div>
                                </CardTitle>
                                <CardText>
                                    <p className="text-break"
                                       style={{whiteSpace: "break-spaces", textAlign: "justify"}}>
                                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a href={decoratedHref} key={key} target="_blank">{decoratedText}</a>)}>
                                            <div
                                                dangerouslySetInnerHTML={{__html: brandOppDetailFullFill?.data?.rd_description}}></div>
                                        </Linkify>
                                    </p>
                                    <div>
                                        <Button.Ripple
                                            tag={Link}
                                            to={`/dashboard/brands/nutritions/${brandOppDetailFullFill?.data?.user_id}`}
                                            color="secondary"
                                            className="m-25"
                                        >
                                            <span className="align-middle ml-50">
                                                <FaUser size={14}/> View Profile
                                            </span>
                                        </Button.Ripple>
                                        <Button.Ripple
                                            color="success"
                                            onClick={() => handleRedirectMessage(brandOppDetailFullFill?.data)}
                                            className="m-25">
                                            <FaSms size={12}/> Message
                                        </Button.Ripple>
                                    </div>
                                </CardText>
                            </Card>
                        </Col>

                        {brandOppDetailFullFill?.data?.docs?.length > 0 && (
                            <Col sm="12">
                                <h3 className="bg-white p-1">Attachments</h3>
                                <div className="d-flex flex-wrap -mi-10px mt-1">
                                    {brandOppDetailFullFill?.data?.docs?.map((item, index) => (
                                        <>
                                            {(item.document_type === 'png' ||
                                                item.document_type === 'jpg' ||
                                                item.document_type === 'jpeg') && (
                                                <div className='media-img' key={index}>
                                                    <div className="download-icon cursor-pointer">
                                                        <a href={item.document_url} target="_blank">
                                                            <Download size={16}/>
                                                        </a>
                                                    </div>
                                                    <img className='rounded img-fit-contain' src={item.document_url}
                                                         alt={item.document_url}/>
                                                </div>
                                            )}
                                            {(item.document_type === 'pdf' ||
                                                item.document_type === 'doc' ||
                                                item.document_type === 'docx' ||
                                                item.document_type === 'xls' ||
                                                item.document_type === 'xlsx') && (
                                                <div className='media-img' key={index}>
                                                    <div className="download-icon cursor-pointer">
                                                        <a href={item.document_url} target="_blank">
                                                            <Download size={16}/>
                                                        </a>
                                                    </div>
                                                    <div className="text-primary text-center">
                                                        <File size={130}/>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ))}
                                </div>
                            </Col>
                        )}

                        <Modal isOpen={participateModal}
                               className='modal-dialog-centered'>
                            <ModalHeader>
                                Confirmation Alert
                            </ModalHeader>
                            <ModalBody>
                                Are you sure you want to participate in that opportunity?
                            </ModalBody>
                            <ModalFooter>
                                <Button color='danger'
                                        onClick={() => setParticipateModal(false)}
                                        disabled={brandOppParticipateLoading}
                                >
                                    No
                                </Button>
                                <Button color='success'
                                        onClick={doParticipate}
                                        disabled={brandOppParticipateLoading}
                                >
                                    {brandOppParticipateLoading ? (
                                        <Spinner style={{width: '14px', height: '14px'}}/>
                                    ) : (
                                        <span>Yes</span>
                                    )}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Row>
                )
            )}
        </>
    )
}

export default OppoDetailBrands
