import {useState} from 'react'
import Products from './Products'
import {useDispatch, useSelector} from 'react-redux'
import '@styles/base/pages/app-ecommerce.scss'

const MyBrands = () => {
    const [activeView, setActiveView] = useState('grid')
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const dispatch = useDispatch()
    const store = useSelector(state => state.ecommerce)

    return (
        <Products
            store={store}
            dispatch={dispatch}
            activeView={activeView}
            sidebarOpen={sidebarOpen}
            setActiveView={setActiveView}
            setSidebarOpen={setSidebarOpen}
        />
    )
}
export default MyBrands
