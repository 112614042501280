import {
    MessageSquare,
    FileText,
    Grid,
    Users,
    Box,
    Gift
} from 'react-feather'

export default [
    {
        id: 'dashboard',
        title: 'Dashboard',
        icon: <Grid size={20}/>,
        navLink: '/dashboard/brands',
        resource: 'brand',
        action: 'manage'
    },
    {
        id: 'messagesBrands',
        title: 'Messages',
        icon: <MessageSquare size={20}/>,
        badge: 'danger',
        badgeText: '0',
        navLink: '/brands/apps/chat/chatBrands',
        resource: 'brand',
        action: 'manage',
        tourClass: 'messages-brands-tour'
    },
    {
        id: 'nutritionExperts',
        title: "Nutrition Experts",
        icon: <Users size={20}/>,
        navLink: "/dashboard/brands/nutritions",
        resource: 'brand',
        action: 'manage',
        tourClass: 'nutrition-experts-tour'
    },
    {
        id: 'myOutReach',
        title: "My Outreach",
        icon: <Box size={20}/>,
        navLink: "/brands/outreach-posts",
        resource: 'brand',
        action: 'manage',
        tourClass: 'nutrition-experts-tour'
    },
    {
        id: 'rdOpportunities',
        title: "RD Opportunities",
        icon: <Gift size={20}/>,
        navLink: "/dashboard/brands/opportunities",
        resource: 'brand',
        action: 'manage',
        tourClass: 'nutrition-experts-tour'
    },
    {
        id: 'managePartnership',
        title: 'Manage Partnerships',
        icon: <FileText size={20}/>,
        navLink: '/dashboard/brands/manage-partnerships',
        resource: 'brand',
        action: 'manage',
        tourClass: 'manage-partnership-tour'
    }
]
