import {useState, Fragment, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {
    getManagePendingRDsData,
    getManagePendingRDsSearchData,
    getStates,
    getIndustries,
    getSpecialities,
    deletePendingManageRDsData,
    changeRdProfileStatus,
    exportPendingRdsData,
    nullSearchData
} from '@src/views/tables/data-tables-admin-pending/store/actions'
import {NULL} from '@src/views/tables/data-tables-admin-pending/store/actionType'
import {
    getCountries,
    getCitiesByStateId,
    getStatesByRegionId,
    getRegionsByCountryId
} from '@src/redux/actions/brandsDashboard'
import {
    ChevronDown,
    Search,
    RefreshCcw,
    FileText,
    MoreHorizontal,
    Trash,
    Eye
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    CustomInput,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Alert
} from 'reactstrap'

import '@styles/react/libs/flatpickr/flatpickr.scss'
import CustomPagination from '@src/views/components/pagination/CustomPagination'

const PendingRDs = () => {

    const [searchName, setSearchName] = useState('')
    const [searchSpeciality, setSearchSpeciality] = useState('')
    const [searchCountry, setSearchCountry] = useState('')
    const [searchRegion, setSearchRegion] = useState('')
    const [searchState, setSearchState] = useState('')
    const [searchCity, setSearchCity] = useState('')
    const [searchEmail, setSearchEmail] = useState('')
    const [searchIndustry, setSearchIndustry] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [deleteFAQModal, setDeleteFAQModal] = useState(false)
    const [show, setShow] = useState(false)
    const [rdId, setRdId] = useState(false)
    const [rdModal, setRdModal] = useState(false)
    const [isPageLoading, setIsPageLoading] = useState(true)
    const [pendingRds, setPendingRds] = useState([])
    const [paginationInfo, setPaginationInfo] = useState(null)
    const [approveMsg, setApproveMsg] = useState('')
    const [deleteMsg, setDeleteMsg] = useState('')
    const [approveLoading, setApproveLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)

    const pendingRdsSelectors = useSelector(store => store.dataTablesAdminPending)
    const brandsDashboardStoreSlice = useSelector(store => store.brandsDashboardSlice)
    const dispatch = useDispatch()

    const updateRdProfileStatus = () => {
        setApproveLoading(true)
        dispatch(changeRdProfileStatus(rdId))
    }

    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
            minWidth: '220px'
        },
        {
            name: 'Cdr',
            selector: 'cdr_number',
            sortable: true,
            minWidth: '50px',
            wrap: true
        },
        {
            name: 'State',
            selector: 'state',
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Industry',
            selector: 'industry',
            sortable: true,
            minWidth: '150px',
            cell: row => {
                return (
                    <div>
                        {row.industry?.map(indust => (
                            <span>{indust.label}</span>
                        ))}
                    </div>
                )
            }
        },
        {
            name: 'Speciality',
            selector: 'speciality',
            sortable: true,
            minWidth: '220px',
            cell: row => {
                return (
                    <div>
                        {row.speciality?.map(special => (
                            <span>{special.label}</span>
                        ))}
                    </div>
                )
            }
        },
        {
            name: 'Plan',
            selector: 'selected_plan',
            sortable: true,
            minWidth: '220px'
        },
        {
            name: 'Approval',
            selector: 'status',
            sortable: true,
            minWidth: '50px',
            cell: row => {
                return (
                    <CustomInput
                        className='custom-control-success'
                        type='switch'
                        id={`success_${row.id}`}
                        name='success'
                        inline
                        defaultChecked={row.profile_status === 'approved'}
                        onClick={() => {
                            setRdModal(true)
                            setRdId(row.id)
                        }}
                    />
                )
            }
        },

        {
            name: 'Registration Date',
            selector: 'reg_date',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Actions',
            allowOverflow: true,
            cell: row => {
                return (
                    <div className='d-flex pl-2'>
                        <UncontrolledDropdown>
                            <DropdownToggle className='pr-1' tag='span'>
                                <MoreHorizontal size={15}/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem tag={Link} to={`/admin/manage-rd-pending/${row.id}`}>
                                    <Eye size={15}/>
                                    <span className='align-middle ml-50'>View</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => {
                                    setDeleteFAQModal(true)
                                    setDeleteId(row.id)
                                }}>
                                    <Trash size={15}/>
                                    <span className='align-middle ml-50'>Delete</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        }
    ])

    useEffect(() => {
        if (!!pendingRdsSelectors?.pendingRds) {
            setPendingRds(pendingRdsSelectors.pendingRds?.data)
            const {links, meta, pages} = pendingRdsSelectors.pendingRds
            setPaginationInfo({...links, ...meta, pages})
            setIsPageLoading(false)
        }

        if (pendingRdsSelectors?.pendingRDUpdatedStatus?.status === true) {
            setRdModal(false)
            setApproveLoading(false)
            setIsPageLoading(true)
            dispatch(getManagePendingRDsData())
            dispatch(nullSearchData(NULL.PROFILE_STATUS))
            setApproveMsg(pendingRdsSelectors?.pendingRDUpdatedStatus?.msg)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000)
        }

        if (pendingRdsSelectors?.pendingRDUpdatedStatus?.status === false) {
            setRdModal(false)
            setApproveLoading(false)
            setIsPageLoading(true)
            dispatch(getManagePendingRDsData())
            dispatch(nullSearchData(NULL.PROFILE_STATUS))
            setErrorMsg(pendingRdsSelectors?.pendingRDUpdatedStatus?.msg)
            setIsError(true)
            setTimeout(() => {
                setIsError(false)
            }, 10000)
        }

    }, [pendingRdsSelectors, brandsDashboardStoreSlice])

    useEffect(() => {
        setIsPageLoading(true)
        dispatch(getManagePendingRDsData())
        dispatch(getCountries())
        dispatch(getStates())
        dispatch(getIndustries())
        dispatch(getSpecialities())
        dispatch(exportPendingRdsData())
    }, [])

    const deletePendingRd = () => {
        setDeleteFAQModal(!deleteFAQModal)
        dispatch(deletePendingManageRDsData(deleteId))
        dispatch(getManagePendingRDsData())
        setIsPageLoading(true)
        setDeleteMsg("Profile deleted.")
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 3000)
    }

    const fetchRegions = (id) => {
        dispatch(getRegionsByCountryId({id}))
    }

    const fetchStates = (id) => {
        dispatch(getStatesByRegionId({id}))
    }

    const fetchCities = (id) => {
        dispatch(getCitiesByStateId({id}))
    }
    const searchPendingRds = () => {
        setIsPageLoading(true)
        dispatch(getManagePendingRDsSearchData(false, {
            name: searchName,
            email: searchEmail,
            city: searchCity || null,
            state: searchState || null,
            country: searchCountry || null,
            region: searchRegion || null,
            industry: searchIndustry ? [searchIndustry] : [],
            speciality_id: searchSpeciality
        }))
    }

    const refreshPendingRds = () => {
        setSearchName('')
        setSearchEmail('')
        setSearchCity('')
        setSearchState('')
        setSearchCountry('')
        setSearchIndustry('')
        setSearchSpeciality('')
        setSearchRegion('')
        dispatch(nullSearchData('pendingRds'))
        setIsPageLoading(true)
        dispatch(getManagePendingRDsData())
        brandsDashboardStoreSlice.regionsByCountryID = []
        brandsDashboardStoreSlice.statesByRegionID = []
        brandsDashboardStoreSlice.citiesByStateID = []

    }

    function convertArrayOfObjectsToCSV(array) {
        let result = ''
        result += "Name, Email, CDR Number, Country, Region, States, Cities, Industry, Speciality , Status, Reg Date, Profile Status, Profile Image, Location, Timezone \n"
        array.forEach(item => {
            result += `${item?.name?.replaceAll(',', '')}, ${item?.email}, ${item?.cdr_number.replaceAll('#', 'No.')}, ${item?.country}, ${item?.region}, ${item?.state}, ${item?.city},`
            result += `${item?.industry.map(e => e.label).join(" | ").replaceAll(',', ' ')},`
            result += `${item?.speciality.map(e => e.label).join(" | ").replaceAll(',', ' ')},`
            result += `${item?.status}, ${item?.reg_date.replaceAll(',', ' ')}, ${item?.profile_status}, ${item?.profile_image}, ${item?.location}, ${item?.timezone}`
            result += "\n"
        })
        return result
    }

    function downloadCSV(array) {
        if (array.length > 0) {
            const link = document.createElement('a')
            let csv = convertArrayOfObjectsToCSV(array)
            if (csv === null) return
            const filename = 'Pending-RDS.csv'
            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`
            }
            link.setAttribute('href', encodeURI(csv))
            link.setAttribute('download', filename)
            link.click()
        } else {
            alert('Data is being loaded, Please wait...')
        }
    }

    const dataToRender = () => {
        return pendingRds
    }

    const handleCustomPagination = pageUrl => {
        setIsPageLoading(true)
        dispatch(getManagePendingRDsSearchData(true, {
            name: searchName,
            email: searchEmail,
            city: searchCity || null,
            state: searchState || null,
            country: searchCountry || null,
            region: searchRegion || null,
            industry: searchIndustry ? [searchIndustry] : [],
            speciality_id: searchSpeciality
        }, pageUrl))
    }

    const onCancelStatus = () => {
        setRdModal(!rdModal)
        dispatch(getManagePendingRDsData())
        setIsPageLoading(true)
    }

    return (
        <Fragment>
            <Card className="brands-my-campaign-table-parent">
                <CardHeader className='d-flex align-items-center justify-content-between flex-wrap border-bottom p-1 px-2'>
                    <CardTitle className="my-1" tag='h4'>Pending RDs</CardTitle>
                    <Button.Ripple className="my-1" color='primary' onClick={() => downloadCSV(pendingRdsSelectors?.exportPendingRds)}>
                        <FileText size={15}/> Export (CSV)
                    </Button.Ripple>
                </CardHeader>
                <CardBody>
                    <Row form className='mt-1 mb-50'>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='name'>Name:</Label>
                                <Input id='name' placeholder='Bruce Wayne' onChange={(e) => setSearchName(e.target.value)} value={searchName}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='email'>Email:</Label>
                                <Input
                                    type='email'
                                    id='email'
                                    placeholder='Bwayne@email.com'
                                    value={searchEmail}
                                    onChange={(e) => setSearchEmail(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='country'>Country:</Label>
                                <Input id='country' type="select" placeholder='Country' onChange={(e) => {
                                    setSearchCountry(e.target.value)
                                    fetchRegions(e.target.value)
                                    setSearchRegion('')
                                    setSearchState('')
                                    setSearchCity('')
                                }}
                                       value={searchCountry}
                                >
                                    <option value=''>Select Country</option>
                                    fetchRegions
                                    {brandsDashboardStoreSlice.countries && brandsDashboardStoreSlice.countries.map(country => (
                                        <option value={country.id}>{country.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='region'>Region:</Label>
                                <Input type="select" id='region' placeholder='region' onChange={(e) => {
                                    setSearchRegion(e.target.value)
                                    fetchStates(e.target.value)
                                    setSearchState('')
                                    setSearchCity('')
                                }}
                                       value={searchRegion}
                                >
                                    <option value=''>Select Region</option>
                                    {brandsDashboardStoreSlice.regionsByCountryID && brandsDashboardStoreSlice.regionsByCountryID.map(region => (
                                        <option value={region.id}>{region.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='state'>State:</Label>
                                <Input type="select" id='state' placeholder='State' onChange={(e) => {
                                    setSearchState(e.target.value)
                                    fetchCities(e.target.value)
                                    setSearchCity('')
                                }}
                                       value={searchState}
                                >
                                    <option value=''>Select State</option>
                                    {brandsDashboardStoreSlice.statesByRegionID && brandsDashboardStoreSlice.statesByRegionID.map(state => (
                                        <option value={state.id}>{state.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='city'>City:</Label>
                                <Input type="select" id='city' placeholder='city' onChange={(e) => setSearchCity(e.target.value)}
                                       value={searchCity}
                                >
                                    <option value=''>Select City</option>
                                    {brandsDashboardStoreSlice.citiesByStateID && brandsDashboardStoreSlice.citiesByStateID.map(city => (
                                        <option value={city.id}>{city.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='industry'>Industry:</Label>
                                <Input type="select" id='industry' placeholder='Industry' onChange={(e) => setSearchIndustry(e.target.value)}
                                       value={searchIndustry}
                                >
                                    <option value=''>Select Industry</option>
                                    {pendingRdsSelectors.industries && pendingRdsSelectors.industries.map(indust => (
                                        <option value={indust.id}>{indust.value}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='speciality'>Speciality:</Label>
                                <Input type="select" id='speciality' placeholder='Weight Management' onChange={(e) => setSearchSpeciality(e.target.value)}
                                       value={searchSpeciality}
                                >
                                    <option value=''>Select Speciality</option>
                                    {pendingRdsSelectors.specialities && pendingRdsSelectors.specialities.map(special => (
                                        <option value={special.id}>{special.value}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <div className="admin-filter-actions inline-direction">
                                <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchPendingRds}>
                                    <Search size={16}/>
                                </Button.Ripple>
                                <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshPendingRds}>
                                    <RefreshCcw size={16}/>
                                </Button.Ripple>
                            </div>
                        </Col>
                    </Row>

                    <Alert color={'success'} isOpen={show} className="mt-2">
                        <div className='alert-body'>{approveMsg || deleteMsg} </div>
                    </Alert>
                    <Alert color="danger" isOpen={isError} className="mt-2">
                        <div className='alert-body'> {errorMsg} </div>
                    </Alert>
                </CardBody>
                {isPageLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isPageLoading && (
                    <DataTable
                        noHeader
                        paginationServer
                        columns={advSearchColumns}
                        className='react-dataTable'
                        sortIcon={<ChevronDown size={10}/>}
                        data={dataToRender()}
                    />
                )}
                <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
            </Card>
            <Modal isOpen={rdModal} className='modal-dialog-centered'>
                <ModalHeader>Change Profile Status</ModalHeader>
                <ModalBody>
                    Are you sure want to approve RD profile ?
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' outline onClick={() => onCancelStatus()} disabled={approveLoading}>
                        No
                    </Button>
                    <Button color='danger' onClick={updateRdProfileStatus} disabled={approveLoading}>
                        {approveLoading ? <Spinner style={{width: '14px', height: '14px'}}/> : 'Yes'}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={deleteFAQModal} toggle={() => setDeleteFAQModal(!deleteFAQModal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setDeleteFAQModal(!deleteFAQModal)}>Delete RD</ModalHeader>
                <ModalBody>
                    Are you sure want to delete this RD?
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' outline onClick={() => setDeleteFAQModal(!deleteFAQModal)}>
                        No
                    </Button>
                    <Button color='danger' onClick={deletePendingRd}>
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default PendingRDs
