const API_URL = process.env.REACT_APP_BASEURL
const MANAGE_RD = {
    MANAGE_RD_LISTING: '[Admin] MANAGE_RD_LISTING',
    SEARCH_MANAGE_RD_GET_DATA: '[Admin] SEARCH_MANAGE_RD_GET_DATA',
    COUNTRIES_DATA: '[Admin] COUNTRIES_DATA',
    STATES_DATA: '[Admin] STATES_DATA',
    INDUSTRIES_DATA: '[Admin] INDUSTRIES_DATA',
    DELETE_MANAGE_RD_GET_DATA: '[Admin] DELETE_MANAGE_RD_GET_DATA',
    SPECIALITIES_DATA: '[Admin] SPECIALITIES_DATA',
    DELETE_RD_RESPONSE: '[Admin] DELETE_RD_RESPONSE',
    UPDATE_RD_STATUS: '[Admin] UPDATE_RD_STATUS',
    PROFILE_INFO: '[Admin] MANAGE_RD_PROFILE',
    EVENT_LIST: '[Admin] MANAGE_RD_EVENT_LIST',
    SEARCHED_EVENT_LIST: '[Admin] MANAGE_RD_SEARCHED_EVENT_LIST',
    BRANDS_WHO_PURCHASED_MEMBERSHIP: '[Admin] BRANDS_WHO_PURCHASED_MEMBERSHIP',
    SEARCHED_BRANDS_WHO_PURCHASED_MEMBERSHIP: '[Admin] SEARCHED_BRANDS_WHO_PURCHASED_MEMBERSHIP',
    BRANDS_WHO_REQUESTED_FOR_PARTICIPATION: '[Admin] BRANDS_WHO_REQUESTED_FOR_PARTICIPATION',
    SEARCHED_BRANDS_WHO_REQUESTED_FOR_PARTICIPATION: '[Admin] SEARCHED_BRANDS_WHO_REQUESTED_FOR_PARTICIPATION',
    NUll_SEARCH: '[Admin] MANAGE_RD_NUll_SEARCH',
    EXPORT_CSV_DATA: '[Admin] EXPORT_CSV_DATA',
    ERROR: '[Admin] MANAGE_RD_ERROR'
}

export {API_URL, MANAGE_RD}