import moment from "moment/moment"
import tab1 from "@src/assets/images/tab1.svg"
import tab2 from "@src/assets/images/tab2.svg"
import tab3 from "@src/assets/images/tab3.svg"
import tab4 from "@src/assets/images/tab4.svg"
import tab5 from "@src/assets/images/tab5.svg"
import bg1 from "@src/assets/images/1.png"
import bg2 from "@src/assets/images/2.png"
import bg3 from "@src/assets/images/3.png"
import bg4 from "@src/assets/images/4.png"
import bg5 from "@src/assets/images/5.png"
import bg6 from "@src/assets/images/6.png"
import * as yup from "yup"
import queryString from "query-string"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"
import {Modifier, EditorState} from "draft-js"

export const SCHEDULE_DATE_OPTIONS = {
    allowInput: false,
    minDate: moment().add(1, "days").startOf("day").toDate(),
    dateFormat: "M d, Y G:i K",
    enableTime: true
}

export const EXPIRY_DATE_OPTIONS = {
    allowInput: false,
    minDate: "today",
    dateFormat: "M d, Y G:i K",
    enableTime: true
}

export const OUTREACH_TYPES = [
    {
        id: 1,
        image: tab5,
        title: "Ambassador Program",
        slug: "ambassador_program",
        description: "Invite dietitians to join your Healthcare Practitioner Program"
    },
    {
        id: 2,
        image: tab4,
        title: "Product Seeding",
        slug: "product_exploration",
        description: "Share product samples with dietitians"
    },
    {
        id: 3,
        image: tab3,
        title: "Collaboration",
        slug: "collaboration",
        description: "Partner with dietitians to promote your brand - seek out content creators, writers, and more"
    },
    {
        id: 4,
        image: tab2,
        title: "Survey",
        slug: "survey",
        description: "Survey dietitians to gather insightful feedback on products, packaging, nutrition, and more."
    },
    {
        id: 5,
        image: tab1,
        title: "Other",
        slug: "others",
        description: "Create your own custom post"
    }
]

export const OUTREACH_EXAMPLE_URL = "https://therdlink.com/how-to-create-effective-rd-outreach-campaigns/"

export const BANNER_FORMATS = ["image/png", "image/jpeg", "image/jpg"]

export const BANNER_MAX_SIZE = 2 * 1024 * 1024

export const DEFAULT_BANNERS = [bg1, bg2, bg3, bg4, bg5, bg6]

export const FORM_INITIAL_VALUES = {
    step2: {
        type: '',
        title: '',
        rdTypes: [],
        isLocation: false,
        countries: [],
        states: [],
        description: '',
        expiry: '',
        isExpiry: false,
        applicants: '',
        noCap: false,
        banner: '',
        defaultBanner: ''
    },
    step3: {
        doYouProvideSamplesToRDs: '',
        benefits: '',
        isHosted: '',
        hostedUrl: '',
        doYouProvideSamplesToNewMembers: '',
        doYouShipToCanadaPOAPO: '',
        rdRequirements: '',
        partnershipRequirementsAndGuidelines: '',
        budgetPerRD: '',
        campaign_form_questions: []
    },
    questions: {
        clickAdd: false,
        editIndex: -1,
        question: '',
        type: '',
        campaign_question_options: [{option: ""}, {option: ""}]
    }
}

export const VALIDATION_SCHEMA =
    {
        default: yup.object().shape({
            type: yup.object().required("This field is required."),
            title: yup.string().trim().max(100, 'Max 100 characters are allowed').required("This field is required."),
            rdTypes: yup.array().min(1, 'This field is required.').required("This field is required"),
            description: yup.string().trim().min(100, 'Write minimum 100 characters').required("This field is required."),
            expiry: yup.array().when('isExpiry', {
                is: false,
                then: yup.array().min('1', 'This field is required.').required("This field is required."),
                otherwise: yup.array()
            }),
            applicants: yup.string().when('noCap', {
                is: false,
                then: yup.string().matches(/^[1-9]\d*$/, 'Applicants must be a positive integer (at least 1)').required('This field is required'),
                otherwise: yup.string()
            }),
            countries: yup.array().when('isLocation', {
                is: true,
                then: yup.array().min('1', 'This field is required.').required("This field is required."),
                otherwise: yup.array()
            }),
            banner: yup.mixed().nullable()
                .test(
                    "fileFormat",
                    "Picture is empty or invalid. Only PNG, JPEG, JPG are allowed.",
                    value => !value || (value && BANNER_FORMATS.includes(value.type))
                )
                .test(
                    "fileSize",
                    "Picture size too large. Max 2MB.",
                    value => !value || (value && value.size <= BANNER_MAX_SIZE)
                )
        }),
        ambassador_program: yup.object().shape({
            doYouProvideSamplesToRDs: yup.string().required("This field is required."),
            isHosted: yup.string().required("This field is required."),
            hostedUrl: yup.string().when('isHosted', {
                is: 'Yes',
                then: yup.string().required("This field is required."),
                otherwise: yup.string()
            })
        }),
        product_exploration: yup.object().shape({
            doYouShipToCanadaPOAPO: yup.string().required("This field is required."),
            campaign_form_questions: yup.array()
        }),
        collaboration: yup.object().shape({
            rdRequirements: yup.string().required("This field is required."),
            partnershipRequirementsAndGuidelines: yup.string().required("This field is required."),
            budgetPerRD: yup.string().required("This field is required."),
            campaign_form_questions: yup.array()
        }),
        survey: yup.object().shape({
            campaign_form_questions: yup.array().min(1, "At least one question is required").required("At least one question is required")
        }),
        others: yup.object().shape({
            campaign_form_questions: yup.array().min(1, "At least one question is required").required("At least one question is required")
        }),
        questions: yup.object().shape({
            question: yup.string().required("This field is required."),
            type: yup.string().required("This field is required."),
            campaign_question_options: yup.array().when('type', {
                is: 'multiple',
                then: yup.array().of(
                    yup.object().shape({
                        option: yup.string().max(100, 'Max 100 characters are allowed').required("Option is required")
                    }))
                    .min(1, "At least one option is required")
                    .required("Options are required"),
                otherwise: yup.array().notRequired()
            })
        })
    }

export const CAMPAIGN_STATUS = [
    {
        label: "Live",
        value: "live"
    },
    {
        label: "Draft",
        value: "draft"
    },
    {
        label: "Scheduled",
        value: "scheduled"
    },
    {
        label: "Disabled",
        value: "disabled"
    },
    {
        label: "Expired",
        value: "expired"
    }
]

export const EDITOR_TOOLBAR = {
    options: ["inline", "list", "link", "remove", "history"],
    inline: {
        options: ["bold", "italic", "underline"]
    },
    list: {
        options: ["unordered", "ordered"]
    },
    link: {
        showOpenOptionOnHover: false
    },
    fontSize: {
        options: [12, 14, 16, 18, 24, 36]
    }
}

export const MODAL_PAGINATION_SIZE = 20

export const getAttributes = (attributes) => {
    const formatOptions = (data, type) => {
        return data ? data.map((item) => ({...item, type})) : []
    }

    return [
        {
            id: 0,
            label: "Speciality",
            options: formatOptions(attributes?.speciality, "speciality")
        },
        {
            id: 1,
            label: "Industry",
            options: formatOptions(attributes?.industry, "industry")
        },
        {
            id: 2,
            label: "Personal Characteristics",
            options: formatOptions(attributes?.personal_attributes, "personal_attributes")
        }
    ]
}

export const getQueryParams = () => {
    const parsed = queryString.parseUrl(window.location.href)
    const pageNo = parsed?.query?.page
    const query = parsed?.query?.query
    const filter = parsed?.query?.filter
    const params = []
    if (query) {
        params.push(`query=${query}`)
    }
    if (filter) {
        params.push(`filter=${filter}`)
    }
    if (pageNo) {
        params.push(`page=${pageNo}`)
    }
    const queryParams = params.length > 0 ? `?${params.join('&')}` : ''
    return queryParams
}

export const getQueryObj = () => {
    const parsed = queryString?.parseUrl(window.location.href)
    const query = parsed?.query?.query
    const filter = parsed?.query?.filter
    const pageNo = parsed?.query?.page
    const params = {}
    if (query) {
        params.search = query
    }
    if (filter) {
        params.filter = filter
    }
    if (pageNo) {
        params.page = pageNo
    }
    return params
}

export const viewCampaign = (campaignId, view) => {
    const params = getQueryParams()
    if (view) {
        return `/brands/rd-view/${campaignId}/view${params ?? ''}`
    } else {
        return `/brands/rd-view/${campaignId}${params ?? ''}`
    }
}

export const showOutreachPage = () => {
    const params = getQueryParams()
    return `/brands/outreach-posts${params}`
}

export const showHomePage = () => {
    return `/dashboard/brands`
}

export const showEditOutreachPage = (campaignId) => {
    return `/brands/review-partnership-details/${campaignId}`
}

export const successAlert = (msg) => {
    const sweetAlert = withReactContent(Swal)
    sweetAlert.fire({
        title: 'Congratulations',
        icon: 'success',
        html: msg
    }).then(r => r)
}

export const errorAlert = (msg) => {
    const sweetAlert = withReactContent(Swal)
    sweetAlert.fire({
        title: 'Please Fix!',
        icon: 'error',
        html: msg
    }).then(r => r)
}

export const confirmAlert = async (title, msg) => {
    const sweetAlert = withReactContent(Swal)
    const result = await sweetAlert.fire({
        title,
        icon: 'warning',
        html: msg,
        confirmButtonText: 'Yes',
        allowOutsideClick: false,
        showCancelButton: true
    })
    return result.isConfirmed
}

export const saveCampaign = async (data) => {
    const formData = new FormData()
    formData.append("version", 'v2')
    if (data?.id) {
        formData.append("id", data?.id)
    }
    formData.append("campaign_type_id", data?.type_id)
    formData.append("campaign_title", data?.title)
    formData.append("campaign_details", data?.description)
    if (data?.banner) {
        formData.append("banner_image", data?.banner)
    }
    if (data?.defaultBanner) {
        const response = await fetch(data?.defaultBanner)
        const blob = await response.blob()
        formData.append("banner_image", blob, 'file.png')
    }
    if (data?.rdTypes) {
        data?.rdTypes?.forEach((item) => {
            if (item?.type === 'speciality') {
                formData.append(`campaign_dietitian_speciality_ids[]`, item?.id)
            } else if (item?.type === 'industry') {
                formData.append(`campaign_dietitian_industry_ids[]`, item?.id)
            } else if (item?.type === 'personal_attributes') {
                formData.append(`campaign_dietitian_personal_attributes_ids[]`, item?.id)
            }
        })
    }
    if (data?.countries) {
        data?.countries?.forEach((item) => {
            formData.append(`campaign_dietitian_country_ids[]`, item?.id)
        })
    }
    if (data?.states) {
        data?.states?.forEach((item) => {
            formData.append(`campaign_dietitian_states_ids[]`, item?.id)
        })
    }
    if (data?.isExpiry) {
        formData.append("expire_after", '')
    } else {
        formData.append("expire_after", data?.expiry[0] ? moment(data?.expiry[0]).format("YYYY-MM-DD HH:mm") : '')
    }
    if (data?.noCap) {
        formData.append("submission_limit", '')
    } else {
        formData.append("submission_limit", data?.applicants)
    }
    if (data?.doYouProvideSamplesToRDs === 'Yes') {
        formData.append("sample_provided_for_participants", 1)
    } else {
        formData.append("sample_provided_for_participants", 0)
    }
    if (data?.benefits) {
        formData.append("benefits_for_rd", data?.benefits)
    } else {
        formData.append("benefits_for_rd", '')
    }
    if (data?.isHosted === 'Yes') {
        formData.append("platform_external_link", data.hostedUrl)
    } else {
        formData.append("platform_external_link", '')
    }
    if (data?.doYouProvideSamplesToNewMembers === 'Yes') {
        formData.append("provide_samples_to_new_members", 1)
    } else {
        formData.append("provide_samples_to_new_members", 0)
    }
    if (data?.doYouShipToCanadaPOAPO === 'Yes') {
        formData.append("ship_to_canada_po_boxes_ap_boxes", 1)
    } else {
        formData.append("ship_to_canada_po_boxes_ap_boxes", 0)
    }
    formData.append("rd_requirements", data?.rdRequirements)
    formData.append("partnership_requirements_and_guidelines", data?.partnershipRequirementsAndGuidelines)
    formData.append("budget_per_rd", data?.budgetPerRD)
    if (data?.campaign_form_questions) {
        data?.campaign_form_questions?.forEach((item, index) => {
            formData.append(`questions[${index}][question]`, item.question)
            formData.append(`questions[${index}][type]`, item.type)
            if (item.type === 'multiple' && item.campaign_question_options) {
                item.campaign_question_options.forEach((item, i) => {
                    formData.append(`questions[${index}][options][${i}]`, item.option)
                })
            }
        })
    }
    return formData
}

export const getReactSelectCustomStyles = (form, field = 'rdTypes') => ({
    control: (base, state) => ({
        ...base,
        borderColor: (form.touched[field] && form.errors[field]) ? "red" : state.isFocused ? "#007bff" : base.borderColor,
        boxShadow: state.isFocused ? "0 0 5px rgba(0, 123, 255, 0.5)" : "none",
        "&:hover": {
            borderColor: "#007bff"
        }
    }),
    menu: (base) => ({
        ...base,
        backgroundColor: "#fff"
    }),
    option: (styles, {isSelected, isFocused}) => ({
        ...styles,
        backgroundColor: isSelected ? "#c1eafd" : isFocused ? "#d3e3fd" : "transparent",
        color: "#000",
        height: "40px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "10px",
        cursor: "pointer"
    }),
    groupHeading: (styles) => ({
        ...styles,
        backgroundColor: "#EFF2F4",
        color: "#000",
        padding: "8px",
        fontSize: "14px",
        textTransform: "capitalize"
    })
})

export const generatePaginationLinks = (currentPage, totalPages) => {
    const links = []
    const maxVisiblePages = 3
    links.push({
        label: "Previous",
        url: currentPage > 1 ? String(currentPage - 1) : null
    })
    if (totalPages <= maxVisiblePages + 2) {
        for (let i = 1; i <= totalPages; i++) {
            links.push({
                label: String(i),
                url: String(i),
                active: i === currentPage
            })
        }
    } else {
        if (currentPage <= 3) {
            for (let i = 1; i <= maxVisiblePages; i++) {
                links.push({
                    label: String(i),
                    url: String(i),
                    active: i === currentPage
                })
            }
            links.push({label: "...", url: null})
            links.push({label: String(totalPages), url: String(totalPages)})
        } else if (currentPage >= totalPages - 2) {
            links.push({label: "1", url: "1"})
            links.push({label: "...", url: null})
            for (let i = totalPages - 2; i <= totalPages; i++) {
                links.push({
                    label: String(i),
                    url: String(i),
                    active: i === currentPage
                })
            }
        } else {
            links.push({label: "1", url: "1"})
            links.push({label: "...", url: null})

            for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                links.push({
                    label: String(i),
                    url: String(i),
                    active: i === currentPage
                })
            }

            links.push({label: "...", url: null})
            links.push({label: String(totalPages), url: String(totalPages)})
        }
    }
    links.push({
        label: "Next",
        url: currentPage < totalPages ? String(currentPage + 1) : null
    })

    return links
}

export const handlePastedText = (text, html, editorState, setDescriptionEditorState) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    if (text.match(urlRegex)) {
        const contentState = editorState.getCurrentContent()
        const selection = editorState.getSelection()
        let newContentState = contentState
        const pastedUrl = text.match(urlRegex)[0]

        if (!selection.isCollapsed()) {
            const contentWithEntity = newContentState.createEntity("LINK", "MUTABLE", {url: pastedUrl})
            const entityKey = contentWithEntity.getLastCreatedEntityKey()
            newContentState = Modifier.applyEntity(newContentState, selection, entityKey)
        } else {
            const contentWithEntity = newContentState.createEntity("LINK", "MUTABLE", {url: pastedUrl})
            const entityKey = contentWithEntity.getLastCreatedEntityKey()
            newContentState = Modifier.replaceText(newContentState, selection, pastedUrl, null, entityKey)
        }

        const newEditorState = EditorState.push(editorState, newContentState, "insert-fragment")
        setDescriptionEditorState(newEditorState)
        return true
    }
    return false
}
