import {useEffect} from 'react'
import {NavLink, useLocation, matchPath} from 'react-router-dom'
import {Badge} from 'reactstrap'
import classnames from 'classnames'
import navigation from '@src/navigation/vertical'
import {search, getAllParents} from '@layouts/utils'
import {PropTypes} from "prop-types"

const VerticalNavMenuLink = ({
                                 item,
                                 setGroupActive,
                                 activeItem,
                                 setActiveItem,
                                 setGroupOpen,
                                 toggleActiveGroup,
                                 parentItem,
                                 routerProps,
                                 currentActiveItem
                             }) => {

    const LinkTag = item.externalLink ? 'a' : NavLink
    const location = useLocation()
    const currentURL = location.pathname
    const match = matchPath(currentURL, {
        path: `${item.navLink}/:param`,
        exact: true,
        strict: false
    })

    const searchParents = (navigation, currentURL) => {
        const parents = search(navigation, currentURL, routerProps)
        const allParents = getAllParents(parents, 'id')
        return allParents
    }

    const resetActiveGroup = navLink => {
        const parents = search(navigation, navLink, match)
        toggleActiveGroup(item.id, parents)
    }

    const resetActiveAndOpenGroups = () => {
        setGroupActive([])
        setGroupOpen([])
    }

    useEffect(() => {
        if (currentActiveItem !== null) {
            setActiveItem(currentActiveItem)
            const arr = searchParents(navigation, currentURL)
            setGroupActive([...arr])
        }
    }, [location])

    return (
        <li
            className={classnames({
                'nav-item': !item.children,
                disabled: item.disabled,
                active: item.navLink === activeItem
            })}
        >
            <LinkTag
                className={`d-flex align-items-center ${item.tourClass}`}
                target={item.newTab ? '_blank' : undefined}
                /*eslint-disable */
                {...(item.externalLink === true
                    ? {
                        href: item.navLink || '/'
                    }
                    : {
                        to: item.navLink || '/',
                        isActive: (match, location) => {
                            if (!match) {
                                return false
                            }

                            if (match.url && match.url !== '' && match.url === item.navLink) {
                                if (location.pathname === item.navLink) {
                                    currentActiveItem = item.navLink
                                } else {
                                    currentActiveItem = location.pathname
                                }
                            }
                        }
                    })}
                /*eslint-enable */
                onClick={e => {
                    if (!item.navLink.length) {
                        e.preventDefault()
                    }
                    parentItem ? resetActiveGroup(item.navLink) : resetActiveAndOpenGroups()
                }}
            >
                {item.icon}
                <span className='menu-item text-truncate'>
          {item.title}
        </span>

                {item.badge && item.badgeText ? (
                    <Badge className='ml-auto mr-1' color={item.badge} pill>
                        {item.badgeText}
                    </Badge>
                ) : null}
            </LinkTag>
        </li>
    )
}

VerticalNavMenuLink.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string,
        navLink: PropTypes.string,
        externalLink: PropTypes.bool,
        newTab: PropTypes.bool,
        disabled: PropTypes.bool,
        icon: PropTypes.node,
        title: PropTypes.string,
        badge: PropTypes.string,
        badgeText: PropTypes.string,
        tourClass: PropTypes.string,
        children: PropTypes.node
    }),
    setGroupActive: PropTypes.func,
    activeItem: PropTypes.string,
    setActiveItem: PropTypes.func,
    setGroupOpen: PropTypes.func,
    toggleActiveGroup: PropTypes.func,
    parentItem: PropTypes.object,
    routerProps: PropTypes.object,
    currentActiveItem: PropTypes.string
}

export default VerticalNavMenuLink
