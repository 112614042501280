import React, {useEffect, useMemo, useState, useRef} from "react"
import {
    Row,
    Col,
    Input,
    Button,
    Card,
    CardBody,
    Badge,
    Table,
    CardTitle,
    Spinner,
    ModalHeader,
    Form,
    ModalBody,
    FormGroup,
    CustomInput,
    Label,
    ModalFooter,
    Modal
} from "reactstrap"
import msg from "@src/assets/images/msg.svg"
import {FaDownload} from "react-icons/fa"
import {FiMessageSquare} from "react-icons/fi"
import {Link, useParams} from "react-router-dom"
import ArrowLeft from "@src/assets/images/arrowLeft.svg"
import HomeIcon from "@src/assets/images/home.svg"
import BlankLogo from "@src/assets/images/avatars/Blank-logo.jpg"
import {
    campaignApp,
    campaignStatus,
    campaignStatusReset
} from "@store/actions/BrandActions"
import {useDispatch, useSelector} from "react-redux"
import Tiktok from "@src/assets/images/icons/tik-tok.svg"
import Pinterest from "@src/assets/images/icons/pinterest.svg"
import Web from "@src/assets/images/icons/web.svg"
import {
    Delete,
    Facebook, Folder,
    Instagram,
    Linkedin, Send,
    Twitter,
    Heart
} from "react-feather"
import {Formik, useFormik} from "formik"
import * as yup from "yup"
import classnames from "classnames"
import {
    campaignMsgAction,
    campaignMsgResetAction,
    campaignAddToFavAction,
    campaignAddToFavResetAction
} from "@src/views/brands/myCampaigns/store/actions"
import moment from "moment/moment"
import {
    createFavoriteFolder,
    getFavoriteFolders,
    nullResponse
} from '@src/redux/actions/brandsDashboard'
import {NULL} from '@src/redux/actionTypes'
import BlueArrow from "@src/assets/images/blueArrow.svg"
import {
    confirmAlert,
    successAlert
} from "./Controller"

const DetailView = () => {
    const {id} = useParams()

    const dispatch = useDispatch()

    const [selectedUser, setSelectedUser] = useState(null)
    const [isExpanded, setIsExpanded] = useState(false)
    const [showMsgModal, setShowMsgModal] = useState(false)
    const [rdIds, setRdIds] = useState([])
    const [selectAllApplicants, setSelectAllApplicants] = useState(false)
    const [isSelectFoldersModalOpen, setIsSelectFoldersModalOpen] = useState(false)
    const [campaignExportLoading, setCampaignExportLoading] = useState(false)
    const [campaignExportData, setCampaignExportData] = useState([])
    const [isFoldersLoading, setIsFoldersLoading] = useState(false)
    const [selectedFolder, setSelectedFolder] = useState(null)
    const [folderName, setFolderName] = useState('')
    const [folderNameError, setFolderNameError] = useState(false)
    const [isFolderCreating, setIsFolderCreating] = useState(false)
    const [isAddingToFavorite, setIsAddingToFavorite] = useState(false)

    const {
        campaignAppLoading,
        campaignAppData,
        campaignAppError,

        campaignStatusLoading,
        campaignStatusData,
        campaignStatusError
    } = useSelector(state => state.brandReducer)

    const {
        campaignMsgLoading,
        campaignMsgData,
        campaignMsgError
    } = useSelector((store) => store.campaignMsgReducer)

    const {
        campaignDetailExport
    } = useSelector((store) => store.brandsOppoprunities)

    const {
        favoriteFoldersRes,
        createfavoriteFolderRes,
        addToFavroiteRes
    } = useSelector(store => store.brandsDashboardSlice)

    const {
        campaignAddToFavLoading,
        campaignAddToFavMsgData,
        campaignAddToFavError
    } = useSelector((store) => store.campaignAddToFavReducer)

    const {campaign} = useMemo(() => {
        return {
            campaign: campaignAppData?.campaign ?? null
        }
    }, [campaignAppData])

    useEffect(() => {
        if (campaignMsgData?.status) {
            successAlert(campaignMsgData?.msg)
            setShowMsgModal(false)
            setRdIds([])
            setSelectAllApplicants(false)
            dispatch(campaignMsgResetAction())
        }

        if (campaignStatusData?.status) {
            successAlert(campaignStatusData?.msg)
            dispatch(campaignStatusReset())
            dispatch(campaignApp({
                campaign_id: id
            }))
        }

        if (createfavoriteFolderRes) {
            setFolderName('')
            setIsFolderCreating(false)
            dispatch(nullResponse(NULL.BRANDS_GET_FOLDERS))
            dispatch(nullResponse(NULL.BRANDS_CREATE_FOLDER))
            setIsFoldersLoading(true)
            dispatch(getFavoriteFolders(false))
        }

        if (favoriteFoldersRes) {
            setIsFoldersLoading(false)
        }

        if (campaignAddToFavMsgData?.status) {
            successAlert(campaignAddToFavMsgData?.msg)
            setIsSelectFoldersModalOpen(false)
            setSelectedFolder(null)
            setSelectAllApplicants(false)
            setRdIds([])
            dispatch(campaignAddToFavResetAction())
        }
    }, [
        campaignMsgData,
        campaignStatusData,
        createfavoriteFolderRes,
        favoriteFoldersRes,
        campaignAddToFavMsgData
    ])

    useEffect(() => {
        if (campaignDetailExport?.data) {
            setCampaignExportData(campaignDetailExport?.data)
            setCampaignExportLoading(false)
        }
    }, [campaignDetailExport])

    useEffect(() => {
        setCampaignExportData([])
        dispatch(campaignApp({
            campaign_id: id,
            query: ""
        }))
        dispatch(getFavoriteFolders(false))
    }, [])

    const escapeCsvValue = (value) => {
        if (typeof value === 'string') {
            value = value.replace(/"/g, '""')
        }
        return `"${value}"`
    }

    const handleSubmissionClick = (user) => {
        setSelectedUser(user)
        setIsExpanded(true) // Expand tbody when a submission is clicked
    }

    const convertArrayOfObjectsToCSV = () => {
        let csvContent = ''
        const cData = campaignAppData?.data
        const columns = [
            'Submission Date',
            'Dietitian Name',
            'Country',
            'Region',
            'States',
            'Cities',
            'Industry',
            'Speciality',
            'Website',
            'Twitter',
            'Tiktok',
            'Linkedin',
            'Facebook',
            'Campaign Title'
        ]

        if (campaign?.is_mailing_address) {
            columns.push('First Name', 'Last Name', 'Street Address 1', 'Street Address 2', 'City', 'State', 'Zipcode')
        }

        if (campaign?.question?.length > 0) {
            campaign?.question.forEach((q) => {
                columns.push(escapeCsvValue(q))
            })
        }

        csvContent = columns.join(',')
        csvContent += "\n"

        cData?.forEach((item) => {
            const {rd_question_answer, get_rd_profile, get_campaign_mailing_address} = item

            csvContent += `${escapeCsvValue(moment(rd_question_answer?.created_at).format('DD MMM, YYYY'))},`
            csvContent += `${escapeCsvValue(item?.name)},`
            csvContent += get_rd_profile?.get_country?.name ? `${escapeCsvValue(get_rd_profile?.get_country?.name)},` : ","
            csvContent += get_rd_profile?.region?.region ? `${escapeCsvValue(get_rd_profile?.region?.region)},` : ","
            csvContent += item?.user_states ? `${escapeCsvValue(item?.user_states?.map(e => e?.states?.name).join(" - "))},` : ","
            csvContent += item?.user_cities ? `${escapeCsvValue(item?.user_cities?.map(e => e?.cities?.name).join(" - "))},` : ","
            csvContent += get_rd_profile?.get_rd_indusry ? `${escapeCsvValue(get_rd_profile?.get_rd_indusry?.map(e => e?.get_indusry?.name).join(" - "))},` : ","
            csvContent += get_rd_profile?.get_rd_speciality ? `${escapeCsvValue(get_rd_profile?.get_rd_speciality?.map(e => e?.get_speciality?.name).join(" - "))},` : ","
            csvContent += `${escapeCsvValue(get_rd_profile?.website ?? '')},`
            csvContent += `${escapeCsvValue(get_rd_profile?.twitter_url ?? '')},`
            csvContent += `${escapeCsvValue(get_rd_profile?.tiktok_url ?? '')},`
            csvContent += `${escapeCsvValue(get_rd_profile?.linkedin_url ?? '')},`
            csvContent += `${escapeCsvValue(get_rd_profile?.facebook_url ?? '')},`
            csvContent += `${escapeCsvValue(campaign?.campaign_title)},`

            if (campaign?.is_mailing_address) {
                csvContent += `${escapeCsvValue(get_campaign_mailing_address?.first_name ?? '')},`
                csvContent += `${escapeCsvValue(get_campaign_mailing_address?.last_name ?? '')},`
                csvContent += `${escapeCsvValue(get_campaign_mailing_address?.street_address_1 ?? '')},`
                csvContent += `${escapeCsvValue(get_campaign_mailing_address?.street_address_2 ?? '')},`
                csvContent += `${escapeCsvValue(get_campaign_mailing_address?.city ?? '')},`
                csvContent += `${escapeCsvValue(get_campaign_mailing_address?.state ?? '')},`
                csvContent += `${escapeCsvValue(get_campaign_mailing_address?.zipcode ?? '')},`
            }

            if (campaign?.question?.length > 0) {
                rd_question_answer?.forEach((form) => {
                    const {get_question, get_correct_option, answer_text} = form
                    if (get_question?.type === 'multiple') {
                        csvContent += `${escapeCsvValue(get_correct_option?.answer)},`
                    } else if (get_question?.type === 'textField') {
                        csvContent += `${escapeCsvValue(answer_text)},`
                    }
                })
            }
            csvContent += "\n"
        })
        return csvContent
    }

    const downloadCSV = () => {
        const csv = convertArrayOfObjectsToCSV()
        if (csv) {
            const filename = 'List-Of-Applied-Users-On-Campaign.csv'
            const csvContent = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csv)}`
            const link = document.createElement('a')
            link.setAttribute('href', csvContent)
            link.setAttribute('download', filename)
            link.click()
        }
    }

    const handleSelectAllCheckBox = (e) => {
        const {checked} = e.target
        if (checked) {
            const data = campaignAppData?.data?.map((val) => {
                return {id: `${val?.id}`}
            })
            setRdIds(data)
        } else {
            setRdIds([])
        }
        setSelectAllApplicants(checked)
    }

    const handleCheckBox = (e) => {
        const {value} = e.target
        const find = rdIds.find(item => item.id === value)
        if (find) {
            setRdIds(prevState => (prevState.filter(item => item.id !== value)))
        } else {
            setRdIds(prevState => [...prevState, {id: value}])
        }
        setSelectAllApplicants(false)
    }

    const sendMessage = (data) => {
        const date = new Date()
        const localData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))
        const rds = []
        const obj = {
            from_user_id: localData?.chat_id,
            message: data.message_content,
            timezone: date.toString(),
            target: 'campaign'
        }
        if (rdIds.length > 0) {
            rdIds.forEach((value, index) => {
                rds.push(value.id)
            })
            const data = {...obj, to_users: rds}
            dispatch(campaignMsgAction(data))
        }
    }

    const updateStatus = async (id) => {
        const isConfirmed = await confirmAlert('Status?', 'Do you really want to update the status?')
        if (isConfirmed) {
            dispatch(campaignStatus({
                campaign_id: id
            }))
        }
    }

    const handleSelectFolder = (folder) => {
        setSelectedFolder(folder)
    }

    const handleCreateFolder = () => {
        if (!folderName) {
            setFolderNameError(true)
            return
        }
        setFolderNameError(false)
        setIsFolderCreating(true)
        dispatch(createFavoriteFolder({folderName}))
    }

    const handleAddToFavorite = () => {
        const obj = []
        rdIds.forEach((value) => {
            obj.push({id: value.id, folder_id: selectedFolder?.id})
        })
        dispatch(campaignAddToFavAction({data: obj}))
    }

    const form = useFormik({
        initialValues: {
            query: ''
        },
        validationSchema: yup.object().shape({
            query: yup.string()
        }),
        onSubmit: (values) => {
            dispatch(campaignApp({
                campaign_id: id,
                query: values.query
            }))
        }
    })

    return (
        <>
            <div className="d-flex align-items-center justify-content-between pb-2">
                <div className="d-flex align-items-center">
                    <Link to="/brands/outreach-posts">
                        <img className="pr-1" src={ArrowLeft} alt="Arrow Left"/>
                    </Link>
                    <h2 className="m-0 font-inter inter-medium text-dark">
                        {campaign?.campaign_title ? `${campaign?.campaign_title} - ` : ''} Submissions
                    </h2>
                </div>
                <div className="d-flex align-items-center">
                    <img src={HomeIcon} alt="Home"/>
                    <div className="px-1">/</div>
                    <div className="font-inter inter-regular">
                        My Outreach
                    </div>
                </div>
            </div>
            <Card className="p-1" tag='div'>
                <div className="my-1 d-flex justify-content-between mb-2">
                    <div>
                        <Form onSubmit={form.handleSubmit}>
                            <FormGroup>
                                <Input type="search"
                                       placeholder="Type name and Press Enter..."
                                       className="search-input-width"
                                       value={form.values.query}
                                       onBlur={form.handleBlur}
                                       onChange={form.handleChange}
                                       name="query"
                                       onInput={(e) => {
                                           if (e.target.value === '') {
                                               form.handleSubmit()
                                           }
                                       }}
                                />
                            </FormGroup>
                        </Form>
                    </div>
                    <div>
                        <Button outline
                                className='mr-1 outline-Btn mr-2 font-inter inter-medium'
                                onClick={() => setShowMsgModal(true)}
                                disabled={campaignAppLoading || campaignAppData?.data?.length === 0 || campaignMsgLoading}
                        >
                            <FiMessageSquare className="mr-25"/> Send message
                        </Button>
                        <button className='btn btn-light font-inter inter-medium btn_color'
                                onClick={() => downloadCSV()}
                                disabled={campaignAppLoading || campaignAppData?.data?.length === 0 || campaignMsgLoading}
                        >
                            <FaDownload className="mr-25"/> Download CSV
                        </button>
                        <Button className="outline-Btn ml-2"
                                onClick={() => setIsSelectFoldersModalOpen(true)}
                                disabled={campaignExportLoading || campaignAppLoading || campaignAppData?.data?.length === 0 || campaignMsgLoading}
                                outline
                        >
                            {campaignExportLoading ? (
                                <>
                                    <Spinner className="loader-small" /> Add to Favorite
                                </>
                            ) : (
                                <>
                                    <Heart size={14} className="mr-25"/> Add to Favorite
                                </>
                            )}
                        </Button>
                    </div>
                </div>
                {campaign?.campaign_title && (
                    <Card tag='div'>
                        <CardBody className="card-border" tag='div'>
                            <div className="d-flex align-items-center justify-content-between">
                                <div md={6}>
                                    <h5 className="font-inter inter-medium">
                                        {campaign?.campaign_title}
                                    </h5>
                                </div>

                                <div md={6} className="text-end">
                                    <div className="d-flex align-items-center">
                                        {campaign?.tag === 'Disabled' && (
                                            <Badge color="danger">{campaign?.tag}</Badge>
                                        )}

                                        {campaign?.tag === 'Expired' && (
                                            <Badge color="danger">{campaign?.tag}</Badge>
                                        )}

                                        {(campaign?.tag === 'Live' || campaign?.tag === 'Completed') && (
                                            <Badge color="success">{campaign?.tag}</Badge>
                                        )}

                                        {campaign?.tag === 'Draft' && (
                                            <Badge color="warning">{campaign?.tag}</Badge>
                                        )}

                                        {campaign?.tag === 'Scheduled' && (
                                            <Badge color="info">{campaign?.tag}</Badge>
                                        )}
                                        <CustomInput
                                            className={(campaign?.tag === 'Draft' || campaign?.tag === 'Scheduled' || campaign?.tag === 'Expired' || campaign?.tag === 'Completed') ? 'custom-control-warning campaign-switch custom-switch-sm ml-2 mr-1' : 'custom-control-success campaign-switch custom-switch-sm ml-2 mr-1'}
                                            type='switch'
                                            id='status'
                                            name='success'
                                            checked={campaign?.status === 'enabled'}
                                            onClick={() => updateStatus(campaign?.id)}
                                            onChange={() => {
                                            }}
                                            disabled={campaignStatusLoading}
                                        />
                                        <strong>Expires on: {campaign?.expire_after ?? 'No Expiry'}</strong>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <Row className="text-center">
                                <Col>
                                    <h5 className="font-inter inter-medium">{campaign?.engagement}</h5>
                                    <p className="text-muted font-inter inter-regular">Engagement</p>
                                </Col>
                                <Col>
                                    <h5 className="font-inter inter-medium">{campaign?.clicked}</h5>
                                    <p className="text-muted font-inter inter-regular">Clicked</p>
                                </Col>
                                <Col>
                                    <h5 className="font-inter inter-medium">{campaign?.converted}</h5>
                                    <p className="text-muted font-inter inter-regular">Converted</p>
                                </Col>
                                <Col>
                                    <h5 className="font-inter inter-medium">{campaign?.invited}</h5>
                                    <p className="text-muted font-inter inter-regular">RDs invited</p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                )}
                <Row>
                    <Col md={7}>
                        <div className="submission-table-container">
                            <Table>
                            <thead>
                            <tr>
                                <th className="font-inter inter-medium">Submission Date</th>
                                <th className="font-inter inter-medium">RD Name</th>
                                <th className="font-inter inter-medium">Actions</th>
                            </tr>
                            </thead>
                            <tbody   className={`tbody ${isExpanded ? "expanded" : ""}`}>
                            {(campaignAppLoading || campaignStatusLoading) ? (
                                <tr>
                                    <td colSpan='4' className='text-center text-primary font-inter inter-regular'><Spinner className="loader-md"/> Please wait...
                                    </td>
                                </tr>
                            ) : (
                                campaignAppData?.data?.length > 0 ? (
                                    campaignAppData?.data?.map((user, i) => (
                                        <tr key={user.id}>
                                            <td className="font-inter inter-regular">
                                                {user?.rd_question_answer[0]?.created_at ? moment(user?.rd_question_answer[0]?.created_at).format('MMM DD, YYYY') : '-'}
                                            </td>
                                            <td className="font-inter inter-regular">{user.name}</td>
                                            <td className="font-inter inter-regular">
                                                <Button className="p-0" color="link" onClick={() => handleSubmissionClick(user)}><span className={`view-submission ${selectedUser === user ? 'font-weight-bold' : ''}`}>View Submission </span><img
                                                    src={BlueArrow} alt="Blue Arrow"/></Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan='8' className='text-center font-inter inter-medium'>No application found</td>
                                    </tr>
                                )
                            )}
                            </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col md={5}>
                        <Card tag='div'>
                            <CardBody tag='div'>
                                {selectedUser ? (
                                    <>
                                        <Card className="card-border" tag='div'>
                                            <div className="d-flex flex-column">
                                                <div className="d-flex align-items-center justify-content-between mb-2">
                                                    <div className="d-flex align-items-center">
                                                        <img src={selectedUser?.profile_image || BlankLogo} alt="Profile" className="detailviewprofileImg"/>
                                                        <CardTitle tag="h5" className="m-0 font-inter inter-medium">{selectedUser.name}</CardTitle>
                                                    </div>
                                                    <Link to={`/brands/apps/chat/chatBrands/${selectedUser?.id}`}>
                                                        <img src={msg} alt="msg" className=""/>
                                                    </Link>
                                                </div>
                                                <div className="ml-1">
                                                    <h3 className="mb-2 font-inter inter-medium">Personal Information</h3>
                                                    <Row>
                                                        <Col md={6}>
                                                            <p className="font-inter inter-medium">Name</p>
                                                            <p className="font-inter inter-regular">{selectedUser.name}</p>
                                                        </Col>
                                                        <Col md={6}>
                                                            <p className="font-inter inter-medium">Location</p>
                                                            <p className="font-inter inter-regular">
                                                                <span>{selectedUser?.get_rd_profile?.get_country?.name}</span>
                                                                {selectedUser?.get_rd_profile?.region?.region && (
                                                                    <span>, {selectedUser?.get_rd_profile?.region?.region}</span>
                                                                )}
                                                                {selectedUser?.user_states && selectedUser?.user_states?.map((locate, i) => (
                                                                    locate?.states?.name && (
                                                                        <span key={i}>, {locate?.states?.name}</span>
                                                                    )
                                                                ))}
                                                            </p>
                                                        </Col>
                                                        <Col md={6}>
                                                            <p className="font-inter inter-medium">Industry</p>
                                                            <p className="font-inter inter-regular d-flex flex-wrap">
                                                                {selectedUser?.get_rd_profile?.get_rd_indusry && selectedUser?.get_rd_profile?.get_rd_indusry?.map((industry, i) => (
                                                                    i === 0 ? (
                                                                        <span className="industry-tag m-25 font-inter inter-regular" key={i}>{industry?.get_indusry?.name}</span>
                                                                    ) : (
                                                                        <span className="industry-tag m-25 font-inter inter-regular" key={i}>{industry?.get_indusry?.name}</span>
                                                                    )
                                                                ))}
                                                            </p>
                                                        </Col>
                                                        <Col md={6}>
                                                            <p className="font-inter inter-medium">Speciality</p>
                                                            <p className="font-inter inter-regular">
                                                                {selectedUser?.get_rd_profile?.get_rd_speciality && selectedUser?.get_rd_profile?.get_rd_speciality?.map((special, i) => (
                                                                    i === 0 ? (
                                                                        <span key={i}>{special?.get_speciality?.name}</span>
                                                                    ) : (
                                                                        <span key={i}>, {special?.get_speciality?.name}</span>
                                                                    )
                                                                ))}
                                                            </p>
                                                        </Col>
                                                        <Col md={6}>
                                                            <p className="font-inter inter-medium">Social Media</p>
                                                            <p className="font-inter inter-regular">
                                                                {selectedUser?.get_rd_profile?.website &&
                                                                    <a target="_blank" href={selectedUser?.get_rd_profile?.website || "#"} className="mr-1">
                                                                        <img src={Web} alt={Web} width="20" height="20"/>
                                                                    </a>}
                                                                {selectedUser?.get_rd_profile?.tiktok_url &&
                                                                    <a target="_blank" href={selectedUser?.get_rd_profile?.tiktok_url || "#"} className="mr-1">
                                                                        <img src={Tiktok} alt={Tiktok} width="20" height="20"/>
                                                                    </a>}
                                                                {selectedUser?.get_rd_profile?.pinterest_url &&
                                                                    <a target="_blank" href={selectedUser?.get_rd_profile?.pinterest_url || "#"} className="mr-1">
                                                                        <img src={Pinterest} alt={Pinterest} width="20" height="20"/>
                                                                    </a>}
                                                                {selectedUser?.get_rd_profile?.instagram_url &&
                                                                    <a target="_blank" href={selectedUser?.get_rd_profile?.instagram_url || "#"} className="mr-1">
                                                                        <Instagram size="20"/>
                                                                    </a>}
                                                                {selectedUser?.get_rd_profile?.facebook_url &&
                                                                    <a target="_blank" href={selectedUser?.get_rd_profile?.facebook_url || "#"} className="mr-1">
                                                                        <Facebook size="20"/>
                                                                    </a>}
                                                                {selectedUser?.get_rd_profile?.twitter_url &&
                                                                    <a target="_blank" href={selectedUser?.get_rd_profile?.twitter_url || "#"} className="mr-1">
                                                                        <Twitter size="20"/>
                                                                    </a>}
                                                                {selectedUser?.get_rd_profile?.linkedin_url &&
                                                                    <a target="_blank" href={selectedUser?.get_rd_profile?.linkedin_url || "#"}>
                                                                        <Linkedin size="20"/>
                                                                    </a>}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Card>
                                        {selectedUser?.rd_question_answer?.length > 0 && (
                                            <Card className="card-border info-card p-2" tag='div'>
                                                {selectedUser?.get_campaign_mailing_address && (
                                                    <div className="mb-50">
                                                        <h3 className="mb-2 font-inter inter-medium dark_text mailing-txt">Mailing Address</h3>
                                                        <div className='mb-1'>
                                                            <div className='d-flex'>
                                                                {/* <div className='mr-1 font-inter inter-medium'><strong>1: </strong></div>
                                                                <strong className="font-inter inter-medium">First Name: </strong>
                                                                <span className='ml-1 font-inter inter-regular'>{selectedUser?.get_campaign_mailing_address?.first_name}</span> */}
                                                                <ul className="pl-1 mb-0 dark_text">
                                                                    <li>
                                                                        <strong className="font-inter inter-medium">First Name: </strong>
                                                                        <span
                                                                            className='ml-1 font-inter inter-regular'>{selectedUser?.get_campaign_mailing_address?.first_name}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className='mb-1'>
                                                            <div className='d-flex'>
                                                                <ul className="pl-1 mb-0 dark_text">
                                                                    <li>
                                                                        <strong className="font-inter inter-medium">Last Name: </strong>
                                                                        <span
                                                                            className='ml-1 font-inter inter-regular'>{selectedUser?.get_campaign_mailing_address?.last_name}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className='mb-1'>
                                                            <div className='d-flex'>
                                                                <ul className="pl-1 mb-0 dark_text">
                                                                    <li>
                                                                        <strong className="font-inter inter-medium">Street Address 1: </strong>
                                                                        <span
                                                                            className='ml-1 font-inter inter-regular'>{selectedUser?.get_campaign_mailing_address?.street_address_1}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className='mb-1'>
                                                            <div className='d-flex'>
                                                                <ul className="pl-1 mb-0 dark_text">
                                                                    <li>
                                                                        <strong className="font-inter inter-medium">Street Address 2: </strong>
                                                                        <span
                                                                            className='ml-1 font-inter inter-regular'>{selectedUser?.get_campaign_mailing_address?.street_address_2}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className='mb-1'>
                                                            <div className='d-flex'>
                                                                <ul className="pl-1 mb-0 dark_text">
                                                                    <li>
                                                                        <strong className="font-inter inter-medium">City: </strong>
                                                                        <span className='ml-1 font-inter inter-regular'>{selectedUser?.get_campaign_mailing_address?.city}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className='mb-1'>
                                                            <div className='d-flex'>
                                                                <ul className="pl-1 mb-0 dark_text">
                                                                    <li>
                                                                        <strong className="font-inter inter-medium">Zipcode: </strong>
                                                                        <span className='ml-1 font-inter inter-regular'>{selectedUser?.get_campaign_mailing_address?.zipcode}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {selectedUser?.rd_question_answer?.some(value => value?.question_id > 0) && (
                                                    <h3 className="mb-2 font-inter inter-medium dark_text mailing-txt">Questions:</h3>
                                                )}
                                                {selectedUser?.rd_question_answer?.filter(x => x.question_id > 0).map((answer, i) => (
                                                    answer.question_id > 0 && (
                                                        <div>
                                                            <div className='d-flex'>
                                                                <div className='font-inter inter-medium dark_text count-col'><strong>{i + 1}: </strong></div>
                                                                <div className='question-col'>
                                                                <div className="font-inter inter-medium dark_text word-break mb-25"
                                                                    dangerouslySetInnerHTML={{__html: answer?.get_question?.question}}></div>
                                                                <p>
                                                                        <div className=""></div>
                                                                        {answer?.get_question?.type === 'multiple' && (
                                                                            <span
                                                                                className='font-inter inter-regular dark_text word-break'>{answer?.get_correct_option?.answer}</span>
                                                                        )}
                                                                        {answer?.get_question?.type === 'textField' && (
                                                                            <span className='font-inter inter-regular dark_text word-break'>{answer?.answer_text}</span>
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )))}
                                            </Card>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <p className="card-border p-2 font-inter inter-regular">No User Selected</p>
                                        <div className="card-border p-2">
                                            <div className="">
                                                <Card tag='div'>
                                                    <div className="bg-light rounded-circle skeleton-loader-img"></div>
                                                    <div className="bg-light rounded mt-3 rounded-pill"></div>
                                                    <div className="bg-light rounded mt-2 rounded-sm"></div>
                                                    <div className="d-flex mt-2">
                                                        <div className="bg-light rounded me-2 rounded-md"></div>
                                                        <div className="bg-light rounded rounded-md"></div>
                                                    </div>
                                                </Card>
                                                <div className="card-border p-2">
                                                    <Card tag='div'>
                                                        <div className="bg-light rounded mb-2 rounded-lg1"></div>
                                                        <div className="bg-light rounded mb-2 rounded-sm"></div>
                                                        <div className="bg-light rounded mb-2 rounded-lg2"></div>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Card>
            {/* --- Send Message Modal --- */}
            <Modal size="lg" isOpen={showMsgModal} className="modal-dialog-centered">
                <ModalHeader className="font-inter inter-medium">Send Message</ModalHeader>
                <Formik
                    initialValues={{message_content: ""}}
                    validationSchema={yup.object().shape({message_content: yup.string().required("This field is required")})}
                    onSubmit={(values) => {
                        sendMessage(values)
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          touched,
                          values
                      }) => (
                        <Form className='auth-reset-password-form mt-2' onSubmit={handleSubmit}>
                            <ModalBody>
                                <FormGroup tag={Col} md="12" className="d-flex justify-content-between">
                                    <CustomInput key="select-all-applicants"
                                                 type='checkbox'
                                                 className="font-inter inter-medium"
                                                 name="select-all-applicants"
                                                 id="select-all-applicants"
                                                 label="Select All"
                                                 checked={selectAllApplicants}
                                                 onChange={handleSelectAllCheckBox}
                                    />
                                    <span className="badge badge-success badge-sm font-inter inter-medium">
                                        Count: {rdIds.length}
                                    </span>
                                </FormGroup>
                                <FormGroup tag={Col} md="12">
                                    <Row className="bg-light pt-1 row-height">
                                        {campaignAppData?.data?.length > 0 && campaignAppData?.data?.map((val, index) => (
                                            <Col md="4" key={index}>
                                                <CustomInput
                                                    key={index}
                                                    type='checkbox'
                                                    className="mb-1 font-inter inter-medium"
                                                    name={`check-${val?.id}`}
                                                    id={`check-${val?.id}`}
                                                    label={val?.name}
                                                    value={val?.id}
                                                    onChange={handleCheckBox}
                                                    checked={rdIds.find(item => item.id === `${val?.id}`)}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </FormGroup>
                                <FormGroup tag={Col} md="12">
                                    <Label className={classnames({
                                        'is-invalid font-inter inter-medium': touched.message_content && errors.message_content,
                                        'form-label': true
                                    })} htmlFor='message_content'> Message<span className="is-invalid">*</span></Label>
                                    <Input rows="5" type="textarea" name={`message_content`}
                                           id="message_content"
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           className={classnames({
                                               'is-invalid font-inter inter-medium': touched.message_content && errors.message_content
                                           })}
                                           disabled={campaignMsgLoading}
                                    />
                                    <div
                                        className="validation-err font-inter inter-regular">{touched.message_content && errors.message_content}</div>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter className="d-flex justify-content-between">
                                <Button className="font-inter inter-medium" color="danger" onClick={() => {
                                    setRdIds([])
                                    setSelectAllApplicants(false)
                                    setShowMsgModal(false)
                                }}>

                                    <Delete size={14}/> Close
                                </Button>
                                <Button className="font-inter inter-medium" color='primary' type='submit'
                                        disabled={campaignMsgLoading || rdIds.length === 0}>
                                    {campaignMsgLoading ? (
                                        <>
                                            <Spinner className="loader-sm"/> Send
                                        </>
                                    ) : (
                                        <>
                                            <Send size={14}/> Send
                                        </>
                                    )}
                                </Button>
                            </ModalFooter>
                        </Form>)}
                </Formik>
            </Modal>

            {/* --- Add To Folder Modal --- */}
            <Modal scrollable isOpen={isSelectFoldersModalOpen} className='modal-dialog-centered' size="lg">
                <ModalHeader>Add To Favorite Folder</ModalHeader>
                <ModalBody>
                    <FormGroup tag={Col} md="12" className="d-flex justify-content-between">
                        <CustomInput
                            key="select-all-applicants"
                            type='checkbox'
                            className="font-weight-bold"
                            name="select-all-applicants"
                            id="select-all-applicants"
                            label="Select All"
                            value={selectAllApplicants}
                            checked={selectAllApplicants}
                            onChange={handleSelectAllCheckBox}
                            disabled={campaignAddToFavLoading}
                        />
                        <span className="font-weight-bold badge badge-success badge-sm">
                            Count: {rdIds.length}
                        </span>
                    </FormGroup>
                    <FormGroup tag={Col} md="12">
                        <Row className="row-height bg-light pt-1">
                            {campaignAppData?.data?.length > 0 && campaignAppData?.data?.map((val, index) => (
                                <Col md="4" key={index}>
                                    <CustomInput
                                        key={index}
                                        type='checkbox'
                                        className="mb-1"
                                        name={`check-${val?.id}`}
                                        id={`check-${val?.id}`}
                                        label={val?.name}
                                        value={val?.id}
                                        onChange={handleCheckBox}
                                        checked={rdIds.find(item => item.id === `${val?.id}`)}
                                        disabled={campaignAddToFavLoading}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </FormGroup>
                    <FormGroup tag={Col} md="12">
                        <Label className="form-label"> Please select a folder<span
                            className="is-invalid">*</span></Label>
                    </FormGroup>
                    {isFoldersLoading ? (
                        <div className="w-100 my-4 text-center"><Spinner color='primary'/></div>
                    ) : (
                        <div className="p-1 row-height">
                            {favoriteFoldersRes?.data?.map(item => (
                                <Card className={classnames({
                                    'ecommerce-card': true,
                                    'mb-1': true,
                                    'border-1px-white': selectedFolder?.id !== item.id,
                                    'cursor-pointer': true,
                                    'border-1px-purple': selectedFolder?.id === item.id
                                })} key={item.folder_name} onClick={() => handleSelectFolder(item)} tag='div'>
                                    <CardBody className="d-flex align-items-center justify-content-between" tag='div'>
                                        <div className="d-flex align-items-center">
                                            <Folder
                                                className='mr-1 text-secondary'
                                                size={24}
                                            />
                                            <h5 className='mb-0'>{item.folder_name}</h5>
                                        </div>
                                    </CardBody>
                                </Card>
                            ))}
                        </div>
                    )}
                    <p className="text-center">OR</p>
                    <div className='form-group'>
                        <Label className={classnames({
                            'form-label': true
                        })} htmlFor={`folderName`}>
                            Folder Name<span className="is-invalid">*</span>
                        </Label>
                        <div className="d-flex justify-content-between">
                            <Input type='text'
                                   onChange={(e) => {
                                       setFolderName(e.target.value)
                                       setFolderNameError(false)
                                   }}
                                   value={folderName}
                                   name={`folderName`}
                                   id={`folderName`}
                                   className={classnames('mr-1', {'is-invalid': folderNameError})}
                                   disabled={campaignAddToFavLoading}
                            />
                            <Button color='primary' disabled={isFolderCreating || campaignAddToFavLoading} onClick={handleCreateFolder}
                                    className="text-nowrap">
                                {isFolderCreating ? (
                                    <>
                                        <Spinner className="loader-sm"/> Create Folder
                                    </>
                                ) : (
                                    <>
                                        <Folder size={14}/> Create Folder
                                    </>
                                )}
                            </Button>
                        </div>

                        {folderNameError && <div className="validation-err">This field is required</div>}
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between">
                    <Button color='danger' onClick={() => {
                        setSelectAllApplicants(false)
                        setSelectedFolder(null)
                        setRdIds([])
                        setIsSelectFoldersModalOpen(false)
                    }}>
                        <Delete size={14}/> Cancel
                    </Button>
                    <Button color='primary' disabled={isAddingToFavorite || !selectedFolder?.id || rdIds.length === 0 || campaignAddToFavLoading}
                            onClick={() => handleAddToFavorite()}>
                        {campaignAddToFavLoading ? (
                            <>
                                <Spinner className="loader-sm"/> Add to Favorite
                            </>
                        ) : (
                            <>
                                <Folder size={14}/> Add to Favorite
                            </>
                        )}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default DetailView
