import axios from "axios"
import {BRAND_ACTION_TYPES} from "@store/actionTypes/BrandActionTypes"

const API_URL = `${process.env.REACT_APP_BASEURL}brand`

const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const saveCreditCard = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/save-card`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_ERROR, payload: error})
    }
}

export const saveCreditCardReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_RESET})
}

export const verifyCouponCode = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/verify-coupon`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_ERROR, payload: error})
    }
}

export const verifyCouponCodeReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_RESET})
}

export const activatePlan = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.ACTIVATE_PLAN_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/get-plan`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.ACTIVATE_PLAN_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.ACTIVATE_PLAN_ERROR, payload: error})
    }
}

export const activatePlanReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.ACTIVATE_PLAN_RESET})
}

export const viewProfile = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.VIEW_PROFILE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/view-profile`, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.VIEW_PROFILE_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.VIEW_PROFILE_ERROR, payload: error})
    }
}

export const saveProfile = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.SAVE_PROFILE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/update-profile`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.SAVE_PROFILE_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.SAVE_PROFILE_ERROR, payload: error})
    }
}

export const saveProfileReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.SAVE_PROFILE_RESET})
}

export const rdGroupList = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.RD_GROUP_LIST_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/all-rds-list`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.RD_GROUP_LIST_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.RD_GROUP_LIST_ERROR, payload: error})
    }
}

export const rdGroupListReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.RD_GROUP_LIST_RESET})
}

export const rdIgData = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.RD_IG_DATA_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/facebook-ig-data`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.RD_IG_DATA_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.RD_IG_DATA_ERROR, payload: error})
    }
}

export const copyCampaign = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/copy-campaign`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_ERROR, payload: error})
    }
}

export const copyCampaignReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_RESET})
}

export const massMessageTempSave = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/save-msg-template`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_ERROR, payload: error})
    }
}

export const massMessageTempSaveReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_RESET})
}

export const massMessageTempLoad = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_LIST_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/load-msg-template${dataObj}`, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_LIST_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_LIST_ERROR, payload: error})
    }
}

export const massMessageTempDelete = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/delete-msg-template`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_ERROR, payload: error})
    }
}

export const massMessageTempDeleteReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_RESET})
}

export const markAsRead = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MARK_AS_READ_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/mark-as-read`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.MARK_AS_READ_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.MARK_AS_READ_ERROR, payload: error})
    }
}

export const markAsReadReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MARK_AS_READ_RESET})
}

export const campaignTypes = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_TYPE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/campaign-types`, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_TYPE_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_TYPE_ERROR, payload: error})
    }
}

export const allCampaigns = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_LISTING_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/my-campaign`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_LISTING_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_LISTING_ERROR, payload: error})
    }
}

export const campaignSave = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_SAVE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/save-campaign-v2`, dataObj, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_SAVE_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_SAVE_ERROR, payload: error})
    }
}

export const deleteCampaign = (campaignId) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.DELETE_CAMPAIGN_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    const payload = {campaign_id: campaignId}
    try {
        const {data} = await axios.post(`${API_URL}/delete-campaign`, payload, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.DELETE_CAMPAIGN_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.DELETE_CAMPAIGN_ERROR, payload: error})
    }
}

export const deleteCampaignReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.DELETE_CAMPAIGN_RESET})
}

export const viewCampaign = (params) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_VIEW_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/view-campaign-v2${params}`, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_VIEW_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_VIEW_ERROR, payload: error})
    }
}

export const viewCampaignReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_VIEW_RESET})
}

export const campaignSaveReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_SAVE_RESET})
}

export const recRDCampaign = (params) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.REC_RD_CAMPAIGN_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/nutrition-expert`, params, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.REC_RD_CAMPAIGN_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.REC_RD_CAMPAIGN_ERROR, payload: error})
    }
}

export const recRDCampaignReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.REC_RD_CAMPAIGN_RESET})
}

export const secPubCampaign = (params) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.SEC_PUB_CAMPAIGN_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/publish-campaign-v2`, params, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.SEC_PUB_CAMPAIGN_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.SEC_PUB_CAMPAIGN_ERROR, payload: error})
    }
}

export const secPubCampaignReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.SEC_PUB_CAMPAIGN_RESET})
}

export const campaignApp = (params) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_APP_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/get-rds-against-campaign-v2`, params, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_APP_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_APP_ERROR, payload: error})
    }
}

export const copyCampaignRow = (campaignId) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/copy-campaign-v2${campaignId}`, payload, {headers: header})
        dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_ERROR, payload: error})
    }
}

export const copyCampaignV2 = (campaignId) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_V2_REQUEST})
    const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/copy-campaign-v2`, {campaign_id: campaignId}, {headers})
        dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_V2_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_V2_ERROR, payload: error})
    }
}

export const copyCampaignV2Reset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.COPY_CAMPAIGN_V2_RESET})
}

export const campaignStatus = (dataObj) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_STATUS_REQUEST})
    const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/campaign-status`, dataObj, {headers})
        dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_STATUS_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_STATUS_ERROR, payload: error})
    }
}

export const campaignStatusReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.CAMPAIGN_STATUS_RESET})
}

export const getFavFolders = (params) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.GET_FAV_FOLDER_REQUEST})
    const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/load-folder-list${params}`, {headers})
        dispatch({type: BRAND_ACTION_TYPES.GET_FAV_FOLDER_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.GET_FAV_FOLDER_ERROR, payload: error})
    }
}

export const getFavFoldersReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.GET_FAV_FOLDER_RESET})
}

export const getFavProfiles = (params) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.GET_FAV_PROFILE_REQUEST})
    const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/favorites-profiles${params}`, {headers})
        dispatch({type: BRAND_ACTION_TYPES.GET_FAV_PROFILE_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.GET_FAV_PROFILE_ERROR, payload: error})
    }
}

export const getFavProfilesReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.GET_FAV_PROFILE_RESET})
}

export const getMyRds = (params) => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MY_RDS_REQUEST})
    const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/my-rds`, params, {headers})
        dispatch({type: BRAND_ACTION_TYPES.MY_RDS_DATA, payload: data})
    } catch (error) {
        dispatch({type: BRAND_ACTION_TYPES.MY_RDS_ERROR, payload: error})
    }
}

export const getMyRdsReset = () => async (dispatch) => {
    dispatch({type: BRAND_ACTION_TYPES.MY_RDS_RESET})
}
