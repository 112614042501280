import React, {useState} from "react"
import style from "./style.module.css"
import quotesIcon from "../../../assets/images/quotesIcon.png"
import RightArrow from "../../../assets/images/RightArrow.png"
import LeftArrow from "../../../assets/images/LeftArrow.png"

const Testimonial = () => {
    const [activeSlide, setActiveSlide] = useState(1)

    const handleSlidePrevious = () => {
        if (activeSlide > 1) setActiveSlide(activeSlide - 1)
    }

    const handleSlideNext = () => {
        if (activeSlide < 3) setActiveSlide(activeSlide + 1)
    }

    return (
        <div className={style.outerWrap}>
            <div className={style.reviewsWrap}>
                <div className={style.innerWrap}>
                    {activeSlide === 1 && (
                        <div className={style.quote}>
                            {/* <img src={quotesIcon} alt="quote" /> */}
                            <div className={style.quoteWrap}>
                                <div className={style.text}>
                                    “Designer Protein’s goal in becoming a Link brand partner was
                                    to expand its network of verified nutrition experts. Before
                                    Link, this process was fragmented and inefficient. Link
                                    enables Designer Protein to seamlessly connect with the
                                    appropriate expertise required for its various needs via one
                                    portal. Link has allowed Designer Protein’s team the liberties
                                    to spend less time on seeking out nutrition experts.<b> The
                                    additional bandwidth that Link provides is an invaluable
                                    time-saver.</b>”
                                </div>
                                <div className={style.name}><i>-Paul P. , Designer Protein</i></div>
                            </div>
                        </div>
                    )}
                    {activeSlide === 2 && (
                        <div className={style.quote}>
                            {/* <img src={quotesIcon} alt="quote" /> */}
                            <div className={style.quoteWrap}>
                                <div className={style.text}>
                                    “Link is the perfect place to reach many entrepreneurial, diverse,
                                    and credible nutrition experts all in one place.<b> Prior to Link,
                                    our only way to find RD's was on LinkedIn and Instagram and those
                                    platforms don't allow us to see the many unique offerings some of
                                    these professionals have.</b> MUSH is a growing brand and we think
                                    it is so important to have credible, organic support and have
                                    found that in using The RD Link.”
                                </div>
                                <div className={style.name}><i>-Nora E., MUSH</i></div>
                            </div>
                        </div>
                    )}
                    {activeSlide === 3 && (
                        <div className={style.quote}>
                            {/* <img src={quotesIcon} alt="quote" /> */}
                            <div className={style.quoteWrap}>
                                <div className={style.text}>
                                    <span>“Before using LINK, our team didn't have one system to track
                                    opportunities to work with RDs on local TV segments, something that was
                                    a goal at the start of 2020. Once we onboarded with LINK, we
                                    immediately began building relationships with RDs and tracking
                                    opportunities all in one place.<b> Our team secured
                                    over a dozen TV segments (in just 6 weeks!) </b>since using Link
                                    with more coming out each month!</span><b> I would highly recommend Link</b>
                                    <span> to brands looking to connect with RDs but don't
                                    know where to start.”</span>
                                </div>
                                <div className={style.name}><i>-Keri S. Enlightened Ice Cream</i></div>
                            </div>
                        </div>
                    )}
                    <div className={style.arrowsWrap}>
                        <button className='btn p-0' onClick={handleSlidePrevious}>
                            <img src={LeftArrow} alt="arrow"/>
                        </button>
                        <button className='btn p-0' onClick={handleSlideNext}>
                            <img src={RightArrow} alt="arrow"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Testimonial
