import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Avatar from '@components/avatar'
import {
    ChevronDown,
    Eye,
    MoreVertical
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardTitle,
    CardHeader,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    Spinner
} from 'reactstrap'
import {getManageRdsGeneralInfo} from '@src/views/apps/invoice/store/actions'
import {useDispatch, useSelector} from 'react-redux'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import '@styles/react/apps/app-invoice.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import {PropTypes} from "prop-types"

const RenderClient = ({row}) => {
    const stateNum = Math.floor(Math.random() * 6),
        states = ['light-success', 'light-danger', 'light-warning', 'light-info', 'light-primary', 'light-secondary'],
        color = states[stateNum]
    if (row?.avatar?.length) {
        return <Avatar className='mr-50' img={row.avatar} width='32' height='32'/>
    } else {
        return <Avatar color={color} className='mr-50' content={row?.name} initials/>
    }
}

RenderClient.propTypes = {
    row: PropTypes.shape({
        avatar: PropTypes.string,
        name: PropTypes.string
    }).isRequired
}

const RenderName = ({row}) => {
    return (
        <div className='d-flex justify-content-left align-items-center'>
            <RenderClient row={row}/>
            <div className='d-flex flex-column'>
                <h6 className='user-name text-truncate mb-0'>{row?.name}</h6>
            </div>
        </div>
    )
}

RenderName.propTypes = {
    row: PropTypes.shape({
        avatar: PropTypes.string,
        name: PropTypes.string
    }).isRequired
}

const RenderMenu = ({row}) => {
    return (
        <div className='column-action d-flex align-items-center'>
            <UncontrolledDropdown>
                <DropdownToggle tag='span'>
                    <MoreVertical size={17} className='cursor-pointer'/>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem tag={Link} to={`/admin/manage-rd/${row.id}`} className='w-100'>
                        <Eye size={14} className='mr-50'/>
                        <span className='align-middle'>View Profile</span>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )
}

RenderMenu.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    }).isRequired
}

const ManageRDs = () => {
    const dispatch = useDispatch()
    const invoiceStoreSlice = useSelector(state => state.invoice)
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [paginationInfo, setPaginationInfo] = useState(null)

    const [columns] = useState([
        {
            name: 'Rd name',
            minWidth: '250px',
            selector: 'name',
            sortable: true,
            cell: row => <RenderName row={row}/>
        },
        {
            name: 'Registration Date',
            selector: 'reg_date',
            sortable: true,
            minWidth: '180px',
            cell: row => row.reg_date
        },
        {
            name: 'Partnerships',
            selector: 'partnerships',
            sortable: true,
            minWidth: '130px',
            cell: row => row?.partnerships || 0
        },
        {
            name: 'Monthly Logins',
            selector: 'monthly_logins',
            sortable: true,
            minWidth: '162px',
            cell: row => row?.monthly_logins || 0
        },
        {
            name: 'Login Time',
            selector: 'login_time',
            sortable: true,
            minWidth: '152px',
            cell: row => row?.login_time || 0
        },
        {
            name: 'Action',
            minWidth: '110px',
            selector: '',
            sortable: true,
            justifyContent: 'center',
            cell: row => <RenderMenu row={row}/>
        }
    ])

    useEffect(() => {
        const manageRdsGeneralInfo = invoiceStoreSlice?.manageRdsGeneralInfo ?? null
        if (manageRdsGeneralInfo) {
            const {links, meta, pages} = manageRdsGeneralInfo
            setPaginationInfo({...links, ...meta, pages})
            setIsTableLoading(false)
        }
    }, [invoiceStoreSlice])

    useEffect(() => {
        setIsTableLoading(true)
        dispatch(getManageRdsGeneralInfo(false))
    }, [])

    const handleCustomPagination = pageUrl => {
        setIsTableLoading(true)
        dispatch(getManageRdsGeneralInfo(true, pageUrl))
    }

    const dataToRender = () => {
        return invoiceStoreSlice?.manageRdsGeneralInfo?.data
    }

    return (
        <div className='invoice-list-wrapper manage-rds'>
            <Card className="pt-0">
                <CardHeader><CardTitle>Manage RDs</CardTitle></CardHeader>
                {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isTableLoading && (
                    <div className='invoice-list-dataTable'>
                        <DataTable
                            noHeader
                            paginationServer
                            subHeader={false}
                            columns={columns}
                            responsive={true}
                            sortIcon={<ChevronDown/>}
                            className='react-dataTable'
                            defaultSortField='reg_date'
                            paginationDefaultPage={1}
                            data={dataToRender()}
                        />
                    </div>)}
                <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
            </Card>
        </div>
    )
}

export default ManageRDs
