import React, {forwardRef, useEffect, useImperativeHandle} from "react"
import {
    Input,
    FormGroup,
    Row,
    Col,
    Form,
    Button,
    CustomInput
} from "reactstrap"
import {useFormik} from "formik"
import classnames from "classnames"
import DeleteBtn from "../../../assets/images/deleteBtn.svg"
import EditBtn from "../../../assets/images/editBtn.svg"
import Minus from "../../../assets/images/minus.svg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import {
    FORM_INITIAL_VALUES,
    VALIDATION_SCHEMA,
    confirmAlert
} from "./Controller"
import CustomLabel from "./CustomLabel"

const Step3 = forwardRef(({submitted, setData, campaign, getPartialData, disableBtn}, ref) => {
    const schema =
        {
            ambassador_program: VALIDATION_SCHEMA.ambassador_program,
            product_exploration: VALIDATION_SCHEMA.product_exploration,
            collaboration: VALIDATION_SCHEMA.collaboration,
            survey: VALIDATION_SCHEMA.survey,
            others: VALIDATION_SCHEMA.others
        }

    const form = useFormik({
        initialValues: FORM_INITIAL_VALUES.step3,
        validationSchema: schema[campaign.slug],
        onSubmit: (values) => {
            submitted(values)
        }
    })

    const formAddQuestion = useFormik({
        initialValues: FORM_INITIAL_VALUES.questions,
        validationSchema: VALIDATION_SCHEMA.questions,
        onSubmit: (values) => {
            const editIndex = values.editIndex
            if (editIndex >= 0) {
                form.values.campaign_form_questions[editIndex] = values
            } else {
                form.values.campaign_form_questions.push(values)
            }
            form.setValues({...form.values})
            formAddQuestion.resetForm()
            disableBtn(false)
        }
    })

    useImperativeHandle(ref, () => ({
        submitThisForm() {
            form.submitForm().then(r => r)
        },
        resetThisForm() {
            form.resetForm()
        },
        getPartialValues() {
            getPartialData(form.values)
        }
    }))

    const addField = () => {
        formAddQuestion.setFieldValue('clickAdd', true)
    }

    const addOption = () => {
        formAddQuestion?.values?.campaign_question_options?.push({option: ""})
        formAddQuestion?.setValues({...formAddQuestion?.values})
    }

    const removeOption = (index) => {
        formAddQuestion.values.campaign_question_options.splice(index, 1)
        formAddQuestion.setValues({...formAddQuestion.values})
    }

    const deleteQuestion = async (index) => {
        const isConfirmed = await confirmAlert('Delete?', 'Do you really want to delete?')
        if (isConfirmed) {
            form.values.campaign_form_questions.splice(index, 1)
            await form.setValues({...form.values})
            formAddQuestion.resetForm()
        }
    }

    const editQuestion = (index) => {
        const obj = form.values.campaign_form_questions[index]
        formAddQuestion.setValues(obj)
        formAddQuestion.setFieldValue('editIndex', index)
        addField()
    }

    useEffect(() => {
        if (setData) {
            form.setFieldValue('doYouShip', setData.doYouShip)
            form.setFieldValue('isHosted', setData.isHosted)
            form.setFieldValue('hostedUrl', setData.hostedUrl)
            form.setFieldValue('doYouProvideSamples', setData.doYouProvideSamples)
            form.setFieldValue('budget', setData.budget)
            form.setFieldValue('campaign_form_questions', setData.campaign_form_questions)
        }
    }, [setData])

    return (
        <div>
            <div className="text-center pb-2 pt-1 font-inter inter-medium outreach-details">Submission Form</div>
            <Form onSubmit={form.handleSubmit}>
                {campaign.id === 1 && (
                    <>
                        <FormGroup>
                            <CustomLabel formik={form}
                                         id='doYouProvideSamplesToRDs'
                                         title='Do you provide samples to new members?'
                            />
                            <Row>
                                <Col md="6">
                                    <FormGroup check className="d-flex align-items-center pl-0 font-inter inter-regular">
                                        <CustomInput type="radio"
                                                     name="doYouProvideSamplesToRDs"
                                                     value="Yes"
                                                     checked={form.values.doYouProvideSamplesToRDs === 'Yes'}
                                                     id="doYouProvideSamplesToRDsYes"
                                                     onChange={(e) => {
                                                         form.setFieldValue('doYouProvideSamplesToRDs', 'Yes')
                                                     }}
                                                     label={<span className="radio-label">Yes</span>}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup check className="d-flex align-items-center pl-0 font-inter inter-regular">
                                        <CustomInput type="radio"
                                                     name="doYouProvideSamplesToRDs"
                                                     value="No"
                                                     checked={form.values.doYouProvideSamplesToRDs === 'No'}
                                                     id="doYouProvideSamplesToRDsNo"
                                                     onChange={(e) => {
                                                         form.setFieldValue('doYouProvideSamplesToRDs', 'No')
                                                     }}
                                                     label={<span className="radio-label">No</span>}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <CustomLabel formik={form}
                                         id='benefits'
                                         title='What benefits do RDs receive when they join your program?'
                                         isRequired={false}
                            />
                            <Row>
                                <Col md="12">
                                    <FormGroup check className="d-flex align-items-center pl-0 font-inter inter-regular">
                                        <Input id="benefits"
                                               name="benefits"
                                               placeholder="RDs receive discounts for patients, educational opportunities, features on our blog, first access to new products, and more"
                                               type="textarea"
                                               rows={4}
                                               value={form.values.benefits}
                                               onBlur={form.handleBlur}
                                               onChange={form.handleChange}
                                               className={classnames({'is-invalid font-inter inter-regular': form.touched.benefits && form.errors.benefits})}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <CustomLabel formik={form}
                                         id='isHosted'
                                         title='Is your program hosted on an external platform?'
                            />
                            <Row>
                                <Col md="6">
                                    <FormGroup check className="d-flex align-items-center pl-0 font-inter inter-regular">
                                        <CustomInput type="radio"
                                                     name="isHosted"
                                                     value="Yes"
                                                     checked={form.values.isHosted === 'Yes'}
                                                     id="isHostedYes"
                                                     onChange={(e) => {
                                                         form.setFieldValue('isHosted', 'Yes')
                                                     }}
                                                     label={<span className="radio-label">Yes</span>}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup check className="d-flex align-items-center pl-0 font-inter inter-regular">
                                        <CustomInput type="radio"
                                                     name="isHosted"
                                                     value="No"
                                                     checked={form.values.isHosted === 'No'}
                                                     id="isHostedNo"
                                                     onChange={(e) => {
                                                         form.setFieldValue('isHosted', 'No')
                                                     }}
                                                     label={<span className="radio-label">No</span>}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                        {form.values.isHosted === 'Yes' && (
                            <FormGroup>
                                <CustomLabel formik={form}
                                             id='hostedUrl'
                                             title='Please provide the sign-up URL'
                                />
                                <Input id="hostedUrl"
                                       name="hostedUrl"
                                       placeholder="Sign-up URL"
                                       type="text"
                                       value={form.values.hostedUrl}
                                       onBlur={form.handleBlur}
                                       onChange={form.handleChange}
                                       className={classnames({'is-invalid font-inter': form.touched.hostedUrl && form.errors.hostedUrl})}
                                />
                                <div className="validation-err font-inter inter-regular">{form.touched.hostedUrl && form.errors.hostedUrl}</div>
                            </FormGroup>
                        )}
                    </>
                )}
                {campaign.id === 2 && (
                    <>
                        <FormGroup>
                            <CustomLabel formik={form}
                                         id='doYouShipToCanadaPOAPO'
                                         title='Do you ship to Canada, PO Boxes, APO Boxes?'
                            />
                            <Row>
                                <Col md="6">
                                    <FormGroup check className="d-flex align-items-center pl-0 font-inter inter-regular">
                                        <CustomInput type="radio"
                                                     name="doYouShipToCanadaPOAPO"
                                                     value="Yes"
                                                     checked={form.values.doYouShipToCanadaPOAPO === 'Yes'}
                                                     id="doYouShipToCanadaPOAPOYes"
                                                     onChange={(e) => {
                                                         form.setFieldValue('doYouShipToCanadaPOAPO', 'Yes')
                                                     }}
                                                     label={<span className="radio-label">Yes</span>}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup check className="d-flex align-items-center pl-0 font-inter inter-regular">
                                        <CustomInput type="radio"
                                                     name="doYouShipToCanadaPOAPO"
                                                     value="No"
                                                     checked={form.values.doYouShipToCanadaPOAPO === 'No'}
                                                     id="doYouShipToCanadaPOAPONo"
                                                     onChange={(e) => {
                                                         form.setFieldValue('doYouShipToCanadaPOAPO', 'No')
                                                     }}
                                                     label={<span className="radio-label">No</span>}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                    </>
                )}
                {campaign.id === 3 && (
                    <>
                        <FormGroup>
                            <CustomLabel formik={form}
                                         id='rdRequirements'
                                         title='RD Requirements: Who is this a good fit for?'
                            />
                            <Input id="rdRequirements"
                                   name="rdRequirements"
                                   placeholder="Describe the ideal fit..."
                                   type="textarea"
                                   rows={1}
                                   value={form.values.rdRequirements}
                                   onBlur={form.handleBlur}
                                   onChange={form.handleChange}
                                   className={classnames({'is-invalid font-inter': form.touched.rdRequirements && form.errors.rdRequirements})}
                            />
                            <div className="validation-err font-inter inter-regular">{form.touched.rdRequirements && form.errors.rdRequirements}</div>
                        </FormGroup>
                        <FormGroup>
                            <CustomLabel formik={form}
                                         id='partnershipRequirementsAndGuidelines'
                                         title='Partnership Requirements & Guidelines: What are you asking the RD to do? Include talking points, how their content will be used, types of content you are seeking, deadlines, etc.'
                            />
                            <Input id="partnershipRequirementsAndGuidelines"
                                   name="partnershipRequirementsAndGuidelines"
                                   placeholder="Outline RD tasks, content usage, and deadlines..."
                                   type="textarea"
                                   rows={1}
                                   value={form.values.partnershipRequirementsAndGuidelines}
                                   onBlur={form.handleBlur}
                                   onChange={form.handleChange}
                                   className={classnames({'is-invalid font-inter': form.touched.partnershipRequirementsAndGuidelines && form.errors.partnershipRequirementsAndGuidelines})}
                            />
                            <div
                                className="validation-err font-inter inter-regular">{form.touched.partnershipRequirementsAndGuidelines && form.errors.partnershipRequirementsAndGuidelines}</div>
                        </FormGroup>
                        <FormGroup>
                            <CustomLabel formik={form}
                                         id='budgetPerRD'
                                         title='Compensation - Starting at, range, or flat rate'
                            />
                            <Input id="budgetPerRD"
                                   name="budgetPerRD"
                                   placeholder="Enter starting rate, range, or flat fee..."
                                   type="text"
                                   value={form.values.budgetPerRD}
                                   onBlur={form.handleBlur}
                                   onChange={form.handleChange}
                                   className={classnames({'is-invalid font-inter': form.touched.budgetPerRD && form.errors.budgetPerRD})}
                            />
                            <div className="validation-err font-inter inter-regular">{form.touched.budgetPerRD && form.errors.budgetPerRD}</div>
                        </FormGroup>
                    </>
                )}
            </Form>
            {campaign.id !== 1 && (
                <>
                    <hr/>
                    <h5 className="mb-2 font-inter inter-medium">
                        Add Question
                    </h5>
                    {form.values.campaign_form_questions?.map((item, index) => (
                        <div className="d-flex align-items-start justify-content-between w-100 mb-2" key={`add-questions-${index}`}>
                            <div className="w-100">
                                <FormGroup className="mb-0 d-flex">
                                    <div className='font-inter inter-medium mr-50'>{index + 1}.</div>
                                    <div className="pre-line font-inter word-break"
                                    dangerouslySetInnerHTML={{
                                        __html: item.question?.replace(/\n/g, "<br />").replace(/\r/g, "<br />")
                                      }}
                                    />
                                </FormGroup>
                            </div>
                            <div className="d-flex align-items-center">
                                <button className="mr-50 outline-none border-0 bg-transparent" onClick={() => deleteQuestion(index)}>
                                    <img src={DeleteBtn} alt="icon"/>
                                </button>
                                <button className="outline-none border-0 bg-transparent" onClick={() => editQuestion(index)}>
                                    <img src={EditBtn} alt="icon"/>
                                </button>
                            </div>
                        </div>
                    ))}
                    {formAddQuestion.values.clickAdd === false && (
                        <>
                            <Button.Ripple color="light"
                                           className='mb-2 font-inter inter-medium blueButton'
                                           onClick={addField}>
                                + Add New
                            </Button.Ripple>
                            <div className="validation-err font-inter inter-regular">{form.touched.campaign_form_questions && form.errors.campaign_form_questions}</div>
                        </>
                    )}
                    {formAddQuestion.values.clickAdd === true && formAddQuestion.values.type === '' && (
                        <FormGroup>
                            <CustomLabel formik={form}
                                         id='type'
                                         title='Type of Question'
                            />
                            <Input type="select"
                                   onChange={(e) => {
                                       formAddQuestion.setFieldValue('type', e.target.value)
                                       disableBtn(true)
                                   }}
                            >
                                <option value="">Choose Form</option>
                                <option value="multiple">Multiple Choice</option>
                                <option value="textField">Text Field</option>
                            </Input>
                            <div className="validation-err font-inter inter-regular">{form.touched.campaign_form_questions && form.errors.campaign_form_questions}</div>
                        </FormGroup>
                    )}
                    <Form onSubmit={formAddQuestion.handleSubmit}>
                        {formAddQuestion.values.type !== '' && (
                            <>
                                <FormGroup className="mt-1">
                                    <CustomLabel formik={formAddQuestion}
                                                 id='question'
                                                 title='Include any additional questions you have for the RDs here'
                                                 isRequired={false}
                                    />
                                    <Input id="question"
                                           name="question"
                                           placeholder="Type question here..."
                                           type="textarea"
                                           rows={1}
                                           value={formAddQuestion.values.question}
                                           onBlur={formAddQuestion.handleBlur}
                                           onChange={formAddQuestion.handleChange}
                                           className={classnames({'is-invalid font-inter inter-regular': formAddQuestion.touched.question && formAddQuestion.errors.question})}
                                    />
                                </FormGroup>
                                {formAddQuestion.values.type === 'multiple' && formAddQuestion?.values?.campaign_question_options.map((field, index) => (
                                    <FormGroup key={`option-${index}`}>
                                        <div className="d-flex align-items-center">
                                        <FormGroup check className="w-100 d-flex align-items-center pl-0">
                                            <CustomInput className="mt-25 mr-50"
                                                         name={`checkbox-${index}`}
                                                         id={`checkbox-${index}`}
                                                         type="checkbox"
                                                         disabled={true}
                                            />
                                            <div className="d-flex flex-column w-100">
                                            <Input type="text"
                                                   placeholder={`Option ${index + 1}`}
                                                   name={`campaign_question_options[${index}].option`}
                                                   value={field.option}
                                                   onChange={formAddQuestion.handleChange}
                                                   onBlur={formAddQuestion.handleBlur}
                                                   className={classnames("font-inter w-100", {
                                                       "is-invalid font-inter":
                                                           formAddQuestion.touched.campaign_question_options?.[index]?.option &&
                                                           formAddQuestion.errors.campaign_question_options?.[index]?.option
                                                   })}
                                            />
                                             {formAddQuestion.touched.campaign_question_options?.[index]?.option &&
                                            formAddQuestion.errors.campaign_question_options?.[index]?.option && (
                                                <div className="validation-err font-inter inter-regular pl-50">
                                                    {formAddQuestion.errors.campaign_question_options[index].option}
                                                </div>
                                            )}
                                            </div>
                                        </FormGroup>
                                        {index > 1 && (
                                            <img src={Minus}
                                                 alt="icon"
                                                 className="ml-1 cursor-pointer"
                                                 onClick={() => removeOption(index)}
                                            />
                                            )}
                                        </div>
                                       
                                    </FormGroup>
                                ))}
                                {formAddQuestion.values.type === 'multiple' && (
                                    <div className="mb-3">
                                        <Button color="link" className="p-0 font-inter inter-medium" onClick={() => addOption()}>
                                            + Add option
                                        </Button>
                                    </div>
                                )}
                                <div className="d-flex justify-content-end">
                                    <Button outline color="primary" className="mr-1 outline-Btn font-inter inter-medium" onClick={() => {
                                        formAddQuestion.resetForm()
                                        disableBtn(false)
                                    }}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" type='submit' className="font-inter inter-medium">
                                        {formAddQuestion.values.editIndex >= 0 ? 'Update' : 'Save'}
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form>
                </>
            )}
        </div>
    )
})

export default Step3
