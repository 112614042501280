import ImgsViewer from "react-images-viewer"
import {PropTypes} from "prop-types"

const ImageViewer = ({show, hide, images}) => {
    return (
        <>
            {show && (
                <style>{`.fade:not(.show){opacity: 1;} .fade{transition: opacity 0s linear;}`}</style>
            )}
            <ImgsViewer imgs={images} isOpen={show} onClose={hide} theme={{opacity: 1}}/>
        </>
    )
}

ImageViewer.propTypes = {
    show: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string.isRequired,
            caption: PropTypes.string,
            alt: PropTypes.string
        })
    ).isRequired
}

export default ImageViewer
