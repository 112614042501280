import { MANAGE_RD } from '../actionType'
// ** Initial State
const initialState = {
  manageRDsList: [],
  total: 1,
  params: {},
  allData: [],
  searchedManageRDsList: [],
  deleteRDsData: null,
  rDsStatus: null,
  countries: [],
  states: [],
  specialities: [],
  industries: null,
  profileInfo: null,
  rdsEventList: null,
  searchedRDsEventList: null,
  brandsWhoPurchasedMembership: null,
  searchedBrandsWhoPurchasedMembership: null,
  brandsWhoRequestedParticipation: null,
  searchedBrandsWhoRequestedParticipation: null,
  error: null,
  exportCsv: []
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case MANAGE_RD.MANAGE_RD_LISTING:
      return {
        ...state,
        manageRDsList: action.manageRDsList
      }
    case MANAGE_RD.SEARCH_MANAGE_RD_GET_DATA:
      return {
        ...state,
        searchedManageRDsList: action.searchedManageRDsListData
      }
    case MANAGE_RD.DELETE_MANAGE_RD_GET_DATA:
      return {
        ...state,
        deleteRDsData: action.deleteRDsData
      }
    case MANAGE_RD.DELETE_RD_RESPONSE:
      return {
        ...state,
        deleteRDsData: action.deleteRDsResponse
      }
    case MANAGE_RD.UPDATE_RD_STATUS:
      return {
        ...state,
        rDsStatus: action.changeRDsStatus
      }
    case MANAGE_RD.COUNTRIES_DATA:
      return {
        ...state,
        countries: action.countries
      }
    case MANAGE_RD.STATES_DATA:
      return {
        ...state,
        states: action.states
      }
    case MANAGE_RD.INDUSTRIES_DATA:
      return {
        ...state,
        industries: action.industries
      }
    case MANAGE_RD.SPECIALITIES_DATA:
      return {
        ...state,
        specialities: action.specialities
      }
    case MANAGE_RD.PROFILE_INFO:
      return {
        ...state,
        profileInfo: action.profileInfo
      }
    case MANAGE_RD.EVENT_LIST:
      return {
        ...state,
        rdsEventList: action.rdsEventList
      }
    case MANAGE_RD.SEARCHED_EVENT_LIST:
      return {
        ...state,
        searchedRDsEventList: action.searchedRDsEventList
      }
    case MANAGE_RD.BRANDS_WHO_PURCHASED_MEMBERSHIP:
      return {
        ...state,
        brandsWhoPurchasedMembership: action.brandsWhoPurchasedMembership
      }
    case MANAGE_RD.BRANDS_WHO_REQUESTED_FOR_PARTICIPATION:
      return {
        ...state,
        brandsWhoRequestedParticipation: action.brandsWhoRequestedParticipation
      }
    case MANAGE_RD.SEARCHED_BRANDS_WHO_REQUESTED_FOR_PARTICIPATION:
      return {
        ...state,
        searchedBrandsWhoRequestedParticipation: action.searchedBrandsWhoRequestedParticipation
      }
    case MANAGE_RD.EXPORT_CSV_DATA:
      return {
        ...state,
        exportCsv: action.exportAllData
      }
    case MANAGE_RD.ERROR:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export default DataTablesReducer
