import {useEffect} from 'react'
import VerticalNavMenuLink from './VerticalNavMenuLink'
import VerticalNavMenuGroup from './VerticalNavMenuGroup'
import VerticalNavMenuSectionHeader from './VerticalNavMenuSectionHeader'
import {useSelector, useDispatch} from 'react-redux'
import {getBrandUnreadMessages} from '../../../../../redux/actions/brandsDashboard'
import {
    resolveVerticalNavMenuItemComponent as resolveNavItemComponent,
    CanViewMenuGroup,
    CanViewMenuItem
} from '@layouts/utils'

const VerticalMenuNavItems = props => {
    const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))
    const brandDashboardStoreSlice = useSelector(state => state.brandsDashboardSlice)
    const dispatch = useDispatch()
    useEffect(() => {
        if (userData) {
            dispatch(getBrandUnreadMessages())
        }
    }, [])

    const Components = {
        VerticalNavMenuSectionHeader,
        VerticalNavMenuGroup,
        VerticalNavMenuLink
    }

    const RenderNavItems = props.items.map((item, index) => {
        const TagName = Components[resolveNavItemComponent(item)]
        if (item.children) {
            return CanViewMenuGroup(item) && <TagName item={item} index={index} key={item.id} {...props} />
        }
        if (item.id === "messagesBrands") {
            item.badgeText = brandDashboardStoreSlice?.brandUnreadMessages?.data > 0 ? brandDashboardStoreSlice?.brandUnreadMessages?.data.toString() : ''
            return CanViewMenuItem(item) && <TagName key={item.id || item.header} item={item} {...props} />

        } else {
            return CanViewMenuItem(item) && <TagName key={item.id || item.header} item={item} {...props} />

        }
    })
    return RenderNavItems
}

export default VerticalMenuNavItems
