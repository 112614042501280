const themeConfig = {
    app: {
        appName: 'LINK RD',
        appLogoImage: require('@src/assets/images/logo/logo.svg').default
    },
    layout: {
        isRTL: false,
        skin: 'light',
        routerTransition: 'fadeIn',
        type: 'vertical',
        contentWidth: 'full',
        menu: {
            isHidden: false,
            isCollapsed: false
        },
        navbar: {
            type: 'floating',
            backgroundColor: 'white'
        },
        footer: {
            type: 'hidden'
        },
        customizer: false,
        scrollTop: true
    }
}

export default themeConfig
