import React, {useState, Fragment, useEffect, useMemo} from "react"
import {useFormik} from "formik"
import {Editor} from "react-draft-wysiwyg"
import "@styles/react/libs/editor/editor.scss"
import "@styles/base/plugins/forms/form-quill-editor.scss"
import Dots from "../../../assets/images/dots.svg"
import DeleteBtn from "../../../assets/images/deleteBtn.svg"
import EditBtn from "../../../assets/images/editBtn.svg"
import UploadBtn from "../../../assets/images/uploaderBtn.svg"
import Minus from "../../../assets/images/minus.svg"
import {
    Card,
    CardBody,
    CardTitle,
    Input,
    FormGroup,
    Row,
    Col,
    Button,
    Form,
    CardText,
    Spinner,
    CustomInput,
    UncontrolledTooltip
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import HomeIcon from "@src/assets/images/home.svg"
import {ContentState, EditorState, convertToRaw} from "draft-js"
import draftToHtml from "draftjs-to-html"
import "@styles/react/libs/flatpickr/flatpickr.scss"
import {useDispatch, useSelector} from "react-redux"
import {
    viewCampaign,
    campaignSave,
    campaignSaveReset,
    viewCampaignReset
} from "@store/actions/BrandActions"
import {getBrandGoalsSelectValues} from '@store/actions/auth'
import classnames from "classnames"
import htmlToDraft from "html-to-draftjs"
import Select, {default as ReactSelect, components} from "react-select"
import {Link, useHistory, useParams} from "react-router-dom"
import BlankLogo from "@src/assets/images/avatars/Blank-logo.jpg"
import ArrowLeft from "@src/assets/images/arrowLeft.svg"
import ImageIcon from "@src/assets/images/image.svg"
import {
    SCHEDULE_DATE_OPTIONS,
    OUTREACH_TYPES,
    FORM_INITIAL_VALUES,
    VALIDATION_SCHEMA,
    EDITOR_TOOLBAR,
    getReactSelectCustomStyles,
    getAttributes,
    saveCampaign,
    confirmAlert,
    successAlert,
    showHomePage,
    showOutreachPage,
    handlePastedText
} from "./Controller"
import {
    getCountries,
    getStates
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import CustomLabel from "./CustomLabel"
import DefaultBanners from "./DefaultBanners"

const Option = (props) => {
    return (
        <components.Option {...props}>
            <CustomInput type="checkbox"
                         checked={props.isSelected}
                         onChange={() => null}
                         name={`${props.data.type}_${props.data.value}`}
                         id={`${props.data.type}_${props.data.value}`}
                         label={props.data.label}
            />
        </components.Option>
    )
}

const MultiValue = (props) => {
    return (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    )
}

const ReviewPartnershipDetails = () => {
    const history = useHistory()

    const {id} = useParams()

    const dispatch = useDispatch()

    const [bgImg, setBgImg] = useState(null)
    const [typeId, setTypeId] = useState(0)
    const [missingData, setMissingData] = useState(false)
    const [selectDefaultBanner, setSelectDefaultBanner] = useState(false)
    const [isUploadedImage, setIsUploadedImage] = useState(false)
    const [statesData, setStatesData] = useState([])

    const {brandGoalsSelectValues} = useSelector(state => state.auth)

    const {
        countries,
        states
    } = useSelector((store) => store.brandsOppoprunities)

    const rdOptions = getAttributes(brandGoalsSelectValues)

    const {
        campaignViewLoading,
        campaignViewData,
        campaignViewError,

        campaignSaveLoading,
        campaignSaveData,
        campaignSaveError
    } = useSelector(state => state.brandReducer)

    const {data} = useMemo(() => {
        return {
            data: campaignViewData?.data ?? null
        }
    }, [campaignViewData])

    const schema =
        {
            default: VALIDATION_SCHEMA.default,
            campaign_1: VALIDATION_SCHEMA.ambassador_program,
            campaign_2: VALIDATION_SCHEMA.product_exploration,
            campaign_3: VALIDATION_SCHEMA.collaboration,
            campaign_4: VALIDATION_SCHEMA.survey,
            campaign_5: VALIDATION_SCHEMA.others
        }

    const saveCampaignData = async (values) => {
        const data = {...values, type_id: values?.type?.id, id}
        const formData = await saveCampaign(data)
        dispatch(campaignSave(formData))
    }

    const form = useFormik({
        initialValues: {...FORM_INITIAL_VALUES.step2, ...FORM_INITIAL_VALUES.step3},
        validationSchema: schema['default'].concat(schema[`campaign_${typeId}`]),
        onSubmit: (values) => {
            saveCampaignData(values).then(r => r)
        }
    })

    const formAddQuestion = useFormik({
        initialValues: FORM_INITIAL_VALUES.questions,
        validationSchema: VALIDATION_SCHEMA.questions,
        onSubmit: (values) => {
            const editIndex = values.editIndex
            if (editIndex >= 0) {
                form.values.campaign_form_questions[editIndex] = values
            } else {
                form.values.campaign_form_questions.push(values)
            }
            form.setValues({...form.values})
            formAddQuestion.resetForm()
        }
    })

    const selectAllOptions = () => {
        const flattenedOptions = rdOptions.reduce((acc, item) => {
            if (item.id === 0) {
                acc.push(item)
            }
            if (item.options) {
                acc.push(...item.options)
            }
            return acc
        }, [])
        form.setFieldValue('rdTypes', flattenedOptions)
    }

    const handleDietitiansTypes = (e) => {
        const hasAll = e.some(item => item.value === "all")
        const typesLength = e.length
        if (hasAll && typesLength === 1) {
            selectAllOptions()
        } else {
            form.setFieldValue('rdTypes', e)
        }
    }

    const flattenedOptions = rdOptions.flatMap(group => group.options || [])

    const handleSelectAll = (checked) => {
        if (checked) {
            handleDietitiansTypes(flattenedOptions)
        } else {
            handleDietitiansTypes([])
        }
    }

    const addOption = () => {
        formAddQuestion?.values?.campaign_question_options?.push({option: ""})
        formAddQuestion?.setValues({...formAddQuestion?.values})
    }

    const removeOption = (index) => {
        formAddQuestion.values.campaign_question_options.splice(index, 1)
        formAddQuestion.setValues({...formAddQuestion.values})
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0]
        if (file) {
            form.setFieldValue('banner', file)
            form.setFieldValue('defaultBanner', '')
            const imageUrl = URL.createObjectURL(file)
            setBgImg(imageUrl)
            setIsUploadedImage(true)
        }
    }

    const selectDefaultImage = (selectedImage) => {
        setBgImg(selectedImage)
        setIsUploadedImage(false)
        setSelectDefaultBanner(false)
        form.setFieldValue('banner', '')
        form.setFieldValue('defaultBanner', selectedImage)
    }

    const addField = () => {
        formAddQuestion.setFieldValue('clickAdd', true)
    }

    const deleteQuestion = async (index) => {
        const isConfirmed = await confirmAlert('Delete?', 'Do you really want to delete?')
        if (isConfirmed) {
            form.values.campaign_form_questions.splice(index, 1)
            await form.setValues({...form.values})
            formAddQuestion.resetForm()
        }
    }

    const editQuestion = (index) => {
        const obj = form.values.campaign_form_questions[index]
        formAddQuestion.setValues(obj)
        formAddQuestion.setFieldValue('editIndex', index)
        addField()
    }

    const [descriptionEditorState, setDescriptionEditorState] = useState(EditorState.createEmpty())

    const convertIntoType = (data) => {
        const obj = []
        if (data) {
            data?.get_specialities?.forEach((item) => {
                obj.push({
                    id: item?.dietitian_id,
                    label: item?.specialiy?.name,
                    value: item?.dietitian_id,
                    type: 'speciality'
                })
            })
            data?.get_industries?.forEach((item) => {
                obj.push({
                    id: item?.dietitian_id,
                    label: item?.industry?.name,
                    value: item?.dietitian_id,
                    type: 'industry'
                })
            })
            data?.get_personal_attr?.forEach((item) => {
                obj.push({
                    id: item?.dietitian_id,
                    label: item?.personal_attr?.name,
                    value: item?.dietitian_id,
                    type: 'personal_attributes'
                })
            })
            form.setFieldValue('rdTypes', obj)
        }
    }

    const convertIntoCountries = (data) => {
        const obj = []
        if (data?.get_countries?.length > 0) {
            form.setFieldValue('isLocation', true)
            data?.get_countries?.forEach((item) => {
                obj.push(item.country)
            })
            form.setFieldValue('countries', obj)
            const countryIds = obj?.map(c => c.id)
            if (states?.length > 0) {
                const filteredStates = states?.filter(x => countryIds.includes(x.country))
                setStatesData(filteredStates)
            } else {
                setStatesData([])
            }
        }
    }

    const convertIntoStates = (data) => {
        const obj = []
        if (data?.get_states?.length > 0) {
            data?.get_states?.forEach((item) => {
                obj.push(item.state)
            })
            form.setFieldValue('states', obj)
        }
    }

    useEffect(() => {
        if (data?.campaign_title) {
            setTypeId(data?.campaign_type?.id ?? 0)
            if (data?.campaign_type?.id) {
                form.setFieldValue('type', {
                    id: data?.campaign_type?.id,
                    label: data?.campaign_type?.title,
                    value: data?.campaign_type?.id
                })
            } else {
                setMissingData(true)
            }
            form.setFieldValue('title', data?.campaign_title)
            setDescriptionEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(data?.campaign_details))))
            form.setFieldValue('description', data?.campaign_details)
            form.setFieldValue('expiry', data?.expire_after ? [new Date(data?.expire_after)] : '')
            form.setFieldValue('isExpiry', !data?.expire_after)
            form.setFieldValue('applicants', data?.submission_limit ?? '')
            form.setFieldValue('noCap', !data?.submission_limit)
            if (data?.sample_provided_for_participants === 1) {
                form.setFieldValue('doYouProvideSamplesToRDs', 'Yes')
            } else if (data?.sample_provided_for_participants === 0) {
                form.setFieldValue('doYouProvideSamplesToRDs', 'No')
            }
            if (data?.benefits_for_rd) {
                form.setFieldValue('benefits', data?.benefits_for_rd)
            } else {
                form.setFieldValue('benefits', '')
            }
            if (data?.platform_external_link) {
                form.setFieldValue('isHosted', 'Yes')
            } else {
                form.setFieldValue('isHosted', 'No')
            }
            form.setFieldValue('hostedUrl', data?.platform_external_link ?? '')
            if (data?.provide_samples_to_new_members === 1) {
                form.setFieldValue('doYouProvideSamplesToNewMembers', 'Yes')
            } else if (data?.provide_samples_to_new_members === 0) {
                form.setFieldValue('doYouProvideSamplesToNewMembers', 'No')
            }
            if (data?.ship_to_canada_po_boxes_ap_boxes === 1) {
                form.setFieldValue('doYouShipToCanadaPOAPO', 'Yes')
            } else if (data?.ship_to_canada_po_boxes_ap_boxes === 0) {
                form.setFieldValue('doYouShipToCanadaPOAPO', 'No')
            }
            form.setFieldValue('rdRequirements', data?.partnership_requirements_and_guidelines ?? '')
            form.setFieldValue('partnershipRequirementsAndGuidelines', data?.rd_requirements ?? '')
            form.setFieldValue('budgetPerRD', data?.budget_per_rd ?? '')
            form.setFieldValue('campaign_form_questions', data?.campaign_questions)
            convertIntoType(data)
            convertIntoCountries(data)
            convertIntoStates(data)
        }
    }, [data])

    useEffect(() => {
        if (campaignSaveData?.success) {
            successAlert(campaignSaveData?.msg)
            dispatch(campaignSaveReset())
            history.push(`/brands/rd-view/${id}`)
        }
    }, [campaignSaveData])

    useEffect(() => {
        dispatch(getBrandGoalsSelectValues())
        dispatch(viewCampaign(`?campaign_id=${id}`))
        dispatch(getCountries())
        dispatch(getStates())
    }, [])

    const handleEditorChange = (editorState) => {
        setDescriptionEditorState(editorState)
        const contentState = editorState.getCurrentContent()
        if (contentState.hasText()) {
            const rawContent = convertToRaw(contentState)
            const htmlContent = draftToHtml(rawContent)
            form.setFieldValue("description", htmlContent)
        } else {
            form.setFieldValue("description", "")
        }
    }

    const goHome = () => {
        dispatch(viewCampaignReset())
        history.push(showHomePage())
    }

    const goBack = () => {
        dispatch(viewCampaignReset())
        history.push(showOutreachPage())
    }

    const goToReview = () => {
        dispatch(viewCampaignReset())
        history.push(viewCampaign(id, false))
    }

    const handleCountries = (e) => {
        form.setFieldValue('countries', e)
        form.setFieldValue('states', [])
        const countryIds = e?.map(c => c.id)
        if (states?.length > 0 && e.length > 0) {
            const filteredStates = states?.filter(x => countryIds.includes(x.country))
            setStatesData(filteredStates)
        } else {
            setStatesData([])
        }
    }

    return (
        <div>
            {campaignViewLoading || campaignSaveLoading ? (
                <div className='bg-primary text-white text-center p-1'>
                    <Spinner className="loader-md"/> Please wait...
                </div>
            ) : (data ? (
                    <>
                        <div className="d-flex align-items-center justify-content-between pb-2 preview-img">
                            <div className="d-flex align-items-center">
                                <button className='btn p-0' onClick={goBack}>
                                    <img className="pr-1" src={ArrowLeft} alt="Arrow Left"/>
                                </button>
                                <h2 className="m-0 font-inter inter-medium text-dark">
                                    {data?.campaign_title} - {data?.campaign_type?.title}
                                </h2>
                            </div>
                            <div className="d-flex align-items-center">
                                <button className='btn p-0' onClick={goHome}>
                                    <img src={HomeIcon} alt="Home"/>
                                </button>
                                <div className="px-1">/</div>
                                <div>
                                    <button className='btn p-0 font-inter inter-regular' onClick={goBack}>
                                        My Outreach
                                    </button>
                                </div>
                                <div className="px-1">/</div>
                                <div>
                                    <button className='btn p-0 font-inter inter-regular' onClick={goToReview}>
                                        Edit Outreach
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Card className="p-2 rdViewCard">
                            <Row className="position-relative">
                                <Col md={12} className="position-relative">
                                    {bgImg ? (
                                        <div className="bg-preview">
                                            <img src={bgImg} alt="banner" className="img-fluid rounded w-100 bgImg" style={{objectFit: isUploadedImage ? "cover" : ""}}/>
                                        </div>
                                    ) : data?.banner_image ? (
                                        <div className="bg-preview">
                                            <img src={data?.banner_image} alt="banner" className="img-fluid rounded w-100 bgImg"
                                                 style={{objectFit: isUploadedImage ? "cover" : ""}}/>
                                        </div>
                                    ) : (
                                        <div className="bg-banner rounded w-100">
                                            <span></span>
                                        </div>
                                    )}
                                    <label id="uploadBannerTooltip" className="bgUpload position-absolute top-0 end-0 p-2 imgHandler">
                                        <img src={UploadBtn} alt="icon"/>
                                        <input type="file" accept="image/*" className="d-none"
                                               onChange={(e) => {
                                                   handleImageUpload(e)
                                               }}
                                        />
                                    </label>
                                    <UncontrolledTooltip placement="left" target="uploadBannerTooltip">
                                        Update Banner
                                    </UncontrolledTooltip>
                                    <label id="selectDefaultBannerTooltip" onClick={() => setSelectDefaultBanner(true)}
                                           className="bgUpload default-image-icon position-absolute cursor-pointer">
                                        <img height={16} width={16} src={ImageIcon} alt="default image"/>
                                    </label>
                                    <UncontrolledTooltip placement="left" target="selectDefaultBannerTooltip">
                                        Select Default Banner
                                    </UncontrolledTooltip>
                                    <div className='text-right'>
                                        <label className='form-label text-danger text-underline font-small-2 font-inter inter-medium'>
                                            Max Size: 2MB (Only: PNG, JPG, JPEG)
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3 position-relative">
                                <Col md={2} className="text-center position-relative">
                                    <img src={`${data?.get_user?.get_brand_profile?.brand_logo_url || BlankLogo}`}
                                         alt="Brand Logo" className="rounded-circle img-fluid profileImg"/>
                                </Col>
                                <Col md={10}>
                                    <div className="name-description">
                                        <CardTitle className="name font-inter inter-medium" tag="h4">
                                            {data?.campaign_title}
                                        </CardTitle>
                                        <CardText className="description font-inter inter-regular">{data?.campaign_type?.title}</CardText>
                                    </div>
                                </Col>
                            </Row>
                            <CardBody>
                                {missingData === true && (
                                    <div className="d-flex align-items-start mb-1">
                                        <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                        <div className="w-100">
                                            <FormGroup>
                                                <CustomLabel formik={form}
                                                             id='type'
                                                             title='Outreach Type'
                                                />
                                                <Select isClearable={false}
                                                        closeMenuOnSelect={true}
                                                        options={OUTREACH_TYPES?.map((items) => {
                                                            return {
                                                                id: items?.id,
                                                                label: items.title,
                                                                value: items?.id
                                                            }
                                                        })}
                                                        value={form.values.type}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        onChange={(e) => {
                                                            form.setFieldValue('type', e)
                                                            setTypeId(e.id)
                                                        }}
                                                        isDisabled={campaignSaveLoading}
                                                        styles={{
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                borderColor: form.touched.type && form.errors.type ? 'red' : baseStyles.borderColor
                                                            }),
                                                            fontFamily: 'Inter sans-serif'
                                                        }}
                                                />
                                                <div className="validation-err font-inter inter-regular">{form.touched.type && form.errors.type}</div>
                                            </FormGroup>
                                        </div>
                                    </div>
                                )}
                                <div className="d-flex align-items-start mb-1">
                                    <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                    <div className="w-100">
                                        <FormGroup>
                                            <CustomLabel formik={form}
                                                         id='title'
                                                         title='Title'
                                                         msg='Max 100 Characters'
                                            />
                                            <Input id="title"
                                                   name="title"
                                                   placeholder="Share a catchy title for this opportunity."
                                                   type="text"
                                                   value={form.values.title}
                                                   onBlur={form.handleBlur}
                                                   onChange={form.handleChange}
                                                   className={classnames({'is-invalid font-inter inter-regular': form.touched.title && form.errors.title})}
                                                   disabled={campaignSaveLoading}
                                            />
                                            <div className="validation-err font-inter inter-regular">{form.touched.title && form.errors.title}</div>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start mb-1">
                                    <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                    <div className="w-100">
                                        <FormGroup className="mb-0">
                                            <CustomLabel formik={form}
                                                         id='description'
                                                         title='Tell us about the opportunity and what you’re offering dietitian.'
                                                         msg='Min 100 Characters'
                                            />
                                            <Editor
                                                editorState={descriptionEditorState}
                                                onEditorStateChange={handleEditorChange}
                                                handlePastedText={handlePastedText}
                                                toolbar={EDITOR_TOOLBAR}
                                                stripPastedStyles={false}
                                                style={{fontFamily: 'Inter sans-serif'}}
                                            />
                                            <div className="validation-err font-inter inter-regular">{form.touched.description && form.errors.description}</div>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start mb-1">
                                    <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                    <div className="w-100">
                                        <FormGroup>
                                            <CustomLabel formik={form}
                                                         id='rdTypes'
                                                         title='What types of dietitians would you like to reach?'
                                            />
                                            <FormGroup check inline className="mt-50 d-flex align-items-center pl-0">
                                                <CustomInput type="checkbox"
                                                             checked={form.values.rdTypes.length === flattenedOptions.length}
                                                             onChange={(e) => handleSelectAll(e.target.checked)}
                                                             name="select-all"
                                                             id="select-all"
                                                             label="Select All"
                                                             className="font-inter inter-medium"
                                                />
                                            </FormGroup>
                                            <p className="txt mb-50">
                                                You can select all options
                                            </p>
                                            <div className="react-select-container">
                                                <ReactSelect options={rdOptions}
                                                             isMulti
                                                             closeMenuOnSelect={false}
                                                             hideSelectedOptions={false}
                                                             components={{Option, MultiValue}}
                                                             onChange={(selectedOptions) => form.setFieldValue("rdTypes", selectedOptions)}
                                                             value={form.values.rdTypes}
                                                             styles={getReactSelectCustomStyles(form)}
                                                             getOptionValue={(option) => option.id || option.value}
                                                             isOptionSelected={(option, selectValue) => {
                                                                 return selectValue.some(selected => (selected.id === option.id) || (selected.value === option.value))
                                                             }}
                                                />
                                            </div>
                                            <div className="validation-err font-inter inter-regular">{form.touched.rdTypes && form.errors.rdTypes}</div>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start mb-1">
                                    <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                    <div className="w-100">
                                        <FormGroup>
                                            <CustomLabel formik={form}
                                                         id='isLocation'
                                                         title='Would you like to connect RDs by location?'
                                                         isRequired={false}
                                            />
                                            <FormGroup check inline className="mt-50 d-flex align-items-center pl-0">
                                                <CustomInput type="checkbox"
                                                             checked={form.values.isLocation}
                                                             onChange={(e) => {
                                                                 form.setFieldValue('isLocation', e.target.checked)
                                                                 form.setFieldValue('countries', [])
                                                                 form.setFieldValue('states', [])
                                                                 setStatesData([])
                                                             }}
                                                             id='isLocation'
                                                             name='isLocation'
                                                             label="Yes"
                                                             className="font-inter inter-medium"
                                                />
                                            </FormGroup>
                                            <p className="txt mb-50">
                                                You can select multiple countries and states
                                            </p>
                                        </FormGroup>
                                        <Row>
                                            {form.values.isLocation && (
                                                <>
                                                    <Col md="6">
                                                        <div className="react-select-container">
                                                            <ReactSelect options={countries}
                                                                         styles={getReactSelectCustomStyles(form, 'countries')}
                                                                         isMulti
                                                                         closeMenuOnSelect={true}
                                                                         hideSelectedOptions={false}
                                                                         onChange={(e) => handleCountries(e)}
                                                                         value={form.values.countries}
                                                                         placeholder='Country...'
                                                                         components={{
                                                                             Option
                                                                         }}
                                                            />
                                                        </div>
                                                        <div className="validation-err font-inter inter-regular">{form.touched.countries && form.errors.countries}</div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="react-select-container">
                                                            <ReactSelect options={statesData}
                                                                         styles={getReactSelectCustomStyles(form, 'states')}
                                                                         isMulti
                                                                         closeMenuOnSelect={true}
                                                                         hideSelectedOptions={false}
                                                                         onChange={(e) => form.setFieldValue('states', e)}
                                                                         value={form.values.states}
                                                                         placeholder='State...'
                                                                         components={{
                                                                             Option
                                                                         }}
                                                            />
                                                        </div>
                                                        <div className="validation-err font-inter inter-regular">{form.touched.states && form.errors.states}</div>
                                                    </Col>
                                                </>
                                            )}
                                        </Row>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start mb-1">
                                    <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                    <div className="w-100">
                                        <FormGroup className="mb-0">
                                            <CustomLabel formik={form}
                                                         id='expiry'
                                                         title='When would you like to close this opportunity?'
                                                         isRequired={false}
                                            />
                                            <Flatpickr id="expiry"
                                                       value={(form.values.expiry)}
                                                       className={classnames('form-control font-inter inter-regular', {'is-invalid': form.touched.expiry && form.errors.expiry})}
                                                       options={SCHEDULE_DATE_OPTIONS}
                                                       onChange={(e) => {
                                                           form.setFieldValue('expiry', e)
                                                       }}
                                                       placeholder="Choose End Date"
                                                       style={{backgroundColor: '#FFF', opacity: '1'}}
                                                       disabled={form.values.isExpiry || campaignSaveLoading}
                                            />
                                            <div className="validation-err">{form.touched.expiry && form.errors.expiry}</div>
                                        </FormGroup>
                                        <p className="text-muted mb-50 font-inter inter-regular">
                                        All Outreach Posts are set to expire/renew after 3 months
                                        </p>
                                        <FormGroup check className="d-flex align-items-center pl-0">
                                            <CustomInput id="isExpiry"
                                                         name="isExpiry"
                                                         type="checkbox"
                                                         checked={form.values.isExpiry}
                                                         onChange={(e) => {
                                                             form.setFieldValue('expiry', '')
                                                             form.setFieldValue('isExpiry', e.target.checked)
                                                         }}
                                                         disabled={campaignSaveLoading}
                                                         label='No specific end date'
                                                         className="font-inter inter-regular"
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start mb-1">
                                    <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                    <div className="w-100">
                                        <FormGroup className="mb-0">
                                            <CustomLabel formik={form}
                                                         id='applicants'
                                                         title='Would you like to cap the number of applicants?'
                                                         isRequired={false}
                                            />
                                        </FormGroup>
                                        <Input id="applicants"
                                               name="applicants"
                                               placeholder="Add Limit"
                                               type="number"
                                               value={form.values.applicants}
                                               onBlur={form.handleBlur}
                                               onChange={form.handleChange}
                                               disabled={form.values.noCap || campaignSaveLoading}
                                               className={classnames('mb-50', {'is-invalid': form.touched.applicants && form.errors.applicants})}
                                        />
                                        <div className="validation-err font-inter inter-regular">{form.touched.applicants && form.errors.applicants}</div>
                                        <p className="text-muted mb-50 font-inter inter-regular">
                                            This will allow unlimited RDs to apply
                                        </p>
                                        <FormGroup check className="d-flex align-items-center pl-0">
                                            <CustomInput id="noCap"
                                                         name="noCap"
                                                         type="checkbox"
                                                         checked={form.values.noCap}
                                                         onChange={(e) => {
                                                             form.setFieldValue('applicants', '')
                                                             form.setFieldValue('noCap', e.target.checked)
                                                         }}
                                                         disabled={campaignSaveLoading}
                                                         label='No Cap'
                                                         className="font-inter inter-regular"
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Form onSubmit={form.handleSubmit}>
                                    {form?.values?.type?.id === 1 && (
                                        <>
                                            <CardTitle className="name font-inter inter-medium" tag="h4">
                                                Submission Form
                                            </CardTitle>
                                            <div className="d-flex align-items-start mb-1">
                                                <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                                <div className="w-100">
                                                    <FormGroup>
                                                        <CustomLabel formik={form}
                                                                     id='doYouProvideSamplesToRDs'
                                                                     title='Do you provide samples to new members?'
                                                        />
                                                        <Row>
                                                            <Col md="6">
                                                                <FormGroup check className="d-flex align-items-center pl-0">
                                                                    <CustomInput type="radio"
                                                                                 name="doYouProvideSamplesToRDs"
                                                                                 value="Yes"
                                                                                 checked={form.values.doYouProvideSamplesToRDs === 'Yes'}
                                                                                 id="doYouProvideSamplesToRDsYes"
                                                                                 onChange={(e) => {
                                                                                     form.setFieldValue('doYouProvideSamplesToRDs', 'Yes')
                                                                                 }}
                                                                                 disabled={campaignSaveLoading}
                                                                                 label={<span className="radio-label">Yes</span>}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="6">
                                                                <FormGroup check className="d-flex align-items-center pl-0">
                                                                    <CustomInput type="radio"
                                                                                 name="doYouProvideSamplesToRDs"
                                                                                 value="No"
                                                                                 checked={form.values.doYouProvideSamplesToRDs === 'No'}
                                                                                 id="doYouProvideSamplesToRDsNo"
                                                                                 onChange={(e) => {
                                                                                     form.setFieldValue('doYouProvideSamplesToRDs', 'No')
                                                                                 }}
                                                                                 disabled={campaignSaveLoading}
                                                                                 label={<span className="radio-label">No</span>}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-start mb-1">
                                                <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                                <div className="w-100">
                                                    <FormGroup>
                                                        <CustomLabel formik={form}
                                                                     id='benefits'
                                                                     title='What benefits do RDs receive when they join your program?'
                                                                     isRequired={false}
                                                        />
                                                        <Row>
                                                            <Col md="12">
                                                                <FormGroup check className="d-flex align-items-center pl-0 font-inter inter-regular">
                                                                    <Input style={{fontFamily: 'Inter'}} id="benefits"
                                                                           name="benefits"
                                                                           placeholder="RDs receive discounts for patients, educational opportunities, features on our blog, first access to new products, and more"
                                                                           type="textarea"
                                                                           rows={4}
                                                                           value={form.values.benefits}
                                                                           onBlur={form.handleBlur}
                                                                           onChange={form.handleChange}
                                                                           className={classnames({'is-invalid font-inter inter-regular': form.touched.benefits && form.errors.benefits})}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-start mb-1">
                                                <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                                <div className="w-100">
                                                    <FormGroup>
                                                        <CustomLabel formik={form}
                                                                     id='isHosted'
                                                                     title='Is your program hosted on an external platform?'
                                                        />
                                                        <Row>
                                                            <Col md="6">
                                                                <FormGroup check className="d-flex align-items-center pl-0">
                                                                    <CustomInput type="radio"
                                                                                 name="isHosted"
                                                                                 value="Yes"
                                                                                 checked={form.values.isHosted === 'Yes'}
                                                                                 id="isHostedYes"
                                                                                 onChange={(e) => {
                                                                                     form.setFieldValue('isHosted', 'Yes')
                                                                                 }}
                                                                                 disabled={campaignSaveLoading}
                                                                                 label={<span className="radio-label">Yes</span>}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="6">
                                                                <FormGroup check className="d-flex align-items-center pl-0">
                                                                    <CustomInput type="radio"
                                                                                 name="isHosted"
                                                                                 value="No"
                                                                                 checked={form.values.isHosted === 'No'}
                                                                                 id="isHostedNo"
                                                                                 onChange={(e) => {
                                                                                     form.setFieldValue('isHosted', 'No')
                                                                                     form.setFieldValue('hostedUrl', '')
                                                                                 }}
                                                                                 disabled={campaignSaveLoading}
                                                                                 label={<span className="radio-label">No</span>}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            {form.values.isHosted === 'Yes' && (
                                                <FormGroup>
                                                    <CustomLabel formik={form}
                                                                 id='hostedUrl'
                                                                 title='Please provide the sign-up URL'
                                                    />
                                                    <Input id="hostedUrl"
                                                           name="hostedUrl"
                                                           placeholder="Sign-up URL"
                                                           type="text"
                                                           value={form.values.hostedUrl}
                                                           onBlur={form.handleBlur}
                                                           onChange={form.handleChange}
                                                           className={classnames({'is-invalid': form.touched.hostedUrl && form.errors.hostedUrl})}
                                                           disabled={campaignSaveLoading}
                                                    />
                                                    {form.touched.hostedUrl && <span className="validation-err">{form.errors.hostedUrl}</span>}
                                                </FormGroup>
                                            )}
                                        </>
                                    )}

                                    {form?.values?.type?.id === 2 && (
                                        <>
                                            <CardTitle className="name font-inter inter-medium" tag="h4">
                                               Submission Form
                                            </CardTitle>
                                            <div className="d-flex align-items-start mb-1">
                                                <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                                <div className="w-100">
                                                    <FormGroup>
                                                        <CustomLabel formik={form}
                                                                     id='doYouShipToCanadaPOAPO'
                                                                     title='Do you ship to Canada,PO Boxes, APO Boxes?'
                                                        />
                                                        <Row>
                                                            <Col md="6">
                                                                <FormGroup check className="d-flex align-items-center pl-0">
                                                                    <CustomInput type="radio"
                                                                                 name="doYouShipToCanadaPOAPO"
                                                                                 value="Yes"
                                                                                 checked={form.values.doYouShipToCanadaPOAPO === 'Yes'}
                                                                                 id="doYouShipToCanadaPOAPOYes"
                                                                                 onChange={(e) => {
                                                                                     form.setFieldValue('doYouShipToCanadaPOAPO', 'Yes')
                                                                                 }}
                                                                                 disabled={campaignSaveLoading}
                                                                                 label={<span className="radio-label">Yes</span>}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="6">
                                                                <FormGroup check className="d-flex align-items-center pl-0">
                                                                    <CustomInput type="radio"
                                                                                 name="doYouShipToCanadaPOAPO"
                                                                                 value="No"
                                                                                 checked={form.values.doYouShipToCanadaPOAPO === 'No'}
                                                                                 id="doYouShipToCanadaPOAPONo"
                                                                                 onChange={(e) => {
                                                                                     form.setFieldValue('doYouShipToCanadaPOAPO', 'No')
                                                                                 }}
                                                                                 disabled={campaignSaveLoading}
                                                                                 label={<span className="radio-label">No</span>}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {form?.values?.type?.id === 3 && (
                                        <>
                                            <CardTitle className="name font-inter inter-medium" tag="h4">
                                                Submission Form
                                            </CardTitle>
                                            <div className="d-flex align-items-start mb-1">
                                                <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                                <div className="w-100">
                                                    <FormGroup>
                                                        <CustomLabel formik={form}
                                                                     id='rdRequirements'
                                                                     title='RD Requirements: Who is this a good fit for?'
                                                        />
                                                        <Input id="rdRequirements"
                                                               name="rdRequirements"
                                                               placeholder="Describe the ideal fit..."
                                                               type="textarea"
                                                               rows='1'
                                                               value={form.values.rdRequirements}
                                                               onBlur={form.handleBlur}
                                                               onChange={form.handleChange}
                                                               className={classnames({'is-invalid': form.touched.rdRequirements && form.errors.rdRequirements})}
                                                               disabled={campaignSaveLoading}
                                                        />
                                                        <div className="validation-err">{form.touched.rdRequirements && form.errors.rdRequirements}</div>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-start mb-1">
                                                <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                                <div className="w-100">
                                                    <FormGroup>
                                                        <CustomLabel formik={form}
                                                                     id='partnershipRequirementsAndGuidelines'
                                                                     title='Partnership Requirements & Guidelines: What are you asking the RD to do? Include talking points, how their content will be used, types of content you are seeking, deadlines, etc.'
                                                        />
                                                        <Input id="partnershipRequirementsAndGuidelines"
                                                               name="partnershipRequirementsAndGuidelines"
                                                               placeholder="Outline RD tasks, content usage, and deadlines..."
                                                               type="textarea"
                                                               rows='1'
                                                               value={form.values.partnershipRequirementsAndGuidelines}
                                                               onBlur={form.handleBlur}
                                                               onChange={form.handleChange}
                                                               className={classnames({'is-invalid': form.touched.partnershipRequirementsAndGuidelines && form.errors.partnershipRequirementsAndGuidelines})}
                                                               disabled={campaignSaveLoading}
                                                        />
                                                        <div
                                                            className="validation-err">{form.touched.partnershipRequirementsAndGuidelines && form.errors.partnershipRequirementsAndGuidelines}</div>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-start mb-1">
                                                <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                                <div className="w-100">
                                                    <FormGroup>
                                                        <CustomLabel formik={form}
                                                                     id='budgetPerRD'
                                                                     title='Compensation - Starting at, range, or flat rate'
                                                        />
                                                        <Input id="budgetPerRD"
                                                               name="budgetPerRD"
                                                               placeholder="Enter starting rate, range, or flat fee..."
                                                               type="text"
                                                               value={form.values.budgetPerRD}
                                                               onBlur={form.handleBlur}
                                                               onChange={form.handleChange}
                                                               className={classnames({'is-invalid': form.touched.budgetPerRD && form.errors.budgetPerRD})}
                                                               disabled={campaignSaveLoading}
                                                        />
                                                        <div className="validation-err">{form.touched.budgetPerRD && form.errors.budgetPerRD}</div>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Form>

                                {form?.values?.type?.id && form?.values?.type?.id !== 1 && (
                                    <>
                                        <CardTitle className={`name font-inter inter-medium ${(form?.values?.type?.id === 4 || form?.values?.type?.id === 5) ? 'mt-0' : 'mt-3'}`}
                                                   tag="h4">
                                            Add Question
                                        </CardTitle>
                                        {form.values.campaign_form_questions && form.values.campaign_form_questions?.length > 0 && form.values.campaign_form_questions?.map((campaign, index) => {
                                            return (
                                                <div className="d-flex align-items-center mb-1" key={`render-${index}`}>
                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                        <div className="w-100">
                                                            <FormGroup className="mb-0 d-flex inter-medium">
                                                                <div className="mr-25">{index + 1}.</div>
                                                                <div className="word-break font-inter"
                                                                     dangerouslySetInnerHTML={{
                                                                         __html: campaign?.question?.replace(/\r\n/g, "<br />")
                                                                     }}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <button className="mr-50 outline-none border-0 bg-transparent font-inter inter-medium"
                                                                    onClick={() => deleteQuestion(index)}>
                                                                <img src={DeleteBtn} alt="icon"/>
                                                            </button>
                                                            <button className="outline-none border-0 bg-transparent font-inter inter-medium" onClick={() => editQuestion(index)}>
                                                                <img src={EditBtn} alt="icon"/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {formAddQuestion.values.clickAdd === false && (
                                            <>
                                                <Button.Ripple color="light" className='mb-2 font-inter inter-medium btn_color' onClick={addField}>
                                                    + Add New
                                                </Button.Ripple>
                                                <div
                                                    className="validation-err font-inter inter-regular">{form.touched.campaign_form_questions && form.errors.campaign_form_questions}</div>
                                            </>
                                        )}
                                        {formAddQuestion.values.clickAdd === true && formAddQuestion.values.type === '' && (
                                            <FormGroup>
                                                <CustomLabel formik={formAddQuestion}
                                                             id='type'
                                                             title='Type of Question'
                                                />
                                                <Input type="select"
                                                       onChange={(e) => formAddQuestion.setFieldValue('type', e.target.value)}
                                                >
                                                    <option value="">---Select---</option>
                                                    <option value="multiple">Multiple Choice</option>
                                                    <option value="textField">Text Field</option>
                                                </Input>
                                                <div className="validation-err">{form.touched.campaign_form_questions && form.errors.campaign_form_questions}</div>
                                            </FormGroup>
                                        )}
                                        <Form onSubmit={formAddQuestion.handleSubmit}>
                                            {formAddQuestion.values.type !== '' && (
                                                <>
                                                    <FormGroup className="mt-1">
                                                        <CustomLabel formik={formAddQuestion}
                                                                     id='question'
                                                                     title='Include any additional questions you have for the RDs here'
                                                        />
                                                        <Input id="question"
                                                               name="question"
                                                               placeholder="Type question here..."
                                                               type="textarea"
                                                               rows={1}
                                                               value={formAddQuestion.values.question}
                                                               onBlur={formAddQuestion.handleBlur}
                                                               onChange={formAddQuestion.handleChange}
                                                               className={classnames({'is-invalid font-inter inter-regular': formAddQuestion.touched.question && formAddQuestion.errors.question})}
                                                        />
                                                    </FormGroup>
                                                    {formAddQuestion.values.type === 'multiple' && formAddQuestion?.values?.campaign_question_options?.map((field, index) => (
                                                        <FormGroup key={`option-${index}`} className="d-flex align-items-center">
                                                            <FormGroup check className="w-100 d-flex align-items-center pl-0">
                                                                <CustomInput className="mt-1"
                                                                             id={`checkbox-${index}`}
                                                                             name={`checkbox-${index}`}
                                                                             type="checkbox"
                                                                             disabled={true}
                                                                />
                                                                {" "}
                                                                <Input type="text"
                                                                       placeholder={`Option ${index + 1}`}
                                                                       name={`campaign_question_options[${index}].option`}
                                                                       value={field?.option}
                                                                       onChange={formAddQuestion.handleChange}
                                                                       onBlur={formAddQuestion.handleBlur}
                                                                       className={classnames("ml-50 font-inter inter-regular", {
                                                                           "is-invalid":
                                                                               formAddQuestion.touched.campaign_question_options?.[index]?.option &&
                                                                               formAddQuestion.errors.campaign_question_options?.[index]?.option
                                                                       })}
                                                                />
                                                                {formAddQuestion.touched.campaign_question_options?.[index]?.option &&
                                                                    formAddQuestion.errors.campaign_question_options?.[index]?.option && (
                                                                        <div className="validation-err">
                                                                            {formAddQuestion.errors.campaign_question_options[index].option}
                                                                        </div>
                                                                    )}
                                                            </FormGroup>
                                                            {index > 1 && (
                                                                <img src={Minus}
                                                                     alt="icon"
                                                                     className="ml-1 cursor-pointer"
                                                                     onClick={() => removeOption(index)}
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    ))}
                                                    {formAddQuestion.values.type === 'multiple' && (
                                                        <div className="mb-3">
                                                            <Button color="link" className="p-0 font-inter inter-medium" onClick={() => addOption()}>
                                                                + Add option
                                                            </Button>
                                                        </div>
                                                    )}
                                                    <div className="d-flex justify-content-end">
                                                        <Button outline color="primary" className="mr-1 outline-Btn font-inter inter-medium" onClick={() => {
                                                            formAddQuestion.resetForm()
                                                        }}>
                                                            Cancel
                                                        </Button>
                                                        <Button color="primary" type='submit' className="font-inter inter-medium">
                                                            {formAddQuestion.values.editIndex >= 0 ? 'Update' : 'Save'}
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </>
                                )}
                                <div className="validation-err font-inter inter-regular">{form.touched.banner && form.errors.banner}</div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <div className="d-flex align-items-center justify-content-between footer-btn">
                                    <div>
                                        <button className='btn btn-light font-inter inter-medium btn_color'
                                                onClick={() => {
                                                    dispatch(viewCampaignReset())
                                                    history.push(`/brands/rd-view/${id}`)
                                                }}
                                                disabled={campaignSaveLoading}
                                        >
                                            View as RD
                                        </button>
                                    </div>
                                    <div>
                                        <Button.Ripple color="light"
                                                       className="mr-1 font-inter inter-medium btn_color"
                                                       disabled={campaignSaveLoading || formAddQuestion.values.clickAdd}
                                                       onClick={() => {
                                                           form.submitForm()
                                                       }}
                                        >
                                            {campaignSaveLoading ? 'Please wait...' : 'Save Changes'}
                                        </Button.Ripple>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </>
                ) : (
                    <div className='font-inter inter-medium text-center'>
                        <div className='mb-1'>Sorry! Outreach not found.</div>
                        <Link className='text-primary font-inter inter-medium' to='/brands/outreach-posts'>Go Back</Link>
                    </div>
                )
            )}
            {/* --- Default Banner Modal --- */}
            <DefaultBanners openModal={selectDefaultBanner}
                            closeModal={() => setSelectDefaultBanner(false)}
                            onClick={(image) => {
                                selectDefaultImage(image)
                            }}
            />
        </div>
    )
}

export default ReviewPartnershipDetails
