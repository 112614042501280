import {Modal, ModalBody, ModalHeader} from "reactstrap"
import ReactPlayer from 'react-player'
import {PropTypes} from "prop-types"

const VideoPlayer = ({show, hide, url, filename}) => {
    return (
        <>
            <Modal isOpen={show} className='modal-dialog-centered' size="lg">
                <ModalHeader toggle={hide}>
                    {filename}
                </ModalHeader>
                <ModalBody>
                    <ReactPlayer url={url} controls={true} width='100%' height='50vh' playing={true} muted={true}/>
                </ModalBody>
            </Modal>
        </>
    )
}

VideoPlayer.propTypes = {
    show: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired
}

export default VideoPlayer
