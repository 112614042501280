import {useEffect, useState} from 'react'
import {useSkin} from '@hooks/useSkin'
import {Link, useHistory, useParams} from 'react-router-dom'
import {ChevronLeft} from 'react-feather'
import InputPassword from '@components/input-password-toggle'
import '@styles/base/pages/page-auth.scss'
import {Formik} from "formik"
import * as yup from "yup"
import classnames from 'classnames'
import {useDispatch, useSelector} from 'react-redux'
import {Row, Col, CardTitle, CardText, Form, Label, Button, Alert} from 'reactstrap'
import {resetMyPassword, nullResponse} from '@store/actions/auth'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ResetPasswordV2 = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [visible, setVisible] = useState(false)
    const [isReseting, setIsReseting] = useState(false)

    const [captchaValue, setCaptchaValue] = useState(null)
    const [captchaError, setCaptchaError] = useState(false)

    const [skin, setSkin] = useSkin()
    const history = useHistory()
    const {token} = useParams()
    const authSelector = useSelector(store => store.auth)
    const dispatch = useDispatch()

    const sweetAlert = withReactContent(Swal)

    useEffect(() => {
        const resetPasswordResponse = authSelector?.resetPasswordResponse ?? null
        if (resetPasswordResponse) {
            const {status, msg} = resetPasswordResponse
            setVisible(true)
            setIsReseting(false)
            setCaptchaError(false)
            setCaptchaValue(null)
            dispatch(nullResponse('USER_RESET_PASSWORD'))
            if (!status) {
                setErrorMessage(msg)
                const timer = setTimeout(() => {
                    setVisible(false)
                    setErrorMessage("")
                    clearTimeout(timer)
                }, 5000)
            } else {
                setSuccessMessage(msg)
                const timer = setTimeout(() => {
                    setVisible(false)
                    setSuccessMessage("")
                    history.push('/login')
                    clearTimeout(timer)
                }, 5000)
            }
        }
    }, [authSelector])

    useEffect(() => {
        setVisible(false)
        setErrorMessage("")
        setSuccessMessage("")
    }, [])

    const illustration = skin === 'dark' ? 'reset-password-v2-dark.svg' : 'reset-password-v2.svg',
        source = require(`@src/assets/images/pages/${illustration}`).default

    const resetPassword = async (values) => {
        setIsReseting(true)
        const cToken = await executeRecaptcha('reset_passwor_v2')
        if (cToken) {
            const params = {
                token,
                new_password: values?.new_password,
                confirm_password: values?.confirm_password,
                re_captcha_token: cToken
            }
            dispatch(resetMyPassword(params))
        } else {
            sweetAlert.fire({
                title: 'Captcha Error',
                icon: 'error',
                html: '<p>Execute recaptcha not yet available.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
    }

    return (
        <div className='auth-wrapper auth-v2'>
            <Row className='auth-inner m-0'>
                <Link className='brand-logo' to='/' onClick={e => e.preventDefault()}>
                    <h2 className='brand-text text-primary ml-1'>RD LINK</h2>
                </Link>
                <Col className='d-none d-lg-flex align-items-center p-5' lg='7' sm='12'>
                    <div className='w-100 d-lg-flex align-items-center justify-content-center px-5'>
                        <img className='img-fluid' src={source} alt='Login V2'/>
                    </div>
                </Col>
                <Col className='d-flex align-items-center auth-bg px-2 p-lg-5' lg='5' sm='12'>
                    <Col className='px-xl-2 mx-auto' sm='8' md='6' lg='12'>
                        <CardTitle tag='h2' className='font-weight-bold mb-1'>
                            Reset Password 🔒
                        </CardTitle>
                        <CardText className='mb-2'>Please set your new password</CardText>
                        <Alert color={!!errorMessage ? 'danger' : 'success'} isOpen={visible} toggle={() => setVisible(false)}>
                            <div className='alert-body'>
                                {errorMessage || successMessage}
                            </div>
                        </Alert>
                        <Formik
                            initialValues={{
                                new_password: "",
                                confirm_password: ""
                            }}
                            validationSchema={yup.object().shape({
                                new_password: yup.string().min(8)
                                    .max(12).matches(
                                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                        "Must contain 8 characters, one uppercase, one lowercase, one number, and one special character"
                                    )
                                    .required("This field is required"),
                                confirm_password: yup.string().oneOf(
                                    [yup.ref("new_password"), null],
                                    "Passwords must match."
                                )
                            })}
                            onSubmit={(values) => {
                                resetPassword(values)
                            }}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  touched,
                                  values
                              }) => (
                                <Form className='auth-reset-password-form mt-2' onSubmit={handleSubmit}>
                                    <div className='form-group form-password-toggle'>
                                        <Label className={classnames({
                                            'is-invalid': touched.new_password && errors.new_password,
                                            'form-label': true
                                        })} htmlFor={`new_password`}>
                                            New Password<span className="is-invalid">*</span>
                                        </Label>
                                        <InputPassword
                                            name='new_password'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.new_password}
                                            id='new-password'
                                            className={classnames('input-group-merge', {'is-invalid': touched.new_password && errors.new_password})}
                                        />
                                        <div className="validation-err">{touched.new_password && errors.new_password}</div>
                                    </div>

                                    <div className='form-group form-password-toggle'>
                                        <Label className={classnames({
                                            'is-invalid': touched.confirm_password && errors.confirm_password,
                                            'form-label': true
                                        })} htmlFor={`confirm_password`}>
                                            Confirm Password<span className="is-invalid">*</span>
                                        </Label>
                                        <InputPassword
                                            name='confirm_password'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.confirm_password}
                                            id='confirm-password'
                                            className={classnames('input-group-merge', {'is-invalid': touched.confirm_password && errors.confirm_password})}
                                        />
                                        <div className="validation-err">{touched.confirm_password && errors.confirm_password}</div>
                                    </div>
                                    <Button.Ripple type='submit' disabled={isReseting} color='primary' block>
                                        {isReseting ? 'Loading...' : 'Set New Password'}
                                    </Button.Ripple>
                                </Form>)}
                        </Formik>
                        <p className='text-center mt-2'>
                            <Link to='/login'>
                                <ChevronLeft className='mr-25' size={14}/>
                                <span className='align-middle'>Back to login</span>
                            </Link>
                        </p>
                    </Col>
                </Col>
            </Row>
        </div>
    )
}

export default ResetPasswordV2
