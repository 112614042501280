import React, {useState, Fragment, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Edit2} from 'react-feather'
import {
    Table,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Input,
    Label,
    Col,
    Spinner, CardHeader, CardTitle, Card
} from 'reactstrap'

import {getAccountSettingsData, updateAccountSettingsData} from '@src/views/tables/reactstrap/admin/admin-account-settings/store/actions'

const AccountSettings = () => {
    const settingsSelector = useSelector(store => store.adminAccountSettings)
    const [isTableLoading, setIsTableLoading] = useState(false)


    const dispatch = useDispatch()
    useEffect(() => {
        const accountSettings = settingsSelector?.accountSettings ?? null
        if (accountSettings) {
            setIsTableLoading(false)
        }
    }, [settingsSelector])
    useEffect(() => {
        setIsTableLoading(true)
        dispatch(getAccountSettingsData())
    }, [])
    const [openModal, setOpenModal] = useState(false)
    const [selectedSetting, setSelectedSetting] = useState('')
    const [selectedSettingLabel, setSelectedSettingLabel] = useState('')
    const [selectedSettingValue, setSelectedSettingValue] = useState('')
    const [selectedSettingKey, setSelectedSettingKey] = useState('')

    const editSettings = (inputName, title, value, key) => {
        setSelectedSetting(inputName)
        setSelectedSettingLabel(title)
        setSelectedSettingValue(value)
        setSelectedSettingKey(key)
        setOpenModal(true)
    }

    const updateAccountSettings = () => {
        const params = {
            settingKey: selectedSettingKey,
            settingValue: selectedSettingValue
        }
        dispatch(updateAccountSettingsData(params))
        setOpenModal(!openModal)
        dispatch(getAccountSettingsData())
        setIsTableLoading(true)
        dispatch(getAccountSettingsData())
    }
    return (
        <Fragment>
            <Card>
                <CardHeader className='border-bottom'>
                    <CardTitle tag='h4'>Account Settings</CardTitle>
                </CardHeader>
            </Card>
            {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
            {!isTableLoading && (
                <Table responsive>
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Value</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {settingsSelector?.accountSettings?.map(x => (
                        <tr key={x.id}>
                            <td><span className='align-middle font-weight-bold'>{x.title}</span></td>
                            <td><span className='align-middle font-weight-bold'>{x.setting_value}</span></td>
                            <td>
                                <span className='align-middle font-weight-bold'>
                                    <Button.Ripple className='btn-icon' outline color='primary' onClick={() => editSettings(x.inputName, x.title, x.setting_value, x.setting_key)}>
                                        <Edit2 siselectedSettingLabelze={16}/>
                                    </Button.Ripple>
                                </span>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}

            <Modal isOpen={openModal} toggle={() => setOpenModal(!openModal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setOpenModal(!openModal)}>Edit {selectedSettingLabel}</ModalHeader>
                <ModalBody>
                    <FormGroup tag={Col} md='12'>
                        <Label htmlFor={selectedSetting}>
                            {selectedSettingLabel}
                        </Label>
                        <Input
                            type={selectedSetting === 'contact-email' ? 'email' : 'text'}
                            onChange={(e) => setSelectedSettingValue(e.target.value)}
                            name={selectedSetting}
                            id={selectedSetting}
                            placeholder=''
                            value={selectedSettingValue}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={updateAccountSettings}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default AccountSettings
