import React, {useEffect, useState} from 'react'
import {
    Button,
    Card,
    CardBody,
    Col,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    CustomInput,
    UncontrolledTooltip
} from "reactstrap"
import BlankLogo from "@src/assets/images/avatars/Blank-logo.jpg"
import {useFormik} from "formik"
import * as yup from "yup"
import moment from "moment"
import momentTime from "moment-timezone"
import classnames from "classnames"
import Flatpickr from "react-flatpickr"
import {ArrowLeftCircle, X} from "react-feather"
import {useDispatch, useSelector} from "react-redux"
import {
    recRDCampaign,
    recRDCampaignReset,
    secPubCampaign,
    secPubCampaignReset,
    getFavFolders,
    getFavFoldersReset,
    getFavProfiles,
    getFavProfilesReset,
    getMyRdsReset,
    getMyRds
} from "@store/actions/BrandActions"
import {getBrandGoalsSelectValues} from '@store/actions/auth'
import Select from 'react-select'
import {
    getCountries,
    getStates
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import FilterIcon from '@src/assets/images/filters.svg'
import CustomPagination from "@src/views/components/pagination/CustomPagination"
import {
    SCHEDULE_DATE_OPTIONS,
    MODAL_PAGINATION_SIZE,
    generatePaginationLinks,
    errorAlert
} from "./Controller"
import {
    inviteCampaignOnEmail,
    inviteCampaignOnEmailReset,
    inviteCampaignOnMsg,
    inviteCampaignOnMsgReset
} from '@src/views/brands/myCampaigns/store/actions'
import CustomLabel from './CustomLabel'

const PublishSchedule = ({openPubSecModal, setOpenPubSecModal, data, onSuccess, id, action}) => {

    const dispatch = useDispatch()

    const [selectAllRecRds, setSelectAllRecRds] = useState(true)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState([])
    const [selectedExperts, setSelectedExperts] = useState([])
    const [selectedTab, setSelectedTab] = useState("recommended")
    const [folderData, setFolderData] = useState(null)
    const [countryValue, setCountryValue] = useState([])
    const [stateValue, setStateValue] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [paginatedData, setPaginatedData] = useState([])
    const [paginatedMeta, setPaginatedMeta] = useState(null)
    const [statesData, setStatesData] = useState([])

    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState)

    const {
        brandGoalsSelectValues
    } = useSelector(state => state.auth)

    const {
        recRDCampaignLoading,
        recRDCampaignData,
        recRDCampaignError,

        secPubCampaignLoading,
        secPubCampaignData,
        secPubCampaignError,

        getFavFolderLoading,
        getFavFolderData,
        getFavFolderError,

        getFavProfileLoading,
        getFavProfileData,
        getFavProfileError,

        myRDsLoading,
        myRDsData,
        myRDsError
    } = useSelector(state => state.brandReducer)

    const {
        favoriteFoldersRes
    } = useSelector(store => store.brandsDashboardSlice)

    const {
        countries,
        states,

        inviteOnEmailLoading,
        inviteOnEmailFullFill,

        inviteOnMsgLoading,
        inviteOnMsgFullFill
    } = useSelector((store) => store.brandsOppoprunities)

    const toggleExpert = (expert) => {
        setSelectAllRecRds(false)
        setSelectedExperts((prevSelectedExperts) => {
            const isExpertSelected = prevSelectedExperts?.includes(expert?.id)
            if (isExpertSelected) {
                return prevSelectedExperts.filter(id => id !== expert?.id)
            } else {
                return [...(prevSelectedExperts || []), expert?.id]
            }
        })
    }

    const selectAll = (event, data) => {
        const isChecked = event.target.checked
        setSelectAllRecRds(isChecked)
        if (isChecked) {
            const selectedExpertIds = data?.map(item => item.id)
            setSelectedExperts(selectedExpertIds)
        } else {
            setSelectedExperts([])
        }
    }

    const formatOptions = (data, type) => {
        if (data) {
            const result = data?.map((item) => {
                return {...item, type}
            })
            return result
        }
    }

    const rdOptions = [
        {
            id: 1,
            label: "Speciality",
            options: formatOptions(brandGoalsSelectValues?.speciality, 'speciality')
        },
        {
            id: 2,
            label: "Industry",
            options: formatOptions(brandGoalsSelectValues?.industry, 'industry')
        },
        {
            id: 3,
            label: "Personal Characteristics",
            options: formatOptions(brandGoalsSelectValues?.personal_attributes, 'personal_attributes')
        }
    ]

    const getRds = () => {
        const obj = {
            filter: 'list'
        }
        const speciality = data?.get_specialities?.map((item) => item.dietitian_id)
        const industry = data?.get_industries?.map((item) => item.dietitian_id)
        const personal_attr = data?.get_personal_attr?.map((item) => item.dietitian_id)
        const countries = data?.get_countries?.map((item) => item.country.id)
        const states = data?.get_states?.map((item) => item?.state?.id)

        if (speciality?.length > 0) {
            obj.speciality = speciality
        }

        if (industry?.length > 0) {
            obj.industry = industry
        }

        if (personal_attr?.length > 0) {
            obj.personal_attr = personal_attr
        }

        if (countries?.length > 0) {
            obj.country = countries
        }

        if (states?.length > 0) {
            obj.state = states
        }

        dispatch(recRDCampaign(obj))
    }

    const handleFilterChange = (e, filterObject) => {
        const {checked} = e.target
        setSelectedFilters((prevFilters) => {
            return checked ? [...prevFilters, filterObject] : prevFilters.filter((filter) => filter.id !== filterObject.id || filter.type !== filterObject.type)
        })

    }

    const createPagination = (data, page) => {
        if (data?.length > 0) {
            const startIndex = ((page ?? 1) - 1) * MODAL_PAGINATION_SIZE
            const endIndex = startIndex + MODAL_PAGINATION_SIZE
            const sortedData = data?.slice().sort((a, b) => a?.name.localeCompare(b?.name))
            setPaginatedData(sortedData?.slice(startIndex, endIndex))
            setPaginatedMeta({
                totalItems: data?.length,
                totalPages: Math.ceil(data?.length / MODAL_PAGINATION_SIZE),
                startIndex,
                endIndex
            })
        }
    }

    useEffect(() => {
        if (openPubSecModal) {
            dispatch(getCountries())
            dispatch(getStates())
            dispatch(getBrandGoalsSelectValues())
            dispatch(recRDCampaignReset())
            dispatch(getFavFoldersReset())
            dispatch(getFavProfilesReset())
            dispatch(getMyRdsReset())
            setCurrentPage(1)
            setPaginatedMeta(null)
            setSelectAllRecRds(true)
            setPaginatedData([])
            getRds()
            if ((action === 'schedule' || action === 'publish')) {
                setSelectedTab('recommended')
            } else {
                setSelectedTab('all')
            }
        }
    }, [openPubSecModal])

    useEffect(() => {
        if (recRDCampaignData?.data) {
            const selectedExpertIds = recRDCampaignData?.data?.map(item => item.id)
            setSelectedExperts(selectedExpertIds)
            createPagination(recRDCampaignData?.data)
        }

        if (getFavProfileData?.data) {
            const selectedExpertIds = getFavProfileData?.data?.map(item => item.id)
            setSelectedExperts(selectedExpertIds)
            createPagination(getFavProfileData?.data)
        }

        if (secPubCampaignData?.success) {
            dispatch(secPubCampaignReset())
            onSuccess(secPubCampaignData?.msg)
        }

        if (favoriteFoldersRes?.data) {
            setFolderData({...folderData, folderLoading: false})
        }

        if (myRDsData?.data) {
            const selectedExpertIds = myRDsData?.data?.map(item => item.id)
            setSelectedExperts(selectedExpertIds)
            createPagination(myRDsData?.data)
        }

        if (inviteOnEmailFullFill?.status && inviteOnMsgFullFill?.status) {
            onSuccess('Invitation sent successfully.')
            dispatch(inviteCampaignOnEmailReset())
            dispatch(inviteCampaignOnMsgReset())
        }

    }, [
        recRDCampaignData,
        secPubCampaignData,
        favoriteFoldersRes,
        getFavProfileData,
        myRDsData,
        inviteOnEmailFullFill,
        inviteOnMsgFullFill
    ])

    useEffect(() => {
        if (secPubCampaignError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (secPubCampaignError?.response?.data?.errors?.publish_datetime) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${secPubCampaignError?.response?.data?.errors?.publish_datetime[0]}</li>`)
            }
            errorHTML.push('</ul>')
            errorAlert(errorHTML.join(''))
            dispatch(secPubCampaignReset())
        }
    }, [secPubCampaignError])

    const secPubCampaignHandle = (data) => {
        const obj = {
            campaign_id: id,
            rd_ids: selectedExperts
        }
        if (data?.publish_datetime) {
            obj.schedule = data?.publish_datetime
        }
        if (data?.time_zone) {
            obj.time_zone = data?.time_zone
        }
        dispatch(secPubCampaign(obj))
    }

    const applyFilters = () => {
        const obj = {
            filter: 'list'
        }
        const speciality = selectedFilters?.filter((item) => item?.type === 'speciality').map((item) => item.id)
        const industry = selectedFilters?.filter((item) => item?.type === 'industry').map((item) => item.id)
        const personal_attr = selectedFilters?.filter((item) => item?.type === 'personal_attributes').map((item) => item.id)

        if (speciality?.length > 0) {
            obj.speciality = speciality
        }

        if (industry?.length > 0) {
            obj.industry = industry
        }

        if (personal_attr?.length > 0) {
            obj.personal_attr = personal_attr
        }

        if (countryValue?.length > 0) {
            obj.country = countryValue?.map((item) => item.id)
        }

        if (stateValue?.length > 0) {
            obj.state = stateValue?.map((item) => item.id)
        }

        const length = Object.keys(obj).length

        if (length === 1) {
            getRds()
        } else {
            dispatch(recRDCampaign(obj))
        }
    }

    const onTabChange = (tab) => {
        setSelectedTab(tab)
        setSelectAllRecRds(true)
        setSelectedFilters([])
        setDropdownOpen(false)
        setSelectedExperts([])
        dispatch(recRDCampaignReset())
        dispatch(getFavFoldersReset())
        dispatch(getFavProfilesReset())
        dispatch(getMyRdsReset())
        setCurrentPage(1)
        setPaginatedMeta(null)
        setPaginatedData([])
        if (tab === "recommended") {
            getRds()
        } else if (tab === "all") {
            dispatch(recRDCampaign({filter: 'list', speciality: [], industry: [], personal_attr: []}))
        } else if (tab === "favorites") {
            dispatch(getFavFolders('?list=all'))
        } else if (tab === "my") {
            dispatch(getMyRds({
                chat_rd_list: 'chat_rd_list'
            }))
        }
    }

    const form = useFormik({
        initialValues: {
            publish_datetime: ""
        },
        validationSchema: yup.object().shape({
            publish_datetime: yup.array().min(1, 'This field is required.').required("This field is required.")
        }),
        onSubmit: (values) => {
            secPubCampaignHandle({
                publish_datetime: values.publish_datetime ? moment(values.publish_datetime[0]).format('YYYY-MM-DD kk:mm:ss') : values.publish_datetime,
                time_zone: momentTime.tz.guess()
            })
        }
    })

    const clearFilters = () => {
        onTabChange(selectedTab)
        setDropdownOpen(false)
        setSelectedFilters([])
        setCountryValue([])
        setStateValue([])
        setStatesData([])
    }

    const handleCustomPagination = (page, data) => {
        setCurrentPage(Number(page))
        createPagination(data, page)
    }

    const handleCountries = (e) => {
        const countryIds = e?.map(c => c.id)
        if (states?.length > 0 && e.length > 0) {
            const filteredStates = states?.filter(x => countryIds.includes(x.country))
            setStatesData(filteredStates)
        } else {
            setStatesData([])
        }
    }

    const inviteRDs = () => {
        const date = new Date()
        const dataObj = {
            campaign_id: id,
            rds: selectedExperts,
            timezone: date.toString()
        }
        dispatch(inviteCampaignOnMsg(dataObj))
        dispatch(inviteCampaignOnEmail(dataObj))
    }

    return (
        <Modal size="lg" isOpen={openPubSecModal} className="modal-dialog-centered invite-rd-modal">
            <ModalHeader className="transparent" toggle={() => setOpenPubSecModal(false)}>
            </ModalHeader>
            <ModalBody className="pt-0">
                <h3 className="font-inter inter-medium rd-modal-title mb-25">Invite Recommended Experts</h3>
                <p className="font-inter inter-regular rd-modal-subtitle">
                    Based on your preferences, we recommend inviting these RDs
                </p>
                {action === 'schedule' && (
                    <Form className='auth-reset-password-form font-inter inter-regular' onSubmit={form.handleSubmit} autoComplete="off">
                        <Row>
                            <FormGroup tag={Col} md="12">
                                <CustomLabel formik={form}
                                             id='publish_datetime'
                                             title='Choose a publish Date'
                                             msg={data?.deadline ? `Outreach Expiry: ${data?.deadline}` : ''}

                                />
                                <Flatpickr
                                    id="publish_datetime"
                                    value={form.values.publish_datetime}
                                    className={classnames('form-control flatPicker', {'is-invalid flatPicker': form.touched.publish_datetime && form.errors.publish_datetime})}
                                    options={SCHEDULE_DATE_OPTIONS}
                                    onChange={(e) => {
                                        form.setFieldValue('publish_datetime', e)
                                    }}
                                    placeholder="Publish Date"
                                    disabled={secPubCampaignLoading}
                                />
                                <div className="validation-err">
                                    {form.touched.publish_datetime && form.errors.publish_datetime}
                                </div>
                            </FormGroup>
                            <FormGroup tag={Col} md="12">
                                <p className='bg-light p-1 w-100 text-danger font-small-3 font-inter inter-regular mb-0'>
                                    <strong>Note: </strong>The outreach will be published according to your local timezone: {momentTime.tz.guess()}
                                </p>
                            </FormGroup>
                        </Row>
                    </Form>
                )}
                <div className='rd-outer'>
                    <div className='d-flex justify-content-between align-items-center mb-0'>
                        <div className="d-flex align-items-center px-0 py-1">
                            {(action === 'schedule' || action === 'publish') && (
                                <FormGroup check className="d-flex align-items-center mr-1 p-0">
                                    <Label check className="d-flex align-items-center pl-0">
                                        <Label check className='d-flex align-items-center pl-0 tab-label'>
                                            <CustomInput
                                                type="radio"
                                                name="tab-recommended-rds"
                                                id='tab-recommended-rds'
                                                checked={selectedTab === "recommended"}
                                                onChange={() => onTabChange('recommended')}
                                                disabled={recRDCampaignLoading || getFavFolderLoading || getFavProfileLoading || secPubCampaignLoading || inviteOnEmailLoading || inviteOnMsgLoading}
                                            />
                                            Recommended RDs
                                        </Label>
                                    </Label>
                                </FormGroup>
                            )}
                            <FormGroup check className="d-flex align-items-center mr-2">
                                <Label check className="d-flex align-items-center pl-0 tab-label">
                                    <CustomInput
                                        type="radio"
                                        name="tab-all-rds"
                                        id='tab-all-rds'
                                        checked={selectedTab === "all"}
                                        onChange={() => onTabChange('all')}
                                        disabled={recRDCampaignLoading || getFavFolderLoading || getFavProfileLoading || secPubCampaignLoading || inviteOnEmailLoading || inviteOnMsgLoading}
                                    />
                                    All RDs
                                </Label>
                            </FormGroup>
                            <FormGroup check className="d-flex align-items-center mr-2">
                                <Label check className="d-flex align-items-center pl-0 tab-label">
                                    <CustomInput
                                        type="radio"
                                        name="tab-my-rds"
                                        id='tab-my-rds'
                                        checked={selectedTab === "my"}
                                        onChange={() => onTabChange('my')}
                                        disabled={recRDCampaignLoading || getFavFolderLoading || getFavProfileLoading || secPubCampaignLoading || inviteOnEmailLoading || inviteOnMsgLoading}
                                    />
                                    My RDs
                                </Label>
                            </FormGroup>
                            <FormGroup check className="d-flex align-items-center">
                                <Label check className="d-flex align-items-center pl-0 tab-label">
                                    <CustomInput type="radio"
                                                 name="tab-invite-from-favorites"
                                                 id='tab-invite-from-favorites'
                                                 checked={selectedTab === "favorites"}
                                                 onChange={() => onTabChange('favorites')}
                                                 disabled={recRDCampaignLoading || getFavFolderLoading || getFavProfileLoading || secPubCampaignLoading || inviteOnEmailLoading || inviteOnMsgLoading}
                                    />
                                    Invite from Favorites
                                </Label>
                            </FormGroup>
                        </div>
                        {(selectedTab === "recommended" || selectedTab === "all") && (
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction="down">
                                <DropdownToggle outline
                                                className="filter-Btn f_size d-flex align-items-center"
                                                disabled={recRDCampaignLoading || getFavFolderLoading || getFavProfileLoading || secPubCampaignLoading || inviteOnEmailLoading || inviteOnMsgLoading}
                                >
                                    <img src={FilterIcon} className='mr-50' alt="filter" width={16} height={16}/>
                                    Filters
                                </DropdownToggle>
                                <DropdownMenu className="p-1 filter-dropdown">
                                    <div className='filter-heading my-0 border-bottom pb-1 d-flex justify-content-between align-items-center position-relative'>
                                        <h3 className='filter-heading my-0 font-inter inter-medium'>Filters</h3>
                                        <Button.Ripple className='btn-icon' color='danger' onClick={() => {
                                            setDropdownOpen(false)
                                        }}>
                                            <X size={16}/>
                                        </Button.Ripple>
                                    </div>
                                    <div className="dropdown-content">
                                        {rdOptions?.map((category, index) => (
                                            <div key={index}>
                                                <h6 className="mt-2 label-bg">{category?.label}</h6>
                                                {category?.options?.map((filter, idx) => (
                                                    <FormGroup check key={idx}>
                                                        <Label check className='mb-1 d-flex align-items-center pl-0 font-inter inter-regular'>
                                                            <CustomInput type="checkbox"
                                                                         value={filter.id}
                                                                         checked={selectedFilters?.some(item => item.id === filter.id && item.type === filter.type)}
                                                                         onChange={(e) => handleFilterChange(e, filter)}
                                                                         name={`filter-${idx}`}
                                                                         id={`filter-${idx}`}
                                                                         label={filter.label}
                                                            />
                                                        </Label>
                                                    </FormGroup>
                                                ))}
                                            </div>
                                        ))}
                                        <FormGroup>
                                            <h6 className='filter-title bg-light p-1 font-inter inter-medium'>Country:</h6>
                                            <Select options={countries}
                                                    isMulti={true}
                                                    className='react-select'
                                                    classNamePrefix='select'
                                                    value={countryValue}
                                                    onChange={(e) => {
                                                        handleCountries(e)
                                                        setCountryValue(e)
                                                        setStateValue([])
                                                    }}
                                                    placeholder='Country...'
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <h6 className='filter-title bg-light p-1 font-inter inter-medium'>State:</h6>
                                            <div className="overlay-select-parent">
                                                <Select className='react-select'
                                                        isMulti={true}
                                                        classNamePrefix='select'
                                                        options={statesData}
                                                        value={stateValue}
                                                        onChange={(e) => {
                                                            setStateValue(e)
                                                        }}
                                                        placeholder='State...'
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className="d-flex justify-content-between flex-column mt-1">
                                        <Button color="primary"
                                                size="sm"
                                                onClick={() => {
                                                    setDropdownOpen(false)
                                                    applyFilters()
                                                }}
                                                className='mb-1 f_size font-inter inter-medium btn-height'
                                                disabled={recRDCampaignLoading || getFavProfileLoading}
                                        >
                                            Apply Filters
                                        </Button>
                                        <Button outline
                                                color="primary"
                                                className="outline-Btn danger-btn font-inter inter-medium"
                                                onClick={() => {
                                                    clearFilters()
                                                }}
                                        >
                                            Clear All Filters
                                        </Button>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        )}
                    </div>

                    {(selectedTab === "recommended" || selectedTab === "all") && (
                        recRDCampaignLoading ? (
                            <div className='text-center col-12'>
                                <Spinner className="loader-md"/> Please wait...
                            </div>
                        ) : (recRDCampaignData?.data ? (
                            <>
                                <div className="d-flex align-items-center mb-1">
                                    {recRDCampaignData?.data?.length > 0 && (
                                        <FormGroup check className='p-0'>
                                            <Label check className="d-flex align-items-center pl-0 tab-label">
                                                <CustomInput type="checkbox"
                                                             name='select-all-1'
                                                             id='select-all-1'
                                                             checked={selectAllRecRds}
                                                             onChange={(e) => selectAll(e, recRDCampaignData?.data)}
                                                             disabled={recRDCampaignLoading || getFavFolderLoading || getFavProfileLoading || secPubCampaignLoading || inviteOnEmailLoading || inviteOnMsgLoading}
                                                />
                                                Select all
                                            </Label>
                                        </FormGroup>

                                    )}
                                    {recRDCampaignData?.data && (
                                        <div className='py-1 tab-label pl-1'>
                                            ({recRDCampaignData?.data?.length || 0} RDs found)
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <Row>
                                        {paginatedData?.map((expert, index) => (
                                            <Col md="3" key={index} className="px-75">
                                                <Card className={classnames("cursor-pointer font-inter inter-regular publish-card", {
                                                    "border-primary": selectedExperts?.includes(expert.id)
                                                })}
                                                >
                                                    <CardBody className="d-flex align-items-center py-0 px-75">
                                                        <CustomInput type="checkbox"
                                                                     checked={selectedExperts?.includes(expert.id)}
                                                                     onChange={() => toggleExpert(expert)}
                                                                     id={`expert-${expert.id}`}
                                                                     name={`expert-${expert.id}`}
                                                                     disabled={secPubCampaignLoading}
                                                                     className='align-self-start mt-75'
                                                        />
                                                        <button className='btn p-0 font-inter inter-medium py-1' onClick={() => toggleExpert(expert)}>
                                                            <img src={`${expert?.profile_img || BlankLogo}`}
                                                                 alt="avatar"
                                                                 className="rounded-circle mr-50 ml-25 border_light"
                                                                 width="40"
                                                                 height="40"
                                                            />
                                                        </button>
                                                        <button className='btn p-0 text-left font-inter inter-medium py-50' onClick={() => toggleExpert(expert)}>
                                                            <div className='d-flex flex-column'>
                                                                <UncontrolledTooltip target={`tooltip-tag-${expert.id}`}>
                                                                    {expert.name}
                                                                </UncontrolledTooltip>
                                                                <span id={`tooltip-tag-${expert.id}`}
                                                                      className="font-inter inter-medium word-break mb-25 publish-card-title"> {expert.name}</span>
                                                                <UncontrolledTooltip target={`tooltip-industry-${expert.id}`}>
                                                                    {expert?.industry && expert?.industry.map((item) => item?.label).join(', ')}
                                                                </UncontrolledTooltip>
                                                                <span id={`tooltip-industry-${expert.id}`}
                                                                      className="small mb-0 font-inter inter-regular word-break experties"> {expert?.industry && expert?.industry.map((item) => item?.label).join(' | ')}</span>
                                                            </div>
                                                        </button>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {paginatedMeta?.totalItems > 0 && (
                                            <span className="font-inter inter-medium w-100">
                                                Showing {paginatedMeta?.startIndex + 1} - {Math.min(paginatedMeta?.endIndex, paginatedMeta?.totalItems)} of {paginatedMeta?.totalItems}
                                            </span>
                                        )}
                                        {paginatedMeta?.totalPages > 1 && (
                                            <CustomPagination pageLinks={generatePaginationLinks(currentPage, paginatedMeta?.totalPages)}
                                                              onPaginationClick={(page) => handleCustomPagination(page, recRDCampaignData?.data)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className='text-center bg-light p-1'>
                                <strong>Sorry! No User found</strong>
                            </div>
                        )))
                    }

                    {(selectedTab === "my") && (
                        myRDsLoading ? (
                            <div className='text-center col-12'>
                                <Spinner className="loader-md"/> Please wait...
                            </div>
                        ) : (myRDsData?.data ? (
                            <>
                                <div className="d-flex align-items-center mb-1">
                                    {myRDsData?.data?.length > 0 && (
                                        <FormGroup check className='p-0'>
                                            <Label check className="d-flex align-items-center pl-0 tab-label">
                                                <CustomInput type="checkbox"
                                                             name='select-all-1'
                                                             id='select-all-1'
                                                             checked={selectAllRecRds}
                                                             onChange={(e) => selectAll(e, myRDsData?.data)}
                                                             disabled={myRDsLoading || getFavFolderLoading || getFavProfileLoading || secPubCampaignLoading || inviteOnEmailLoading || inviteOnMsgLoading}
                                                />
                                                Select all
                                            </Label>
                                        </FormGroup>
                                    )}
                                    {myRDsData?.data && (
                                        <div className='py-1 tab-label pl-1'>
                                            ({myRDsData?.data?.length || 0} RDs found)
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <Row>
                                        {paginatedData?.map((expert, index) => (
                                            <Col md="3" key={index} className="px-75">
                                                <Card
                                                    className={`${selectedExperts?.includes(expert.id) ? "border-primary cursor-pointer" : "cursor-pointer"} font-inter inter-regular publish-card`}
                                                >
                                                    <CardBody className="d-flex align-items-center py-0 px-75">
                                                        <CustomInput type="checkbox"
                                                                     checked={selectedExperts?.includes(expert.id)}
                                                                     onChange={() => {
                                                                         toggleExpert(expert)
                                                                     }}
                                                                     id={`expert-${expert.id}`}
                                                                     name={`expert-${expert.id}`}
                                                                     disabled={secPubCampaignLoading}
                                                                     className='align-self-start mt-75'
                                                        />
                                                        <button className='btn p-0 font-inter inter-medium py-1' onClick={() => toggleExpert(expert)}>
                                                            <img src={`${expert?.profile_img || BlankLogo}`}
                                                                 alt="avatar"
                                                                 className="rounded-circle mr-50 ml-25 border_light"
                                                                 width="40"
                                                                 height="40"
                                                            />
                                                        </button>
                                                        <button className='btn p-0 text-left font-inter inter-medium py-1' onClick={() => toggleExpert(expert)}>
                                                            <div className='d-flex flex-column'>
                                                                <UncontrolledTooltip target={`tooltip-tag-${expert.id}`}>
                                                                    {expert.name}
                                                                </UncontrolledTooltip>
                                                                <span id={`tooltip-tag-${expert.id}`}
                                                                      className="font-inter inter-medium word-break mb-25 publish-card-title"> {expert.name}</span>
                                                                <UncontrolledTooltip target={`tooltip-industry-${expert.id}`}>
                                                                    {expert?.industry && expert?.industry.map((item) => item?.label).join(', ')}
                                                                </UncontrolledTooltip>
                                                                <span id={`tooltip-industry-${expert.id}`}
                                                                      className="small mb-0 font-inter inter-regular word-break experties"> {expert?.industry && expert?.industry.map((item) => item?.label).join(' | ')}</span>
                                                            </div>
                                                        </button>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {paginatedMeta?.totalItems > 0 && (
                                            <span className="font-inter inter-medium w-100">
                                                Showing {paginatedMeta?.startIndex + 1} - {Math.min(paginatedMeta?.endIndex, paginatedMeta?.totalItems)} of {paginatedMeta?.totalItems}
                                            </span>
                                        )}
                                        {paginatedMeta?.totalPages > 1 && (
                                            <CustomPagination pageLinks={generatePaginationLinks(currentPage, paginatedMeta?.totalPages)}
                                                              onPaginationClick={(page) => handleCustomPagination(page, myRDsData?.data)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className='text-center bg-light p-1'>
                                <strong>Sorry! No User found</strong>
                            </div>
                        )))
                    }

                    {selectedTab === "favorites" && (
                        getFavFolderLoading ? (
                            <div className='text-center col-12'>
                                <Spinner className="loader-md"/> Please wait...
                            </div>
                        ) : (
                            <>
                                <div className="p-1 bg-light mb-2 font-inter inter-regular mt-50">
                                    <span><strong>Select Folder</strong></span>
                                </div>
                                <div>
                                    <Row>
                                        {getFavFolderData?.data?.map((item, index) => (
                                            <Col xs={12} md={3} key={index}>
                                                <Card className="cursor-pointer font-inter inter-regular publish-card"
                                                      onClick={() => {
                                                          onTabChange('profiles')
                                                          setFolderData(item?.folder_name)
                                                          dispatch(getFavProfiles(`?folder_id=${item?.id}&list=all`))
                                                      }}
                                                >
                                                    <CardBody className=" fav-folders">
                                                        <div className="align-items-center">
                                                            <h5 className='mb-0'>
                                                                {item?.folder_name.length > 20 ? `${item?.folder_name.substring(0, 20)}...` : item?.folder_name}
                                                            </h5>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </>
                        )
                    )}

                    {selectedTab === "profiles" && (
                        getFavProfileLoading ? (
                            <div className='text-center col-12'>
                                <Spinner className="loader-md"/> Please wait...
                            </div>
                        ) : (
                            <>
                                <div className="d-flex align-items-center justify-content-between bg-light mt-1 p-50 font-inter inter-regular">
                                    <button className="text-primary d-flex align-items-center btn p-0 font-inter inter-medium"
                                            onClick={() => {
                                                onTabChange('favorites')
                                                setFolderData(null)
                                            }}
                                            disabled={secPubCampaignLoading}
                                    >
                                        <ArrowLeftCircle size={15}/> <strong className='ml-25'>Back</strong>
                                    </button>
                                    <span><strong>{folderData}</strong></span>
                                </div>
                                <div className="d-flex align-items-center mb-1 py-50 mb-1 font-inter inter-regular">
                                    {getFavProfileData?.data?.length > 0 && (
                                        <FormGroup check className='p-0'>
                                            <Label check className="d-flex align-items-center pl-0 tab-label">
                                                <CustomInput type="checkbox"
                                                             name='select-all-2'
                                                             id='select-all-2'
                                                             checked={selectAllRecRds}
                                                             onChange={(e) => selectAll(e, getFavProfileData?.data)}
                                                             disabled={recRDCampaignLoading || getFavProfileLoading}
                                                />
                                                Select all
                                            </Label>
                                        </FormGroup>
                                    )}
                                    {getFavProfileData?.data && (
                                        <div className='py-1 tab-label pl-1'>
                                            ({getFavProfileData?.data?.length || 0} RDs found)
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <Row>
                                        {paginatedData?.map((expert, index) => (
                                            <Col md="3" key={index} className="px-75">
                                                <Card
                                                    className={`${selectedExperts?.includes(expert.id) ? "border-primary cursor-pointer" : "cursor-pointer"} font-inter inter-regular publish-card`}
                                                >
                                                    <CardBody className="d-flex align-items-center py-0 px-75">
                                                        <CustomInput type="checkbox"
                                                                     checked={selectedExperts?.includes(expert.id)}
                                                                     onChange={() => {
                                                                         toggleExpert(expert)
                                                                     }}
                                                                     id={`expert-${expert.id}`}
                                                                     name={`expert-${expert.id}`}
                                                                     disabled={secPubCampaignLoading}
                                                                     className='align-self-start mt-75'
                                                        />
                                                        <button className='btn p-0 font-inter inter-medium py-1' onClick={() => toggleExpert(expert)}>
                                                            <img src={`${expert?.profile_img || BlankLogo}`}
                                                                 alt="avatar"
                                                                 className="rounded-circle mr-50 ml-25 border_light"
                                                                 width="40"
                                                                 height="40"
                                                            />
                                                        </button>
                                                        <button className='btn p-0 text-left font-inter inter-medium py-50' onClick={() => toggleExpert(expert)}>
                                                            <div className='d-flex flex-column'>
                                                                <UncontrolledTooltip target={`tooltip-tag-${expert.id}`}>
                                                                    {expert.name}
                                                                </UncontrolledTooltip>
                                                                <span id={`tooltip-tag-${expert.id}`}
                                                                      className="font-inter inter-medium word-break mb-25 publish-card-title"> {expert.name}</span>
                                                                <UncontrolledTooltip target={`tooltip-industry-${expert.id}`}>
                                                                    {expert?.attributes?.industry && expert?.attributes?.industry.map((item) => item?.label).join(', ')}
                                                                </UncontrolledTooltip>
                                                                <span id={`tooltip-industry-${expert.id}`}
                                                                      className="small mb-0 font-inter inter-regular word-break experties"> {expert?.attributes?.industry && expert?.attributes?.industry.map((item) => item?.label).join(' | ')}</span>
                                                            </div>
                                                        </button>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {paginatedMeta?.totalItems > 0 && (
                                            <span className="font-inter inter-medium w-100">
                                                Showing {paginatedMeta?.startIndex + 1} - {Math.min(paginatedMeta?.endIndex, paginatedMeta?.totalItems)} of {paginatedMeta?.totalItems}
                                            </span>
                                        )}
                                        {paginatedMeta?.totalPages > 1 && (
                                            <CustomPagination pageLinks={generatePaginationLinks(currentPage, paginatedMeta?.totalPages)}
                                                              onPaginationClick={(page) => handleCustomPagination(page, getFavProfileData?.data)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                        )
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end w-100">
                    {action === 'schedule' && (
                        <Button outline={true}
                                color="primary"
                                className="mr-1 outline-Btn font-inter inter-medium"
                                onClick={() => {
                                    form.submitForm().then(r => r)
                                }}
                                disabled={secPubCampaignLoading || recRDCampaignLoading || getFavFolderLoading || getFavProfileLoading || inviteOnEmailLoading || inviteOnMsgLoading}
                        >
                            {secPubCampaignLoading ? (
                                <>
                                    <Spinner className="loader-sm"/> Please wait...
                                </>
                            ) : (
                                <span className="font-inter inter-medium">Schedule & Invite Later</span>
                            )}
                        </Button>
                    )}
                    {action === 'publish' && (
                        <Button color="primary"
                                type='button'
                                onClick={() => secPubCampaignHandle(null)}
                                disabled={secPubCampaignLoading || recRDCampaignLoading || getFavFolderLoading || getFavProfileLoading || inviteOnEmailLoading || inviteOnMsgLoading}
                        >
                            {secPubCampaignLoading ? (
                                <>
                                    <Spinner className="loader-sm"/> Please wait...
                                </>
                            ) : (
                                <span className="font-inter inter-medium">Invite & Publish</span>
                            )}
                        </Button>
                    )}
                    {action === 'invite' && (
                        <Button color="primary"
                                className="mr-1 outline-Btn font-inter inter-medium"
                                onClick={() => {
                                    inviteRDs()
                                }}
                                disabled={secPubCampaignLoading || recRDCampaignLoading || getFavFolderLoading || getFavProfileLoading || inviteOnEmailLoading || inviteOnMsgLoading}
                        >
                            {(inviteOnEmailLoading || inviteOnMsgLoading) ? (
                                <>
                                    <Spinner className="loader-sm"/> Please wait...
                                </>
                            ) : (
                                <span className="font-inter inter-medium">Send Invite</span>
                            )}
                        </Button>
                    )}
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default PublishSchedule