import {Link} from 'react-router-dom'
import {useSkin} from '@hooks/useSkin'
import {User, Users} from 'react-feather'
import {Row, Col, CardTitle, CardText} from 'reactstrap'
import '@styles/base/pages/page-auth.scss'
import logo from '@src/assets/images/logo.png'

const Createloginrd = () => {
    return (
        <div className='auth-wrapper auth-v2'>
            <Row className='auth-inner m-0'>
                <Link className='brand-logo' to='/'>
                    <img src={logo} width="140" alt='logo'/>
                </Link>
                <Col className='d-flex align-items-center p-5 auth-bg' lg='12' sm='12'>
                    <ul className="list-unstyled" style={{position: 'absolute', top: '0', right: '0', margin: '2rem'}}>
                        <li className="d-inline-block">
                            <Link to='/'>Home</Link>
                        </li>
                    </ul>
                    <div className="mx-auto shadow p-5">
                        <CardTitle tag="h1">Create your Account</CardTitle>
                        <CardText className='text-center'>
                            Already have an account?
                            <Link to='/login?target=web'> Sign in here</Link>
                        </CardText>
                        <CardText className='text-center font-medium-2 mb-2 mt-2'>
                            <strong>Please select your role</strong>
                        </CardText>
                        <div>
                            <ul className="list-unstyled">
                                <li><Link to='/register-rd' className='btn btn-primary btn-block'><User size={14}/> Registered Dietitian</Link></li>
                                <li><p className='text-center mt-1'><strong>OR</strong></p></li>
                                <li><Link to='/register-brand' className='btn btn-primary btn-block'><Users size={14}/> Brand</Link></li>
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Createloginrd
