import {Modal, ModalBody, ModalHeader} from "reactstrap"
import {PropTypes} from "prop-types"

const PdfViewer = ({show, hide, url, filename}) => {
    return (
        <>
            <Modal isOpen={show} className='modal-dialog-centered' size="xl">
                <ModalHeader toggle={hide}>
                    {filename}
                </ModalHeader>
                <ModalBody>
                    <div className='pdf-viewer-height'>
                        <iframe src={url} width="100%" height="100%" className='border-0' title='PDF Viewer'/>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

PdfViewer.propTypes = {
    show: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired
}

export default PdfViewer
