import {useDispatch, useSelector} from 'react-redux'
import {Formik} from "formik"
import * as yup from "yup"
import classnames from 'classnames'
import {useState, useEffect} from 'react'
import InputPassword from '@components/input-password-toggle'
import {
    Card,
    CardBody,
    CardTitle,
    CardText,
    Form,
    FormGroup,
    Label,
    Button,
    Alert
} from 'reactstrap'
import '@styles/base/pages/page-auth.scss'
import {updateAdminPassword, nullResponse} from '@store/actions/navbar'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

const ChangePassword = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const [visible, setVisible] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [formRef, setFormRef] = useState(null)
    const [updatePasswordRes, setUpdatePasswordRes] = useState(null)

    const [captchaValue, setCaptchaValue] = useState(null)
    const [captchaError, setCaptchaError] = useState(false)

    const sweetAlert = withReactContent(Swal)

    const dispatch = useDispatch()
    const navbarStoreSlice = useSelector(store => store.navbar)

    useEffect(() => {
        const updatePasswordRes = navbarStoreSlice?.updatePasswordRes ? navbarStoreSlice?.updatePasswordRes : null
        if (updatePasswordRes) {
            const {success} = updatePasswordRes
            setUpdatePasswordRes(updatePasswordRes)
            setVisible(true)
            setIsSubmitting(false)
            dispatch(nullResponse('UpdateAdminPassword'))
            setCaptchaError(false)
            setCaptchaValue(null)
            if (success) {
                formRef.resetForm()
            }
        }
    }, [navbarStoreSlice])

    const handleChangePassword = async (values, form) => {
        const {oldPassword, newPassword, confirmNewPassword} = values
        setVisible(false)
        setIsSubmitting(true)
        const token = await executeRecaptcha('admin_change_password')
        if (token) {
            dispatch(updateAdminPassword({
                current_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmNewPassword,
                re_captcha_token: token
            }))
        } else {
            sweetAlert.fire({
                title: 'Captcha Error',
                icon: 'error',
                html: '<p>Execute recaptcha not yet available.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
        setFormRef(form)
    }

    return (
        <div className='auth-wrapper auth-v1 px-2'>
            <div className='auth-inner py-2'>
                <Card className='mb-0'>
                    <CardBody className="p-3">
                        <CardTitle className='mb-75 text-center'>
                            <h3> CHANGE PASSWORD </h3>
                        </CardTitle>
                        <CardText className='mb-2 text-center'>Your new password must be different from previously used passwords</CardText>
                        <Alert color={!updatePasswordRes?.success ? 'danger' : 'success'} isOpen={visible} toggle={() => setVisible(false)}>
                            <div className='alert-body'>
                                {updatePasswordRes?.msg}
                            </div>
                        </Alert>
                        <Formik
                            initialValues={{
                                oldPassword: "",
                                newPassword: "",
                                confirmNewPassword: ""
                            }}
                            validationSchema={yup.object().shape({
                                oldPassword: yup.string()
                                    .required("This field is required"),
                                newPassword: yup.string().min(8)
                                    .max(255)
                                    .matches(
                                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                                    )
                                    .required("This field is required"),
                                confirmNewPassword: yup.string().oneOf(
                                    [yup.ref("newPassword"), null],
                                    "Passwords must match."
                                ).required("This field is required")
                            })}
                            onSubmit={(values, form) => {
                                handleChangePassword(values, form)
                            }}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  touched,
                                  values
                              }) => (
                                <Form className='auth-reset-password-form mt-2' onSubmit={handleSubmit}>
                                    <div className='form-group form-password-toggle'>
                                        <Label className={classnames({
                                            'is-invalid': touched.oldPassword && errors.oldPassword,
                                            'form-label': true
                                        })} htmlFor={`oldPassword`}>
                                            Current Password<span className="is-invalid">*</span>
                                        </Label>
                                        <InputPassword
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.oldPassword}
                                            name={`oldPassword`}
                                            id={`oldPassword`}
                                            className={classnames({'is-invalid': touched.oldPassword && errors.oldPassword})}
                                        />
                                        <div className="validation-err">{touched.oldPassword && errors.oldPassword}</div>
                                    </div>
                                    <FormGroup>
                                        <Label className={classnames({
                                            'is-invalid': touched.newPassword && errors.newPassword,
                                            'form-label': true
                                        })} htmlFor='newPassword'>
                                            New password<span className="is-invalid">*</span>
                                        </Label>
                                        <InputPassword
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.newPassword}
                                            name={`newPassword`}
                                            id={`newPassword`}
                                            className={classnames({'is-invalid': touched.newPassword && errors.newPassword})}/>
                                        <div className="validation-err">{touched.newPassword && errors.newPassword}</div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className={classnames({
                                            'is-invalid': touched.confirmNewPassword && errors.confirmNewPassword,
                                            'form-label': true
                                        })} htmlFor='confirmNewPassword'>
                                            Confirm new password
                                        </Label>
                                        <InputPassword
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.confirmNewPassword}
                                            name={`confirmNewPassword`}
                                            id={`confirmNewPassword`}
                                            className={classnames({'is-invalid': touched.confirmNewPassword && errors.confirmNewPassword})}/>
                                        <div className="validation-err">{touched.confirmNewPassword && errors.confirmNewPassword}</div>
                                    </FormGroup>
                                    <Button.Ripple type='submit' color='primary' className='mt-2 mx-auto d-flex' disabled={isSubmitting}>
                                        {isSubmitting ? 'Updating...' : 'Update Password'}
                                    </Button.Ripple>
                                </Form>)}
                        </Formik>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default ChangePassword