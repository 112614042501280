import {Doughnut} from 'react-chartjs-2'
import {selectThemeColors} from '@utils'
import {Card, CardHeader, CardBody, Row, Col, Spinner} from 'reactstrap'
import Select from 'react-select'
import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getAllRdsChartData} from '@src/redux/actions/adminDashboard'

const groupedOptions = [
    {
        label: 'Industry',
        value: 'filterByIndustry'
    },
    {
        label: 'Speciality',
        value: 'filterBySpeciality'
    },
    {
        label: 'Regions',
        value: 'filterByRegion'
    },

    {
        label: 'Country',
        value: 'filterByCountry'
    }
]

const AllRDsChart = () => {

    const [isStatsLoading, setIsStatsLoading] = useState(false)
    const [isDispatched, setIsDispatched] = useState(true)
    const [filter, setFilter] = useState("filterByIndustry")
    const [dataSet, setDataSet] = useState(null)
    const dispatch = useDispatch()
    const adminDashboardStoreSlice = useSelector(state => state.adminDashboardSlice)
    useSelector(state => state.auth)

    useEffect(() => {
        const rdGraphData = adminDashboardStoreSlice?.rdGraphData ?? null
        if (rdGraphData) {
            const array = [
                rdGraphData?.country.toFixed(2) || 10,
                rdGraphData?.industry.toFixed(2) || 20,
                rdGraphData?.speciality.toFixed(2) || 30,
                rdGraphData?.region.toFixed(2) || 40

            ]
            setDataSet(array)
            setIsStatsLoading(false)
        }
    }, [adminDashboardStoreSlice])

    useEffect(() => {
        if (isDispatched) {
            setIsStatsLoading(true)
            const body = {
                filter
            }
            dispatch(getAllRdsChartData(body))
            setIsDispatched(false)
        }
    }, [])

    const options = {
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            cutoutPercentage: 0,
            legend: {display: false},
            tooltips: {
                callbacks: {
                    label(tooltipItem, data) {
                        const label = data.datasets[0].labels[tooltipItem.index] || '',
                            value = data.datasets[0].data[tooltipItem.index]
                        const output = ` ${label} : ${value} %`
                        return output
                    }
                },
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: '#fff',
                backgroundColor: '#fff',
                titleFontColor: '#000',
                bodyFontColor: '#000'
            }
        },
        data = {
            datasets: [
                {
                    labels: ['Speciality', 'Industry', 'Region', 'Country'],

                    data: dataSet,
                    backgroundColor: ["#00EBCF", '#F2B44D', "#214090", "#2cb6d1"],
                    borderWidth: 4
                }
            ]
        }

    const filterStats = (filterValue) => {

        setFilter(filterValue)
        setIsStatsLoading(true)
        const body = {
            filter: filterValue
        }
        dispatch(getAllRdsChartData(body))
    }

    return (
        <Card>
            <CardHeader className='d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column'>
                <div className="card-title">All RDs</div>
                <Select
                    theme={selectThemeColors}
                    className='react-select w-250px'
                    classNamePrefix='select'
                    defaultValue={groupedOptions[0]}
                    options={groupedOptions}
                    isClearable={false}
                    isSearchable={false}
                    onChange={(e) => filterStats(e.value)}
                />
            </CardHeader>
            <CardBody>
                <Row>
                    <Col lg="6">
                        <div style={{height: '200px', position: 'relative'}}>
                            <div className="d-flex align-items-center justify-content-center total-wrap">
                                <div className="totalRD">
                                    <p className="mb-0">{adminDashboardStoreSlice?.rdGraphData?.Totalcount}</p>
                                    <span>Total RDs</span>
                                </div>
                            </div>
                            <Doughnut data={data} options={options} height={200}/>
                        </div>
                        <div className='d-inline-flex justify-content-between my-1 mr-2'>
                            <div className='d-flex align-items-center'>
                                <span className="dot dot-industry"></span>
                                <span className='font-weight-bold ml-75 mr-25'>Industry</span>
                            </div>
                        </div>
                        <div className='d-inline-flex justify-content-between my-1 mr-2'>
                            <div className='d-flex align-items-center'>
                                <span className="dot dot-green"></span>
                                <span className='font-weight-bold ml-75 mr-25'>Speciality</span>
                            </div>
                        </div>
                        <div className='d-inline-flex justify-content-between my-1 mr-2'>
                            <div className='d-flex align-items-center'>
                                <span className="dot dot-region"></span>
                                <span className='font-weight-bold ml-75 mr-25'>Region</span>
                            </div>
                        </div>
                        <div className='d-inline-flex justify-content-between my-1 mr-2'>
                            <div className='d-flex align-items-center'>
                                <span className="dot dot-country"></span>
                                <span className='font-weight-bold ml-75 mr-25'>Country</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg="6">
                        {isStatsLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                        {!isStatsLoading && (
                            <div className="total-category-title my-2">
                                <h4 className='text-primary'>
                                    <u>
                                        {filter === 'filterByCountry' && <span>Country</span>}
                                        {filter === 'filterByIndustry' && <span>Industry </span>}
                                        {filter === 'filterByRegion' && <span>Region </span>}
                                        {filter === 'filterBySpeciality' && <span>Speciality </span>}
                                        <b>
                                            ({adminDashboardStoreSlice?.rdGraphData?.Totalcount})
                                        </b>
                                    </u>
                                </h4>
                            </div>)}
                        {!isStatsLoading && (
                            adminDashboardStoreSlice?.rdGraphData?.list?.length &&
                            adminDashboardStoreSlice?.rdGraphData.list?.map((rd) => (
                                <div className='d-flex justify-content-between each-chart-item-stat mr-75' key={`rd-graph-${rd?.count}`}>
                                    <div className='d-flex align-items-center'>
                                        <span className='subconcardtitle-gray font-weight-bold mr-25'>{rd?.name}</span>
                                        <span className="font-weight-bolder text-primary">
                                                    ({rd?.count} - {parseFloat(rd?.percentage).toFixed(2)}%)
                                                </span>
                                    </div>
                                </div>
                            )))}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default AllRDsChart
