import React from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardText
} from "reactstrap"
import {
    OUTREACH_TYPES,
    OUTREACH_EXAMPLE_URL
} from "./Controller"

const Step1 = ({activeCard, getActiveCard}) => {
    const handleCardClick = (item) => {
        getActiveCard(item)
    }

    return (
        <div>
            <div className="text-center pb-2 pt-1 font-inter inter-medium select_type">Select Type</div>
            <Row className="d-flex justify-content-center align-items-center card-container">
                {OUTREACH_TYPES?.map((item, index) => (
                    <Col md="4" key={`campaign-type-${index}`} className="campaign-card">
                        <Card onClick={() => handleCardClick(item)}
                              className={`d-flex justify-content-center align-items-center px-75 py-2 rounded-lg stepperCard ${
                                  activeCard?.id === item.id ? "steppeActive" : ""
                              }`}
                        >
                            <img alt="icon" height={70} width={70} src={item.image} className="pb-1"/>
                            <CardBody className="p-0">
                                <CardTitle className="text-center mb-25 font-inter inter-medium campaign-title" tag="h5">
                                    {item.title}
                                </CardTitle>
                                <CardText className="text-center stepper-text font-inter inter-regular f_size campaign-description">
                                    {item.description}
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
            <div className='text-center'>
                <button className="btn btn-link text-underline font-inter inter-regular f_size mb-1" onClick={() => {
                    window.open(OUTREACH_EXAMPLE_URL, 'blank')
                }}>
                    See best practices and examples here
                </button>
                <p className='font-weight-bold'>
                    Need more ideas? Contact us for support with RD strategy and recommendations.
                </p>
                <p className='font-weight-bold'>
                    Call/Text Jenna @ 508-904-3454 or email at jenna@therdlink.com.
                </p>
            </div>
        </div>
    )
}

export default Step1
