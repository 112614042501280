import React, { useState, useEffect, Fragment } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { User, X, Filter } from 'react-feather'
import classnames from 'classnames'
import Avatar from "@components/avatar"
import BlankLogo from "@src/assets/images/avatars/Blank-logo.jpg"
import {
  InputGroup, InputGroupAddon, Input, Button, CustomInput,
  Row, Col, Card, CardHeader, CardText, CardBody, CardFooter,
  TabContent, TabPane, Nav, NavItem, FormGroup,
  NavLink, Spinner
} from 'reactstrap'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import { NULL } from '@src/redux/actionTypes'
import {
  getFavoriteBrandsProfiles, searchFavoriteBrandsProfiles,
  getBrandProductAttributes, nullResponse
} from '@src/redux/actions/rdDashboard'

const WishlistRdFolderDetails = () => {
  const [active, setActive] = useState('1')
  const [activeView, setActiveView] = useState('grid')
  const [isProfilesLoading, setIsProfilesLoading] = useState(false)
  const [showProfileFilters, setShowProfileFilters] = useState(false)
  const [brandProductAttribute, setBrandProductAttribute] = useState(null)
  const [brandProductAttributeValues, setBrandProductAttributeValues] = useState([])

  const [searchNameValue, setSearchNameValue] = useState('')
  const [profilesPaginationInfo, setProfilesPaginationInfo] = useState(false)

  const rdDashboardStoreSlice = useSelector(store => store.rd)
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    const favoriteBrandsProfilesRes = rdDashboardStoreSlice?.favoriteBrandsProfilesRes ?? null
    if (favoriteBrandsProfilesRes) {
      const { links, meta, pages } = favoriteBrandsProfilesRes
      setProfilesPaginationInfo({ ...links, ...meta, pages })
      setIsProfilesLoading(false)
    }

    if (!!rdDashboardStoreSlice?.brandProductAttributesRes) {
      setBrandProductAttribute(rdDashboardStoreSlice?.brandProductAttributesRes)
    }

  }, [rdDashboardStoreSlice])

  useEffect(() => {
    setIsProfilesLoading(true)
    dispatch(getFavoriteBrandsProfiles(false, '', id))
    dispatch(getBrandProductAttributes())
  }, [])

  const handleProfilesFiltersSearch = () => {
    setIsProfilesLoading(true)
    dispatch(searchFavoriteBrandsProfiles(false, '', id, {
      filters: brandProductAttributeValues,
      brand_name: ''
    }))
  }

  const handleProfileFiltersChange = (filterName, name, value) => {

    let values = [...brandProductAttributeValues]
    if (value) values.push(parseInt(name))
    if (!value) values = values.filter(x => x !== parseInt(name))
    setBrandProductAttributeValues(values)
    switch (filterName) {
      case 'ProductAttrChange':
        break
      case 'SpecialDietChange':
        break
      case 'CategoryChange':
        break
    }
  }

  const handleResetSearch = (form) => {

    switch (form) {
      case 'BrandName':
        setIsProfilesLoading(true)
        setSearchNameValue('')
        dispatch(getFavoriteBrandsProfiles(false, '', id))
        break
      case 'ProfilesClearAll':
        setIsProfilesLoading(true)
        document.querySelectorAll("input[type=checkbox]").forEach(checkbox => { checkbox.checked = false })
        dispatch(getFavoriteBrandsProfiles(false, '', id))
        break
    }

  }

  const handleSearchByName = () => {
    setIsProfilesLoading(true)
    dispatch(searchFavoriteBrandsProfiles(false, '', id, {
      filters: [],
      brand_name: searchNameValue
    }))
  }

  const handleProfilesPagination = (pageUrl) => {
    setIsProfilesLoading(true)
    dispatch(searchFavoriteBrandsProfiles(true, pageUrl, id, {
      filters: brandProductAttributeValues,
      brand_name: searchNameValue
    }))
  }

  const toggle = tab => {
    if (active !== tab) {
      setActive(tab)
    }
  }

  const onEnterSearch = (e) => {
    if (e.key === 'Enter') {
      handleSearchByName()
    }
  }

  return (
    <React.Fragment>
      <div className="ecommerce-application">
        <h4 className="mb-2">Favorites</h4>
        <Card>
          <Nav className="mb-0" tabs>
            <NavItem>
              <NavLink
                active={active === '1'}
                onClick={() => {
                  toggle('1')
                }}
              >
                Profiles
              </NavLink>
            </NavItem>
          </Nav>
        </Card>
        <TabContent className='py-50' activeTab={active}>
          <TabPane tabId='1'>
            <Card>
              <CardBody>
                <Row>
                  <Col md="6">
                    <InputGroup size='lg' className="">
                      <InputGroupAddon addonType='append'>
                        <Button tag={Link} to={'/dashboard/rd/brands'} color='primary' outline>
                          All Brands
                        </Button>
                      </InputGroupAddon>
                      <InputGroupAddon addonType='append'>
                        <Button tag={Link} to={'/dashboard/rd/my-brands'} color='primary' outline>
                          My Brands
                        </Button>
                      </InputGroupAddon>
                      <InputGroupAddon addonType='append'>
                        <Button tag={Link} to={'/dashboard/rd/favorite-folders'} color='primary' outline>
                          Favorites
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <InputGroup size='lg' className="">
                      <Input placeholder='Search By Name' value={searchNameValue} onChange={(e) => setSearchNameValue(e.target.value)} onKeyDown={onEnterSearch} />
                      <InputGroupAddon addonType='append'>
                        <Button color='primary' onClick={handleSearchByName} outline>
                          Search
                        </Button>
                      </InputGroupAddon>
                      <InputGroupAddon addonType='append'>
                        <Button color='primary' onClick={() => handleResetSearch('BrandName')} outline>
                          Reset
                        </Button>
                      </InputGroupAddon>
                      <InputGroupAddon addonType='append'>
                        <Button
                          color='primary'
                          onClick={() => setShowProfileFilters(!showProfileFilters)}
                        >
                          <Filter size={18} />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {showProfileFilters && <Card className='my-0 filters-right-sidebar'>
              <CardHeader className="card-header-c">
                <h3 className='filter-heading my-0'>Filters</h3>
                <Button.Ripple className='btn-icon' color='danger' onClick={() => setShowProfileFilters(false)}>
                  <X size={16} />
                </Button.Ripple>
              </CardHeader>
              <CardBody className="pt-2 overflow-y-auto">
                <h5 className='filter-title mt-0 mb-2'>Product Attributes</h5>
                <div className='d-flex flex-wrap' onChange={e => handleProfileFiltersChange('ProductAttrChange', e.target.name, e.target.checked)}>
                  {brandProductAttribute?.values.map((brand, i) => {
                    return (
                      <CustomInput
                        key={brand.label}
                        type='checkbox'
                        className="mx-2 mb-1"
                        name={brand.id}
                        id={brand.label + i}
                        label={brand.label + brand.id}
                      />
                    )
                  })}
                </div>
                <h5 className='filter-title mt-2 mb-2'>Special Diet</h5>
                <div className='d-flex flex-wrap' onChange={(e) => handleProfileFiltersChange('SpecialDietChange', e.target.name, e.target.checked)}>
                  {brandProductAttribute?.special_diet.map(brand => {
                    return (
                      <CustomInput
                        key={brand.label}
                        type='checkbox'
                        className="mx-2 mb-1"
                        id={brand.label}
                        value={brand.id}
                        name={brand.id}
                        label={brand.label + brand.id}
                        defaultChecked={brand.checked}
                      />
                    )
                  })}
                </div>
                <h5 className='filter-title mt-2 mb-2'>Category</h5>
                <div className='d-flex flex-wrap' onChange={e => handleProfileFiltersChange('CategoryChange', e.target.name, e.target.checked)}>
                  {brandProductAttribute?.category.map(brand => {
                    return (
                      <CustomInput
                        key={brand.label}
                        className="mx-2 mb-1"
                        type='checkbox'
                        id={brand.label}
                        name={brand.id}
                        label={brand.label + brand.id}
                      />)
                  })}
                </div>
              </CardBody>
              <CardFooter>
                <Button.Ripple color='primary' className="mb-1" onClick={handleProfilesFiltersSearch} block>
                  Apply Filters
                </Button.Ripple>
                <Button.Ripple onClick={() => handleResetSearch('ProfilesClearAll')} color='danger' outline block>
                  Clear All Filters
                </Button.Ripple>
              </CardFooter>
            </Card>}
            {isProfilesLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary' /></div>)}
            {!isProfilesLoading && (
              <Fragment>
                {rdDashboardStoreSlice?.favoriteBrandsProfilesRes?.data.length === 0 && (<h5 className="text-center mt-5">Record doesn't exists</h5>)}
                <div
                  className={classnames({
                    'grid-view brands-nutritions': activeView === 'grid',
                    'list-view': activeView === 'list'
                  })}>
                  {rdDashboardStoreSlice?.favoriteBrandsProfilesRes?.data.map(item => (
                    <Card className='ecommerce-card'>
                      <div className="item-img text-center mx-auto mt-2">
                        <Avatar img={item.company_logo || BlankLogo} imgHeight="120" imgWidth="120" />
                      </div>
                      <CardBody>
                        <h5 className='item-name text-center'>
                          {item.brand_name || 'N/A'}
                        </h5>
                      </CardBody>
                      <div className='item-options text-center d-flex flex-wrap'>
                        <Button tag={Link} to={`/dashboard/rd/brands/${item.profile_id}`} color='primary' className="btn-cart move-cart border-only-bottom" block>
                          <User className="mr-50" size={14} />
                          <span>View Profile</span>
                        </Button>
                      </div>
                    </Card>
                  ))}
                </div>
                <CustomPagination pageLinks={profilesPaginationInfo?.pages} onPaginationClick={handleProfilesPagination} />
              </Fragment>)}
          </TabPane>
        </TabContent>
      </div>
    </React.Fragment>
  )
}
export default WishlistRdFolderDetails
