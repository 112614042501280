export const ADMIN_ACTION_TYPES = {
    BRAND_CANCEL_PLAN_REQUEST: 'BRAND_CANCEL_PLAN_REQUEST',
    BRAND_CANCEL_PLAN_SUCCESS: 'BRAND_CANCEL_PLAN_SUCCESS',
    BRAND_CANCEL_PLAN_ERROR: 'BRAND_CANCEL_PLAN_ERROR',
    BRAND_CANCEL_PLAN_RESET: 'BRAND_CANCEL_PLAN_RESET',

    DASHBOARD_MANAGE_BRANDS_REQUEST: 'DASHBOARD_MANAGE_BRANDS_REQUEST',
    DASHBOARD_MANAGE_BRANDS_SUCCESS: 'DASHBOARD_MANAGE_BRANDS_SUCCESS',
    DASHBOARD_MANAGE_BRANDS_ERROR: 'DASHBOARD_MANAGE_BRANDS_ERROR',

    DASHBOARD_MANAGE_BRANDS_CSV_REQUEST: 'DASHBOARD_MANAGE_BRANDS_CSV_REQUEST',
    DASHBOARD_MANAGE_BRANDS_CSV_SUCCESS: 'DASHBOARD_MANAGE_BRANDS_CSV_SUCCESS',
    DASHBOARD_MANAGE_BRANDS_CSV_ERROR: 'DASHBOARD_MANAGE_BRANDS_CSV_ERROR',
    DASHBOARD_MANAGE_BRANDS_CSV_RESET: 'DASHBOARD_MANAGE_BRANDS_CSV_RESET',

    BRAND_DIRECT_MESSAGES_REQUEST: 'BRAND_DIRECT_MESSAGES_REQUEST',
    BRAND_DIRECT_MESSAGES_SUCCESS: 'BRAND_DIRECT_MESSAGES_SUCCESS',
    BRAND_DIRECT_MESSAGES_ERROR: 'BRAND_DIRECT_MESSAGES_ERROR',

    RD_EMAIL_NOTIFICATION_STATA_REQUEST: 'RD_EMAIL_NOTIFICATION_STATA_REQUEST',
    RD_EMAIL_NOTIFICATION_STATA_SUCCESS: 'RD_EMAIL_NOTIFICATION_STATA_SUCCESS',
    RD_EMAIL_NOTIFICATION_STATA_ERROR: 'RD_EMAIL_NOTIFICATION_STATA_ERROR',

    RD_SITE_VIEW_STATA_REQUEST: 'RD_SITE_VIEW_STATA_REQUEST',
    RD_SITE_VIEW_STATA_SUCCESS: 'RD_SITE_VIEW_STATA_SUCCESS',
    RD_SITE_VIEW_STATA_ERROR: 'RD_SITE_VIEW_STATA_ERROR',

    ADMIN_ADD_USER_REQUEST: 'ADMIN_ADD_USER_REQUEST',
    ADMIN_ADD_USER_SUCCESS: 'ADMIN_ADD_USER_SUCCESS',
    ADMIN_ADD_USER_ERROR: 'ADMIN_ADD_USER_ERROR',
    ADMIN_ADD_USER_RESET: 'ADMIN_ADD_USER_RESET',

    ADMIN_USERS_LIST_REQUEST: 'ADMIN_USERS_LIST_REQUEST',
    ADMIN_USERS_LIST_SUCCESS: 'ADMIN_USERS_LIST_SUCCESS',
    ADMIN_USERS_LIST_ERROR: 'ADMIN_USERS_LIST_ERROR',

    ADMIN_USER_STATUS_REQUEST: 'ADMIN_USER_STATUS_REQUEST',
    ADMIN_USER_STATUS_SUCCESS: 'ADMIN_USER_STATUS_SUCCESS',
    ADMIN_USER_STATUS_ERROR: 'ADMIN_USER_STATUS_ERROR',
    ADMIN_USER_STATUS_RESET: 'ADMIN_USER_STATUS_RESET',

    ADMIN_USER_DELETE_REQUEST: 'ADMIN_USER_DELETE_REQUEST',
    ADMIN_USER_DELETE_SUCCESS: 'ADMIN_USER_DELETE_SUCCESS',
    ADMIN_USER_DELETE_ERROR: 'ADMIN_USER_DELETE_ERROR',
    ADMIN_USER_DELETE_RESET: 'ADMIN_USER_DELETE_RESET',

    ADMIN_USER_UPDATE_REQUEST: 'ADMIN_USER_UPDATE_REQUEST',
    ADMIN_USER_UPDATE_SUCCESS: 'ADMIN_USER_UPDATE_SUCCESS',
    ADMIN_USER_UPDATE_ERROR: 'ADMIN_USER_UPDATE_ERROR',
    ADMIN_USER_UPDATE_RESET: 'ADMIN_USER_UPDATE_RESET',

    ADMIN_USER_SET_PASSWORD_REQUEST: 'ADMIN_USER_SET_PASSWORD_REQUEST',
    ADMIN_USER_SET_PASSWORD_SUCCESS: 'ADMIN_USER_SET_PASSWORD_SUCCESS',
    ADMIN_USER_SET_PASSWORD_ERROR: 'ADMIN_USER_SET_PASSWORD_ERROR',
    ADMIN_USER_SET_PASSWORD_RESET: 'ADMIN_USER_SET_PASSWORD_RESET',

    ADMIN_BLOG_LIST_REQUEST: 'ADMIN_BLOG_LIST_REQUEST',
    ADMIN_BLOG_LIST_SUCCESS: 'ADMIN_BLOG_LIST_SUCCESS',
    ADMIN_BLOG_LIST_ERROR: 'ADMIN_BLOG_LIST_ERROR',

    ADMIN_BLOG_DELETE_REQUEST: 'ADMIN_BLOG_DELETE_REQUEST',
    ADMIN_BLOG_DELETE_SUCCESS: 'ADMIN_BLOG_DELETE_SUCCESS',
    ADMIN_BLOG_DELETE_ERROR: 'ADMIN_BLOG_DELETE_ERROR',
    ADMIN_BLOG_DELETE_RESET: 'ADMIN_BLOG_DELETE_RESET',

    ADMIN_BLOG_ADD_UPDATE_REQUEST: 'ADMIN_BLOG_ADD_UPDATE_REQUEST',
    ADMIN_BLOG_ADD_UPDATE_SUCCESS: 'ADMIN_BLOG_ADD_UPDATE_SUCCESS',
    ADMIN_BLOG_ADD_UPDATE_ERROR: 'ADMIN_BLOG_ADD_UPDATE_ERROR',
    ADMIN_BLOG_ADD_UPDATE_RESET: 'ADMIN_BLOG_ADD_UPDATE_RESET',

    ADMIN_BLOG_DETAILS_REQUEST: 'ADMIN_BLOG_DETAILS_REQUEST',
    ADMIN_BLOG_DETAILS_SUCCESS: 'ADMIN_BLOG_DETAILS_SUCCESS',
    ADMIN_BLOG_DETAILS_ERROR: 'ADMIN_BLOG_DETAILS_ERROR',
    ADMIN_BLOG_DETAILS_RESET: 'ADMIN_BLOG_DETAILS_RESET',

    ADMIN_COUPON_LIST_REQUEST: 'ADMIN_COUPON_LIST_REQUEST',
    ADMIN_COUPON_LIST_SUCCESS: 'ADMIN_COUPON_LIST_SUCCESS',
    ADMIN_COUPON_LIST_ERROR: 'ADMIN_COUPON_LIST_ERROR',

    ADMIN_COUPON_DELETE_REQUEST: 'ADMIN_COUPON_DELETE_REQUEST',
    ADMIN_COUPON_DELETE_SUCCESS: 'ADMIN_COUPON_DELETE_SUCCESS',
    ADMIN_COUPON_DELETE_ERROR: 'ADMIN_COUPON_DELETE_ERROR',
    ADMIN_COUPON_DELETE_RESET: 'ADMIN_COUPON_DELETE_RESET',

    ADMIN_COUPON_CREATE_REQUEST: 'ADMIN_COUPON_CREATE_REQUEST',
    ADMIN_COUPON_CREATE_SUCCESS: 'ADMIN_COUPON_CREATE_SUCCESS',
    ADMIN_COUPON_CREATE_ERROR: 'ADMIN_COUPON_CREATE_ERROR',
    ADMIN_COUPON_CREATE_RESET: 'ADMIN_COUPON_CREATE_RESET'
}