import axios from "axios"
import {RD_ACTION_TYPES} from "@store/actionTypes/RDActionTypes"

const API_URL = `${process.env.REACT_APP_BASEURL}rd`

const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getProfile = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.GET_PROFILE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/view-profile`, {headers: header})
        dispatch({type: RD_ACTION_TYPES.GET_PROFILE_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.GET_PROFILE_ERROR, payload: error})
    }
}

export const getProfileReset = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.GET_PROFILE_RESET})
}

export const updateProfile = (dataObj) => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.UPDATE_PROFILE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/update-profile`, dataObj, {headers: header})
        dispatch({type: RD_ACTION_TYPES.UPDATE_PROFILE_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.UPDATE_PROFILE_ERROR, payload: error})
    }
}

export const updateProfileReset = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.UPDATE_PROFILE_RESET})
}

export const removeMediaFromProfile = (dataObj) => async (dispatch) => {
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        await axios.post(`${API_URL}/delete-media-document`, dataObj, {headers: header})
    } catch (error) {
    }
}

export const deleteAccount = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.DELETE_ACCOUNT_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/delete-account`, {headers: header})
        dispatch({type: RD_ACTION_TYPES.DELETE_ACCOUNT_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.DELETE_ACCOUNT_ERROR, payload: error})
    }
}

export const deleteAccountReset = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.DELETE_ACCOUNT_RESET})
}

export const facebookLogin = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.FACEBOOK_LOGIN_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/facebook-login`, {headers: header})
        dispatch({type: RD_ACTION_TYPES.FACEBOOK_LOGIN_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.FACEBOOK_LOGIN_ERROR, payload: error})
    }
}

export const facebookLoginReset = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.FACEBOOK_LOGIN_RESET})
}

export const facebookToken = (dataObj) => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.FACEBOOK_TOKEN_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/facebook-token`, dataObj, {headers: header})
        dispatch({type: RD_ACTION_TYPES.FACEBOOK_TOKEN_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.FACEBOOK_TOKEN_ERROR, payload: error})
    }
}

export const facebookTokenReset = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.FACEBOOK_TOKEN_RESET})
}

export const facebookIgId = (dataObj) => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.FACEBOOK_IGID_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/facebook-ig-id`, dataObj, {headers: header})
        dispatch({type: RD_ACTION_TYPES.FACEBOOK_IGID_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.FACEBOOK_IGID_ERROR, payload: error})
    }
}

export const facebookIgIdReset = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.FACEBOOK_IGID_RESET})
}

export const facebookIgData = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.FACEBOOK_IG_DATA_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/facebook-ig-data`, {headers: header})
        dispatch({type: RD_ACTION_TYPES.FACEBOOK_IG_DATA_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.FACEBOOK_IG_DATA_ERROR, payload: error})
    }
}

export const facebookIgDataReset = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.FACEBOOK_IG_DATA_RESET})
}

export const facebookIgLogout = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.FACEBOOK_IG_LOGOUT_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/facebook-ig-logout`, {headers: header})
        dispatch({type: RD_ACTION_TYPES.FACEBOOK_IG_LOGOUT_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.FACEBOOK_IG_LOGOUT_ERROR, payload: error})
    }
}

export const facebookIgLogoutReset = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.FACEBOOK_IG_LOGOUT_RESET})
}

export const markAsRead = (dataObj) => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.MARK_AS_READ_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/mark-as-read`, dataObj, {headers: header})
        dispatch({type: RD_ACTION_TYPES.MARK_AS_READ_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.MARK_AS_READ_ERROR, payload: error})
    }
}

export const markAsReadReset = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.MARK_AS_READ_RESET})
}

export const getNewEvents = (dataObj) => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.GET_NEW_EVENTS_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/load-upcoming-event-list${dataObj}`, {headers: header})
        dispatch({type: RD_ACTION_TYPES.GET_NEW_EVENTS_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.GET_NEW_EVENTS_ERROR, payload: error})
    }
}

export const deleteEvent = (dataObj) => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.DELETE_EVENT_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/delete-event`, dataObj, {headers: header})
        dispatch({type: RD_ACTION_TYPES.DELETE_EVENT_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.DELETE_EVENT_ERROR, payload: error})
    }
}

export const deleteEventReset = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.DELETE_EVENT_RESET})
}

export const getPastEvents = (dataObj) => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.GET_PAST_EVENTS_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/load-past-event-list${dataObj}`, {headers: header})
        dispatch({type: RD_ACTION_TYPES.GET_PAST_EVENTS_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.GET_PAST_EVENTS_ERROR, payload: error})
    }
}

export const getEventBrands = (dataObj) => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.GET_EVENT_BRANDS_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/get-brands-for-feedback${dataObj}`, {headers: header})
        dispatch({type: RD_ACTION_TYPES.GET_EVENT_BRANDS_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.GET_EVENT_BRANDS_ERROR, payload: error})
    }
}

export const copyEvent = (dataObj) => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.COPY_EVENT_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/rd-copy-opportunity`, dataObj, {headers: header})
        dispatch({type: RD_ACTION_TYPES.COPY_EVENT_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.COPY_EVENT_ERROR, payload: error})
    }
}

export const copyEventReset = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.COPY_EVENT_RESET})
}

export const campaignView = (dataObj) => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.CAMPAIGN_VIEW_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/campaign-details-v2${dataObj}`, {headers: header})
        dispatch({type: RD_ACTION_TYPES.CAMPAIGN_VIEW_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.CAMPAIGN_VIEW_ERROR, payload: error})
    }
}

export const applyOnCampaign = (dataObj) => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.APPLY_ON_CAMPAIGN_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/apply-campaign-v2`, dataObj, {headers: header})
        dispatch({type: RD_ACTION_TYPES.APPLY_ON_CAMPAIGN_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.APPLY_ON_CAMPAIGN_ERROR, payload: error})
    }
}

export const applyOnCampaignReset = () => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.APPLY_ON_CAMPAIGN_RESET})
}

export const campaignListing = (dataObj) => async (dispatch) => {
    dispatch({type: RD_ACTION_TYPES.CAMPAIGN_LISTING_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/all-campaigns${dataObj}`, {headers: header})
        dispatch({type: RD_ACTION_TYPES.CAMPAIGN_LISTING_DATA, payload: data})
    } catch (error) {
        dispatch({type: RD_ACTION_TYPES.CAMPAIGN_LISTING_ERROR, payload: error})
    }
}