import React, {useEffect, useState, Fragment} from "react"
import {useSelector, useDispatch} from 'react-redux'
import {selectThemeColors} from '@utils'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'
import {
    X,
    Video,
    ArrowRight,
    File,
    Trash,
    Eye
} from 'react-feather'
import PDF from "@src/assets/images/icons/pdf1.png"
import {
    Label,
    FormGroup,
    Row,
    Col,
    Input,
    Form,
    Button,
    CustomInput,
    Alert,
    Card,
    CardBody,
    Spinner,
    Table
} from 'reactstrap'
import {
    getBrandGoalsSelectValues,
    getBrandCompanySelectValues,
    getHearAboutUsOptions
} from '@store/actions/auth'
import {NULL} from '@store/actionTypes'
import {
    getCountries,
    nullResponse,
    removeMediaFromProfile
} from '@store/actions/brandsDashboard'
import * as yup from "yup"
import {useFormik} from "formik"
import {
    getRegions,
    getStates,
    getMultipleCities
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import Avatar from "@components/avatar"
import blankImage from "@src/assets/images/avatars/avatar-blank.png"
import classnames from "classnames"
import {
    viewProfile,
    saveProfile,
    saveProfileReset
} from "@src/redux/actions/BrandActions"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Uploader from "@src/views/components/Uploader"
import ImageViewer from "@src/views/components/ImageViewer"
import PdfViewer from "@src/views/components/PdfViewer"
import VideoPlayer from "@src/views/components/VideoPlayer"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from 'html-to-draftjs'
import {ContentState, convertToRaw, EditorState} from "draft-js"
import {Editor} from 'react-draft-wysiwyg'
import '@styles/react/libs/editor/editor.scss'
import '@styles/base/plugins/forms/form-quill-editor.scss'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"

const EditBrandProfile = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()

    const sweetAlert = withReactContent(Swal)

    const userData = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))

    const COMPANY_LOGO_FORMATS = ["image/png", "image/jpeg", "image/jpg"]

    const urlRegex = /([hH][tT]{2}[pP][sS]?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/

    const onlyCharRegex = /^[aA-zZ\s]+$/

    const charAndNumberRegex = /^[a-zA-Z0-9\s]+$/

    const companyRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s!@#$%^&*()_+=-]*$/

    const animatedComponents = makeAnimated()

    const [companyLogoViewLink, setCompanyLogoViewLink] = useState("")
    const [profileMedia, setProfileMedia] = useState([])
    const [showUploader, setShowUploader] = useState(false)
    const [attachments, setAttachments] = useState([])
    const [imageViewer, setImageViewer] = useState({show: false, src: ''})
    const [pdfViewer, setPdfViewer] = useState({show: false, url: '', filename: ''})
    const [videoPlayer, setVideoPlayer] = useState({show: false, url: '', filename: ''})

    const dispatch = useDispatch()

    const {
        countries,
        removeMediaAtProfile
    } = useSelector((store) => store.brandsDashboardSlice)

    const {
        hearAboutOptions,
        brandCompanySelectValues,
        brandGoalsSelectValues
    } = useSelector(state => state.auth)


    const {
        viewProfileLoading,
        viewProfileData,

        saveProfileLoading,
        saveProfileData,
        saveProfileError
    } = useSelector(state => state.brandReducer)

    const {
        regions,
        states,
        cities
    } = useSelector((store) => store.brandsOppoprunities)

    const [aboutCompanyEditorState, setAboutCompanyEditorState] = useState(null)

    const updateProfileForm = useFormik({
        initialValues: {
            email: '',
            firstAndLastName: '',
            companyName: '',
            personTitle: '',
            phoneNumber: '',
            companyLogoFile: '',
            aboutCompany: '',
            website: '',
            facebook: '',
            instagram: '',
            twitter: '',
            pinterest: '',
            linkedin: '',
            tiktok: '',
            companyValues: '',
            specialDiet: '',
            foodCategory: '',
            goals: '',
            personalAttributes: '',
            industry: '',
            speciality: '',
            country: '',
            region: '',
            state: '',
            city: '',
            hearAboutUs: '',
            hereAboutOtherInput: ''
        },
        validationSchema: yup.object().shape({
            email: yup.string().email('This field is required.').required("This field is required."),
            firstAndLastName: yup.string().trim().required("This field is required.").matches(onlyCharRegex, "Only alphabets are allowed."),
            companyName: yup.string().trim().required("This field is required."),
            personTitle: yup.string().trim().required("This field is required."),
            phoneNumber: yup.string().trim().required("This field is required."),
            companyLogoFile: yup.mixed()
                .test(
                    "fileFormat",
                    "File is empty or invalid.",
                    value => !value || (value && COMPANY_LOGO_FORMATS.includes(value.type))
                ),
            website: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            facebook: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            instagram: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            twitter: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            pinterest: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            linkedin: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            tiktok: yup.string().test('urlMatch', 'Invalid URL.', (value) => {
                if (value && value.length > 0) {
                    return urlRegex.test(value)
                }
                return true
            }),
            goals: yup.string().trim().required("This field is required.")
        }),
        onSubmit: async (values) => {
            const token = await executeRecaptcha('brand_update_profile')
            if (token) {
                const formData = new FormData()
                formData.append("email", values.email)
                formData.append("name", values.firstAndLastName)
                formData.append("brand_name", values.companyName)
                formData.append("person_title", values.personTitle)
                formData.append("phone_number", values.phoneNumber)
                if (values?.companyLogoFile) {
                    formData.append("company_logo", values?.companyLogoFile)
                }
                formData.append("about_company", values.aboutCompany)
                formData.append("website", values.website)
                formData.append("facebook_url", values.facebook)
                formData.append("instagram_url", values.instagram)
                formData.append("twitter_url", values.twitter)
                formData.append("pinterest_url", values.pinterest)
                formData.append("linkedin_url", values.linkedin)
                formData.append("tiktok_url", values.tiktok)
                if (values?.companyValues) {
                    values?.companyValues?.forEach(id => formData.append("values[]", id.id))
                }
                if (values?.specialDiet) {
                    values?.specialDiet?.forEach(id => formData.append("special_diet[]", id.id))
                }
                if (values?.foodCategory) {
                    values?.foodCategory?.forEach(id => formData.append("category[]", id.id))
                }
                formData.append("goals", values.goals)
                if (values?.personalAttributes) {
                    values?.personalAttributes?.forEach(id => formData.append("personal_attributes[]", id.id))
                }
                if (values?.industry) {
                    values?.industry?.forEach(id => formData.append("industry[]", id.id))
                }
                if (values?.speciality) {
                    values?.speciality?.forEach(id => formData.append("speciality[]", id.id))
                }
                if (values?.country) {
                    formData.append("country[]", values?.country.id)
                }
                if (values?.region) {
                    formData.append("region[]", values?.region.id)
                }
                if (values?.state) {
                    values?.state?.forEach(id => formData.append("state[]", id.id))
                }
                if (values?.city) {
                    values?.city?.forEach(id => formData.append("city[]", id.id))
                }
                if (values?.hearAboutUs) {
                    formData.append("here_about", values?.hearAboutUs?.id)
                    formData.append("is_other_hear_about", values?.hereAboutOtherInput)
                }
                if (attachments?.length > 0) {
                    attachments?.forEach(item => formData.append("media_documents[]", JSON.stringify(item)))
                }
                formData.append("re_captcha_token", token)
                dispatch(saveProfile(formData))
            } else {
                sweetAlert.fire({
                    title: 'Captcha Error',
                    icon: 'error',
                    html: '<p>Execute recaptcha not yet available.</p>',
                    confirmButtonText: 'Reload',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                })
            }
        }
    })

    useEffect(() => {
        if (removeMediaAtProfile?.success) {
            dispatch(nullResponse(NULL.BRANDS_REMOVE_MEDIA_ON_PROFILE))
        }
    }, [removeMediaAtProfile])

    useEffect(() => {
        if (viewProfileData?.data) {
            const res = viewProfileData?.data
            updateProfileForm.setFieldValue('email', res?.email)
            updateProfileForm.setFieldValue('firstAndLastName', res?.name)
            updateProfileForm.setFieldValue('companyName', res?.brand_name)
            updateProfileForm.setFieldValue('personTitle', res?.contact_title)
            updateProfileForm.setFieldValue('phoneNumber', res?.phone_number)
            setAboutCompanyEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(res?.about_company))))
            updateProfileForm.setFieldValue('aboutCompany', res?.about_company)
            setCompanyLogoViewLink(res?.company_logo)
            updateProfileForm.setFieldValue('website', res?.website)
            updateProfileForm.setFieldValue('facebook', res?.facebook_url)
            updateProfileForm.setFieldValue('instagram', res?.instagram_url)
            updateProfileForm.setFieldValue('twitter', res?.twitter_url)
            updateProfileForm.setFieldValue('pinterest', res?.pinterest_url)
            updateProfileForm.setFieldValue('linkedin', res?.linkedin_url)
            updateProfileForm.setFieldValue('tiktok', res?.tiktok_url)
            updateProfileForm.setFieldValue('companyValues', res?.attributes?.values)
            updateProfileForm.setFieldValue('specialDiet', res?.attributes?.special_diet)
            updateProfileForm.setFieldValue('foodCategory', res?.attributes?.category)
            updateProfileForm.setFieldValue('goals', res?.goals)
            updateProfileForm.setFieldValue('personalAttributes', res?.interested_brands?.personal_attributes)
            updateProfileForm.setFieldValue('industry', res?.interested_brands?.industry)
            updateProfileForm.setFieldValue('speciality', res?.interested_brands?.speciality)
            updateProfileForm.setFieldValue('country', res?.location?.country)
            updateProfileForm.setFieldValue('region', res?.location?.region)
            updateProfileForm.setFieldValue('state', res?.location?.state)
            updateProfileForm.setFieldValue('city', res?.location?.city)
            if (res?.hear_about_us) {
                updateProfileForm.setFieldValue('hearAboutUs', res?.hear_about_us)
            }
            updateProfileForm.setFieldValue('hereAboutOtherInput', res?.is_other_hear_about)
            setProfileMedia(res?.media)
        }
    }, [viewProfileData])

    useEffect(() => {
        if (saveProfileError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (saveProfileError?.response?.data?.email) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${saveProfileError?.response?.data?.email[0]}</li>`)
            }
            if (saveProfileError?.response?.data?.name) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${saveProfileError?.response?.data?.name[0]}</li>`)
            }
            if (saveProfileError?.response?.data?.brand_name) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${saveProfileError?.response?.data?.brand_name[0]}</li>`)
            }
            if (saveProfileError?.response?.data?.person_title) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${saveProfileError?.response?.data?.person_title[0]}</li>`)
            }
            if (saveProfileError?.response?.data?.phone_number) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${saveProfileError?.response?.data?.phone_number[0]}</li>`)
            }
            if (saveProfileError?.response?.data?.company_logo) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${saveProfileError?.response?.data?.company_logo[0]}</li>`)
            }
            if (saveProfileError?.response?.data?.re_captcha_token) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${saveProfileError?.response?.data?.re_captcha_token[0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            updateProfileForm.setFieldValue('captchaToken', '')
            dispatch(saveProfileReset())
        }
        if (saveProfileData?.success) {
            userData.name = saveProfileData?.data.name
            userData.brand_name = saveProfileData?.data.company_name
            userData.logo = saveProfileData?.data.company_logo
            localStorage.setItem(`${process.env.REACT_APP_USERDATA}`, JSON.stringify(userData))
            sweetAlert.fire({
                title: 'Congratulations!',
                icon: 'success',
                html: '<p class="font-small-3 mt-2 mb-0 font-weight-bold">Your profile updated successfully.</p> <p class="font-small-3 mb-5">Please wait a moment we are loading your changes.</p>',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false
            })
            dispatch(saveProfileReset())
            setTimeout(() => {
                window.location.reload()
            }, 5000)
        }
    }, [saveProfileData, saveProfileError])

    useEffect(() => {
        dispatch(getCountries())
        dispatch(getBrandCompanySelectValues())
        dispatch(getBrandGoalsSelectValues())
        dispatch(getHearAboutUsOptions())
        dispatch(viewProfile())
    }, [])

    const getRegionsById = (e) => {
        dispatch(getRegions(e?.id))
    }

    const getStatesById = (e) => {
        dispatch(getStates(e?.id))
    }

    const getCitiesById = (e) => {
        const formData = new FormData()
        e.map((item) => {
            formData.append('state_id[]', item?.id)
        })
        dispatch(getMultipleCities(formData))
    }

    const handleRemoveMedia = (file) => {
        if (file?.id) {
            setProfileMedia(profileMedia?.filter(x => x.id !== file.id))
            dispatch(removeMediaFromProfile(file?.id))
        }
    }

    const handleCompanyLogo = (event) => {
        if (event.target.files && event.target.files[0]) {
            setCompanyLogoViewLink(URL.createObjectURL(event.target.files[0]))
        }
    }

    const deleteUploads = (index) => {
        sweetAlert.fire({
            title: 'Confirm!',
            icon: 'warning',
            html: '<p>Do you really want to delete?</p>',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonText: 'No',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                setAttachments(prevAttachments => {
                    return prevAttachments.filter((_, i) => i !== index)
                })
            }
        })
    }

    const onSuccessUploads = (data) => {
        setAttachments(prevAttachments => [...prevAttachments, data])
        sweetAlert.fire({
            title: 'Success!',
            icon: 'success',
            html: '<p>File uploaded successfully.</p>',
            confirmButtonText: 'OK'
        })
        setShowUploader(false)
    }

    const onErrorUploads = (error) => {
        sweetAlert.fire({
            title: 'Error!',
            icon: 'error',
            html: `<p>${JSON.stringify(error)}</p>`,
            confirmButtonText: 'OK'
        })
        setShowUploader(false)
    }

    return (
        <Fragment>
            <h3>Edit Your Profile</h3>
            <Card>
                <CardBody>
                    {viewProfileLoading ? (
                        <Alert className="mx-2 mt-2" color='primary'>
                            <div className='alert-body text-center'>
                                <Spinner style={{width: '18px', height: '18px'}}/> Please wait...
                            </div>
                        </Alert>
                    ) : (
                        <Form onSubmit={updateProfileForm.handleSubmit}>
                            <Row>
                                <FormGroup tag={Col} md='12'>
                                    <div className="item-img text-center mx-auto">
                                        <Avatar img={companyLogoViewLink || blankImage} imgHeight="150" imgWidth="150" className="avatar-img-fit-contain"/>
                                    </div>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <hr/>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor="email"
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.email && updateProfileForm.errors.email,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Email <span className="is-invalid">*</span>
                                    </Label>
                                    <Input type="email"
                                           name="email"
                                           id="email"
                                           value={updateProfileForm.values.email}
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           className={classnames({'is-invalid': updateProfileForm.touched.email && updateProfileForm.errors.email})}
                                           placeholder="Email"
                                           disabled={viewProfileLoading || saveProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.email && updateProfileForm.errors.email}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor="firstAndLastName"
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.firstAndLastName && updateProfileForm.errors.firstAndLastName,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        First and Last Name <span className="is-invalid">*</span>
                                    </Label>
                                    <Input type="text"
                                           name="firstAndLastName"
                                           id="firstAndLastName"
                                           value={updateProfileForm.values.firstAndLastName}
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           className={classnames({'is-invalid': updateProfileForm.touched.firstAndLastName && updateProfileForm.errors.firstAndLastName})}
                                           placeholder="First and Last Name"
                                           disabled={viewProfileLoading || saveProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.firstAndLastName && updateProfileForm.errors.firstAndLastName}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor="companyName"
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.companyName && updateProfileForm.errors.companyName,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Company Name <span className="is-invalid">*</span>
                                    </Label>
                                    <Input type="text"
                                           name="companyName"
                                           id="companyName"
                                           value={updateProfileForm.values.companyName}
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           className={classnames({'is-invalid': updateProfileForm.touched.companyName && updateProfileForm.errors.companyName})}
                                           placeholder="Company Name"
                                           disabled={viewProfileLoading || saveProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.companyName && updateProfileForm.errors.companyName}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor="personTitle"
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.personTitle && updateProfileForm.errors.personTitle,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Your Title <span className="is-invalid">*</span>
                                    </Label>
                                    <Input type="text"
                                           name="personTitle"
                                           id="personTitle"
                                           value={updateProfileForm.values.personTitle}
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           className={classnames({'is-invalid': updateProfileForm.touched.personTitle && updateProfileForm.errors.personTitle})}
                                           placeholder="Your Title"
                                           disabled={viewProfileLoading || saveProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.personTitle && updateProfileForm.errors.personTitle}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor="phoneNumber"
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.phoneNumber && updateProfileForm.errors.phoneNumber,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Phone Number <span className="is-invalid">*</span>
                                    </Label>
                                    <Input type="text"
                                           name="phoneNumber"
                                           id="phoneNumber"
                                           value={updateProfileForm.values.phoneNumber}
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           className={classnames({'is-invalid': updateProfileForm.touched.phoneNumber && updateProfileForm.errors.phoneNumber})}
                                           placeholder="Phone Number"
                                           disabled={viewProfileLoading || saveProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.phoneNumber && updateProfileForm.errors.phoneNumber}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor="companyLogoFile"
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.companyLogoFile && updateProfileForm.errors.companyLogoFile,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Company Logo
                                    </Label>
                                    <CustomInput type="file"
                                                 name="companyLogoFile"
                                                 id="companyLogoFile"
                                                 accept=".png, .jpg, .jpeg"
                                                 onChange={(e) => {
                                                     updateProfileForm.setFieldValue('companyLogoFile', e.target.files[0])
                                                     handleCompanyLogo(e)
                                                 }}
                                                 onBlur={updateProfileForm.handleBlur}
                                                 className={classnames({'is-invalid': updateProfileForm.touched.companyLogoFile && updateProfileForm.errors.companyLogoFile})}
                                                 disabled={viewProfileLoading || saveProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.companyLogoFile && updateProfileForm.errors.companyLogoFile}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <Label htmlFor="aboutCompany"
                                           className={classnames({
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        About Company
                                    </Label>
                                    <Editor editorState={aboutCompanyEditorState}
                                            onEditorStateChange={(e) => {
                                                setAboutCompanyEditorState(e)
                                                const contentState = e.getCurrentContent()
                                                if (contentState.hasText()) {
                                                    updateProfileForm.setFieldValue('aboutCompany', draftToHtml(convertToRaw(contentState)))
                                                } else {
                                                    updateProfileForm.setFieldValue('aboutCompany', "")
                                                }
                                            }}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'link', 'remove', 'history'],
                                                inline: {
                                                    options: ['bold', 'italic', 'underline']
                                                },
                                                list: {
                                                    options: ['unordered', 'ordered']
                                                },
                                                blockType: {
                                                    options: ['Normal', 'H1', 'H2', 'H3']
                                                },
                                                link: {
                                                    showOpenOptionOnHover: false
                                                },
                                                fontSize: {
                                                    options: [12, 14, 16, 18, 24, 36]
                                                }
                                            }}
                                            stripPastedStyles={true}
                                            editorStyle={{minHeight: '250px'}}
                                    />
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <h6 className="font-weight-bolder bg-light p-1">
                                        Social Media Links
                                    </h6>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor="website"
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.website && updateProfileForm.errors.website,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Website
                                    </Label>
                                    <Input type="text"
                                           name='website'
                                           id="website"
                                           value={updateProfileForm.values.website}
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           className={classnames({'is-invalid': updateProfileForm.touched.website && updateProfileForm.errors.website})}
                                           placeholder="Website URL"
                                           disabled={viewProfileLoading || saveProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.website && updateProfileForm.errors.website}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor="facebook"
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.facebook && updateProfileForm.errors.facebook,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Facebook
                                    </Label>
                                    <Input type="text"
                                           name="facebook"
                                           id="facebook"
                                           value={updateProfileForm.values.facebook}
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           className={classnames({'is-invalid': updateProfileForm.touched.facebook && updateProfileForm.errors.facebook})}
                                           placeholder="Facebook URL"
                                           disabled={viewProfileLoading || saveProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.facebook && updateProfileForm.errors.facebook}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor="instagram"
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.instagram && updateProfileForm.errors.instagram,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Instagram
                                    </Label>
                                    <Input type="text"
                                           name="instagram"
                                           id="instagram"
                                           value={updateProfileForm.values.instagram}
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           className={classnames({'is-invalid': updateProfileForm.touched.instagram && updateProfileForm.errors.instagram})}
                                           placeholder="Instagram URL"
                                           disabled={viewProfileLoading || saveProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.instagram && updateProfileForm.errors.instagram}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor="twitter"
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.twitter && updateProfileForm.errors.twitter,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Twitter
                                    </Label>
                                    <Input type="text"
                                           name="twitter"
                                           id="twitter"
                                           value={updateProfileForm.values.twitter}
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           className={classnames({'is-invalid': updateProfileForm.touched.twitter && updateProfileForm.errors.twitter})}
                                           placeholder="Twitter URL"
                                           disabled={viewProfileLoading || saveProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.twitter && updateProfileForm.errors.twitter}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor="pinterest"
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.pinterest && updateProfileForm.errors.pinterest,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Pinterest
                                    </Label>
                                    <Input type="text"
                                           name="pinterest"
                                           id="pinterest"
                                           value={updateProfileForm.values.pinterest}
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           className={classnames({'is-invalid': updateProfileForm.touched.pinterest && updateProfileForm.errors.pinterest})}
                                           placeholder="Pinterest URL"
                                           disabled={viewProfileLoading || saveProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.pinterest && updateProfileForm.errors.pinterest}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor="linkedin"
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.linkedin && updateProfileForm.errors.linkedin,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        LinkedIn
                                    </Label>
                                    <Input type="text"
                                           name="linkedin"
                                           id="linkedin"
                                           value={updateProfileForm.values.linkedin}
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           className={classnames({'is-invalid': updateProfileForm.touched.linkedin && updateProfileForm.errors.linkedin})}
                                           placeholder="LinkedIn URL"
                                           disabled={viewProfileLoading || saveProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.linkedin && updateProfileForm.errors.linkedin}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='4'>
                                    <Label htmlFor="tiktok"
                                           className={classnames({
                                               'is-invalid': updateProfileForm.touched.tiktok && updateProfileForm.errors.tiktok,
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        TikTok
                                    </Label>
                                    <Input type="text"
                                           name="tiktok"
                                           id="tiktok"
                                           value={updateProfileForm.values.tiktok}
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           className={classnames({'is-invalid': updateProfileForm.touched.tiktok && updateProfileForm.errors.tiktok})}
                                           placeholder="TikTok URL"
                                           disabled={viewProfileLoading || saveProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.tiktok && updateProfileForm.errors.tiktok}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <h6 className="font-weight-bolder bg-light p-1">
                                        Select Product and Company Characteristics
                                    </h6>
                                </FormGroup>
                                <FormGroup tag={Col} md="4">
                                    <Label htmlFor="companyValues"
                                           className={classnames({
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Company Values
                                    </Label>
                                    <Select isClearable={false}
                                            theme={selectThemeColors}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti={true}
                                            options={brandCompanySelectValues?.values}
                                            value={updateProfileForm.values.companyValues}
                                            className='react-select'
                                            classNamePrefix='select'
                                            onChange={(e) => updateProfileForm.setFieldValue('companyValues', e)}
                                            id="companyValues"
                                            isDisabled={viewProfileLoading || saveProfileLoading}
                                    />
                                </FormGroup>
                                <FormGroup tag={Col} md="4">
                                    <Label htmlFor="specialDiet"
                                           className={classnames({
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Special Diet
                                    </Label>
                                    <Select isClearable={false}
                                            theme={selectThemeColors}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            options={brandCompanySelectValues?.special_diet}
                                            value={updateProfileForm.values.specialDiet}
                                            className='react-select'
                                            classNamePrefix='select'
                                            onChange={(e) => updateProfileForm.setFieldValue('specialDiet', e)}
                                            id="specialDiet"
                                            isDisabled={viewProfileLoading || saveProfileLoading}
                                    />
                                </FormGroup>
                                <FormGroup tag={Col} md="4">
                                    <Label htmlFor="foodCategory"
                                           className={classnames({
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Food Category
                                    </Label>
                                    <Select isClearable={false}
                                            theme={selectThemeColors}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            options={brandCompanySelectValues?.category}
                                            value={updateProfileForm.values.foodCategory}
                                            className='react-select'
                                            classNamePrefix='select'
                                            onChange={(e) => updateProfileForm.setFieldValue('foodCategory', e)}
                                            id="foodCategory"
                                            isDisabled={viewProfileLoading || saveProfileLoading}
                                    />
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <h6 className="font-weight-bolder bg-light p-1">
                                        <span>What do you hope to get out of using Link?</span>
                                        <span className="is-invalid"> *</span>
                                    </h6>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <Input type="textarea"
                                           name="goals"
                                           id="goals"
                                           rows='10'
                                           value={updateProfileForm.values.goals}
                                           onBlur={updateProfileForm.handleBlur}
                                           onChange={updateProfileForm.handleChange}
                                           className={classnames({'is-invalid': updateProfileForm.touched.goals && updateProfileForm.errors.goals})}
                                           placeholder="Your answer here..."
                                           disabled={viewProfileLoading || saveProfileLoading}
                                    />
                                    <div className="validation-err">{updateProfileForm.touched.goals && updateProfileForm.errors.goals}</div>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <h6 className="font-weight-bolder bg-light p-1">
                                        Tell us more about the types of RDs you are hoping to connect with?
                                        This will help us make custom recommendations
                                    </h6>
                                </FormGroup>
                                <FormGroup tag={Col} md="4">
                                    <Label htmlFor="personalAttributes"
                                           className={classnames({
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Personal Attributes
                                    </Label>
                                    <Select isClearable={false}
                                            theme={selectThemeColors}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            options={brandGoalsSelectValues?.personal_attributes}
                                            value={updateProfileForm.values.personalAttributes}
                                            isMulti={true}
                                            className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Select Attributes"
                                            onChange={(e) => updateProfileForm.setFieldValue('personalAttributes', e)}
                                            id="personalAttributes"
                                            isDisabled={viewProfileLoading || saveProfileLoading}
                                    />
                                </FormGroup>
                                <FormGroup tag={Col} md="4">
                                    <Label htmlFor="industry"
                                           className={classnames({
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Industry
                                    </Label>
                                    <Select isClearable={false}
                                            theme={selectThemeColors}
                                            closeMenuOnSelect={false}
                                            options={brandGoalsSelectValues?.industry}
                                            components={animatedComponents}
                                            value={updateProfileForm.values.industry}
                                            isMulti={true}
                                            className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Select Industry"
                                            onChange={(e) => updateProfileForm.setFieldValue('industry', e)}
                                            id="industry"
                                            isDisabled={viewProfileLoading || saveProfileLoading}
                                    />
                                </FormGroup>
                                <FormGroup tag={Col} md="4">
                                    <Label htmlFor="speciality"
                                           className={classnames({
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Speciality
                                    </Label>
                                    <Select isClearable={false}
                                            theme={selectThemeColors}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            options={brandGoalsSelectValues?.speciality}
                                            value={updateProfileForm.values.speciality}
                                            isMulti={true}
                                            className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Select Speciality"
                                            onChange={(e) => updateProfileForm.setFieldValue('speciality', e)}
                                            id="speciality"
                                            isDisabled={viewProfileLoading || saveProfileLoading}
                                    />
                                </FormGroup>
                                <FormGroup tag={Col} md="6">
                                    <Label htmlFor="country"
                                           className={classnames({
                                               'form-label': true,
                                               'font-weight-bold': true,
                                               'd-flex justify-content-between': true
                                           })}>
                                        <span>Country</span>
                                        <span className="text-primary">(If you wish to change locations, start from the country)</span>
                                    </Label>
                                    <Select isClearable={true}
                                            theme={selectThemeColors}
                                            options={countries}
                                            value={updateProfileForm.values.country}
                                            className='react-select'
                                            classNamePrefix='select'
                                            onChange={(e) => {
                                                updateProfileForm.setFieldValue('country', e)
                                                updateProfileForm.setFieldValue('region', null)
                                                updateProfileForm.setFieldValue('state', null)
                                                updateProfileForm.setFieldValue('city', null)
                                                getRegionsById(e)
                                            }}
                                            id="country"
                                            isDisabled={viewProfileLoading || saveProfileLoading}
                                    />
                                </FormGroup>
                                <FormGroup tag={Col} md="6">
                                    <Label htmlFor="regions"
                                           className={classnames({
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Region
                                    </Label>
                                    <div className="overlay-select-parent">
                                        <Select isClearable={true}
                                                theme={selectThemeColors}
                                                options={regions ?? []}
                                                value={updateProfileForm.values.region}
                                                className='react-select'
                                                classNamePrefix='select'
                                                onChange={(e) => {
                                                    updateProfileForm.setFieldValue('region', e)
                                                    updateProfileForm.setFieldValue('state', null)
                                                    updateProfileForm.setFieldValue('city', null)
                                                    getStatesById(e)
                                                }}
                                                id='regions'
                                                isDisabled={viewProfileLoading || saveProfileLoading}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup tag={Col} md="6">
                                    <Label htmlFor="states"
                                           className={classnames({
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        States
                                    </Label>
                                    <div className="overlay-select-parent">
                                        <Select isClearable={false}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                theme={selectThemeColors}
                                                options={states ?? []}
                                                value={updateProfileForm.values.state}
                                                className='react-select'
                                                classNamePrefix='select'
                                                onChange={(e) => {
                                                    updateProfileForm.setFieldValue('state', e)
                                                    updateProfileForm.setFieldValue('city', null)
                                                    getCitiesById(e)
                                                }}
                                                id="states"
                                                isDisabled={viewProfileLoading || saveProfileLoading}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup tag={Col} md="6">
                                    <Label htmlFor="cities"
                                           className={classnames({
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        Cities
                                    </Label>
                                    <div className="overlay-select-parent">
                                        <Select isClearable={false}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                theme={selectThemeColors}
                                                options={cities ?? []}
                                                value={updateProfileForm.values.city}
                                                className='react-select'
                                                classNamePrefix='select'
                                                onChange={(e) => updateProfileForm.setFieldValue('city', e)}
                                                id="cities"
                                                isDisabled={viewProfileLoading || saveProfileLoading}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <hr/>
                                </FormGroup>
                                <FormGroup tag={Col} md='6'>
                                    <Label htmlFor="hearAboutUs"
                                           className={classnames({
                                               'form-label': true,
                                               'font-weight-bold': true
                                           })}>
                                        How did you hear about The RD Link?
                                    </Label>
                                    <Select isClearable={false}
                                            closeMenuOnSelect={true}
                                            theme={selectThemeColors}
                                            options={hearAboutOptions?.data}
                                            value={updateProfileForm.values.hearAboutUs}
                                            className='react-select'
                                            classNamePrefix='select'
                                            onChange={(e) => {
                                                updateProfileForm.setFieldValue('hearAboutUs', e)
                                                updateProfileForm.setFieldValue('hereAboutOtherInput', '')
                                            }}
                                            id="hearAboutUs"
                                            isDisabled={viewProfileLoading || saveProfileLoading}
                                    />
                                </FormGroup>
                                <FormGroup tag={Col} md='6'>
                                    {updateProfileForm.values?.hearAboutUs?.id === 'Other' && (
                                        <>
                                            <Label htmlFor="hereAboutOtherInput"
                                                   className={classnames({
                                                       'form-label': true,
                                                       'font-weight-bold': true
                                                   })}>
                                                Where did you hear about us?
                                            </Label>
                                            <Input type="text"
                                                   name="hereAboutOtherInput"
                                                   id="hereAboutOtherInput"
                                                   value={updateProfileForm.values.hereAboutOtherInput}
                                                   onBlur={updateProfileForm.handleBlur}
                                                   onChange={updateProfileForm.handleChange}
                                                   placeholder="Your answer..."
                                                   isDisabled={viewProfileLoading || saveProfileLoading}
                                            />
                                        </>
                                    )}
                                </FormGroup>
                                <FormGroup tag={Col} md='12'>
                                    <h6 className="font-weight-bolder bg-light p-1">
                                        Upload Product Image, PDF or Video
                                    </h6>
                                </FormGroup>
                                <FormGroup tag={Col} md="12">
                                    <Button.Ripple color='primary' type="button" disabled={viewProfileLoading || saveProfileLoading} onClick={() => setShowUploader(true)}>
                                        <File size='16'/> Choose File
                                    </Button.Ripple>
                                    {attachments?.length > 0 && (
                                        <>
                                            <Table className='mt-1 mb-1 table-bordered'>
                                                <thead>
                                                <tr>
                                                    <th width={1}>Sr.</th>
                                                    <th>Uploads</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {attachments.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.title}</td>
                                                        <td>
                                                            <Button.Ripple color='danger' type="button" size='sm'
                                                                           onClick={() => deleteUploads(index)} disabled={viewProfileLoading || saveProfileLoading}>
                                                                <Trash size='14'/> Delete
                                                            </Button.Ripple>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </>
                                    )}
                                </FormGroup>
                                {profileMedia?.length > 0 && (
                                    <>
                                        <FormGroup tag={Col} md='12'>
                                            <h6 className="font-weight-bolder bg-light p-1">
                                                Attachments
                                            </h6>
                                        </FormGroup>
                                        <FormGroup tag={Col} sm="12" className="d-flex flex-wrap">
                                            {profileMedia?.map((media, i) => {
                                                const type = media.image_type?.toLowerCase()
                                                return (
                                                    <Fragment key={i}>
                                                        {(type === 'png' || type === 'jpg' || type === 'jpeg') && (
                                                            <div className='media-img media-file'>
                                                                <div className="remove-icon">
                                                                    <button className='btn btn-link p-0 text-danger' onClick={() => handleRemoveMedia(media)}>
                                                                        <X size={16}/>
                                                                    </button>
                                                                </div>
                                                                <div className="download-icon">
                                                                    <button className='btn btn-link p-0'
                                                                       onClick={() => setImageViewer({show: true, src: media.image_url})}>
                                                                        <Eye size={16}/>
                                                                    </button>
                                                                </div>
                                                                <div className="name-wrap">
                                                                    <img className='rounded img-fit-contain' src={media?.image_url} alt={media.product_image}
                                                                         style={{height: '100px', width: '100px'}}/>
                                                                    <p className="text-truncate mt-1">
                                                                        <strong>{media.product_image}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(type === 'pdf') && (
                                                            <div className='media-img media-file'>
                                                                <div className="remove-icon">
                                                                    <button className='btn btn-link p-0 text-danger' onClick={() => handleRemoveMedia(media)}>
                                                                        <X size={16}/>
                                                                    </button>
                                                                </div>
                                                                <div className="download-icon">
                                                                    <button className='btn btn-link p-0'
                                                                       onClick={() => setPdfViewer({show: true, url: media.image_url, filename: media.product_image})}>
                                                                        <Eye size={16}/>
                                                                    </button>
                                                                </div>
                                                                <div className="name-wrap">
                                                                    <img className='rounded img-fit-contain png-icon-img' src={PDF} alt={media.product_image}/>
                                                                    <p className="text-truncate mt-1">
                                                                        <strong>{media.product_image}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(type === 'mp4') && (
                                                            <div className='media-img media-file'>
                                                                <div className="remove-icon">
                                                                    <button className='btn btn-link p-0 text-danger' onClick={() => handleRemoveMedia(media)}>
                                                                        <X size={16}/>
                                                                    </button>
                                                                </div>
                                                                <div className="download-icon">
                                                                    <button className='btn btn-link p-0'
                                                                       onClick={() => setVideoPlayer({show: true, url: media.image_url, filename: media.product_image})}>
                                                                        <Eye size={16}/>
                                                                    </button>
                                                                </div>
                                                                <div className="name-wrap">
                                                                    <Video className="text-primary text-center" size={90}/>
                                                                    <p className="text-truncate mt-1">
                                                                        <strong>{media.product_image}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Fragment>)
                                            })}
                                        </FormGroup>
                                    </>
                                )}
                                <FormGroup tag={Col} md='12'>
                                    <hr/>
                                </FormGroup>
                                <FormGroup tag={Col} sm="12" className="d-flex align-items-center justify-content-end">
                                    <div>
                                        <Button.Ripple color='success' type="submit" disabled={viewProfileLoading || saveProfileLoading}>
                                            {saveProfileLoading ? (
                                                <span><Spinner style={{width: '16px', height: '16px'}}/> Updating Profile...</span>
                                            ) : (
                                                <span>Update Profile <ArrowRight size="18"/></span>
                                            )}
                                        </Button.Ripple>
                                    </div>
                                </FormGroup>
                            </Row>
                        </Form>
                    )}
                </CardBody>
            </Card>
            <Uploader
                show={showUploader}
                hide={() => setShowUploader(false)}
                onSuccess={(data) => onSuccessUploads(data)}
                onError={(error) => onErrorUploads(error)}
            />
            <ImageViewer
                show={imageViewer.show}
                hide={() => setImageViewer({show: false, src: ''})}
                images={[{src: imageViewer.src}]}
            />
            <PdfViewer show={pdfViewer.show}
                       url={pdfViewer.url}
                       filename={pdfViewer.filename}
                       hide={() => setPdfViewer({show: false, url: '', filename: ''})}
            />
            <VideoPlayer show={videoPlayer.show}
                         url={videoPlayer.url}
                         filename={videoPlayer.filename}
                         hide={() => setVideoPlayer({show: false, url: '', filename: ''})}
            />
        </Fragment>
    )
}

export default EditBrandProfile