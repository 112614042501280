import {ADMIN_ACTION_TYPES} from "@store/actionTypes/AdminActionTypes"

const initialState = {
    brandCancelPlanLoading: false,
    brandCancelPlanData: null,
    brandCancelPlanError: null,

    dashboardManageBrandsLoading: false,
    dashboardManageBrandsData: null,
    dashboardManageBrandsError: null,

    dashboardManageBrandsCSVLoading: false,
    dashboardManageBrandsCSVData: null,
    dashboardManageBrandsCSVError: null,

    brandDirectMessagesLoading: false,
    brandDirectMessagesData: null,
    brandDirectMessagesError: null,

    rdEmailNotificationsStatsLoading: false,
    rdEmailNotificationsStatsData: null,
    rdEmailNotificationsStatsError: null,

    rdSiteViewStatsLoading: false,
    rdSiteViewStatsData: null,
    rdSiteViewStatsError: null,

    adminAddUserLoading: false,
    adminAddUserData: null,
    adminAddUserError: null,

    adminUsersListLoading: false,
    adminUsersListData: null,
    adminUsersListError: null,

    adminUserStatusLoading: false,
    adminUserStatusData: null,
    adminUserStatusError: null,

    adminUserDeleteLoading: false,
    adminUserDeleteData: null,
    adminUserDeleteError: null,

    adminUserUpdateLoading: false,
    adminUserUpdateData: null,
    adminUserUpdateError: null,

    adminUserSetPasswordLoading: false,
    adminUserSetPasswordData: null,
    adminUserSetPasswordError: null,

    adminBlogListLoading: false,
    adminBlogListData: null,
    adminBlogListError: null,

    adminBlogDeleteLoading: false,
    adminBlogDeleteData: null,
    adminBlogDeleteError: null,

    adminBlogAddUpdateLoading: false,
    adminBlogAddUpdateData: null,
    adminBlogAddUpdateError: null,

    adminBlogDetailsLoading: false,
    adminBlogDetailsData: null,
    adminBlogDetailsError: null,

    adminCouponListLoading: false,
    adminCouponListData: null,
    adminCouponListError: null,

    adminCouponDeleteLoading: false,
    adminCouponDeleteData: null,
    adminCouponDeleteError: null,

    adminCouponCreateLoading: false,
    adminCouponCreateData: null,
    adminCouponCreateError: null
}

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_ACTION_TYPES.BRAND_CANCEL_PLAN_REQUEST:
            return {
                ...state,
                brandCancelPlanLoading: true,
                brandCancelPlanData: null,
                brandCancelPlanError: null
            }
        case ADMIN_ACTION_TYPES.BRAND_CANCEL_PLAN_SUCCESS:
            return {
                ...state,
                brandCancelPlanLoading: false,
                brandCancelPlanData: action.payload,
                brandCancelPlanError: null
            }
        case ADMIN_ACTION_TYPES.BRAND_CANCEL_PLAN_ERROR:
            return {
                ...state,
                brandCancelPlanLoading: false,
                brandCancelPlanData: null,
                brandCancelPlanError: action.payload
            }
        case ADMIN_ACTION_TYPES.BRAND_CANCEL_PLAN_RESET:
            return {
                ...state,
                brandCancelPlanLoading: false,
                brandCancelPlanData: null,
                brandCancelPlanError: null
            }

        case ADMIN_ACTION_TYPES.DASHBOARD_MANAGE_BRANDS_REQUEST:
            return {
                ...state,
                dashboardManageBrandsLoading: true,
                dashboardManageBrandsData: null,
                dashboardManageBrandsError: null
            }
        case ADMIN_ACTION_TYPES.DASHBOARD_MANAGE_BRANDS_SUCCESS:
            return {
                ...state,
                dashboardManageBrandsLoading: false,
                dashboardManageBrandsData: action.payload,
                dashboardManageBrandsError: null
            }
        case ADMIN_ACTION_TYPES.DASHBOARD_MANAGE_BRANDS_ERROR:
            return {
                ...state,
                dashboardManageBrandsLoading: false,
                dashboardManageBrandsData: null,
                dashboardManageBrandsError: action.payload
            }

        case ADMIN_ACTION_TYPES.DASHBOARD_MANAGE_BRANDS_CSV_REQUEST:
            return {
                ...state,
                dashboardManageBrandsCSVLoading: true,
                dashboardManageBrandsCSVData: null,
                dashboardManageBrandsCSVError: null
            }
        case ADMIN_ACTION_TYPES.DASHBOARD_MANAGE_BRANDS_CSV_SUCCESS:
            return {
                ...state,
                dashboardManageBrandsCSVLoading: false,
                dashboardManageBrandsCSVData: action.payload,
                dashboardManageBrandsCSVError: null
            }
        case ADMIN_ACTION_TYPES.DASHBOARD_MANAGE_BRANDS_CSV_ERROR:
            return {
                ...state,
                dashboardManageBrandsCSVLoading: false,
                dashboardManageBrandsCSVData: null,
                dashboardManageBrandsCSVError: action.payload
            }
        case ADMIN_ACTION_TYPES.DASHBOARD_MANAGE_BRANDS_CSV_RESET:
            return {
                ...state,
                dashboardManageBrandsCSVLoading: false,
                dashboardManageBrandsCSVData: null,
                dashboardManageBrandsCSVError: null
            }

        case ADMIN_ACTION_TYPES.BRAND_DIRECT_MESSAGES_REQUEST:
            return {
                ...state,
                brandDirectMessagesLoading: true,
                brandDirectMessagesData: null,
                brandDirectMessagesError: null
            }
        case ADMIN_ACTION_TYPES.BRAND_DIRECT_MESSAGES_SUCCESS:
            return {
                ...state,
                brandDirectMessagesLoading: false,
                brandDirectMessagesData: action.payload,
                brandDirectMessagesError: null
            }
        case ADMIN_ACTION_TYPES.BRAND_DIRECT_MESSAGES_ERROR:
            return {
                ...state,
                brandDirectMessagesLoading: false,
                brandDirectMessagesData: null,
                brandDirectMessagesError: action.payload
            }

        case ADMIN_ACTION_TYPES.RD_EMAIL_NOTIFICATION_STATA_REQUEST:
            return {
                ...state,
                rdEmailNotificationsStatsLoading: true,
                rdEmailNotificationsStatsData: null,
                rdEmailNotificationsStatsError: null
            }
        case ADMIN_ACTION_TYPES.RD_EMAIL_NOTIFICATION_STATA_SUCCESS:
            return {
                ...state,
                rdEmailNotificationsStatsLoading: false,
                rdEmailNotificationsStatsData: action.payload,
                rdEmailNotificationsStatsError: null
            }
        case ADMIN_ACTION_TYPES.RD_EMAIL_NOTIFICATION_STATA_ERROR:
            return {
                ...state,
                rdEmailNotificationsStatsLoading: false,
                rdEmailNotificationsStatsData: null,
                rdEmailNotificationsStatsError: action.payload
            }

        case ADMIN_ACTION_TYPES.RD_SITE_VIEW_STATA_REQUEST:
            return {
                ...state,
                rdSiteViewStatsLoading: true,
                rdSiteViewStatsData: null,
                rdSiteViewStatsError: null
            }
        case ADMIN_ACTION_TYPES.RD_SITE_VIEW_STATA_SUCCESS:
            return {
                ...state,
                rdSiteViewStatsLoading: false,
                rdSiteViewStatsData: action.payload,
                rdSiteViewStatsError: null
            }
        case ADMIN_ACTION_TYPES.RD_SITE_VIEW_STATA_ERROR:
            return {
                ...state,
                rdSiteViewStatsLoading: false,
                rdSiteViewStatsData: null,
                rdSiteViewStatsError: action.payload
            }

        case ADMIN_ACTION_TYPES.ADMIN_ADD_USER_REQUEST:
            return {
                ...state,
                adminAddUserLoading: true,
                adminAddUserData: null,
                adminAddUserError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_ADD_USER_SUCCESS:
            return {
                ...state,
                adminAddUserLoading: false,
                adminAddUserData: action.payload,
                adminAddUserError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_ADD_USER_ERROR:
            return {
                ...state,
                adminAddUserLoading: false,
                adminAddUserData: null,
                adminAddUserError: action.payload
            }
        case ADMIN_ACTION_TYPES.ADMIN_ADD_USER_RESET:
            return {
                ...state,
                adminAddUserLoading: false,
                adminAddUserData: null,
                adminAddUserError: null
            }

        case ADMIN_ACTION_TYPES.ADMIN_USERS_LIST_REQUEST:
            return {
                ...state,
                adminUsersListLoading: true,
                adminUsersListData: null,
                adminUsersListError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_USERS_LIST_SUCCESS:
            return {
                ...state,
                adminUsersListLoading: false,
                adminUsersListData: action.payload,
                adminUsersListError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_USERS_LIST_ERROR:
            return {
                ...state,
                adminUsersListLoading: false,
                adminUsersListData: null,
                adminUsersListError: action.payload
            }

        case ADMIN_ACTION_TYPES.ADMIN_USER_STATUS_REQUEST:
            return {
                ...state,
                adminUserStatusLoading: true,
                adminUserStatusData: null,
                adminUserStatusError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_USER_STATUS_SUCCESS:
            return {
                ...state,
                adminUserStatusLoading: false,
                adminUserStatusData: action.payload,
                adminUserStatusError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_USER_STATUS_ERROR:
            return {
                ...state,
                adminUserStatusLoading: false,
                adminUserStatusData: null,
                adminUserStatusError: action.payload
            }
        case ADMIN_ACTION_TYPES.ADMIN_USER_STATUS_RESET:
            return {
                ...state,
                adminUserStatusLoading: false,
                adminUserStatusData: null,
                adminUserStatusError: null
            }

        case ADMIN_ACTION_TYPES.ADMIN_USER_DELETE_REQUEST:
            return {
                ...state,
                adminUserDeleteLoading: true,
                adminUserDeleteData: null,
                adminUserDeleteError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_USER_DELETE_SUCCESS:
            return {
                ...state,
                adminUserDeleteLoading: false,
                adminUserDeleteData: action.payload,
                adminUserDeleteError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_USER_DELETE_ERROR:
            return {
                ...state,
                adminUserDeleteLoading: false,
                adminUserDeleteData: null,
                adminUserDeleteError: action.payload
            }
        case ADMIN_ACTION_TYPES.ADMIN_USER_DELETE_RESET:
            return {
                ...state,
                adminUserDeleteLoading: false,
                adminUserDeleteData: null,
                adminUserDeleteError: null
            }

        case ADMIN_ACTION_TYPES.ADMIN_USER_UPDATE_REQUEST:
            return {
                ...state,
                adminUserUpdateLoading: true,
                adminUserUpdateData: null,
                adminUserUpdateError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_USER_UPDATE_SUCCESS:
            return {
                ...state,
                adminUserUpdateLoading: false,
                adminUserUpdateData: action.payload,
                adminUserUpdateError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_USER_UPDATE_ERROR:
            return {
                ...state,
                adminUserUpdateLoading: false,
                adminUserUpdateData: null,
                adminUserUpdateError: action.payload
            }
        case ADMIN_ACTION_TYPES.ADMIN_USER_UPDATE_RESET:
            return {
                ...state,
                adminUserUpdateLoading: false,
                adminUserUpdateData: null,
                adminUserUpdateError: null
            }

        case ADMIN_ACTION_TYPES.ADMIN_USER_SET_PASSWORD_REQUEST:
            return {
                ...state,
                adminUserSetPasswordLoading: true,
                adminUserSetPasswordData: null,
                adminUserSetPasswordError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_USER_SET_PASSWORD_SUCCESS:
            return {
                ...state,
                adminUserSetPasswordLoading: false,
                adminUserSetPasswordData: action.payload,
                adminUserSetPasswordError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_USER_SET_PASSWORD_ERROR:
            return {
                ...state,
                adminUserSetPasswordLoading: false,
                adminUserSetPasswordData: null,
                adminUserSetPasswordError: action.payload
            }
        case ADMIN_ACTION_TYPES.ADMIN_USER_SET_PASSWORD_RESET:
            return {
                ...state,
                adminUserSetPasswordLoading: false,
                adminUserSetPasswordData: null,
                adminUserSetPasswordError: null
            }

        case ADMIN_ACTION_TYPES.ADMIN_BLOG_LIST_REQUEST:
            return {
                ...state,
                adminBlogListLoading: true,
                adminBlogListData: null,
                adminBlogListError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_BLOG_LIST_SUCCESS:
            return {
                ...state,
                adminBlogListLoading: false,
                adminBlogListData: action.payload,
                adminBlogListError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_BLOG_LIST_ERROR:
            return {
                ...state,
                adminBlogListLoading: false,
                adminBlogListData: null,
                adminBlogListError: action.payload
            }

        case ADMIN_ACTION_TYPES.ADMIN_BLOG_DELETE_REQUEST:
            return {
                ...state,
                adminBlogDeleteLoading: true,
                adminBlogDeleteData: null,
                adminBlogDeleteError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_BLOG_DELETE_SUCCESS:
            return {
                ...state,
                adminBlogDeleteLoading: false,
                adminBlogDeleteData: action.payload,
                adminBlogDeleteError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_BLOG_DELETE_ERROR:
            return {
                ...state,
                adminBlogDeleteLoading: false,
                adminBlogDeleteData: null,
                adminBlogDeleteError: action.payload
            }
        case ADMIN_ACTION_TYPES.ADMIN_BLOG_DELETE_RESET:
            return {
                ...state,
                adminBlogDeleteLoading: false,
                adminBlogDeleteData: null,
                adminBlogDeleteError: null
            }

        case ADMIN_ACTION_TYPES.ADMIN_BLOG_ADD_UPDATE_REQUEST:
            return {
                ...state,
                adminBlogAddUpdateLoading: true,
                adminBlogAddUpdateData: null,
                adminBlogAddUpdateError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_BLOG_ADD_UPDATE_SUCCESS:
            return {
                ...state,
                adminBlogAddUpdateLoading: false,
                adminBlogAddUpdateData: action.payload,
                adminBlogAddUpdateError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_BLOG_ADD_UPDATE_ERROR:
            return {
                ...state,
                adminBlogAddUpdateLoading: false,
                adminBlogAddUpdateData: null,
                adminBlogAddUpdateError: action.payload
            }
        case ADMIN_ACTION_TYPES.ADMIN_BLOG_ADD_UPDATE_RESET:
            return {
                ...state,
                adminBlogAddUpdateLoading: false,
                adminBlogAddUpdateData: null,
                adminBlogAddUpdateError: null
            }

        case ADMIN_ACTION_TYPES.ADMIN_BLOG_DETAILS_REQUEST:
            return {
                ...state,
                adminBlogDetailsLoading: true,
                adminBlogDetailsData: null,
                adminBlogDetailsError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_BLOG_DETAILS_SUCCESS:
            return {
                ...state,
                adminBlogDetailsLoading: false,
                adminBlogDetailsData: action.payload,
                adminBlogDetailsError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_BLOG_DETAILS_ERROR:
            return {
                ...state,
                adminBlogDetailsLoading: false,
                adminBlogDetailsData: null,
                adminBlogDetailsError: action.payload
            }
        case ADMIN_ACTION_TYPES.ADMIN_BLOG_DETAILS_RESET:
            return {
                ...state,
                adminBlogDetailsLoading: false,
                adminBlogDetailsData: null,
                adminBlogDetailsError: null
            }

        case ADMIN_ACTION_TYPES.ADMIN_COUPON_LIST_REQUEST:
            return {
                ...state,
                adminCouponListLoading: true,
                adminCouponListData: null,
                adminCouponListError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_COUPON_LIST_SUCCESS:
            return {
                ...state,
                adminCouponListLoading: false,
                adminCouponListData: action.payload,
                adminCouponListError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_COUPON_LIST_ERROR:
            return {
                ...state,
                adminCouponListLoading: false,
                adminCouponListData: null,
                adminCouponListError: action.payload
            }

        case ADMIN_ACTION_TYPES.ADMIN_COUPON_DELETE_REQUEST:
            return {
                ...state,
                adminCouponDeleteLoading: true,
                adminCouponDeleteData: null,
                adminCouponDeleteError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_COUPON_DELETE_SUCCESS:
            return {
                ...state,
                adminCouponDeleteLoading: false,
                adminCouponDeleteData: action.payload,
                adminCouponDeleteError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_COUPON_DELETE_ERROR:
            return {
                ...state,
                adminCouponDeleteLoading: false,
                adminCouponDeleteData: null,
                adminCouponDeleteError: action.payload
            }
        case ADMIN_ACTION_TYPES.ADMIN_COUPON_DELETE_RESET:
            return {
                ...state,
                adminCouponDeleteLoading: false,
                adminCouponDeleteData: null,
                adminCouponDeleteError: null
            }

        case ADMIN_ACTION_TYPES.ADMIN_COUPON_CREATE_REQUEST:
            return {
                ...state,
                adminCouponCreateLoading: true,
                adminCouponCreateData: null,
                adminCouponCreateError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_COUPON_CREATE_SUCCESS:
            return {
                ...state,
                adminCouponCreateLoading: false,
                adminCouponCreateData: action.payload,
                adminCouponCreateError: null
            }
        case ADMIN_ACTION_TYPES.ADMIN_COUPON_CREATE_ERROR:
            return {
                ...state,
                adminCouponCreateLoading: false,
                adminCouponCreateData: null,
                adminCouponCreateError: action.payload
            }
        case ADMIN_ACTION_TYPES.ADMIN_COUPON_CREATE_RESET:
            return {
                ...state,
                adminCouponCreateLoading: false,
                adminCouponCreateData: null,
                adminCouponCreateError: null
            }

        default:
            return state
    }
}

export default adminReducer