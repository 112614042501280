import {Fragment, useState, useEffect} from 'react'
import Chat from './Chat'
import Sidebar from './SidebarLeft'
import UserProfileSidebar from './UserProfileSidebar'
import classnames from 'classnames'
import {useDispatch, useSelector} from 'react-redux'
import {getChatContacts} from './store/actions'

import '@styles/base/pages/app-chat.scss'
import '@styles/base/pages/app-chat-list.scss'
import {PropTypes} from "prop-types"

const AppChat = (props) => {
    const dispatch = useDispatch()
    const store = useSelector(state => state.chatAdmin)
    const [user, setUser] = useState({})
    const [sidebar, setSidebar] = useState(false)
    const [userSidebarRight, setUserSidebarRight] = useState(false)
    const [userSidebarLeft, setUserSidebarLeft] = useState(false)

    const handleSidebar = () => setSidebar(!sidebar)
    const handleUserSidebarLeft = () => setUserSidebarLeft(false)
    const handleUserSidebarRight = () => setUserSidebarRight(false)

    const handleOverlayClick = () => {
        setSidebar(false)
        setUserSidebarRight(false)
        setUserSidebarLeft(false)
    }

    const handleUser = obj => setUser(obj)

    useEffect(() => {
        dispatch(getChatContacts())
    }, [dispatch])

    return (
        <Fragment>
            <Sidebar
                store={store}
                sidebar={sidebar}
                handleSidebar={handleSidebar}
                userSidebarLeft={userSidebarLeft}
                generalUserId={props.mainUser}
                handleUserSidebarLeft={handleUserSidebarLeft}
            />
            <div className='content-right'>
                <div className='content-wrapper'>
                    <div className='content-body'>
                        <div className={classnames('body-content-overlay', {
                            show: userSidebarRight === true || sidebar === true || userSidebarLeft === true
                        })}>
                            <button onClick={handleOverlayClick} className='btn p-0'></button>
                        </div>
                        <Chat
                            store={store}
                            handleUser={handleUser}
                            handleSidebar={handleSidebar}
                            userSidebarLeft={userSidebarLeft}
                            handleUserSidebarRight={handleUserSidebarRight}
                            generalUserId={props.mainUser}
                        />
                        <UserProfileSidebar
                            user={user}
                            userSidebarRight={userSidebarRight}
                            handleUserSidebarRight={handleUserSidebarRight}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

AppChat.propTypes = {
    mainUser: PropTypes.string.isRequired
}

export default AppChat
