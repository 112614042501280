import React, {useState, Fragment, useEffect, useRef} from "react"
import {Formik, useFormik} from "formik"
import * as yup from "yup"
import {
    CheckCircle,
    Clock,
    Edit,
    Eye,
    Trash2,
    Users,
    MessageCircle,
    Delete,
    PlusCircle,
    XCircle,
    List, Copy
} from "react-feather"
import {Link, useHistory} from "react-router-dom"
import classnames from 'classnames'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CustomInput,
    Spinner,
    InputGroup,
    InputGroupAddon,
    Form,
    CardText,
    Alert
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import {
    createCampaings,
    campaingsFormOptions,
    campaingsListing,
    getEditCampaingsData,
    updateCampaingsData,
    deleteCampaingsData,
    getCreateCampaignFormQuestions,
    updateCampaignStatus,
    createCampaingsReset,
    getEditCampaingsDataReset,
    updateCampaingsDataReset,
    deleteCampaingsDataReset,
    updateCampaignStatusReset,
    updateCampaignLiveStatusReset,
    getCreateCampaignFormQuestionsReset,
    campaingsAllListing,
    inviteCampaignOnEmail,
    inviteCampaignOnEmailReset,
    inviteCampaignOnMsg,
    inviteCampaignOnMsgReset,
    updateCampaignLiveStatus
} from './store/actions/index'
import "@styles/react/libs/flatpickr/flatpickr.scss"
import {useSelector, useDispatch} from "react-redux"
import CustomPagination from "@src/views/components/pagination/CustomPagination"
import Avatar from "@components/avatar"
import queryString from "query-string"
import Select from "react-select"
import moment from "moment"
import momentTime from 'moment-timezone'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import {Editor} from 'react-draft-wysiwyg'
import {EditorState, ContentState, convertToRaw} from 'draft-js'
import '@styles/react/libs/editor/editor.scss'
import '@styles/base/plugins/forms/form-quill-editor.scss'
import {copyCampaign, copyCampaignReset} from "@src/redux/actions/BrandActions"

const MyCampaigns = () => {
    const history = useHistory()
    const createCampaignFormRef = useRef()
    const sweetAlert = withReactContent(Swal)

    const [editModal, setEditModal] = useState(false)
    const [deleteFAQModal, setDeleteFAQModal] = useState(false)
    const [searchInputValue, setSearchInputValue] = useState("")
    const [campId, setCampId] = useState("")
    const [isApplied, setIsApplied] = useState(false)
    const [brandDetail, setBrandDetail] = useState({})
    const [expireAfter, setExpireAfter] = useState(null)
    const [successRes, setSuccessRes] = useState(null)
    const [addCampaignModal, setAddCampaignModal] = useState(null)
    const [campaignIndex, setCampaignIndex] = useState(null)
    const [disableDate, setDisableDate] = useState(true)
    const [inviteCampaignModal, setInviteCampaignModal] = useState(false)
    const [rdIds, setRdIds] = useState([])
    const [selectAllApplicants, setSelectAllApplicants] = useState(false)
    const [allRDs, setAllRDs] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [clickButton, setClickButton] = useState("")
    const [deleteQue, setDeleteQue] = useState([])
    const [publishModal, setPublishModal] = useState(false)
    const [campaignData, setCampaignData] = useState(null)
    const [previewModal, setPreviewModal] = useState(false)
    const [expDateConfig] = useState({
        allowInput: false,
        minDate: "today",
        dateFormat: "M d, Y"
    })

    const dispatch = useDispatch()
    const {
        brandCampaignsListingLoading,
        brandCampaignsListingFullFill,

        brandCreateCampaignLoading,
        brandCreateCampaignFullFill,

        brandEditCampaignLoading,
        brandEditCampaignFullFill,

        brandCampaignQuestionsLoading,
        brandCampaignQuestionsFullFill,

        brandCampaignUpdateLoading,
        brandCampaignUpdateFullFill,

        brandCampaignDeleteLoading,
        brandCampaignDeleteFullFill,

        brandCampaignStatusFullFill,

        brandCampaignLiveLoading,
        brandCampaignLiveFullFill,
        brandCampaignLiveError,

        brandCampaignFormLoading,
        brandCampaignFormFullFill,

        brandCampaignsAllListingLoading,
        brandCampaignsAllListingFullFill,

        inviteOnEmailLoading,
        inviteOnEmailFullFill,

        inviteOnMsgLoading,
        inviteOnMsgFullFill
    } = useSelector(store => store.brandsOppoprunities)

    const {
        copyCampaignLoading,
        copyCampaignData,
        copyCampaignError
    } = useSelector(store => store.brandReducer)

    const convertToDateAndTimePattern = (dateString) => {
        const date = new Date(dateString)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    }

    const updateData = (values) => {
        const params = {
            campaign_id: values.campaign_id,
            campaign_title: values.campaign_title,
            campaign_details: values.campaign_details,
            submission_limit: values.submission_limit,
            once_limit_hit: 0,
            campaign_form_type: values.campaign_form_type,
            campaign_form_id: values.campaign_form_id,
            campaign_form_title: values.campaign_form_title,
            campaign_form_questions: values.campaign_form_questions,
            deleted_questions: deleteQue
        }
        dispatch(updateCampaingsData(params))
    }

    const editCampaignForm = useFormik({
        initialValues: {
            campaign_id: "",
            campaign_title: "",
            campaign_details: "",
            campaign_editor: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(""))),
            submission_limit: "",
            campaign_form_type: "",
            campaign_form_id: "",
            campaign_form_title: "",
            campaign_form_questions: [{id: "0", question: "", type: ""}]
        },
        validationSchema: yup.object().shape({
            campaign_title: yup.string().trim().required("This field is required"),
            campaign_details: yup.string().trim().required("This field is required"),
            submission_limit: yup.number().test("lessThanZero", "Minimum 1 is required.", (value) => {
                if (value !== undefined && value !== '' && value <= 0) {
                    return false
                }
                return true
            }),
            campaign_form_type: yup.string().required("This field is required"),
            campaign_form_id: yup.number().when('campaign_form_type', {
                is: (e) => e === 'existing',
                then: yup.number().required("This field is required"),
                otherwise: yup.number()
            }),
            campaign_form_title: yup.string().when('campaign_form_type', {
                is: (e) => e === 'custom',
                then: yup.string().trim().required("This field is required"),
                otherwise: yup.string()
            }),
            campaign_form_questions: yup.array().when('campaign_form_type', {
                is: (e) => e === 'custom',
                then: yup.array().of(
                    yup.object().shape({
                        question: yup.string().trim().required("This field is required"),
                        type: yup.string().required("This field is required")
                    })
                ),
                otherwise: yup.array().notRequired()
            })
        }),
        onSubmit: (values) => {
            updateData(values)
        }
    })

    const SCHEDULE_DATE_OPTIONS = {
        allowInput: false,
        minDate: "today",
        dateFormat: "M d, Y G:i K",
        enableTime: true
    }

    // --- All Campaigns Listing ---
    const campaigns = () => {
        dispatch(campaingsAllListing("?list=all"))
        const parsed = queryString.parseUrl(window.location.href)
        const pageNo = parsed?.query?.page
        const search = parsed?.query?.search
        if (pageNo && search) {
            dispatch(campaingsListing(`?search=${search}&page=${pageNo}`))
            setSearchInputValue(search)
        } else if (pageNo) {
            dispatch(campaingsListing(`?page=${pageNo}`))
        } else if (search) {
            dispatch(campaingsListing(`?search=${search}`))
            setSearchInputValue(search)
        } else {
            dispatch(campaingsListing(""))
        }
    }

    // --- Get Campaign Questions ---
    const handleFormSelect = (id) => {
        dispatch(getCreateCampaignFormQuestions({
            form_id: id
        }))
    }

    useEffect(() => {
        if (brandCreateCampaignFullFill?.status) {
            setSuccessRes({
                show: true,
                msg: brandCreateCampaignFullFill?.msg
            })
            history.push({
                pathname: window.location.pathname
            })
            dispatch(createCampaingsReset())
            setAddCampaignModal(false)
            campaigns()
            setSearchInputValue("")
            setTimeout(() => setSuccessRes(null), 5000)
        }

        if (brandEditCampaignFullFill?.data) {
            setIsApplied(brandEditCampaignFullFill?.is_applied)
            const editData = brandEditCampaignFullFill?.data
            editCampaignForm.setFieldValue('campaign_id', editData.campaign_id)
            editCampaignForm.setFieldValue('campaign_title', editData.campaign_title)
            editCampaignForm.setFieldValue('campaign_editor', EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(editData?.campaign_details))))
            editCampaignForm.setFieldValue('campaign_details', editData.campaign_details)
            if (editData.submission_limit) {
                editCampaignForm.setFieldValue('submission_limit', editData.submission_limit)
            }
            editCampaignForm.setFieldValue('campaign_form_type', editData.campaign_form_type)
            editCampaignForm.setFieldValue('campaign_form_id', editData.campaign_form_id)
            editCampaignForm.setFieldValue('campaign_form_title', editData.campaign_form_title)
            editCampaignForm.setFieldValue('campaign_form_questions', editData.campaign_form_questions ?? [{id: "0", question: "", type: ""}])
            handleFormSelect(editData?.campaign_form_id)
            dispatch(getEditCampaingsDataReset())
        }

        if (brandCampaignUpdateFullFill?.status) {
            setSuccessRes({
                show: true,
                msg: brandCampaignUpdateFullFill?.msg
            })
            history.push({
                pathname: window.location.pathname
            })
            dispatch(updateCampaingsDataReset())
            setEditModal(false)
            setCampId(null)
            setCampaignData(null)
            campaigns()
            setSearchInputValue("")
            setTimeout(() => setSuccessRes(null), 5000)
            setDeleteQue([])
        }

        if (brandCampaignDeleteFullFill?.status) {
            setSuccessRes({
                show: true,
                msg: brandCampaignDeleteFullFill?.msg
            })
            dispatch(deleteCampaingsDataReset())
            setCampId(null)
            setCampaignData(null)
            setDeleteFAQModal(false)
            campaigns()
            setTimeout(() => setSuccessRes(null), 5000)
        }

        if (brandCampaignStatusFullFill?.status) {
            setSuccessRes({
                show: true,
                msg: brandCampaignStatusFullFill?.msg
            })
            dispatch(updateCampaignStatusReset())
            campaigns()
            setCampId(null)
            setCampaignData(null)
            setCampaignIndex(null)
            setTimeout(() => setSuccessRes(null), 5000)
        }

        if (brandCampaignLiveFullFill?.status) {
            setSuccessRes({
                show: true,
                msg: brandCampaignLiveFullFill?.msg
            })
            dispatch(updateCampaignLiveStatusReset())
            campaigns()
            setCampId(null)
            setCampaignData(null)
            setPublishModal(false)
            setTimeout(() => setSuccessRes(null), 5000)
        }

        if (brandCampaignsAllListingFullFill?.rds) {
            const data = brandCampaignsAllListingFullFill?.rds.map((item) => {
                return {
                    ...item,
                    id: parseInt(item.id)
                }
            })
            setAllRDs(data)
        }

        if (inviteOnEmailFullFill?.status) {
            setSuccessRes({
                show: true,
                msg: clickButton === 'both' ? 'Campaign invitation sent successfully.' : inviteOnEmailFullFill?.msg
            })
            dispatch(inviteCampaignOnEmailReset())
            setRdIds([])
            setSelectAllApplicants(false)
            setSearchValue("")
            setInviteCampaignModal(false)
            setTimeout(() => setSuccessRes(null), 5000)
        }

        if (inviteOnMsgFullFill?.status) {
            setSuccessRes({
                show: true,
                msg: clickButton === 'both' ? 'Campaign invitation sent successfully.' : inviteOnMsgFullFill?.msg
            })
            dispatch(inviteCampaignOnMsgReset())
            setRdIds([])
            setSelectAllApplicants(false)
            setSearchValue("")
            setInviteCampaignModal(false)
            setTimeout(() => setSuccessRes(null), 5000)
        }

        if (brandCampaignLiveError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (brandCampaignLiveError?.response?.data?.publish_datetime) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${brandCampaignLiveError?.response?.data?.publish_datetime[0]}</li>`)
            }
            if (brandCampaignLiveError?.response?.data?.expire_after) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${brandCampaignLiveError?.response?.data?.expire_after[0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(updateCampaignLiveStatusReset())
        }

        if (copyCampaignData?.status) {
            setSuccessRes({
                show: true,
                msg: copyCampaignData?.msg
            })
            history.push({
                pathname: window.location.pathname
            })
            dispatch(copyCampaignReset())
            setAddCampaignModal(false)
            campaigns()
            setSearchInputValue("")
            setTimeout(() => setSuccessRes(null), 5000)
        }
    }, [
        brandCreateCampaignFullFill,
        brandEditCampaignFullFill,
        brandCampaignUpdateFullFill,
        brandCampaignDeleteFullFill,
        brandCampaignStatusFullFill,
        brandCampaignLiveFullFill,
        brandCampaignsAllListingFullFill,
        inviteOnEmailFullFill,
        inviteOnMsgFullFill,
        brandCampaignLiveError,
        copyCampaignData
    ])

    useEffect(() => {
        campaigns()
        dispatch(campaingsFormOptions())
        const localData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))
        setBrandDetail(localData)
    }, [])

    // --- Add New Campaign ---
    const addNewCampaign = (values) => {
        const params = {
            campaign_title: values.campaign_title,
            campaign_details: values.campaign_details,
            submission_limit: values.submission_limit,
            once_limit_hit: 0,
            campaign_form_type: values.campaign_form_type,
            campaign_form_id: values.campaign_form_id,
            campaign_form_title: values.campaign_form_title,
            campaign_form_questions: values.campaign_form_questions
        }
        dispatch(createCampaings(params))
    }

    // --- Pagination ---
    const handleCustomPagination = (pageUrl) => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        const query = queryString.parseUrl(window.location.href)
        const search = query?.query?.search

        if (pageNo && search) {
            dispatch(campaingsListing(`?search=${search}&page=${pageNo}`))
        } else {
            dispatch(campaingsListing(`?page=${pageNo}`))
        }

        history.push({
            pathname: window.location.pathname,
            search: search ? `?search=${search}&page=${pageNo}` : `?page=${pageNo}`
        })
    }

    // --- Campaign Edit Data ---
    const editData = (id) => {
        setCampId(id)
        setEditModal(true)
        dispatch(getEditCampaingsData({
            campaign_id: id
        }))
    }

    // --- Delete Campaign ---
    const delCampaign = () => {
        dispatch(deleteCampaingsData({
            campaign_id: campId
        }))
    }

    // --- Campaign Status ---
    const campaignStatus = (id, index) => {
        setCampId(id)
        setCampaignIndex(index)
        dispatch(updateCampaignStatus({
            campaign_id: id
        }))
    }

    // --- Campaign Live ---
    const liveCampaign = (id, data) => {
        setCampaignData(data)
        setCampId(id)
        setPublishModal(true)
    }

    // --- Campaign Search ---
    const handeSearchCampaign = () => {
        dispatch(campaingsListing(`?search=${searchInputValue}`))
        history.push({
            pathname: window.location.pathname,
            search: `?search=${searchInputValue}`
        })
    }

    // --- Campaign Search On Enter ---
    const onEnterSearch = (e) => {
        if (e.key === 'Enter') {
            handeSearchCampaign()
        }
    }

    // --- Campaign Search Reset ---
    const handeResetSearch = () => {
        setSearchInputValue("")
        dispatch(campaingsListing(""))
        history.push({
            pathname: window.location.pathname
        })
    }

    const handleCheckBox = (e) => {
        const {value} = e.target
        const intValue = parseInt(value)
        const find = rdIds.find(item => parseInt(item.id) === intValue)
        if (find) {
            setRdIds(prevState => (prevState.filter(item => parseInt(item.id) !== intValue)))
        } else {
            setRdIds(prevState => [...prevState, {id: intValue}])
        }
        setSelectAllApplicants(false)
    }

    const searchRds = (query) => {
        setSearchValue(query)
        const search = brandCampaignsAllListingFullFill?.rds?.filter((obj) => JSON.stringify(obj).toLowerCase().includes(query.toLowerCase()))
        const data = search.map((item) => {
            return {
                ...item,
                id: parseInt(item.id)
            }
        })
        setAllRDs(data)
    }

    const handleSelectAllCheckBox = (e) => {
        const {checked} = e.target
        if (checked) {
            const data = brandCampaignsAllListingFullFill?.rds?.map((val) => {
                return {id: parseInt(val?.id)}
            })
            setRdIds(data)
        } else {
            setRdIds([])
        }
        setSelectAllApplicants(!selectAllApplicants)
    }

    const addField = (form) => {
        form.values.campaign_form_questions.push({id: "0", question: "", type: ""})
        form.setValues({...form.values})
    }

    const removeField = (form, index, id) => {
        const parseId = parseInt(id)
        if (parseId > 0) {
            setDeleteQue([...deleteQue, {id: parseId}])
        }
        form.values.campaign_form_questions.splice(index, 1)
        form.setValues({...form.values})
    }

    const modifyPublish = (data) => {
        setCampId(data?.campaign_id)
        setCampaignData(data)
        setPublishModal(true)
    }

    const preview = (data) => {
        setCampaignData(data)
        setPreviewModal(true)
    }

    const copy = (data) => {
        sweetAlert.fire({
            title: 'Confirmation!',
            icon: 'warning',
            html: `Do you want to copy this campaign?`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(copyCampaign({
                    campaign_id: data.campaign_id
                }))
            }
        })
    }

    return (
        <Fragment>
            <h2>My Campaigns</h2>
            <Card className="mt-2">
                <CardBody className="">
                    {successRes?.show && (
                        <Alert color='success'>
                            <div className='alert-body'>
                                <span>{successRes?.msg}</span>
                            </div>
                        </Alert>
                    )}
                    <Row>
                        <Col lg="6" md="6">
                            <InputGroup size="lg" className="">
                                <Input
                                    placeholder="Search Campaign"
                                    value={searchInputValue}
                                    onChange={(e) => setSearchInputValue(e.target.value)}
                                    onKeyDown={onEnterSearch}
                                />
                                <InputGroupAddon addonType="append">
                                    <Button color="primary" onClick={handeSearchCampaign} outline>
                                        Search
                                    </Button>
                                </InputGroupAddon>
                                <InputGroupAddon addonType="append">
                                    <Button color="primary" onClick={handeResetSearch} outline>
                                        Reset
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                        <Col lg="6" md="6">
                            <Button.Ripple
                                className="float-right"
                                color="success"
                                onClick={() => {
                                    setAddCampaignModal(true)
                                    setExpireAfter(null)
                                    setDisableDate(true)
                                    dispatch(getCreateCampaignFormQuestionsReset())
                                }}
                            >
                                <CheckCircle size={14}/>
                                <span> Create Campaign</span>
                            </Button.Ripple>
                            <Button.Ripple className="float-right mr-1" color="primary"
                                           disabled={brandCampaignsAllListingLoading}
                                           onClick={() => {
                                               setInviteCampaignModal(true)
                                               setClickButton('')
                                           }}>
                                {brandCampaignsAllListingLoading ? (
                                    <>
                                        <Spinner style={{height: '14px', width: '14px'}}/>
                                        <span> Invite to a Campaign</span>
                                    </>
                                ) : (
                                    <>
                                        <Users size={14}/>
                                        <span> Invite to a Campaign</span>
                                    </>
                                )}
                            </Button.Ripple>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {/* --- Campaign Listing --- */}
            {brandCampaignsListingLoading ? (
                <div className="w-100 my-4 text-center">
                    <Spinner color="primary"/>
                </div>
            ) : (
                <>
                    <Row>
                        {brandCampaignsListingFullFill?.data?.map((camps, index) => (
                            <Col xl={6} lg={6} md={6} sm={6} key={index}>
                                <Card>
                                    <CardHeader>
                                        <CardTitle title={camps?.campaign_title} className="cursor-pointer">
                                            <div>
                                                <span>{camps?.short_campaign_title}</span>
                                                {camps?.tag === 'Disabled' && (
                                                    <span className="font-small-2 text-danger"> ({camps?.tag})</span>
                                                )}

                                                {camps?.tag === 'Live' && (
                                                    <span className="font-small-2 text-success"> ({camps?.tag})</span>
                                                )}

                                                {camps?.tag === 'Draft' && (
                                                    <span className="font-small-2 text-warning"> ({camps?.tag})</span>
                                                )}

                                                {camps?.tag === 'Scheduled' && (
                                                    <>
                                                        <span className="font-small-2 text-warning"> ({camps?.tag}) - </span>
                                                        <button className="btn btn-link p-0 font-small-2 text-underline" onClick={() => modifyPublish(camps)}>
                                                            Modify
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                <span className="font-small-3 text-primary">
                                                    {camps?.expiry_status}
                                                </span>
                                            </div>
                                        </CardTitle>
                                        <CardText title="Delete this campaign">
                                            <Trash2 className="cursor-pointer text-danger" size="20" onClick={() => {
                                                setDeleteFAQModal(true)
                                                setCampId(camps?.campaign_id)
                                            }}/>
                                        </CardText>
                                    </CardHeader>
                                    <CardBody>
                                        <>
                                            <Row className="mb-2">
                                                <Col lg="6" className="mb-1">
                                                    <div className='font-medium-2'>
                                                        <strong>Applications: </strong>
                                                        <span className='badge badge-info'>{camps?.no_of_rds}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex justify-content-between">
                                                    <div>
                                                        <Button.Ripple color="secondary" className="round btn-sm campaign-btn" onClick={() => preview(camps)}>
                                                            <List size={14}/>
                                                            <span className="align-middle"> Preview</span>
                                                        </Button.Ripple>
                                                        <Button.Ripple color="secondary" className="round btn-sm campaign-btn" onClick={() => copy(camps)}
                                                                       disabled={copyCampaignLoading}>
                                                            <Copy size={14}/>
                                                            <span className="align-middle"> Copy</span>
                                                        </Button.Ripple>
                                                        <Button.Ripple tag={Link} to={`/dashboard/brands/campaign-detail/${camps?.campaign_id}`} color="secondary"
                                                                       className="round btn-sm campaign-btn">
                                                            <Eye size={14}/>
                                                            <span className="align-middle"> View RDs</span>
                                                        </Button.Ripple>
                                                        <Button.Ripple color="secondary" className="round btn-sm campaign-btn" onClick={() => editData(camps?.campaign_id)}>
                                                            <Edit size={14}/>
                                                            <span className="align-middle"> Edit</span>
                                                        </Button.Ripple>
                                                        {(camps?.is_live === 0 && camps?.schedule === false) && (
                                                            <Button.Ripple color="secondary" className="round btn-sm campaign-btn"
                                                                           onClick={() => liveCampaign(camps?.campaign_id, camps)}>
                                                                <Clock size={14}/>
                                                                <span className="align-middle"> Publish</span>
                                                            </Button.Ripple>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {campaignIndex === index ? (
                                                            <Spinner color="primary"/>
                                                        ) : (
                                                            <CustomInput
                                                                className={camps?.tag === 'Draft' ? 'custom-control-warning campaign-switch' : 'custom-control-success campaign-switch'}
                                                                type='switch'
                                                                id={`switch-${index}`}
                                                                name={`switch-${index}`}
                                                                inline={true}
                                                                defaultChecked={camps?.status === 'enabled'}
                                                                onClick={() => campaignStatus(camps?.campaign_id, index)}
                                                            />
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                        {brandCampaignsListingFullFill?.data?.length === 0 && (
                            <Col className="text-center">
                                <span className="font-weight-bold">Sorry! No data found!</span>
                            </Col>
                        )}
                    </Row>
                    <CustomPagination pageLinks={brandCampaignsListingFullFill?.pages} onPaginationClick={handleCustomPagination}/>
                </>
            )}

            {/* --- Create Campaign Modal --- */}
            <Modal size="lg" isOpen={addCampaignModal} className="modal-dialog-centered">
                <ModalHeader toggle={() => {
                    setAddCampaignModal(false)
                }}>
                    Create Campaign
                </ModalHeader>
                <ModalBody>
                    <div className="item-img text-center mx-auto">
                        <Avatar img={brandDetail?.logo} imgHeight="80" imgWidth="80" className="avatar-img-fit-contain"/>
                    </div>
                    <CardBody>
                        <h5 className="item-name text-center">
                            {brandDetail?.brand_name}
                        </h5>
                    </CardBody>
                    <Formik
                        innerRef={createCampaignFormRef}
                        initialValues={{
                            campaign_title: "",
                            campaign_details: "",
                            submission_limit: "",
                            campaign_form_type: "",
                            campaign_form_id: "",
                            campaign_form_title: "",
                            campaign_form_questions: [{id: "0", question: "", type: ""}]
                        }}
                        validationSchema={yup.object().shape({
                            campaign_title: yup.string().trim().required("This field is required"),
                            campaign_details: yup.string().trim().required("This field is required"),
                            submission_limit: yup.number().test("lessThanZero", "Minimum 1 is required.", (value) => {
                                if (value !== undefined && value !== '' && value <= 0) {
                                    return false
                                }
                                return true
                            }),
                            campaign_form_type: yup.string().required("This field is required"),
                            campaign_form_id: yup.number().when('campaign_form_type', {
                                is: (e) => e === 'existing',
                                then: yup.number().required("This field is required"),
                                otherwise: yup.number()
                            }),
                            campaign_form_title: yup.string().when('campaign_form_type', {
                                is: (e) => e === 'custom',
                                then: yup.string().trim().required("This field is required"),
                                otherwise: yup.string()
                            }),
                            campaign_form_questions: yup.array().when('campaign_form_type', {
                                is: (e) => e === 'custom',
                                then: yup.array().of(
                                    yup.object().shape({
                                        question: yup.string().trim().required("This field is required"),
                                        type: yup.string().required("This field is required")
                                    })
                                ),
                                otherwise: yup.array().notRequired()
                            })
                        })}
                        onSubmit={(values) => {
                            addNewCampaign(values)
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              setFieldValue,
                              touched,
                              values
                          }) => (
                            <Form className='auth-reset-password-form mt-2' onSubmit={handleSubmit} autoComplete="off">
                                <Row>
                                    <FormGroup tag={Col} md="12">
                                        <Label className={classnames({
                                            'is-invalid': touched.campaign_title && errors.campaign_title,
                                            'form-label': true
                                        })} htmlFor={`campaign_title`}>
                                            <strong>Campaign Name</strong>
                                            <span className="is-invalid">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            id="campaign_title"
                                            name="campaign_title"
                                            value={values.campaign_title}
                                            placeholder="Campaign Title"
                                            className={classnames({'is-invalid': touched.campaign_title && errors.campaign_title})}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            disabled={brandCreateCampaignLoading}
                                        />
                                        <div
                                            className="validation-err">{touched.campaign_title && errors.campaign_title}</div>
                                    </FormGroup>

                                    <FormGroup tag={Col} md="12">
                                        <Label className={classnames({
                                            'is-invalid': touched.campaign_details && errors.campaign_details,
                                            'form-label': true
                                        })} htmlFor='campaign_details'>
                                            <span><strong>Describe your campaign and opportunity for RDs</strong></span>
                                            <span className="is-invalid">*</span>
                                        </Label>
                                        <Editor onEditorStateChange={(e) => {
                                            const contentState = e.getCurrentContent()
                                            if (contentState.hasText()) {
                                                setFieldValue('campaign_details', draftToHtml(convertToRaw(e.getCurrentContent())))
                                            } else {
                                                setFieldValue('campaign_details', "")
                                            }
                                        }}
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'link', 'remove', 'history'],
                                                    inline: {
                                                        options: ['bold', 'italic', 'underline']
                                                    },
                                                    list: {
                                                        options: ['unordered', 'ordered']
                                                    },
                                                    blockType: {
                                                        options: ['Normal', 'H1', 'H2', 'H3']
                                                    },
                                                    link: {
                                                        showOpenOptionOnHover: false
                                                    },
                                                    fontSize: {
                                                        options: [12, 14, 16, 18, 24, 36]
                                                    }
                                                }}
                                                stripPastedStyles={true}
                                        />
                                        <div className="validation-err">
                                            {touched.campaign_details && errors.campaign_details}
                                        </div>
                                    </FormGroup>

                                    <FormGroup tag={Col} md="12">
                                        <Label
                                            className={classnames({'is-invalid': touched.submission_limit && errors.submission_limit})}>
                                            <span>
                                                <strong>Submission Limit</strong>
                                                <span> (Unlimited if value is empty)</span>
                                            </span>
                                        </Label>
                                        <Input
                                            type="number"
                                            name="submission_limit"
                                            value={values.submission_limit}
                                            id="submission_limit"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            disabled={brandCreateCampaignLoading}
                                            placeholder='Unlimited'
                                            className={classnames({'is-invalid': touched.submission_limit && errors.submission_limit})}
                                        />
                                        <div className="validation-err">{touched.submission_limit && errors.submission_limit}</div>
                                    </FormGroup>

                                    <FormGroup tag={Col} md="12">
                                        <Label className={classnames({
                                            'is-invalid': touched.campaign_form_type && errors.campaign_form_type,
                                            'form-label': true
                                        })} htmlFor='campaign_form_type'
                                        >
                                            <strong>Campaign Form</strong>
                                            <span className="is-invalid">*</span>
                                        </Label>
                                        <Input type="select"
                                               name='campaign_form_type'
                                               value={values.campaign_form_type}
                                               id='campaign_form_type'
                                               className={classnames({'is-invalid': touched.campaign_form_type && errors.campaign_form_type})}
                                               disabled={brandCreateCampaignLoading}
                                               onChange={(e) => {
                                                   if (e.target.value === 'existing') {
                                                       setFieldValue('campaign_form_title', '')
                                                       setFieldValue('campaign_form_questions', [{question: "", type: ""}])
                                                   } else if (e.target.value === 'custom') {
                                                       setFieldValue('campaign_form_id', '')
                                                       dispatch(getCreateCampaignFormQuestionsReset())
                                                   }
                                                   setFieldValue('campaign_form_type', e.target.value)
                                               }}
                                        >
                                            <option value="">---Select---</option>
                                            <option value="existing">Choose existing template</option>
                                            <option value="custom">Create your own template</option>
                                        </Input>
                                        <div className="validation-err">
                                            {touched.campaign_form_type && errors.campaign_form_type}
                                        </div>
                                    </FormGroup>

                                    {values.campaign_form_type === 'custom' && (
                                        <>
                                            <FormGroup tag={Col} md="12">
                                                <p className='p-1 bg-light'><strong>Template</strong></p>
                                            </FormGroup>

                                            <FormGroup tag={Col} md="12">
                                                <Label className={classnames({
                                                    'is-invalid': touched.campaign_form_title && errors.campaign_form_title,
                                                    'form-label': true
                                                })} htmlFor={`campaign_form_title`}>
                                                    <div>
                                                        <strong>Campaign Form Title</strong>
                                                        <span className="is-invalid">*</span>
                                                    </div>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    id="campaign_form_title"
                                                    name="campaign_form_title"
                                                    value={values.campaign_form_title}
                                                    placeholder="Campaign Form Title"
                                                    className={classnames({'is-invalid': touched.campaign_form_title && errors.campaign_form_title})}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    disabled={brandCreateCampaignLoading}
                                                />
                                                <div className="validation-err">{touched.campaign_form_title && errors.campaign_form_title}</div>
                                            </FormGroup>

                                            <FormGroup tag={Col} md="12">
                                                <div className='text-right'>
                                                    <button className='btn btn-link p-0' onClick={() => addField(createCampaignFormRef?.current)}>
                                                        <strong className='text-underline'>
                                                            <PlusCircle size='14'/> Add New Question
                                                        </strong>
                                                    </button>
                                                </div>
                                            </FormGroup>

                                            {createCampaignFormRef?.current?.values?.campaign_form_questions.map((field, index) => (
                                                <Fragment key={index}>
                                                    <FormGroup tag={Col} md="7">
                                                        <Label className={classnames({
                                                            'is-invalid': touched.campaign_form_questions?.[index]?.question && errors.campaign_form_questions?.[index]?.question,
                                                            'form-label': true
                                                        })} htmlFor={`question_${index}`}>
                                                            <strong>Campaign Question</strong>
                                                            <span className="is-invalid">*</span>
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id={`question_${index}`}
                                                            name={`campaign_form_questions[${index}].question`}
                                                            value={field.question}
                                                            placeholder="Campaign Question"
                                                            className={classnames({'is-invalid': touched.campaign_form_questions?.[index]?.question && errors.campaign_form_questions?.[index]?.question})}
                                                            onBlur={createCampaignFormRef?.current?.handleBlur}
                                                            onChange={createCampaignFormRef?.current?.handleChange}
                                                            disabled={brandCreateCampaignLoading}
                                                        />
                                                        <div className="validation-err">
                                                            {touched.campaign_form_questions?.[index]?.question && errors.campaign_form_questions?.[index]?.question}
                                                        </div>
                                                    </FormGroup>
                                                    <FormGroup tag={Col} md="4">
                                                        <Label className={classnames({
                                                            'is-invalid': touched.campaign_form_questions?.[index]?.type && errors.campaign_form_questions?.[index]?.type,
                                                            'form-label': true
                                                        })} htmlFor={`type_${index}`}>
                                                            <strong>Field Type</strong>
                                                            <span className="is-invalid">*</span>
                                                        </Label>
                                                        <Input
                                                            type="select"
                                                            name={`campaign_form_questions[${index}].type`}
                                                            value={field.type}
                                                            id={`type_${index}`}
                                                            className={classnames({'is-invalid': touched.campaign_form_questions?.[index]?.type && errors.campaign_form_questions?.[index]?.type})}
                                                            disabled={brandCreateCampaignLoading}
                                                            onBlur={createCampaignFormRef?.current?.handleBlur}
                                                            onChange={createCampaignFormRef?.current?.handleChange}
                                                        >
                                                            <option value="">---Select---</option>
                                                            <option value="input">Input Field</option>
                                                            <option value="radio">Options (Yes, No)</option>
                                                        </Input>
                                                        <div className="validation-err">
                                                            {touched.campaign_form_questions?.[index]?.type && errors.campaign_form_questions?.[index]?.type}
                                                        </div>
                                                    </FormGroup>
                                                    {index > 0 && (
                                                        <FormGroup tag={Col} md="1" className='d-flex justify-content-center align-items-center mt-1 cursor-pointer'>
                                                            <Trash2 color='red' onClick={() => removeField(createCampaignFormRef.current, index)}/>
                                                        </FormGroup>
                                                    )}
                                                </Fragment>
                                            ))}
                                        </>
                                    )}

                                    {values.campaign_form_type === 'existing' && (
                                        <>
                                            <FormGroup tag={Col} md="12">
                                                <p className='p-1 bg-light'><strong>Template</strong></p>
                                            </FormGroup>

                                            <FormGroup tag={Col} md="12">
                                                <Label className={classnames({
                                                    'is-invalid': touched.campaign_form_id && errors.campaign_form_id,
                                                    'form-label': true
                                                })} htmlFor='campaign_form_id'>
                                                    <b>Please select a form template for the RDs to complete</b>
                                                    <span className="is-invalid">*</span>
                                                </Label>
                                                <Input type="select"
                                                       onChange={(e) => {
                                                           setFieldValue('campaign_form_id', e.target.value)
                                                           handleFormSelect(e.target.value)
                                                       }}
                                                       value={values.campaign_form_id}
                                                       name={`campaign_form_id`}
                                                       id={`campaign_form_id`}
                                                       className={classnames({'is-invalid': touched.campaign_form_id && errors.campaign_form_id})}
                                                       disabled={brandCreateCampaignLoading || brandCampaignFormLoading || brandCampaignQuestionsLoading}
                                                >
                                                    <option value="">--- Select ---</option>
                                                    {brandCampaignFormFullFill?.data?.length && brandCampaignFormFullFill?.data?.map((formOtions) => (
                                                        <option key={formOtions?.id} value={formOtions?.id}>{formOtions?.title}</option>
                                                    ))}
                                                </Input>
                                                <div className="validation-err">{touched.campaign_form_id && errors.campaign_form_id}</div>
                                            </FormGroup>

                                            {brandCampaignQuestionsLoading ? (
                                                <div className="w-100 my-2 text-center">
                                                    <Spinner color='primary'/>
                                                </div>
                                            ) : (
                                                <FormGroup className="mb-0" tag={Col} md="12">
                                                    {brandCampaignQuestionsLoading === false && brandCampaignQuestionsFullFill?.data?.length > 0 && (
                                                        <>
                                                            <p><strong>Campaign Questions:</strong></p>
                                                            {brandCampaignQuestionsFullFill?.data.map((item, i) => (
                                                                <p key={i}
                                                                   className="bg-light p-1 text-wrap text-break">
                                                                    <b>{++i} - </b>{item.question}
                                                                </p>
                                                            ))}
                                                        </>
                                                    )}
                                                </FormGroup>
                                            )}
                                        </>
                                    )}

                                    <FormGroup tag={Col} md="12">
                                        <hr/>
                                    </FormGroup>

                                    <FormGroup className="mb-0 text-right" tag={Col} md="12">
                                        <Button.Ripple type='submit' color='primary' disabled={brandCreateCampaignLoading}>
                                            {brandCreateCampaignLoading ? (
                                                <Spinner style={{height: '14px', width: '14px'}}/>
                                            ) : (
                                                <span>Save as Draft</span>
                                            )}
                                        </Button.Ripple>
                                    </FormGroup>
                                </Row>
                            </Form>)}
                    </Formik>
                </ModalBody>
            </Modal>

            {/* --- Update Campaign Modal --- */}
            <Modal size="lg" isOpen={editModal} className="modal-dialog-centered">
                <ModalHeader toggle={() => {
                    setEditModal(false)
                    editCampaignForm.resetForm()
                }}>
                    Edit Campaign
                </ModalHeader>
                <ModalBody className="mb-2">
                    {brandEditCampaignLoading ? (
                        <div className="w-100 my-4 text-center">
                            <Spinner color="primary"/>
                        </div>
                    ) : (
                        <>
                            <div className="item-img text-center mx-auto">
                                <Avatar img={brandDetail?.logo} imgHeight="80" imgWidth="80" className="avatar-img-fit-contain"/>
                            </div>
                            <CardBody>
                                <h4 className="item-name text-center">
                                    {brandDetail?.brand_name}
                                </h4>
                            </CardBody>
                            <Form className='auth-reset-password-form mt-2' onSubmit={editCampaignForm.handleSubmit}>
                                <Row>
                                    <FormGroup tag={Col} md="12">
                                        <Label className={classnames({
                                            'is-invalid': editCampaignForm.touched.campaign_title && editCampaignForm.errors.campaign_title,
                                            'form-label': true
                                        })} htmlFor={`campaign_title`}>
                                            <strong>Campaign Name</strong>
                                            <span className="is-invalid">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            id="campaign_title"
                                            name="campaign_title"
                                            value={editCampaignForm.values.campaign_title}
                                            placeholder="Campaign Name"
                                            className={classnames({'is-invalid': editCampaignForm.touched.campaign_title && editCampaignForm.errors.campaign_title})}
                                            onBlur={editCampaignForm.handleBlur}
                                            onChange={editCampaignForm.handleChange}
                                            disabled={brandCampaignUpdateLoading}
                                        />
                                        <div
                                            className="validation-err">{editCampaignForm.touched.campaign_title && editCampaignForm.errors.campaign_title}</div>
                                    </FormGroup>

                                    <FormGroup tag={Col} md="12">
                                        <Label className={classnames({
                                            'is-invalid': editCampaignForm.touched.campaign_details && editCampaignForm.errors.campaign_details,
                                            'form-label': true
                                        })} htmlFor='campaign_details'>
                                            <strong>Describe you campaign and opportunity for RDs</strong>
                                            <span className="is-invalid">*</span>
                                        </Label>
                                        <Editor editorState={editCampaignForm.values.campaign_editor}
                                                onEditorStateChange={(e) => {
                                                    editCampaignForm.setFieldValue('campaign_editor', e)
                                                    const contentState = e.getCurrentContent()
                                                    if (contentState.hasText()) {
                                                        editCampaignForm.setFieldValue('campaign_details', draftToHtml(convertToRaw(e.getCurrentContent())))
                                                    } else {
                                                        editCampaignForm.setFieldValue('campaign_details', "")
                                                    }
                                                }}
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'link', 'remove', 'history'],
                                                    inline: {
                                                        options: ['bold', 'italic', 'underline']
                                                    },
                                                    list: {
                                                        options: ['unordered', 'ordered']
                                                    },
                                                    blockType: {
                                                        options: ['Normal', 'H1', 'H2', 'H3']
                                                    },
                                                    link: {
                                                        showOpenOptionOnHover: false
                                                    },
                                                    fontSize: {
                                                        options: [12, 14, 16, 18, 24, 36]
                                                    }
                                                }}
                                                stripPastedStyles={true}
                                        />
                                        <div className="validation-err">
                                            {editCampaignForm.touched.campaign_details && editCampaignForm.errors.campaign_details}
                                        </div>
                                    </FormGroup>

                                    <FormGroup tag={Col} md="12">
                                        <Label className={classnames({'is-invalid': editCampaignForm.touched.submission_limit && editCampaignForm.errors.submission_limit})}>
                                            <strong>Submission Limit</strong>
                                            <span> (Unlimited if value is empty)</span>
                                        </Label>
                                        <Input
                                            type="number"
                                            name="submission_limit"
                                            value={editCampaignForm.values.submission_limit}
                                            id="submission_limit"
                                            onBlur={editCampaignForm.handleBlur}
                                            onChange={editCampaignForm.handleChange}
                                            disabled={brandCampaignUpdateLoading}
                                            placeholder='Unlimited'
                                            className={classnames({'is-invalid': editCampaignForm.touched.submission_limit && editCampaignForm.errors.submission_limit})}
                                        />
                                        <div className="validation-err">{editCampaignForm.touched.submission_limit && editCampaignForm.errors.submission_limit}</div>
                                    </FormGroup>

                                    <FormGroup tag={Col} md="12">
                                        <Label className={classnames({
                                            'is-invalid': editCampaignForm.touched.campaign_form_type && editCampaignForm.errors.campaign_form_type,
                                            'form-label': true
                                        })} htmlFor='campaign_form_type'
                                        >
                                            <strong>Campaign Form</strong>
                                            <span className="is-invalid">*</span>
                                        </Label>
                                        <Input type="select"
                                               name='campaign_form_type'
                                               value={editCampaignForm.values.campaign_form_type}
                                               id='campaign_form_type'
                                               className={classnames({'is-invalid': editCampaignForm.touched.campaign_form_type && editCampaignForm.errors.campaign_form_type})}
                                               disabled={true}
                                               onChange={(e) => {
                                                   if (e.target.value === 'existing') {
                                                       editCampaignForm.setFieldValue('campaign_form_title', '')
                                                       editCampaignForm.setFieldValue('campaign_form_questions', [{question: "", type: ""}])
                                                   } else if (e.target.value === 'custom') {
                                                       editCampaignForm.setFieldValue('campaign_form_id', '')
                                                       dispatch(getCreateCampaignFormQuestionsReset())
                                                   }
                                                   editCampaignForm.setFieldValue('campaign_form_type', e.target.value)
                                               }}
                                        >
                                            <option value="">---Select---</option>
                                            <option value="existing">Choose existing template</option>
                                            <option value="custom">Create your own template</option>
                                        </Input>
                                        <div className="validation-err">
                                            {editCampaignForm.touched.campaign_form_type && editCampaignForm.errors.campaign_form_type}
                                        </div>
                                    </FormGroup>

                                    {editCampaignForm.values.campaign_form_type === 'custom' && (
                                        <>
                                            <FormGroup tag={Col} md="12">
                                                <p className='p-1 bg-light'><strong>Template</strong></p>
                                            </FormGroup>

                                            <FormGroup tag={Col} md="12">
                                                <Label className={classnames({
                                                    'is-invalid': editCampaignForm.touched.campaign_form_title && editCampaignForm.errors.campaign_form_title,
                                                    'form-label': true
                                                })} htmlFor={`campaign_form_title`}>
                                                    <div>
                                                        <strong>Campaign Form Title</strong>
                                                        <span className="is-invalid">*</span>
                                                    </div>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    id="campaign_form_title"
                                                    name="campaign_form_title"
                                                    value={editCampaignForm.values.campaign_form_title}
                                                    placeholder="Campaign Form Title"
                                                    className={classnames({'is-invalid': editCampaignForm.touched.campaign_form_title && editCampaignForm.errors.campaign_form_title})}
                                                    onBlur={editCampaignForm.handleBlur}
                                                    onChange={editCampaignForm.handleChange}
                                                    disabled={brandCreateCampaignLoading}
                                                />
                                                <div className="validation-err">{editCampaignForm.touched.campaign_form_title && editCampaignForm.errors.campaign_form_title}</div>
                                            </FormGroup>

                                            <FormGroup tag={Col} md="12">
                                                <div className='text-right'>
                                                    <button className='btn btn-link p-0' onClick={() => addField(editCampaignForm)}>
                                                        <strong className='text-underline'>
                                                            <PlusCircle size='14'/> Add New Question
                                                        </strong>
                                                    </button>
                                                </div>
                                            </FormGroup>

                                            {editCampaignForm?.values?.campaign_form_questions.map((field, index) => (
                                                <Fragment key={index}>
                                                    <FormGroup tag={Col} md="7">
                                                        <Label className={classnames({
                                                            'is-invalid': editCampaignForm.touched.campaign_form_questions?.[index]?.question && editCampaignForm.errors.campaign_form_questions?.[index]?.question,
                                                            'form-label': true
                                                        })} htmlFor={`question_${index}`}>
                                                            <strong>Campaign Question</strong>
                                                            <span className="is-invalid">*</span>
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id={`question_${index}`}
                                                            name={`campaign_form_questions[${index}].question`}
                                                            value={field.question}
                                                            placeholder="Campaign Question"
                                                            className={classnames({'is-invalid': editCampaignForm.touched.campaign_form_questions?.[index]?.question && editCampaignForm.errors.campaign_form_questions?.[index]?.question})}
                                                            onBlur={editCampaignForm.handleBlur}
                                                            onChange={editCampaignForm.handleChange}
                                                            disabled={brandCreateCampaignLoading}
                                                        />
                                                        <div className="validation-err">
                                                            {editCampaignForm.touched.campaign_form_questions?.[index]?.question && editCampaignForm.errors.campaign_form_questions?.[index]?.question}
                                                        </div>
                                                    </FormGroup>
                                                    <FormGroup tag={Col} md="4">
                                                        <Label className={classnames({
                                                            'is-invalid': editCampaignForm.touched.campaign_form_questions?.[index]?.type && editCampaignForm.errors.campaign_form_questions?.[index]?.type,
                                                            'form-label': true
                                                        })} htmlFor={`type_${index}`}>
                                                            <strong>Field Type</strong>
                                                            <span className="is-invalid">*</span>
                                                        </Label>
                                                        <Input
                                                            type="select"
                                                            name={`campaign_form_questions[${index}].type`}
                                                            value={field.type}
                                                            id={`type_${index}`}
                                                            className={classnames({'is-invalid': editCampaignForm.touched.campaign_form_questions?.[index]?.type && editCampaignForm.errors.campaign_form_questions?.[index]?.type})}
                                                            disabled={brandCreateCampaignLoading}
                                                            onBlur={editCampaignForm.handleBlur}
                                                            onChange={editCampaignForm.handleChange}
                                                        >
                                                            <option value="">---Select---</option>
                                                            <option value="input">Input Field</option>
                                                            <option value="radio">Options (Yes, No)</option>
                                                        </Input>
                                                        <div className="validation-err">
                                                            {editCampaignForm.touched.campaign_form_questions?.[index]?.type && editCampaignForm.errors.campaign_form_questions?.[index]?.type}
                                                        </div>
                                                    </FormGroup>
                                                    {index > 0 && (
                                                        <FormGroup tag={Col} md="1"
                                                                   className='d-flex justify-content-center align-items-center mt-1 cursor-pointer'>
                                                            <Trash2 color='red' onClick={() => removeField(editCampaignForm, index, field?.id)}/>
                                                        </FormGroup>
                                                    )}
                                                </Fragment>
                                            ))}
                                        </>
                                    )}

                                    {editCampaignForm.values.campaign_form_type === 'existing' && (
                                        <>
                                            <FormGroup tag={Col} md="12">
                                                <p className='p-1 bg-light'><strong>Template</strong></p>
                                            </FormGroup>

                                            <FormGroup tag={Col} md="12">
                                                <Label className={classnames({
                                                    'is-invalid': editCampaignForm.touched.campaign_form_id && editCampaignForm.errors.campaign_form_id,
                                                    'form-label': true
                                                })} htmlFor='campaign_form_id'>
                                                    <b>Please select a form template for the RDs to complete</b>
                                                    <span className="is-invalid">*</span>
                                                </Label>
                                                <Input type="select"
                                                       onChange={(e) => {
                                                           editCampaignForm.setFieldValue('campaign_form_id', e.target.value)
                                                           handleFormSelect(e.target.value)
                                                       }}
                                                       value={editCampaignForm.values.campaign_form_id}
                                                       name={`campaign_form_id`}
                                                       id={`campaign_form_id`}
                                                       className={classnames({'is-invalid': editCampaignForm.touched.campaign_form_id && editCampaignForm.errors.campaign_form_id})}
                                                       disabled={brandCreateCampaignLoading || brandCampaignFormLoading || brandCampaignQuestionsLoading || brandCampaignUpdateLoading}
                                                >
                                                    <option value="">--- Select ---</option>
                                                    {brandCampaignFormFullFill?.data?.length && brandCampaignFormFullFill?.data?.map((formOtions) => (
                                                        <option key={formOtions?.id} value={formOtions?.id}>{formOtions?.title}</option>
                                                    ))}
                                                </Input>
                                                <div className="validation-err">{editCampaignForm.touched.campaign_form_id && editCampaignForm.errors.campaign_form_id}</div>
                                            </FormGroup>

                                            {brandCampaignQuestionsLoading ? (
                                                <div className="w-100 my-2 text-center">
                                                    <Spinner color='primary'/>
                                                </div>
                                            ) : (
                                                <FormGroup className="mb-0" tag={Col} md="12">
                                                    {brandCampaignQuestionsLoading === false && brandCampaignQuestionsFullFill?.data?.length > 0 && (
                                                        <>
                                                            <p><strong>Campaign Questions:</strong></p>
                                                            {brandCampaignQuestionsFullFill?.data.map((item, i) => (
                                                                <p key={i}
                                                                   className="bg-light p-1 text-wrap text-break">
                                                                    <b>{++i} - </b>{item.question}
                                                                </p>
                                                            ))}
                                                        </>
                                                    )}
                                                </FormGroup>
                                            )}
                                        </>
                                    )}

                                    <FormGroup tag={Col} md="12">
                                        <hr/>
                                    </FormGroup>

                                    <FormGroup tag={Col} md="12" className="text-right">
                                        <Button.Ripple type='submit' color='primary' disabled={brandCampaignUpdateLoading}>
                                            {brandCampaignUpdateLoading ? (<Spinner style={{height: '14px', width: '14px'}}/>) : "Update"}
                                        </Button.Ripple>
                                    </FormGroup>
                                </Row>
                            </Form>
                        </>
                    )}
                </ModalBody>
            </Modal>

            {/* --- Campaign Delete Modal --- */}
            <Modal isOpen={deleteFAQModal} className="modal-dialog-centered">
                <ModalHeader>Are you sure?</ModalHeader>
                <ModalBody>Do want to delete this campaign?</ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        outline
                        onClick={() => {
                            setDeleteFAQModal(false)
                            setCampId(null)
                            setCampaignData(null)
                        }}
                        disabled={brandCampaignDeleteLoading}
                    >
                        No
                    </Button>
                    <Button color="danger" onClick={delCampaign} disabled={brandCampaignDeleteLoading}>
                        {brandCampaignDeleteLoading ? (<Spinner style={{height: '14px', width: '14px'}}/>) : "Yes"}
                    </Button>
                </ModalFooter>
            </Modal>

            {/* --- Publish Modal --- */}
            <Modal isOpen={publishModal} className="modal-dialog-centered" size='lg'>
                <ModalHeader>Publish ({campaignData?.short_campaign_title})</ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{
                            publish_method: "",
                            publish_datetime: "",
                            expire_after: ""
                        }}
                        validationSchema={yup.object().shape({
                            publish_method: yup.string().trim().required("This field is required"),
                            publish_datetime: yup.array().when("publish_method", {
                                is: 'schedule',
                                then: yup.array().min(1, 'This field is required.').required("This field is required.")
                            }),
                            expire_after: yup.array().min(1, 'This field is required.').required("This field is required.")
                        })}
                        onSubmit={(values) => {
                            dispatch(updateCampaignLiveStatus({
                                campaign_id: campId,
                                publish_method: values.publish_method,
                                publish_datetime: values.publish_datetime ? moment(values.publish_datetime[0]).format('YYYY-MM-DD kk:mm:ss') : values.publish_datetime,
                                expire_after: values.expire_after ? moment(values.expire_after[0]).format('YYYY-MM-DD') : values.expire_after,
                                time_zone: momentTime.tz.guess()
                            }))
                        }}
                    >
                        {({
                              errors,
                              handleSubmit,
                              setFieldValue,
                              touched,
                              values,
                              resetForm
                          }) => (
                            <Form className='auth-reset-password-form' onSubmit={handleSubmit} autoComplete="off">
                                <Row>
                                    {campaignData?.schedule_time && (
                                        <FormGroup tag={Col} md="12">
                                            <div className='font-medium-2 text-primary'><strong>Current Schedule:</strong></div>
                                            <div className='d-flex justify-content-between mt-1'>
                                                <div>
                                                    <div className='mb-1'><strong>Publish Method</strong></div>
                                                    <div><span className='badge badge-info'>Scheduled</span></div>
                                                </div>
                                                <div>
                                                    <div className='mb-1'><strong>Publish Date</strong></div>
                                                    <div><span className='badge badge-info'>{campaignData?.schedule_time}</span></div>
                                                </div>
                                                <div>
                                                    <div className='mb-1'><strong>Expiry Date</strong></div>
                                                    <div>
                                                        <span className='badge badge-info'>
                                                            {campaignData?.expire_after ? moment(campaignData?.expire_after).format("DD MMM, YYYY") : ""}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className='font-medium-2 text-primary'><strong>Modify:</strong></div>
                                        </FormGroup>
                                    )}

                                    <FormGroup tag={Col} md="12">
                                        <Label
                                            className={classnames({'is-invalid': touched.publish_method && errors.publish_method})}>
                                            <span><strong>Choose a publish method</strong></span>
                                            <span className="is-invalid">*</span>
                                        </Label>
                                        <Input
                                            type="select"
                                            name="publish_method"
                                            value={values.publish_method}
                                            id="publish_method"
                                            className={classnames({'is-invalid': touched.publish_method && errors.publish_method})}
                                            onChange={(e) => {
                                                setFieldValue('publish_method', e.target.value)
                                                setFieldValue('publish_datetime', [])
                                                setFieldValue('expire_after', [])
                                            }}
                                            disabled={brandCampaignLiveLoading}
                                        >
                                            <option value="">--- Select ---</option>
                                            <option value="instant">Instant Publish</option>
                                            <option value="schedule">Schedule Publish</option>
                                        </Input>
                                        <div className="validation-err">{touched.publish_method && errors.publish_method}</div>
                                    </FormGroup>

                                    {values.publish_method === 'schedule' && (
                                        <>
                                            <FormGroup tag={Col} md="12">
                                                <Label
                                                    className={classnames({'is-invalid': touched.publish_datetime && errors.publish_datetime})}>
                                                    <span><strong>Choose a publish Date</strong></span>
                                                    <span className="is-invalid">*</span>
                                                </Label>
                                                <Flatpickr
                                                    id="publish_datetime"
                                                    value={values.publish_datetime}
                                                    className={classnames('form-control', {'is-invalid': touched.publish_datetime && errors.publish_datetime})}
                                                    options={SCHEDULE_DATE_OPTIONS}
                                                    onChange={(e) => {
                                                        setFieldValue('publish_datetime', e)
                                                        setFieldValue('expire_after', [])
                                                    }}
                                                    placeholder="Publish Date"
                                                    style={{backgroundColor: '#FFF', opacity: '1'}}
                                                    disabled={brandCampaignLiveLoading}
                                                />
                                                <div className="validation-err">{touched.publish_datetime && errors.publish_datetime}</div>
                                            </FormGroup>
                                        </>
                                    )}
                                    {values.publish_method && (
                                        <FormGroup tag={Col} md="12">
                                            <Label className={classnames('d-flex justify-content-between', {'is-invalid': touched.expire_after && errors.expire_after})}>
                                            <span>
                                                <strong>Campaign Expiration Date</strong>
                                                <span className="is-invalid">*</span>
                                                <span
                                                    className='text-danger'> (We have a maximum of three months from {values.publish_method === 'schedule' ? 'publish' : 'today\'s'} date.)</span>
                                            </span>
                                            </Label>
                                            <Flatpickr
                                                id="expire_after"
                                                value={values.expire_after}
                                                className={classnames('form-control cursor-pointer', {'is-invalid': touched.expire_after && errors.expire_after})}
                                                options={expDateConfig}
                                                onChange={(e) => setFieldValue('expire_after', e)}
                                                placeholder="Expiration Date"
                                                disabled={brandCreateCampaignLoading}
                                                style={{backgroundColor: '#FFF', opacity: '1'}}
                                            />
                                            <div className="validation-err">
                                                {touched.expire_after && errors.expire_after}
                                            </div>
                                        </FormGroup>
                                    )}

                                    <FormGroup tag={Col} md="12">
                                        <hr/>
                                    </FormGroup>

                                    <FormGroup className="d-flex justify-content-between" tag={Col} md="12">
                                        <Button.Ripple type='button'
                                                       color='danger'
                                                       onClick={() => {
                                                           setPublishModal(false)
                                                           setCampaignData(null)
                                                           resetForm()
                                                       }}
                                                       disabled={brandCampaignLiveLoading}
                                        >
                                            <span><XCircle size={14}/> Close</span>
                                        </Button.Ripple>
                                        <Button.Ripple type='submit' color='primary' disabled={brandCampaignLiveLoading}>
                                            {brandCampaignLiveLoading ? (
                                                <Spinner style={{height: '14px', width: '14px'}}/>
                                            ) : (
                                                <span><Clock size={14}/> Submit</span>
                                            )}
                                        </Button.Ripple>
                                    </FormGroup>
                                </Row>
                            </Form>)}
                    </Formik>
                </ModalBody>
            </Modal>

            {/* --- Invite to a Campaign Modal --- */}
            <Modal size="lg" isOpen={inviteCampaignModal} className="modal-dialog-centered">
                <ModalHeader>Invite to a Campaign</ModalHeader>
                <ModalBody className="p-1">
                    <Formik
                        initialValues={{
                            campaign: "",
                            via: ""
                        }}
                        validationSchema={yup.object().shape({
                            campaign: yup.object().required("This field is required"),
                            via: yup.object().required("This field is required")
                        })}
                        onSubmit={(values) => {
                            const date = new Date()
                            const dataObj = {
                                campaign_id: values?.campaign.id,
                                rds: rdIds,
                                timezone: date.toString()
                            }
                            const viaValue = values?.via?.value
                            switch (viaValue) {
                                case 'email':
                                    setClickButton('email')
                                    dispatch(inviteCampaignOnEmail(dataObj))
                                    break
                                case 'message':
                                    setClickButton('message')
                                    dispatch(inviteCampaignOnMsg(dataObj))
                                    break
                                case 'both':
                                    setClickButton('both')
                                    dispatch(inviteCampaignOnMsg(dataObj))
                                    dispatch(inviteCampaignOnEmail(dataObj))
                                    break
                                default:
                                    break
                            }
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              setFieldValue,
                              touched,
                              values
                          }) => (
                            <Form onSubmit={handleSubmit} autoComplete="off">
                                <Row>
                                    <FormGroup tag={Col} md="12"
                                               className="d-flex justify-content-between align-items-center">
                                        <CustomInput
                                            key="select-all-applicants"
                                            type='checkbox'
                                            className="font-weight-bold cursor-pointer"
                                            name="select-all-applicants"
                                            id="select-all-applicants"
                                            label="Select All"
                                            value={selectAllApplicants}
                                            checked={selectAllApplicants}
                                            onChange={(e) => {
                                                handleSelectAllCheckBox(e)
                                            }}
                                            disabled={searchValue || inviteOnEmailLoading || inviteOnMsgLoading}
                                        />
                                        <span className="font-weight-bold badge badge-success badge-sm">
                                                    Count: {rdIds.length}
                                                </span>
                                        <Input
                                            type="search"
                                            id="campaign_title"
                                            name="campaign_title"
                                            className="w-25 input-sm"
                                            placeholder="Search..."
                                            value={searchValue}
                                            onChange={(e) => searchRds(e.target.value)}
                                            disabled={selectAllApplicants || inviteOnEmailLoading || inviteOnMsgLoading}
                                        />
                                    </FormGroup>
                                    <FormGroup tag={Col} md="12">
                                        <Row style={{maxHeight: '400px', overflow: 'auto'}} className="bg-light pt-1">
                                            {allRDs?.length > 0 ? allRDs?.map((val, index) => (
                                                <Col md="4" key={index}>
                                                    <CustomInput
                                                        key={index}
                                                        type='checkbox'
                                                        className="mb-1"
                                                        name={`check-${val?.id}`}
                                                        id={`check-${val?.id}`}
                                                        label={val?.name}
                                                        value={val?.id}
                                                        onChange={handleCheckBox}
                                                        checked={!!rdIds.find(item => parseInt(item.id) === parseInt(val?.id))}
                                                        disabled={inviteOnEmailLoading || inviteOnMsgLoading}
                                                    />
                                                </Col>
                                            )) : (
                                                <div className="text-center w-100 pb-1 font-weight-bold">
                                                    <span>Sorry! No result found.</span>
                                                </div>
                                            )}
                                        </Row>
                                    </FormGroup>
                                    <FormGroup tag={Col} md="12">
                                        <Label>
                                            <span><strong>Select Campaign</strong></span>
                                            <span className="is-invalid">*</span>
                                        </Label>
                                        <Select
                                            isClearable={false}
                                            closeMenuOnSelect={true}
                                            options={brandCampaignsAllListingFullFill?.campaigns?.map((items) => {
                                                return {
                                                    id: items?.id,
                                                    label: items.campaign_title,
                                                    value: items?.id
                                                }
                                            })}
                                            value={values.campaign}
                                            className='react-select'
                                            classNamePrefix='select'
                                            onChange={(e) => setFieldValue('campaign', e)}
                                            isDisabled={inviteOnEmailLoading || inviteOnMsgLoading}
                                        />
                                        <div className="validation-err">
                                            {touched.campaign && errors.campaign}
                                        </div>
                                    </FormGroup>
                                    <FormGroup tag={Col} md="12">
                                        <Label>
                                            <span><strong>Send an invitation via</strong></span>
                                            <span className="is-invalid">*</span>
                                        </Label>
                                        <Select
                                            isClearable={false}
                                            closeMenuOnSelect={true}
                                            options={[
                                                {
                                                    id: 1,
                                                    label: 'Message',
                                                    value: 'message'
                                                },
                                                {
                                                    id: 2,
                                                    label: 'Email',
                                                    value: 'email'
                                                },
                                                {
                                                    id: 3,
                                                    label: 'Both (Message + Email)',
                                                    value: 'both'
                                                }
                                            ]}
                                            value={values.via}
                                            className='react-select'
                                            classNamePrefix='select'
                                            onChange={(e) => setFieldValue('via', e)}
                                            isDisabled={inviteOnEmailLoading || inviteOnMsgLoading}
                                        />
                                        <div className="validation-err">
                                            {touched.via && errors.via}
                                        </div>
                                    </FormGroup>
                                    <FormGroup tag={Col} md="12">
                                        <hr/>
                                    </FormGroup>
                                    <FormGroup tag={Col} md="12">
                                        <div className="d-flex justify-content-between">
                                            <Button.Ripple type='submit' color='primary' className="mr-2"
                                                           disabled={inviteOnEmailLoading || inviteOnMsgLoading || rdIds.length === 0}
                                                           onClick={() => setClickButton('msg')}>
                                                {(inviteOnMsgLoading || inviteOnEmailLoading) ? (
                                                    <>
                                                        <Spinner style={{height: '14px', width: '14px'}}/> Send
                                                    </>
                                                ) : (
                                                    <>
                                                        <MessageCircle size={14}/> Send
                                                    </>
                                                )}
                                            </Button.Ripple>
                                            <Button.Ripple type='button' color='danger' disabled={inviteOnEmailLoading || inviteOnMsgLoading} onClick={() => {
                                                setInviteCampaignModal(false)
                                                setRdIds([])
                                                setSelectAllApplicants(false)
                                                searchRds("")
                                            }}>
                                                <Delete size={14}/> Close
                                            </Button.Ripple>
                                            {/*<Button.Ripple type='submit' color='success'*/}
                                            {/*               disabled={inviteOnEmailLoading || inviteOnMsgLoading || rdIds.length === 0}*/}
                                            {/*               onClick={() => setClickButton('email')}>*/}
                                            {/*    {inviteOnEmailLoading ? (*/}
                                            {/*        <>*/}
                                            {/*            <Spinner style={{height: '14px', width: '14px'}}/> Email*/}
                                            {/*        </>*/}
                                            {/*    ) : (*/}
                                            {/*        <>*/}
                                            {/*            <Inbox size={14}/> Email*/}
                                            {/*        </>*/}
                                            {/*    )}*/}
                                            {/*</Button.Ripple>*/}
                                        </div>
                                    </FormGroup>
                                </Row>
                            </Form>)}
                    </Formik>
                </ModalBody>
            </Modal>

            {/* --- Preview Campaign Modal --- */}
            <Modal size="lg" isOpen={previewModal} className="modal-dialog-centered">
                <ModalHeader toggle={() => {
                    setPreviewModal(false)
                    setCampaignData(null)
                }}>
                    Campaign Preview
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardBody>
                            <div className="item-img text-center mx-auto">
                                <Avatar img={brandDetail?.logo} imgHeight="100" imgWidth="100" className="avatar-img-fit-contain"/>
                            </div>
                            <CardBody>
                                <h4 className="item-name text-center">
                                    {brandDetail?.brand_name}
                                </h4>
                            </CardBody>
                            <CardText className="bg-light p-1 text-break">
                                <b>{campaignData?.campaign_title}</b>
                            </CardText>
                            <div className="text-break mb-2" style={{whiteSpace: "break-spaces", textAlign: "justify"}}>
                                <div dangerouslySetInnerHTML={{__html: campaignData?.campaign_details}}></div>
                            </div>
                            <hr/>
                            <CardText className="font-weight-bold text-primary">
                                Complete the form below to apply for this opportunity.
                            </CardText>
                            <CardText className="bg-light p-1 text-break">
                                <b>{campaignData?.campaign_form_title}</b>
                            </CardText>
                            <Form className="auth-reset-password-form mt-2">
                                <Row>
                                    {campaignData?.campaign_form_questions?.length && campaignData?.campaign_form_questions?.map((dat, index) => (
                                        <FormGroup tag={Col} md="12" key={index}>
                                            <div className="font-weight-bold text-break font-small-3 mb-1">
                                                {index + 1} - {dat?.question}
                                                <span className="is-invalid">*</span>
                                            </div>
                                            {dat?.type === "input" && (
                                                <Input type="text" placeholder={dat?.question} disabled={true}/>
                                            )}
                                            {dat?.type === "radio" && (
                                                <Fragment>
                                                    <CustomInput type="radio" id={`exampleCustomRadio_1_${index}`} name={dat?.id} inline label="Yes" value="yes" disabled={true}/>
                                                    <CustomInput type="radio" id={`exampleCustomRadio_2_${index}`} name={dat?.id} inline label="No" value="no" disabled={true}/>
                                                </Fragment>
                                            )}
                                        </FormGroup>
                                    ))}
                                    <FormGroup tag={Col} md="12" className="mt-2">
                                        <Button.Ripple type="button" color="primary" className="d-flex" disabled={true}>
                                            Submit For This Campaign
                                        </Button.Ripple>
                                    </FormGroup>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default MyCampaigns
