import axios from "axios"
import {ADMIN_ACTION_TYPES} from "@store/actionTypes/AdminActionTypes"

const API_URL = `${process.env.REACT_APP_BASEURL}admin`

const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const brandCancelPlanAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.BRAND_CANCEL_PLAN_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/cancel-brand-subscription`, dataObj, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.BRAND_CANCEL_PLAN_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.BRAND_CANCEL_PLAN_ERROR, payload: error})
    }
}

export const brandCancelPlanResetAction = () => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.BRAND_CANCEL_PLAN_RESET})
}

export const dashboardManageBrandsAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.DASHBOARD_MANAGE_BRANDS_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/admin-manage-brands${dataObj}`, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.DASHBOARD_MANAGE_BRANDS_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.DASHBOARD_MANAGE_BRANDS_ERROR, payload: error})
    }
}

export const dashboardManageBrandsCSVAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.DASHBOARD_MANAGE_BRANDS_CSV_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/admin-manage-brands${dataObj}`, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.DASHBOARD_MANAGE_BRANDS_CSV_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.DASHBOARD_MANAGE_BRANDS_CSV_ERROR, payload: error})
    }
}

export const dashboardManageBrandsCSVResetAction = () => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.DASHBOARD_MANAGE_BRANDS_CSV_RESET})
}

export const brandsDirectMessagesAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.BRAND_DIRECT_MESSAGES_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/brands-direct-message${dataObj}`, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.BRAND_DIRECT_MESSAGES_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.BRAND_DIRECT_MESSAGES_ERROR, payload: error})
    }
}

export const rdEmailNotificationStatsAction = () => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.RD_EMAIL_NOTIFICATION_STATA_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/notification-toggle-stats`, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.RD_EMAIL_NOTIFICATION_STATA_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.RD_EMAIL_NOTIFICATION_STATA_ERROR, payload: error})
    }
}

export const rdSiteViewStatsAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.RD_SITE_VIEW_STATA_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/entry-point-stats`, dataObj, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.RD_SITE_VIEW_STATA_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.RD_SITE_VIEW_STATA_ERROR, payload: error})
    }
}

export const adminAddUserAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_ADD_USER_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/add-user`, dataObj, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_ADD_USER_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_ADD_USER_ERROR, payload: error})
    }
}

export const adminAddUserActionReset = () => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_ADD_USER_RESET})
}

export const adminUsersListAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USERS_LIST_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/all-users${dataObj}`, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USERS_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USERS_LIST_ERROR, payload: error})
    }
}

export const adminUserStatusAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_STATUS_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/users-status`, dataObj, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_STATUS_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_STATUS_ERROR, payload: error})
    }
}

export const adminUserStatusResetAction = () => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_STATUS_RESET})
}

export const adminUserDeleteAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_DELETE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/user-delete`, dataObj, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_DELETE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_DELETE_ERROR, payload: error})
    }
}

export const adminUserDeleteResetAction = () => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_DELETE_RESET})
}

export const adminUserUpdateAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_UPDATE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/update-user`, dataObj, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_UPDATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_UPDATE_ERROR, payload: error})
    }
}

export const adminUserUpdateResetAction = () => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_UPDATE_RESET})
}

export const adminUserSetPasswordAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_SET_PASSWORD_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/user-set-password`, dataObj, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_SET_PASSWORD_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_SET_PASSWORD_ERROR, payload: error})
    }
}

export const adminUserSetPasswordResetAction = () => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_USER_SET_PASSWORD_RESET})
}

export const adminBlogListAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_LIST_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/load-blog-list${dataObj}`, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_LIST_ERROR, payload: error})
    }
}

export const adminBlogDeleteAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_DELETE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/delete-blog?blog_id=${dataObj.id}`, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_DELETE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_DELETE_ERROR, payload: error})
    }
}

export const adminBlogDeleteResetAction = () => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_DELETE_RESET})
}

export const adminBlogStatusAction = (dataObj) => async (dispatch) => {
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        await axios.post(`${API_URL}/update-blog-status`, dataObj, {headers: header})
    } catch (error) {
    }
}

export const adminBlogAddUpdateAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_ADD_UPDATE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/blog-submit`, dataObj, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_ADD_UPDATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_ADD_UPDATE_ERROR, payload: error})
    }
}

export const adminBlogAddUpdateResetAction = () => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_ADD_UPDATE_RESET})
}

export const adminBlogDetailsAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_DETAILS_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/blog-details?blog_id=${dataObj.id}`, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_DETAILS_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_DETAILS_ERROR, payload: error})
    }
}

export const adminBlogDetailsResetAction = () => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_BLOG_DETAILS_RESET})
}

export const adminCouponListAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_COUPON_LIST_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}/load-coupons${dataObj}`, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_COUPON_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_COUPON_LIST_ERROR, payload: error})
    }
}

export const adminCouponDeleteAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_COUPON_DELETE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/delete-coupon`, dataObj, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_COUPON_DELETE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_COUPON_DELETE_ERROR, payload: error})
    }
}

export const adminCouponDeleteResetAction = () => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_COUPON_DELETE_RESET})
}

export const adminCouponCreateAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_COUPON_CREATE_REQUEST})
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}/coupon-submit`, dataObj, {headers: header})
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_COUPON_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADMIN_ACTION_TYPES.ADMIN_COUPON_CREATE_ERROR, payload: error})
    }
}

export const adminCouponCreateResetAction = () => async (dispatch) => {
    dispatch({type: ADMIN_ACTION_TYPES.ADMIN_COUPON_CREATE_RESET})
}