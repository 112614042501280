import {useEffect} from 'react'
import VerticalNavMenuLink from './VerticalNavMenuLink'
import VerticalNavMenuGroup from './VerticalNavMenuGroup'
import {useSelector, useDispatch} from 'react-redux'
import {getRdUnreadMessages} from '../../../../../redux/actions/rdDashboard'
import {
    resolveVerticalNavMenuItemComponent as resolveNavItemComponent,
    CanViewMenuGroup,
    CanViewMenuItem
} from '@layouts/utils'

const VerticalMenuNavItems = props => {
    const {rdUnreadMessages} = useSelector(state => state.rd)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getRdUnreadMessages())
    }, [])

    const Components = {
        VerticalNavMenuGroup,
        VerticalNavMenuLink
    }

    const RenderNavItems = props.items.map((item, index) => {
        const TagName = Components[resolveNavItemComponent(item)]
        const commonProps = {item, index, key: item.id || item.header, ...props}
        if (item.children) {
            return CanViewMenuGroup(item) && <TagName {...commonProps} />
        }
        if (item.id === "messagesRd") {
            item.badgeText = rdUnreadMessages?.data > 0 ? rdUnreadMessages?.data.toString() : ''
        }
        return CanViewMenuItem(item) && <TagName {...commonProps} />
    })
    return RenderNavItems
}

export default VerticalMenuNavItems
