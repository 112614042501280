import React, {useEffect, Fragment, useState} from "react"
import {Link} from "react-router-dom"
import Avatar from "@components/avatar"
import BlankProfile from "@src/assets/images/avatars/avatar-blank.png"
import img4 from "@src/assets/images/banner/banner-44.jpg"
import PDF from "@src/assets/images/icons/pdf1.png"
import Tiktok from "@src/assets/images/icons/tik-tok.svg"
import Pinterest from "@src/assets/images/icons/pinterest.svg"
import {useDispatch, useSelector} from "react-redux"
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import {
    Facebook,
    Twitter,
    Linkedin,
    Instagram,
    Edit,
    List,
    Trello,
    MapPin,
    Calendar,
    Gift,
    ArrowRightCircle,
    CheckCircle,
    Eye,
    Video
} from "react-feather"
import {
    Card,
    CardBody,
    CardText,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    Button,
    TabContent,
    TabPane,
    CardHeader,
    CardTitle,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    CustomInput
} from "reactstrap"
import "@styles/base/pages/page-auth.scss"
import {
    getRdProfile, getUpcommingOpportunitiesAtProfile,
    getPastOpportunitiesAtProfile, getBrandCampaignsAtProfile,
    getBrandsByEventId
} from '@src/redux/actions/rdDashboard'
import {
    facebookLogin,
    facebookLoginReset,
    facebookToken,
    facebookTokenReset,
    facebookIgId,
    facebookIgIdReset,
    facebookIgData,
    facebookIgLogout,
    facebookIgLogoutReset
} from "@src/redux/actions/RDActions"
import queryString from "query-string"
import * as yup from "yup"
import {Formik} from "formik"
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content"
import ImageViewer from "@src/views/components/ImageViewer"
import PdfViewer from "@src/views/components/PdfViewer"
import VideoPlayer from "@src/views/components/VideoPlayer"

const RDViewProfile = () => {
    const [isBrandsListLoading, setIsBrandsListLoading] = useState(false)
    const [participateModal, setParticipateModal] = useState(false)
    const [eventId, setEventId] = useState('')
    const [rdDetails, setRdDetails] = useState(null)
    const [upcommingOpportunities, setUpcommingOpportunities] = useState(null)
    const [pastOpportunities, setPastOpportunities] = useState(null)
    const [brandsByPastEventId, setBrandsByPastEventId] = useState(null)
    const [upcommingOpportunitiesPaginationInfo, setUpcommingOpportunitiesPaginationInfo] = useState(null)
    const [pastOpportunitiesPaginationInfo, setPastOpportunitiesPaginationInfo] = useState(null)
    const [isUpcommingOpportunitiesLoading, setIsUpcommingOpportunitiesLoading] = useState(false)
    const [isPastOpportunitiesLoading, setIsPastOpportunitiesLoading] = useState(false)
    const [isProfileLoading, setIsProfileLoading] = useState(false)
    const [active, setActive] = useState("1")
    const [fbModal, setFbModal] = useState(false)
    const [fbToken, setFbToken] = useState(null)
    const [fbSteps, setFbSteps] = useState('1')
    const [userData, setUserData] = useState(null)
    const [imageViewer, setImageViewer] = useState({show: false, src: ''})
    const [pdfViewer, setPdfViewer] = useState({show: false, url: '', filename: ''})
    const [videoPlayer, setVideoPlayer] = useState({show: false, url: '', filename: ''})

    const rdDashboardStoreSlice = useSelector((store) => store.rd)
    const rdCampaignSelector = useSelector((store) => store.rdCampaigns)
    const {
        facebookLoginLoading,
        facebookLoginData,
        facebookLoginError,

        facebookTokenLoading,
        facebookTokenData,
        facebookTokenError,

        facebookIgIdLoading,
        facebookIgIdData,
        facebookIgIdError,

        facebookIgDataLoading,
        facebookIgDataData,
        facebookIgDataError,

        facebookIgLogoutLoading,
        facebookIgLogoutData,
        facebookIgLogoutError
    } = useSelector((store) => store.rdReducer)

    const dispatch = useDispatch()

    const sweetAlert = withReactContent(Swal)

    useEffect(() => {

        if (!!rdDashboardStoreSlice?.viewRdProfile) {
            setRdDetails(rdDashboardStoreSlice?.viewRdProfile.data)
            setIsProfileLoading(false)
        }

        const upcommingOpportunitiesAtProfile = rdDashboardStoreSlice?.upcommingOpportunitiesAtProfile ?? null
        if (upcommingOpportunitiesAtProfile) {
            const {links, meta, pages} = upcommingOpportunitiesAtProfile
            setUpcommingOpportunitiesPaginationInfo({...links, ...meta, pages})
            setUpcommingOpportunities(upcommingOpportunitiesAtProfile?.data)
            setIsUpcommingOpportunitiesLoading(false)
        }

        const pastOpportunitiesAtProfile = rdDashboardStoreSlice?.pastOpportunitiesAtProfile ?? null
        if (pastOpportunitiesAtProfile) {
            const {links, meta, pages} = pastOpportunitiesAtProfile
            setPastOpportunitiesPaginationInfo({...links, ...meta, pages})
            setPastOpportunities(pastOpportunitiesAtProfile?.data)
            setIsPastOpportunitiesLoading(false)
        }

        if (!!rdDashboardStoreSlice?.brandsByPastEventId) {
            setBrandsByPastEventId(rdDashboardStoreSlice?.brandsByPastEventId.data)
            setIsBrandsListLoading(false)
        }
    }, [rdDashboardStoreSlice, rdCampaignSelector])

    useEffect(() => {
        if (facebookLoginData?.success) {
            dispatch(facebookLoginReset())
            window.location.href = facebookLoginData?.url
        }

        if (facebookTokenData?.success) {
            setFbSteps('2')
        }

        if (facebookIgIdData?.success) {
            dispatch(facebookIgData())
            dispatch(facebookTokenReset())
            dispatch(facebookIgIdReset())
            setFbSteps('3')
        }

        if (facebookIgIdData?.success === false) {
            sweetAlert.fire({
                title: 'Error!',
                text: facebookIgIdData?.msg,
                icon: 'error'
            })
            dispatch(facebookIgIdReset())
        }
    }, [
        facebookLoginData,
        facebookTokenData,
        facebookIgIdData,
        facebookIgDataData,
        facebookIgDataError
    ])

    useEffect(() => {
        if (facebookIgLogoutData?.success) {
            dispatch(facebookIgLogoutReset())
            window.location.href = facebookIgLogoutData?.url
        }
    }, [facebookIgLogoutData])

    useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem('userData')))
        setIsProfileLoading(true)
        setIsUpcommingOpportunitiesLoading(true)
        setIsPastOpportunitiesLoading(true)
        dispatch(getRdProfile())
        dispatch(getUpcommingOpportunitiesAtProfile(false))
        dispatch(getPastOpportunitiesAtProfile(false))
        dispatch(getBrandCampaignsAtProfile(false))
        const parsed = queryString.parseUrl(window.location.href)
        if (parsed?.query?.fb_login && parsed?.query?.token_id) {
            setFbToken(parsed?.query?.token_id)
            setFbModal(true)
        }
        dispatch(facebookIgData())
    }, [])

    const handleUpcommingOpportunitiesPagination = (pageUrl) => {
        setIsUpcommingOpportunitiesLoading(true)
        dispatch(getUpcommingOpportunitiesAtProfile(true, pageUrl))
    }

    const handlePastOpportunitiesPagination = (pageUrl) => {
        setIsPastOpportunitiesLoading(true)
        dispatch(getPastOpportunitiesAtProfile(true, pageUrl))
    }

    const getBrandsByPastOpportunity = (id) => {
        setEventId(id)
        setIsBrandsListLoading(true)
        dispatch(getBrandsByEventId(id))
        setParticipateModal(true)
    }

    const toggle = (tab) => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const facebookSteps = (step, item) => {
        if (step === '1') {
            dispatch(facebookToken({
                token_id: fbToken
            }))
        }

        if (step === '3') {
            setFbModal(false)
            window.location.href = '/dashboard/rd/view-profile'
        }
    }

    const removeIntegration = () => {
        sweetAlert.fire({
            title: 'Confirm!',
            text: 'Do you want to remove the instagram integration?',
            icon: 'warning',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(facebookIgLogout())
            }
        })
    }

    return (
        <Fragment>
            <h3 className="mb-2">Profile</h3>
            {isProfileLoading ? (
                <div className="w-100 my-4 text-center">
                    <Spinner color='primary'/>
                </div>
            ) : (
                <Fragment>
                    <Row className="match-height">
                        {userData?.email === 'jenna@jennagorhamrd.com' && (
                            rdDetails?.facebook_graph_login === true && (
                                <Col lg="12">
                                    <div className='bg-success p-1 text-white mb-2 font-weight-bold d-flex align-items-center cursor-pointer'>
                                        <button className='btn btn-link p-0' onClick={() => dispatch(facebookLogin())}>
                                            {facebookLoginLoading ? (
                                                <>
                                                    <Spinner style={{height: '14px', width: '14px', marginRight: '5px'}}/> <span className='text-reset'>Please wait...</span>
                                                </>
                                            ) : (
                                                <>
                                                    <Instagram size={18} style={{marginRight: '5px'}}/> <span className='text-underline'>Connect your instagram business account</span>
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </Col>
                            ))}
                        <Col md="5" lg="3" sm="12">
                            <Card className="ecommerce-card view-card text-center">
                                <div className="item-img text-center mx-auto mt-3">
                                    <Avatar img={rdDetails?.profile_img || BlankProfile} imgHeight="120" imgWidth="120"/>
                                </div>
                                <CardBody className="text-center">
                                    <h5 className="item-name font-weight-bolder">{rdDetails?.name}</h5>
                                    {(rdDetails?.location?.city?.length > 0 || rdDetails?.location?.state?.length > 0)
                                        && <CardText className="my-1">
                                            {rdDetails?.location.city[0]?.value || ''}
                                            {" "}{!!rdDetails?.location.state[0]?.value && '|'}{" "}
                                            {rdDetails?.location.state[0]?.value || ''}
                                        </CardText>}
                                    {!!rdDetails?.website &&
                                        <CardText className="item-description d-flex justify-content-center font-weight-bolder">
                                            <span className="text-truncate">
                                                <a href={rdDetails?.website || '#'} target="_blank">View Website</a>
                                            </span>
                                        </CardText>}
                                    <hr/>
                                    {facebookIgDataData?.profile_picture_url && (
                                        <div className='p-1'>
                                            <div className='mb-2'><strong>Instagram Insights</strong></div>
                                            <div className='text-center'>
                                                <Avatar img={facebookIgDataData?.profile_picture_url} imgHeight="120" imgWidth="120"/>
                                            </div>
                                            <div className='text-center mt-1 mb-2'>
                                                <strong>{facebookIgDataData?.name}</strong>
                                            </div>
                                            <div className='text-center mt-1 mb-2'>
                                                <span>{facebookIgDataData?.biography}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div className='text-center'>
                                                    <p className='badge badge-primary'>{facebookIgDataData?.followers_count}</p>
                                                    <p>Followers</p>
                                                </div>
                                                <div className='text-center'>
                                                    <p className='badge badge-primary'>{facebookIgDataData?.follows_count}</p>
                                                    <p>Follows</p>
                                                </div>
                                                <div className='text-center'>
                                                    <p className='badge badge-primary'>{facebookIgDataData?.media_count}</p>
                                                    <p>Media</p>
                                                </div>
                                            </div>
                                            {userData?.email === 'jenna@jennagorhamrd.com' && (
                                                <div className='mt-1'>
                                                    <span className='text-danger cursor-pointer text-underline'>
                                                        {facebookIgLogoutLoading ? (
                                                            <strong>Please wait...</strong>
                                                        ) : (
                                                            <button className='btn btn-link p-0' onClick={removeIntegration}>Remove Integration</button>
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                            <hr/>
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-center flex-wrap">
                                        {!!rdDetails?.instagram &&
                                            <a className="mx-1 text-truncate mb-1" href={rdDetails?.instagram || '#'} target="_blank">
                                                <Instagram/>{" "}
                                            </a>
                                        }
                                        {!!rdDetails?.secondary_instagram_url &&
                                            <a className="mx-1 text-truncate mb-1" href={rdDetails?.secondary_instagram_url || '#'} target="_blank">
                                                <Instagram/>{" "}
                                            </a>
                                        }
                                        {!!rdDetails?.facebook &&
                                            <a className="mx-1 text-truncate mb-1" href={rdDetails?.facebook || '#'} target="_blank">
                                                <Facebook/>{" "}
                                            </a>
                                        }
                                        {!!rdDetails?.secondary_facebook_url &&
                                            <a className="mx-1 text-truncate mb-1" href={rdDetails?.secondary_facebook_url || '#'} target="_blank">
                                                <Facebook/>{" "}
                                            </a>
                                        }
                                        {!!rdDetails?.twitter &&
                                            <a className="mx-1 text-truncate mb-1" href={rdDetails?.twitter || '#'} target="_blank">
                                                <Twitter/>{" "}
                                            </a>
                                        }
                                        {!!rdDetails?.linkedin &&
                                            <a className="mx-1 text-truncate mb-1" href={rdDetails?.linkedin || '#'} target="_blank">
                                                <Linkedin/>{" "}
                                            </a>
                                        }
                                        {!!rdDetails?.tiktok &&
                                            <a className="mx-1 text-truncate mb-1" href={rdDetails?.tiktok || '#'} target="_blank">
                                                <img src={Tiktok} alt={Tiktok} width="24" height="26"/>{" "}
                                            </a>
                                        }
                                        {!!rdDetails?.pinterest &&
                                            <a className="mx-1 text-truncate mb-1" href={rdDetails?.pinterest || '#'} target="_blank">
                                                <img src={Pinterest} alt={Pinterest} width="24" height="24"/>{" "}
                                            </a>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="7" lg="9" sm="12">
                            <Card className="ecommerce-card">
                                <CardBody>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="font-weight-bolder">About me</h4>
                                        <Button.Ripple tag={Link} to="/dashboard/rd/edit-profile">
                                            <Edit size={16}/> Edit
                                        </Button.Ripple>
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: rdDetails?.about_me || 'N/A'}}/>
                                    {rdDetails?.attributes?.industry?.length > 0 &&
                                        <div className='mb-2'>
                                            <h5 className="text-primary font-weight-bolder">Industry</h5>
                                            {rdDetails?.attributes.industry?.map((val, i) => (
                                                <span key={i}>
                                                    {val.value} {rdDetails?.attributes?.industry?.length - 1 !== i && ", "}
                                                </span>
                                            ))}
                                        </div>
                                    }
                                    {rdDetails?.attributes?.speciality?.length > 0 &&
                                        <div className='mb-2'>
                                            <h5 className="text-primary font-weight-bolder">Specialty</h5>
                                            {rdDetails?.attributes?.speciality?.map((val, i) => (
                                                <span key={i}>
                                                    {val.value} {rdDetails?.attributes?.speciality?.length - 1 !== i && ", "}
                                                </span>
                                            ))}
                                        </div>
                                    }
                                    {rdDetails?.attributes?.personal_attributes?.length > 0 &&
                                        <div>
                                            <h5 className="text-primary font-weight-bolder">Personal Attributes</h5>
                                            {rdDetails?.attributes?.personal_attributes?.map((val, i) => (
                                                <span key={i}>
                                                {val.value} {rdDetails?.attributes?.personal_attributes?.length - 1 !== i && ", "}
                                            </span>
                                            ))}
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Fragment>
                                <h3 className="mt-2">Opportunities</h3>
                                <hr/>
                                <Nav tabs className="my-2">
                                    <NavItem>
                                        <NavLink active={active === "1"}
                                                 onClick={() => {
                                                     toggle("1")
                                                 }}>
                                            Upcoming Opportunities
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={active === "2"}
                                                 onClick={() => {
                                                     toggle("2")
                                                 }}>
                                            Past Opportunities
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={active === "4"}
                                                 onClick={() => {
                                                     toggle("4")
                                                 }}>
                                            Media Kit
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="ml-auto">
                                        <Button.Ripple color="primary"
                                                       block
                                                       tag={Link}
                                                       to="/dashboard/rd/opportunities-rdform">
                                            <span className="align-middle">Add Opportunity</span>
                                        </Button.Ripple>
                                    </NavItem>
                                </Nav>
                                <TabContent className="py-50" activeTab={active}>
                                    <TabPane tabId="1">
                                        <Row className="match-height">
                                            {isUpcommingOpportunitiesLoading && (
                                                <div className="w-100 my-4 text-center">
                                                    <Spinner color='primary'/>
                                                </div>
                                            )}
                                            {!isUpcommingOpportunitiesLoading && upcommingOpportunities?.length === 0 && (
                                                <div className="w-100 my-4 text-center">
                                                    <p>Record doesn't exists</p>
                                                </div>)}

                                            {!isUpcommingOpportunitiesLoading && upcommingOpportunities?.map((item, index) => (
                                                <Col sm="6" md="4" lg="4" key={index}>
                                                    <Card className="event-card-at-profile">
                                                        <CardHeader>
                                                            <CardTitle>{item.title}</CardTitle>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <div className="d-flex flex-wrap">
                                                                <p className="item"><MapPin size={17} className="mr-1"/>{item.country}</p>
                                                                <p className="item"><Gift size={17} className="mr-1"/>{item.event_type}</p>
                                                                <p className="item"><Calendar size={17} className="mr-1"/>{item.start_datetime} - {item.end_datetime}</p>
                                                            </div>
                                                            <div className="mt-1">
                                                                <Button.Ripple
                                                                    tag={Link}
                                                                    to={`/dashboard/rd/edit-opportunities-rdform/${item?.id}`}
                                                                    color="secondary"
                                                                    className="round m-25"
                                                                >
                                                                    <List size={14}/>
                                                                    <span className="align-middle ml-50">Manage</span>
                                                                </Button.Ripple>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                        <CustomPagination pageLinks={upcommingOpportunitiesPaginationInfo?.pages} onPaginationClick={handleUpcommingOpportunitiesPagination}/>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row className="match-height">
                                            {isPastOpportunitiesLoading && (
                                                <div className="w-100 my-4 text-center">
                                                    <Spinner color='primary'/>
                                                </div>)}
                                            {!isPastOpportunitiesLoading && pastOpportunities?.length === 0 && (
                                                <div className="w-100 my-4 text-center">
                                                    <p>Record doesn't exists</p>
                                                </div>)}

                                            {!isPastOpportunitiesLoading && pastOpportunities?.map((item, index) => (
                                                <Col sm="6" md="4" lg="4" key={index}>
                                                    <Card className="event-card-at-profile">
                                                        <CardHeader>
                                                            <CardTitle>{item.title}</CardTitle>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <div className="d-flex flex-wrap">
                                                                <p className="item"><MapPin size={17} className="mr-1"/>{item.country}</p>
                                                                <p className="item"><Gift size={17} className="mr-1"/>{item.event_type}</p>
                                                                <p className="item"><Calendar size={17} className="mr-1"/>{item.start_datetime} - {item.end_datetime}</p>
                                                            </div>

                                                            <div className="mt-1">
                                                                <Button.Ripple
                                                                    color="secondary"
                                                                    className="round m-25"
                                                                    onClick={() => getBrandsByPastOpportunity(item.id)}
                                                                >
                                                                    <Trello size={14}/>
                                                                    <span className="align-middle ml-50">Feedback</span>
                                                                </Button.Ripple>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                        <CustomPagination pageLinks={pastOpportunitiesPaginationInfo?.pages} onPaginationClick={handlePastOpportunitiesPagination}/>
                                    </TabPane>
                                    <TabPane tabId="4">
                                        {rdDetails?.docs.length === 0 && (
                                            <div className="w-100 my-4 text-center">
                                                <p>Media doesn't exists</p>
                                            </div>
                                        )}
                                        <div className="d-flex flex-wrap mt-2 -mi-10px">
                                            {rdDetails?.docs?.map((item, index) => (
                                                <div key={index}>
                                                    {(item?.document_type === 'png' || item?.document_type === 'jpg' || item?.document_type === 'jpeg') && (
                                                        <div className='media-img media-file'>
                                                            <div className="download-icon cursor-pointer">
                                                                <button className='btn btn-link p-0' onClick={() => setImageViewer({show: true, src: item.document_url})}>
                                                                    <Eye size={16}/>
                                                                </button>
                                                            </div>
                                                            <div className="name-wrap">
                                                                <img className='rounded img-fit-contain' src={item.document_url} alt={item.document_name}
                                                                     style={{height: '100px', width: '100px'}}/>
                                                                <p className="text-truncate mt-1">
                                                                    <strong>{item.document_name}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {item?.document_type === 'pdf' && (
                                                        <div className='media-img media-file'>
                                                            <div className="download-icon cursor-pointer">
                                                                <button className='btn btn-link p-0'
                                                                        onClick={() => setPdfViewer({show: true, url: item.document_url, filename: item.document_name})}>
                                                                    <Eye size={16}/>
                                                                </button>
                                                            </div>
                                                            <div className="name-wrap">
                                                                <img className='rounded png-icon-img' src={PDF} alt='PDF Icon'/>
                                                                <p className="text-truncate mt-1">
                                                                    <strong>{item.document_name}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {(item?.document_type === 'mp4') && (
                                                        <div className='media-img media-file'>
                                                            <div className="download-icon cursor-pointer">
                                                                <button className='btn btn-link p-0'
                                                                        onClick={() => setVideoPlayer({show: true, url: item.document_url, filename: item.document_name})}>
                                                                    <Eye size={16}/>
                                                                </button>
                                                            </div>
                                                            <div className="name-wrap text-center">
                                                                <Video className="text-primary" size={90}/>
                                                                <p className="text-truncate">
                                                                    <strong>{item.document_name}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </TabPane>
                                </TabContent>
                                <Modal isOpen={participateModal} toggle={() => setParticipateModal(!participateModal)} className="modal-dialog-centered">
                                    <ModalHeader toggle={() => setParticipateModal(!participateModal)}>
                                        Select Brand
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            {isBrandsListLoading && (
                                                <div className="w-100 my-4 text-center">
                                                    <Spinner color="primary"/>
                                                </div>
                                            )}
                                            {!isBrandsListLoading && brandsByPastEventId?.length === 0 && (
                                                <div className="w-100 my-4 text-center">
                                                    <p>Record doesn't exists</p>
                                                </div>
                                            )}
                                            {!isBrandsListLoading && (
                                                <Fragment>
                                                    {brandsByPastEventId?.map((brand, index) => (
                                                        <Col lg="6" key={index}>
                                                            <Link to={{pathname: `/dashboard/rd/partnerships/${eventId}/provide-event-feedback/${brand.id}`}}>
                                                                <Card className="my-1">
                                                                    <CardBody className="d-flex align-items-center">
                                                                        <img
                                                                            className="mr-1"
                                                                            src={brand?.brand_logo || img4}
                                                                            alt={brand?.brand_logo || img4}
                                                                            height="60"
                                                                            width="60"
                                                                        />
                                                                        <h5>{brand?.brand_name}</h5>
                                                                    </CardBody>
                                                                </Card>
                                                            </Link>
                                                        </Col>
                                                    ))}
                                                </Fragment>
                                            )}
                                        </Row>
                                    </ModalBody>
                                </Modal>
                            </Fragment>
                        </Col>
                    </Row>
                </Fragment>
            )}
            <Modal isOpen={fbModal} className="modal-dialog-centered">
                <ModalHeader toggle={() => {
                    window.location.href = '/dashboard/rd/view-profile'
                    setFbModal(false)
                }}>
                    Instagram Integration
                </ModalHeader>
                <ModalBody>
                    {fbSteps === '1' && (
                        <>
                            <div className='mb-1'>
                                <strong>Please click on the "Continue" to proceed!</strong>
                            </div>
                            <hr/>
                            <div className='text-right'>
                                <Button.Ripple color='primary' onClick={() => facebookSteps('1', null)} disabled={facebookTokenLoading}>
                                    {facebookTokenLoading ? (
                                        <>
                                            <Spinner style={{height: '14px', width: '14px', marginRight: '5px'}}/>
                                            <span className='text-reset'>Please wait...</span>
                                        </>
                                    ) : (
                                        <>
                                            <ArrowRightCircle size={14}/> <span>Continue</span>
                                        </>
                                    )}
                                </Button.Ripple>
                            </div>
                        </>
                    )}
                    {fbSteps === '2' && (
                        <>
                            <div className='mb-1'>
                                <strong>Please choose your facebook page!</strong>
                            </div>
                            <Formik
                                initialValues={{
                                    page_id: ""
                                }}
                                validationSchema={yup.object().shape({
                                    page_id: yup.string().trim().required("This field is required.")
                                })}
                                onSubmit={(values) => {
                                    dispatch(facebookIgId({
                                        page_id: values?.page_id
                                    }))
                                }}
                            >
                                {({
                                      errors,
                                      handleSubmit,
                                      touched,
                                      setFieldValue
                                  }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <div className='mb-1'>
                                            {facebookTokenData.data.length > 0 ? (
                                                facebookTokenData.data.map((item, index) => (
                                                    <div key={index}>
                                                        <CustomInput
                                                            type='radio'
                                                            className='custom-control-primary'
                                                            id={`ig-account-${index}`}
                                                            name='page_id'
                                                            value={item.id}
                                                            label={item.name}
                                                            onChange={(e) => setFieldValue('page_id', e.target.value)}
                                                        />
                                                        <div className="validation-err">{touched.page_id && errors.page_id}</div>
                                                    </div>
                                                ))) : (
                                                <span>Sorry! Your facebook page not found. Please try to login again</span>
                                            )}
                                        </div>
                                        <hr/>
                                        <div className='text-right'>
                                            <Button.Ripple color='primary' disabled={facebookIgIdLoading} type='submit'>
                                                {facebookIgIdLoading ? (
                                                    <>
                                                        <Spinner style={{height: '14px', width: '14px', marginRight: '5px'}}/>
                                                        <span className='text-reset'>Please wait...</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <ArrowRightCircle size={14}/> <span>Continue</span>
                                                    </>
                                                )}
                                            </Button.Ripple>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    )}
                    {fbSteps === '3' && (
                        <>
                            {facebookIgDataLoading ? (
                                <div className='text-center'>
                                    <Spinner style={{height: '16px', width: '16px', marginRight: '5px'}} color='primary'/>
                                    <span className='text-reset'>Fetching data...</span>
                                </div>
                            ) : (facebookIgDataData?.profile_picture_url ? (
                                <>
                                    <div className='text-center'>
                                        <Avatar img={facebookIgDataData?.profile_picture_url} imgHeight="120" imgWidth="120"/>
                                    </div>
                                    <div className='text-center mt-1 mb-2'>
                                        <strong>{facebookIgDataData?.name}</strong>
                                    </div>
                                    <div className='text-center mt-1 mb-2'>
                                        <span>{facebookIgDataData?.biography}</span>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className='text-center'>
                                            <p className='badge badge-primary'>{facebookIgDataData?.followers_count}</p>
                                            <p>Followers</p>
                                        </div>
                                        <div className='text-center'>
                                            <p className='badge badge-primary'>{facebookIgDataData?.follows_count}</p>
                                            <p>Follows</p>
                                        </div>
                                        <div className='text-center'>
                                            <p className='badge badge-primary'>{facebookIgDataData?.media_count}</p>
                                            <p>Media</p>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className='text-center'>
                                    Sorry! No information found.
                                </div>
                            ))}
                            <hr/>
                            <div className='text-right'>
                                <Button.Ripple color='primary' onClick={() => facebookSteps('3', null)} disabled={facebookIgDataLoading}>
                                    <CheckCircle size={14}/> <span>Done</span>
                                </Button.Ripple>
                            </div>
                        </>
                    )}
                </ModalBody>
            </Modal>
            <ImageViewer
                show={imageViewer.show}
                hide={() => setImageViewer({show: false, src: ''})}
                images={[{src: imageViewer.src}]}
            />
            <PdfViewer show={pdfViewer.show}
                       url={pdfViewer.url}
                       filename={pdfViewer.filename}
                       hide={() => setPdfViewer({show: false, url: '', filename: ''})}
            />
            <VideoPlayer show={videoPlayer.show}
                         url={videoPlayer.url}
                         filename={videoPlayer.filename}
                         hide={() => setVideoPlayer({show: false, url: '', filename: ''})}
            />
        </Fragment>
    )
}

export default RDViewProfile
