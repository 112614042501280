import React, {useState, Fragment, useEffect, useRef} from "react"
import {
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Progress,
    Table,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Form,
    Row,
    FormGroup,
    Col,
    CustomInput,
    Input,
    Label,
    UncontrolledTooltip
} from "reactstrap"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Preview from "@src/assets/images/preview.svg"
import Copy from "@src/assets/images/copy.svg"
import View from "@src/assets/images/eye.svg"
import Edit from "@src/assets/images/edit.svg"
import Publish from "@src/assets/images/publish.svg"
import Delete from "@src/assets/images/delete.svg"
import ClockIcon from "@src/assets/images/clock.svg"
import Share from "@src/assets/images/share.svg"
import HomeIcon from "@src/assets/images/home.svg"
import {
    getBrandGoalsSelectValues
} from '@store/actions/auth'
import "@styles/react/libs/flatpickr/flatpickr.scss"
import {useSelector, useDispatch} from "react-redux"
import CustomPagination from "@src/views/components/pagination/CustomPagination"
import queryString from "query-string"
import moment from "moment"
import * as BSICON from "react-icons/bs"
import {
    allCampaigns,
    campaignSave,
    campaignSaveReset,
    deleteCampaign,
    copyCampaignV2,
    copyCampaignV2Reset,
    campaignStatus,
    campaignStatusReset,
    deleteCampaignReset,
    viewCampaignReset
} from "@store/actions/BrandActions"
import {BsThreeDotsVertical} from "react-icons/bs"
import {Link, useHistory} from "react-router-dom"
import {Formik, useFormik} from "formik"
import * as yup from "yup"
import Select from "react-select"
import {
    campaingsAllListing,
    updateCampaignLiveStatus,
    updateCampaignLiveStatusReset
} from '@src/views/brands/myCampaigns/store/actions'
import momentTime from "moment-timezone"
import classnames from "classnames"
import Flatpickr from "react-flatpickr"
import {Clock, XCircle, X} from "react-feather"
import CircularProgress from "./CircularProgress"
import AddIcon from "@src/assets/images/addIcon.svg"
import {
    SCHEDULE_DATE_OPTIONS,
    CAMPAIGN_STATUS,
    viewCampaign,
    saveCampaign,
    successAlert,
    confirmAlert,
    getQueryObj, showOutreachPage
} from "./Controller"
import FilterIcon from "@src/assets/images/filterBtn.svg"
import {
    getCountries,
    getStates
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import PublishSchedule from "./PublishSchedule"

const steps = [1, 2, 3]

const OutreachPosts = () => {
    const history = useHistory()
    const step2Ref = useRef(null)
    const step3Ref = useRef(null)
    const dispatch = useDispatch()

    const {
        allCampaignsLoading,
        allCampaignsData,
        allCampaignsError,

        campaignSaveLoading,
        campaignSaveData,
        campaignSaveError,

        copyCampaignV2Loading,
        copyCampaignV2Data,
        copyCampaignV2Error,

        campaignStatusLoading,
        campaignStatusData,
        campaignStatusError,

        deleteCampaignLoading,
        deleteCampaignData,
        deleteCampaignError
    } = useSelector(state => state.brandReducer)

    const {
        brandCampaignLiveLoading,
        brandCampaignLiveFullFill,
        brandCampaignLiveError
    } = useSelector(store => store.brandsOppoprunities)

    const [currentStep, setCurrentStep] = useState(1)
    const [createCampaignModal, setCreateCampaignModal] = useState(false)
    const [activeCard, setActiveCard] = useState(null)
    const [stepperFormData, setStepperFormData] = useState({step2: null, step3: null})
    const [disableBtn, setDisableBtn] = useState(false)
    const [publishModal, setPublishModal] = useState(false)
    const [campaignData, setCampaignData] = useState(null)
    const [shareModal, setShareModal] = useState(false)
    const [copied, setCopied] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [statusValue, setStatusValue] = useState(null)
    const [openPubSecModal, setOpenPubSecModal] = useState(false)
    const [openSecModal, setOpenSecModal] = useState(false)
    const [action, setAction] = useState(false)

    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState)

    const shareURLV2 = `${window.location.protocol}//${window.location.host}/rd/apply-on-campaign/`

    const shareURLV1 = `${window.location.protocol}//${window.location.host}/dashboard/rd/campaign-application/`

    const searchForm = useFormik({
        initialValues: {
            query: ''
        },
        validationSchema: yup.object().shape({
            query: yup.string()
        }),
        onSubmit: (values) => {
            dispatch(allCampaigns({
                search: values.query
            }))
            history.push({
                pathname: window.location.pathname,
                search: values.query ? `?query=${values.query}` : ''
            })
        }
    })

    const handleStepClick = (step) => {
        setCurrentStep(step)
    }

    const handleViewCampaign = (campaignId, mode) => {
        history.push(viewCampaign(campaignId, mode))
    }

    const saveCampaignData = async (data) => {
        const values = {...data, ...stepperFormData?.step2, type_id: activeCard?.id}
        const formData = await saveCampaign(values)
        dispatch(campaignSave(formData))
    }

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <Step1 getActiveCard={(res) => {
                    setActiveCard(res)
                    setCurrentStep(2)
                }}
                              activeCard={activeCard}
                />
            case 2:
                return <Step2 ref={step2Ref}
                              submitted={(res) => {
                                  setStepperFormData({...stepperFormData, step2: res})
                                  setCurrentStep(3)
                              }}
                              setData={stepperFormData?.step2}
                              getPartialData={(res) => setStepperFormData({...stepperFormData, step2: res})}
                />
            case 3:
                return <Step3 ref={step3Ref}
                              submitted={(res) => {
                                  setStepperFormData({...stepperFormData, step3: res})
                                  saveCampaignData(res)
                              }}
                              setData={stepperFormData?.step3}
                              campaign={activeCard}
                              getPartialData={(res) => setStepperFormData({...stepperFormData, step3: res})}
                              disableBtn={(res) => setDisableBtn(res)}
                />
        }
    }

    const handleNext = () => {
        const steps = {
            2: step2Ref,
            3: step3Ref
        }
        if (activeCard.id >= 1 && activeCard.id <= 5 && steps[currentStep]?.current) {
            steps[currentStep].current.submitThisForm()
        } else {
            setCurrentStep(2)
        }
        dispatch(viewCampaignReset())
    }

    const handlePrevious = () => {
        setDisableBtn(false)
        if (step2Ref.current) {
            step2Ref.current.getPartialValues()
        }
        if (step3Ref.current) {
            step3Ref.current.getPartialValues()
        }
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1)
        }
    }

    const handleCustomPagination = (pageUrl) => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        dispatch(allCampaigns({
            page: pageNo,
            search: searchForm.values.query,
            filter: statusValue?.value ?? ""
        }))
        const params = []
        if (searchForm.values.query) {
            params.push(`query=${searchForm.values.query}`)
        }
        if (statusValue?.value) {
            params.push(`filter=${statusValue.value}`)
        }
        if (pageNo) {
            params.push(`page=${pageNo}`)
        }
        history.push({
            pathname: window.location.pathname,
            search: `?${params.join('&')}`
        })
    }

    const closeCampaignModal = () => {
        if (step2Ref.current) {
            step2Ref.current.resetThisForm()
        }
        if (step3Ref.current) {
            step3Ref.current.resetThisForm()
        }
        setStepperFormData({
            step2: null,
            step3: null
        })
        setCreateCampaignModal(false)
        setActiveCard(null)
        setCurrentStep(1)
    }

    useEffect(() => {
        const params = getQueryObj()
        if (params?.search) {
            searchForm.setFieldValue('query', query)
        }
        if (params?.filter) {
            setStatusValue({
                label: params?.filter.charAt(0).toUpperCase() + params?.filter.slice(1),
                value: params?.filter
            })
        }
        dispatch(allCampaigns(params))
        dispatch(getBrandGoalsSelectValues())
        dispatch(getCountries())
        dispatch(getStates())
    }, [])

    useEffect(() => {
        if (campaignSaveData?.success) {
            closeCampaignModal()
            history.push(viewCampaign(campaignSaveData?.campaign, false))
            dispatch(campaignSaveReset())
        }

        if (copyCampaignV2Data?.success) {
            successAlert(copyCampaignV2Data?.msg)
            dispatch(copyCampaignV2Reset())
            const parsed = queryString.parseUrl(window.location.href)
            const pageNo = parsed?.query?.page
            const query = parsed?.query?.query
            const filter = parsed?.query?.filter
            const params = {
                search: query,
                filter,
                page: pageNo ?? ''
            }
            dispatch(allCampaigns(params))
        }

        if (campaignStatusData?.status) {
            successAlert(campaignStatusData?.msg)
            dispatch(campaignStatusReset())
            dispatch(allCampaigns(getQueryObj()))
        }

        if (deleteCampaignData?.status) {
            successAlert(deleteCampaignData?.msg)
            dispatch(deleteCampaignReset())
            dispatch(allCampaigns(getQueryObj()))
        }

        if (brandCampaignLiveFullFill?.status) {
            successAlert(brandCampaignLiveFullFill?.msg)
            dispatch(updateCampaignLiveStatusReset())
            dispatch(campaingsAllListing("?list=all"))
            dispatch(allCampaigns(getQueryObj()))
            setCampaignData(null)
            setPublishModal(false)
        }
    }, [
        campaignSaveData,
        copyCampaignV2Data,
        campaignStatusData,
        deleteCampaignData,
        brandCampaignLiveFullFill
    ])

    const handleDeleteCampaign = async (campaignId) => {
        const isConfirmed = await confirmAlert('Delete?', 'Do you really want to delete?')
        if (isConfirmed) {
            dispatch(deleteCampaign(campaignId))
        }
    }

    const handleCopyCampaign = (campaignId) => {
        dispatch(copyCampaignV2(campaignId))
    }

    const updateStatus = async (id) => {
        const isConfirmed = await confirmAlert('Status?', 'Do you really want to update the status?')
        if (isConfirmed) {
            dispatch(campaignStatus({
                campaign_id: id
            }))
        }
    }

    const viewRds = (data) => {
        history.push(`/brands/campaign-applications/${data?.campaign_id}`)
    }

    const copyToClipboard = async (data) => {
        try {
            let text = ''
            if (data?.version) {
                text = `${shareURLV2}${data?.campaign_id}`
            } else {
                text = `${shareURLV1}${campaignData?.campaign_id}`
            }
            await navigator.clipboard.writeText(text)
            setCopied(true)
            setTimeout(() => setCopied(false), 2000)
        } catch (error) {
        }
    }

    const inviteMoreRDs = (items) => {
        setAction('invite')
        setCampaignData(items)
        setOpenPubSecModal(true)
    }

    const applyFilters = () => {
        dispatch(allCampaigns({
            search: searchForm.values.query,
            filter: statusValue.value
        }))
        const params = []
        if (searchForm.values.query) {
            params.push(`query=${searchForm.values.query}`)
        }
        if (statusValue.value) {
            params.push(`filter=${statusValue.value}`)
        }
        history.push({
            pathname: window.location.pathname,
            search: `?${params.join('&')}`
        })
    }

    const clearFilters = () => {
        setDropdownOpen(false)
        setStatusValue(null)
        searchForm.resetForm()
        dispatch(allCampaigns({}))
        history.push({
            pathname: window.location.pathname
        })
    }

    const onSuccess = (msg) => {
        successAlert(msg)
        setOpenPubSecModal(false)
    }

    return (
        <Fragment>
            <div className="d-flex align-items-center justify-content-between pb-2 table-header">
                <h2 className="text-dark m-0 font-weight-bold">My Outreach</h2>
                <div className="d-flex align-items-center">
                    <Link to='/dashboard/brands' className='btn p-0'>
                        <img src={HomeIcon} alt="Home"/>
                    </Link>
                    <div className="px-1">/</div>
                    <div>
                        <Link to='/brands/outreach-posts' className='btn p-0'>
                            My Outreach
                        </Link>
                    </div>
                </div>
            </div>

            <Card className="card-radius-14">
                <CardBody>
                    <div className='topHeader mb-25'>
                        <Form onSubmit={searchForm.handleSubmit}>
                            <FormGroup className='mb-0'>
                                <Input className='searchInput'
                                       type='search'
                                       placeholder='Type and Press Enter...'
                                       value={searchForm.values.query}
                                       onBlur={searchForm.handleBlur}
                                       onChange={searchForm.handleChange}
                                       name="query"
                                       onInput={(e) => {
                                           if (e.target.value === '') {
                                               searchForm.handleSubmit()
                                               setStatusValue(null)
                                           }
                                       }}
                                />
                            </FormGroup>
                        </Form>
                        <div className="actionButtonContainer listing-filter">
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction="down">
                                <DropdownToggle className="p-0 border-0" disabled={allCampaignsLoading}>
                                    <img src={FilterIcon} alt="filter"/>
                                </DropdownToggle>
                                <DropdownMenu className="p-1 w-sm">
                                    <div className='filter-heading my-0 border-bottom pb-1 d-flex justify-content-between align-items-center position-relative'>
                                        <h3 className='filter-heading my-0 font-inter inter-medium'>Filters</h3>
                                        <Button.Ripple className='btn-icon' color='danger' onClick={() => {
                                            clearFilters()
                                        }}>
                                            <X size={16}/>
                                        </Button.Ripple>
                                    </div>
                                    <div>
                                        <FormGroup>
                                            <Select options={CAMPAIGN_STATUS}
                                                    className='react-select'
                                                    classNamePrefix='select'
                                                    value={statusValue}
                                                    onChange={(e) => setStatusValue(e)}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="d-flex justify-content-between flex-column mt-1">
                                        <Button color="primary"
                                                size="sm"
                                                onClick={() => {
                                                    setDropdownOpen(false)
                                                    applyFilters()
                                                }}
                                                className='mb-1 f_size font-inter inter-medium btn-height'
                                        >
                                            Apply Filter
                                        </Button>
                                        <Button outline
                                                color="primary"
                                                className="outline-Btn danger-btn font-inter inter-medium"
                                                onClick={() => {
                                                    clearFilters()
                                                }}
                                        >
                                            Clear All Filter
                                        </Button>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                            <button className='btn font-inter inter-regular d-flex align-items-center blueButton'
                                    onClick={() => setCreateCampaignModal(true)}
                            >
                                <img src={AddIcon} alt="add" className="mr-50"/>
                                Create New Outreach
                            </button>
                        </div>
                    </div>
                    <div>
                        <Table responsive striped className="table-responsive">
                            <tbody>
                            {allCampaignsLoading || copyCampaignV2Loading || campaignStatusLoading || deleteCampaignLoading ? (
                                <tr>
                                    <td colSpan='9' className='text-center text-primary'><Spinner className="loader-md"/> Please wait...</td>
                                </tr>
                            ) : (
                                allCampaignsData?.data?.length > 0 ? (
                                    allCampaignsData?.data?.map((item) => (
                                        <tr key={`campaign-${item?.campaign_id}`}>
                                            <td className="py-50 w-300">
                                                <div className="d-flex align-items-center h-100">
                                                    <CircularProgress percentage={item?.progress ?? 0}/>
                                                    <div className="pl-2">
                                                        <div>
                                                            <UncontrolledTooltip target={`tooltip-${item?.campaign_id}`}>
                                                                {item?.campaign_title}
                                                            </UncontrolledTooltip>
                                                            {(item?.applied > 0 || item?.no_of_rds > 0) ? (
                                                                <span
                                                                    id={`tooltip-${item?.campaign_id}`}

                                                                    className="d-block font-inter f_size inter-medium txt_color mb-25 title-overflow"
                                                                    onClick={() => viewRds(item)}
                                                                >
                                                                {item?.campaign_title}
                                                            </span>
                                                            ) : (
                                                                <span
                                                                    id={`tooltip-${item?.campaign_id}`}
                                                                    className="d-block font-inter f_size inter-medium txt_color mb-25 title-overflow"
                                                                >
                                                                {item?.campaign_title}
                                                            </span>
                                                            )}
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-flex align-items-center pr-2">
                                                                <span className="d-block mt-25 font-inter f_size inter-regular subTxt d-inline-block">
                                                                    {item?.expiry_status}
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-center flex-column h-100 mr-2">
                                                                {item?.tag === 'Disabled' && (
                                                                    <>
                                                                        <UncontrolledTooltip target={`tooltip-tag-${item?.campaign_id}`}>
                                                                            {item?.tag}
                                                                        </UncontrolledTooltip>
                                                                        <span id={`tooltip-tag-${item?.campaign_id}`}
                                                                              className="text-danger font-inter tags tag-overflow"> {item?.tag}</span>
                                                                    </>
                                                                )}

                                                                {item?.tag === 'Expired' && (
                                                                    <>
                                                                        <UncontrolledTooltip target={`tooltip-tag-${item?.campaign_id}`}>
                                                                            {item?.tag}
                                                                        </UncontrolledTooltip>
                                                                        <span id={`tooltip-tag-${item?.campaign_id}`}
                                                                              className="text-danger font-inter tags expire-tag">{item?.tag}</span>
                                                                    </>
                                                                )}

                                                                {(item?.tag === 'Live' || item?.tag === 'Completed') && (
                                                                    <>
                                                                        <UncontrolledTooltip target={`tooltip-tag-${item?.campaign_id}`}>
                                                                            {item?.tag}
                                                                        </UncontrolledTooltip>
                                                                        <span id={`tooltip-tag-${item?.campaign_id}`}
                                                                              className="text-success font-inter tags complete-tag">{item?.tag}</span>
                                                                    </>
                                                                )}

                                                                {item?.tag === 'Draft' && (
                                                                    <>
                                                                        <UncontrolledTooltip target={`tooltip-tag-${item?.campaign_id}`}>
                                                                            {item?.tag}
                                                                        </UncontrolledTooltip>
                                                                        <span id={`tooltip-tag-${item?.campaign_id}`}
                                                                              className="text-warning font-inter tags draft-tag">{item?.tag}</span>
                                                                    </>
                                                                )}

                                                                {item?.tag === 'Scheduled' && (
                                                                    <>
                                                                        <UncontrolledTooltip target={`tooltip-tag-${item?.campaign_id}`}>
                                                                            {item?.tag} on {item?.schedule_time}
                                                                        </UncontrolledTooltip>
                                                                        <span id={`tooltip-tag-${item?.campaign_id}`}
                                                                              className="text-primary font-inter tags schedule-tag">{item?.tag} on {item?.schedule_time}</span>

                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center align-self-end">
                                                        <CustomInput
                                                            className={(item?.tag === 'Draft' || item?.tag === 'Scheduled' || item?.tag === 'Expired' || item?.tag === 'Completed') ? 'custom-control-warning custom-switch-sm' : 'custom-control-success custom-switch-sm'}
                                                            type='switch'
                                                            id={`switch-${item.campaign_id}`}
                                                            name={`switch-${item.campaign_id}`}
                                                            inline={true}
                                                            checked={item?.status === 'enabled'}
                                                            onChange={() => {
                                                                updateStatus(item?.campaign_id)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-center flex-column h-100 txt_color font-inter f_size inter-medium">
                                                    {item?.new_applicants > 0 ? (
                                                        <Link to={`/brands/campaign-applications/${item?.campaign_id}`}>
                                                            {item?.new_applicants}
                                                        </Link>
                                                    ) : (
                                                        item?.new_applicants
                                                    )}
                                                    <span className="d-block txt_color f_size inter-regular font-inter">New Applicants</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    className="d-flex align-items-center justify-content-center flex-column h-100 font-inter f_size inter-medium txt_color">{item?.clicks}
                                                    <span className="d-block mt-25 font-inter f_size inter-regular txt_color">Views</span></div>
                                            </td>
                                            {/* <td>
                                                <div
                                                    className="d-flex align-items-center justify-content-center flex-column h-100 font-inter f_size inter-medium txt_color">{item?.views}
                                                    <span className="d-block mt-25 font-inter f_size inter-regular txt_color">Views</span></div>
                                            </td>
                                            <td>
                                                <div
                                                    className="d-flex align-items-center justify-content-center flex-column h-100 font-inter f_size inter-medium txt_color">{item?.clicks}
                                                    <span className="d-block  mt-25 font-inter f_size inter-regular txt_color">Clicks</span></div>
                                            </td> */}
                                            <td>
                                                <div className="d-flex align-items-center justify-content-center flex-column h-100 font-inter f_size inter-medium txt_color">
                                                    {item?.applied > 0 ? (
                                                        <Link to={`/brands/campaign-applications/${item?.campaign_id}`}>
                                                            {item?.applied}
                                                        </Link>
                                                    ) : (
                                                        item?.no_of_rds > 0 ? (
                                                            <Link to={`/dashboard/brands/campaign-detail/${item?.campaign_id}`}>
                                                                {item?.no_of_rds}
                                                            </Link>
                                                        ) : (
                                                            item?.applied
                                                        )
                                                    )}
                                                    <span className="d-block mt-25 font-inter f_size inter-regular txt_color">Applied</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    className="d-flex align-items-center justify-content-center flex-column h-100 txt_color font-inter f_size inter-medium">{item?.remaining_days}
                                                    <span className="d-block mt-25 font-inter f_size inter-regular txt_color">Days Remaining</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-center flex-column h-100">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle className='cursor-pointer' tag='span'>
                                                            <BsThreeDotsVertical size={20}/>
                                                        </DropdownToggle>
                                                        <DropdownMenu container="body" className="dropdown-menu-container">
                                                            <DropdownItem onClick={() => handleViewCampaign(item?.campaign_id, true)} className="d-flex align-items-center">
                                                                <img src={Preview} height={20} width={20} alt="Preview"/>
                                                                <div className="pl-1 text-dark font-inter inter-regular">Preview</div>
                                                            </DropdownItem>
                                                            <DropdownItem className={`d-flex align-items-center`} onClick={() => handleCopyCampaign(item?.campaign_id)}>
                                                                <img src={Copy} height={20} width={20} alt="Copy"/>
                                                                <div className="pl-1 text-dark font-inter inter-regular">Copy</div>
                                                            </DropdownItem>
                                                            {(item?.applied > 0 || item?.no_of_rds > 0) && (
                                                                <DropdownItem className="d-flex align-items-center" onClick={() => viewRds(item)}>
                                                                    <img src={View} height={20} width={20} alt="View RDs"/>
                                                                    <div className="pl-1 text-dark font-inter inter-regular">View Results</div>
                                                                </DropdownItem>
                                                            )}
                                                            {item?.is_live === 1 && item?.tag === 'Live' && (
                                                                <DropdownItem onClick={() => inviteMoreRDs(item)} className="d-flex align-items-center"
                                                                              disabled={allCampaignsLoading}>
                                                                    <BSICON.BsPerson size={20} color='black'/>
                                                                    <div className="pl-1 text-dark font-inter inter-regular">Invite More RDs</div>
                                                                </DropdownItem>
                                                            )}
                                                            {item?.is_live === 1 && item?.tag === 'Live' && (
                                                                <DropdownItem className="d-flex align-items-center" onClick={() => {
                                                                    setCampaignData(item)
                                                                    setShareModal(true)
                                                                }}>
                                                                    <img src={Share} height={20} width={20} alt="View RDs"/>
                                                                    <div className="pl-1 text-dark font-inter inter-regular">Share</div>
                                                                </DropdownItem>
                                                            )}
                                                            {(item?.is_live !== 1 || item?.tag !== 'Live') && (
                                                                <DropdownItem className="d-flex align-items-center" onClick={() => {
                                                                    dispatch(viewCampaignReset())
                                                                    history.push(`/brands/review-partnership-details/${item?.campaign_id}`)
                                                                }}>
                                                                    <img src={Edit} height={20} width={20} alt="Edit"/>
                                                                    <div className="pl-1 text-dark font-inter inter-regular">Edit</div>
                                                                </DropdownItem>
                                                            )}
                                                            {item?.tag === 'Draft' && (
                                                                <DropdownItem className="d-flex align-items-center" onClick={() => {
                                                                    dispatch(viewCampaignReset())
                                                                    handleViewCampaign(item?.campaign_id, false)
                                                                }}>
                                                                    <img src={Publish} height={20} width={20} alt="Publish"/>
                                                                    <div className="pl-1 text-dark font-inter inter-regular">Publish</div>
                                                                </DropdownItem>
                                                            )}
                                                            {item?.schedule && (
                                                                <DropdownItem className="d-flex align-items-center" onClick={() => {
                                                                    setCampaignData(item)
                                                                    setPublishModal(true)
                                                                }}>
                                                                    <img src={ClockIcon} height={20} width={20} alt="Publish"/>
                                                                    <div className="pl-1 text-dark font-inter inter-regular">Modify Schedule</div>
                                                                </DropdownItem>
                                                            )}
                                                            <DropdownItem className="d-flex align-items-center text-danger" onClick={() => handleDeleteCampaign(item?.campaign_id)}>
                                                                <img src={Delete} height={20} width={20} alt="Delete"/>
                                                                <div className="pl-1 font-inter inter-regular">Delete</div>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan='9' className='text-center font-inter inter-medium'>No Outreach found</td>
                                    </tr>
                                )
                            )}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-between align-items-center">
                            {allCampaignsData?.meta && (
                                <span className='font-inter inter-medium w-100'>
                                    Showing {allCampaignsData?.meta?.from} - {allCampaignsData?.meta?.to} of {allCampaignsData?.meta?.total}
                                </span>
                            )}
                            <CustomPagination pageLinks={allCampaignsData?.pages} onPaginationClick={handleCustomPagination}/>
                        </div>
                    </div>
                </CardBody>
            </Card>

            {/* --- Create Campaign Modal --- */}
            <Modal size="lg" isOpen={createCampaignModal} className="modal-dialog-centered">
                <ModalHeader toggle={closeCampaignModal}>
                    <div className="font-inter f_size inter-medium txt_color mailing-txt">
                        Create New Outreach Post
                    </div>
                </ModalHeader>
                <ModalBody className="px-2">
                    <div className="d-flex justify-content-center align-items-center py-1">
                        <div className="d-flex justify-content-between align-items-center position-relative stepper-container">
                            {steps.map((step) => (
                                <div key={step}
                                     onClick={() => handleStepClick(step)}
                                     className={`rounded-circle d-flex align-items-center justify-content-center ${currentStep >= step ? "bg-primary text-white" : "bg-light text-dark"}`}
                                     style={{width: 40, height: 40, cursor: "pointer", zIndex: 2}}>
                                    {step}
                                </div>
                            ))}
                            <Progress
                                value={currentStep === 1 ? 27 : currentStep === 2 ? 60 : currentStep === 3 ? 60 : 0}
                                color={currentStep === 1 ? "grey" : "primary"}
                                style={{
                                    position: "absolute",
                                    background: currentStep === 2 ? "linear-gradient(to right, #56b6ff 45%, #ededed 28%)" : "#ededed",
                                    top: "50%",
                                    left: "7%",
                                    width: "20%",
                                    height: 5,
                                    zIndex: 1,
                                    transition: "opacity 0.3s ease-in-out"
                                }}
                            />
                        </div>
                    </div>
                    {renderStep()}
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        {currentStep > 1 && (
                            <Button className="outline-Btn font-inter inter-regular f_size"
                                    outline
                                    onClick={handlePrevious}
                                    disabled={campaignSaveLoading}
                            >
                                Previous
                            </Button>
                        )}
                        <div className="d-flex justify-content-end w-100">
                            <Button.Ripple type="button"
                                           variant="outline"
                                           onClick={handleNext}
                                           disabled={!activeCard || campaignSaveLoading || disableBtn}
                                           className="bgBlue font-inter inter-regular f_size"
                            >
                                {campaignSaveLoading && (
                                    <Spinner className="loader-sm"/>
                                )} {currentStep >= 3 ? 'Preview Campaign' : 'Next'}
                            </Button.Ripple>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>

            {/* --- Publish Campaign Modal --- */}
            <Modal isOpen={publishModal} className="modal-dialog-centered" size='lg'>
                <ModalHeader>Publish ({campaignData?.short_campaign_title})</ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{
                            publish_method: "",
                            publish_datetime: ""
                        }}
                        validationSchema={yup.object().shape({
                            publish_method: yup.string().trim().required("This field is required"),
                            publish_datetime: yup.array().when("publish_method", {
                                is: 'schedule',
                                then: yup.array().min(1, 'This field is required.').required("This field is required.")
                            })
                        })}
                        onSubmit={(values) => {
                            dispatch(updateCampaignLiveStatus({
                                campaign_id: campaignData?.campaign_id,
                                publish_method: values.publish_method,
                                publish_datetime: values.publish_datetime ? moment(values.publish_datetime[0]).format('YYYY-MM-DD kk:mm:ss') : values.publish_datetime,
                                time_zone: momentTime.tz.guess()
                            }))
                        }}
                    >
                        {({
                              errors,
                              handleSubmit,
                              setFieldValue,
                              touched,
                              values,
                              resetForm
                          }) => (
                            <Form className='auth-reset-password-form' onSubmit={handleSubmit} autoComplete="off">
                                <Row>
                                    {campaignData?.schedule_time && (
                                        <FormGroup tag={Col} md="12">
                                            <div className='font-medium-2 text-primary'><strong>Current Schedule:</strong></div>
                                            <div className='d-flex justify-content-between mt-1'>
                                                <div>
                                                    <div className='mb-1'><strong>Publish Method</strong></div>
                                                    <div><span className='badge badge-info'>Scheduled</span></div>
                                                </div>
                                                <div>
                                                    <div className='mb-1'><strong>Publish Date</strong></div>
                                                    <div><span className='badge badge-info'>{campaignData?.schedule_time}</span></div>
                                                </div>
                                                <div>
                                                    <div className='mb-1'><strong>Expiry Datetime</strong></div>
                                                    <div>
                                                        <span className='badge badge-info'>
                                                            {campaignData?.expire_after ? moment(campaignData?.expire_after).format("DD MMM, YYYY HH:mm") : ""}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className='font-medium-2 text-primary'><strong>Modify:</strong></div>
                                        </FormGroup>
                                    )}

                                    <FormGroup tag={Col} md="12">
                                        <Label
                                            className={classnames({'is-invalid': touched.publish_method && errors.publish_method})}>
                                            <span><strong>Choose a publish method</strong></span>
                                            <span className="is-invalid">*</span>
                                        </Label>
                                        <Input
                                            type="select"
                                            name="publish_method"
                                            value={values.publish_method}
                                            id="publish_method"
                                            className={classnames({'is-invalid': touched.publish_method && errors.publish_method})}
                                            onChange={(e) => {
                                                setFieldValue('publish_method', e.target.value)
                                                setFieldValue('publish_datetime', [])
                                                setFieldValue('expire_after', [])
                                            }}
                                            disabled={brandCampaignLiveLoading}
                                        >
                                            <option value="">--- Select ---</option>
                                            <option value="instant">Instant Publish</option>
                                            <option value="schedule">Schedule Publish</option>
                                        </Input>
                                        <div className="validation-err">{touched.publish_method && errors.publish_method}</div>
                                    </FormGroup>

                                    {values.publish_method === 'schedule' && (
                                        <>
                                            <FormGroup tag={Col} md="12">
                                                <Label
                                                    className={classnames({'is-invalid': touched.publish_datetime && errors.publish_datetime})}>
                                                    <span><strong>Choose a publish Date</strong></span>
                                                    <span className="is-invalid">*</span>
                                                </Label>
                                                <Flatpickr
                                                    id="publish_datetime"
                                                    value={values.publish_datetime}
                                                    className={classnames('form-control flatPicker', {'is-invalid flatPicker': touched.publish_datetime && errors.publish_datetime})}
                                                    options={SCHEDULE_DATE_OPTIONS}
                                                    onChange={(e) => {
                                                        setFieldValue('publish_datetime', e)
                                                        setFieldValue('expire_after', [])
                                                    }}
                                                    placeholder="Publish Date"
                                                    disabled={brandCampaignLiveLoading}
                                                />
                                                <div className="validation-err">{touched.publish_datetime && errors.publish_datetime}</div>
                                            </FormGroup>
                                            <FormGroup tag={Col} md="12">
                                                <Label className='bg-light p-1 w-100 text-danger font-small-3'>
                                                    <strong>Note: </strong>The outreach will be published according to your local timezone: {momentTime.tz.guess()}
                                                </Label>
                                            </FormGroup>
                                        </>
                                    )}

                                    <FormGroup tag={Col} md="12">
                                        <hr/>
                                    </FormGroup>

                                    <FormGroup className="d-flex justify-content-between" tag={Col} md="12">
                                        <Button.Ripple type='button'
                                                       color='danger'
                                                       onClick={() => {
                                                           setPublishModal(false)
                                                           setCampaignData(null)
                                                           resetForm()
                                                       }}
                                                       disabled={brandCampaignLiveLoading}
                                        >
                                            <span><XCircle size={14}/> Close</span>
                                        </Button.Ripple>
                                        <Button.Ripple type='submit' color='primary' disabled={brandCampaignLiveLoading}>
                                            {brandCampaignLiveLoading ? (
                                                <Spinner className="loader-sm"/>
                                            ) : (
                                                <span><Clock size={14}/> Submit</span>
                                            )}
                                        </Button.Ripple>
                                    </FormGroup>
                                </Row>
                            </Form>)}
                    </Formik>
                </ModalBody>
            </Modal>

            {/* --- Share Campaign Modal --- */}
            <Modal isOpen={shareModal} className="modal-dialog-centered">
                <ModalHeader toggle={() => {
                    setShareModal(false)
                    setCampaignData(null)
                }}>
                    Share Outreach
                </ModalHeader>
                <ModalBody className="modal-height">
                    <div className='p-1 text-center'>
                        <div className='mt-1 mb-2'>
                            <p className='font-large-1 font-weight-bold'>Share your outreach</p>
                        </div>
                        <div>
                            <p>Please copy this link and share it with the RDs.</p>
                        </div>
                        <div className='mt-2 mb-2'>
                            <p className='font-medium-1 font-weight-bold bg-light p-1'>
                                {campaignData?.version ? `${shareURLV2}${campaignData?.campaign_id}` : `${shareURLV2}${campaignData?.campaign_id}`}
                            </p>
                        </div>
                        <div>
                            <button className='btn btn-primary' onClick={() => copyToClipboard(campaignData)} disabled={copied}>
                                {copied ? "Copied!" : "Copy to Clipboard"}
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* --- Invite Modal --- */}
            <PublishSchedule openPubSecModal={openPubSecModal}
                             setOpenPubSecModal={(status) => setOpenPubSecModal(status)}
                             openSecModal={openSecModal}
                             setOpenSecModal={(status) => setOpenSecModal(status)}
                             data={[]}
                             onSuccess={(res) => {
                                 onSuccess(res)
                             }}
                             id={campaignData?.campaign_id}
                             action={action}
            />
        </Fragment>
    )
}

export default OutreachPosts
