import {BRAND_ACTION_TYPES} from "@store/actionTypes/BrandActionTypes"

const initialState = {
    saveCreditCardLoading: false,
    saveCreditCardData: null,
    saveCreditCardError: null,

    verifyCouponCodeLoading: false,
    verifyCouponCodeData: null,
    verifyCouponCodeError: null,

    activatePlanLoading: false,
    activatePlanData: null,
    activatePlanError: null,

    viewProfileLoading: false,
    viewProfileData: null,
    viewProfileError: null,

    saveProfileLoading: false,
    saveProfileData: null,
    saveProfileError: null,

    rdGroupListLoading: false,
    rdGroupListData: null,
    rdGroupListError: null,

    rdIgDataLoading: false,
    rdIgDataData: null,
    rdIgDataError: null,

    copyCampaignLoading: false,
    copyCampaignData: null,
    copyCampaignError: null,

    massMsgTempSaveLoading: false,
    massMsgTempSaveData: null,
    massMsgTempSaveError: null,

    massMsgTempListLoading: false,
    massMsgTempListData: null,
    massMsgTempListError: null,

    massMsgTempDeleteLoading: false,
    massMsgTempDeleteData: null,
    massMsgTempDeleteError: null,

    markAsReadLoading: false,
    markAsReadData: null,
    markAsReadError: null,

    campaignTypesLoading: false,
    campaignTypesData: null,
    campaignTypesError: null,

    allCampaignsLoading: false,
    allCampaignsData: null,
    allCampaignsError: null,

    campaignSaveLoading: false,
    campaignSaveData: null,
    campaignSaveError: null,

    campaignViewLoading: false,
    campaignViewData: null,
    campaignViewError: null,

    recRDCampaignLoading: false,
    recRDCampaignData: null,
    recRDCampaignError: null,

    secPubCampaignLoading: false,
    secPubCampaignData: null,
    secPubCampaignError: null,

    campaignAppLoading: false,
    campaignAppData: null,
    campaignAppError: null,

    copyCampaignV2Loading: false,
    copyCampaignV2Data: null,
    copyCampaignV2Error: null,

    campaignStatusLoading: false,
    campaignStatusData: null,
    campaignStatusError: null,

    deleteCampaignLoading: false,
    deleteCampaignData: null,
    deleteCampaignError: null,

    getFavFolderLoading: false,
    getFavFolderData: null,
    getFavFolderError: null,

    getFavProfileLoading: false,
    getFavProfileData: null,
    getFavProfileError: null,

    myRDsLoading: false,
    myRDsData: null,
    myRDsError: null
}

const brandReducer = (state = initialState, action) => {
    switch (action.type) {
        case BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_REQUEST:
            return {
                ...state,
                saveCreditCardLoading: true,
                saveCreditCardData: null,
                saveCreditCardError: null
            }
        case BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_DATA:
            return {
                ...state,
                saveCreditCardLoading: false,
                saveCreditCardData: action.payload,
                saveCreditCardError: null
            }
        case BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_ERROR:
            return {
                ...state,
                saveCreditCardLoading: false,
                saveCreditCardData: null,
                saveCreditCardError: action.payload
            }
        case BRAND_ACTION_TYPES.SAVE_CREDIT_CARD_RESET:
            return {
                ...state,
                saveCreditCardLoading: false,
                saveCreditCardData: null,
                saveCreditCardError: null
            }

        case BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_REQUEST:
            return {
                ...state,
                verifyCouponCodeLoading: true,
                verifyCouponCodeData: null,
                verifyCouponCodeError: null
            }
        case BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_DATA:
            return {
                ...state,
                verifyCouponCodeLoading: false,
                verifyCouponCodeData: action.payload,
                verifyCouponCodeError: null
            }
        case BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_ERROR:
            return {
                ...state,
                verifyCouponCodeLoading: false,
                verifyCouponCodeData: null,
                verifyCouponCodeError: action.payload
            }
        case BRAND_ACTION_TYPES.VERIFY_COUPON_CODE_RESET:
            return {
                ...state,
                verifyCouponCodeLoading: false,
                verifyCouponCodeData: null,
                verifyCouponCodeError: null
            }

        case BRAND_ACTION_TYPES.ACTIVATE_PLAN_REQUEST:
            return {
                ...state,
                activatePlanLoading: true,
                activatePlanData: null,
                activatePlanError: null
            }
        case BRAND_ACTION_TYPES.ACTIVATE_PLAN_DATA:
            return {
                ...state,
                activatePlanLoading: false,
                activatePlanData: action.payload,
                activatePlanError: null
            }
        case BRAND_ACTION_TYPES.ACTIVATE_PLAN_ERROR:
            return {
                ...state,
                activatePlanLoading: false,
                activatePlanData: null,
                activatePlanError: action.payload
            }
        case BRAND_ACTION_TYPES.ACTIVATE_PLAN_RESET:
            return {
                ...state,
                activatePlanLoading: false,
                activatePlanData: null,
                activatePlanError: null
            }

        case BRAND_ACTION_TYPES.VIEW_PROFILE_REQUEST:
            return {
                ...state,
                viewProfileLoading: true,
                viewProfileData: null,
                viewProfileError: null
            }
        case BRAND_ACTION_TYPES.VIEW_PROFILE_DATA:
            return {
                ...state,
                viewProfileLoading: false,
                viewProfileData: action.payload,
                viewProfileError: null
            }
        case BRAND_ACTION_TYPES.VIEW_PROFILE_ERROR:
            return {
                ...state,
                viewProfileLoading: false,
                viewProfileData: null,
                viewProfileError: action.payload
            }

        case BRAND_ACTION_TYPES.SAVE_PROFILE_REQUEST:
            return {
                ...state,
                saveProfileLoading: true,
                saveProfileData: null,
                saveProfileError: null
            }
        case BRAND_ACTION_TYPES.SAVE_PROFILE_DATA:
            return {
                ...state,
                saveProfileLoading: false,
                saveProfileData: action.payload,
                saveProfileError: null
            }
        case BRAND_ACTION_TYPES.SAVE_PROFILE_ERROR:
            return {
                ...state,
                saveProfileLoading: false,
                saveProfileData: null,
                saveProfileError: action.payload
            }
        case BRAND_ACTION_TYPES.SAVE_PROFILE_RESET:
            return {
                ...state,
                saveProfileLoading: false,
                saveProfileData: null,
                saveProfileError: null
            }

        case BRAND_ACTION_TYPES.RD_GROUP_LIST_REQUEST:
            return {
                ...state,
                rdGroupListLoading: true,
                rdGroupListData: null,
                rdGroupListError: null
            }
        case BRAND_ACTION_TYPES.RD_GROUP_LIST_DATA:
            return {
                ...state,
                rdGroupListLoading: false,
                rdGroupListData: action.payload,
                rdGroupListError: null
            }
        case BRAND_ACTION_TYPES.RD_GROUP_LIST_ERROR:
            return {
                ...state,
                rdGroupListLoading: false,
                rdGroupListData: null,
                rdGroupListError: action.payload
            }
        case BRAND_ACTION_TYPES.RD_GROUP_LIST_RESET:
            return {
                ...state,
                rdGroupListLoading: false,
                rdGroupListData: null,
                rdGroupListError: null
            }

        case BRAND_ACTION_TYPES.RD_IG_DATA_REQUEST:
            return {
                ...state,
                rdIgDataLoading: true,
                rdIgDataData: null,
                rdIgDataError: null
            }
        case BRAND_ACTION_TYPES.RD_IG_DATA_DATA:
            return {
                ...state,
                rdIgDataLoading: false,
                rdIgDataData: action.payload,
                rdIgDataError: null
            }
        case BRAND_ACTION_TYPES.RD_IG_DATA_ERROR:
            return {
                ...state,
                rdIgDataLoading: false,
                rdIgDataData: null,
                rdIgDataError: action.payload
            }

        case BRAND_ACTION_TYPES.COPY_CAMPAIGN_REQUEST:
            return {
                ...state,
                copyCampaignLoading: true,
                copyCampaignData: null,
                copyCampaignError: null
            }
        case BRAND_ACTION_TYPES.COPY_CAMPAIGN_DATA:
            return {
                ...state,
                copyCampaignLoading: false,
                copyCampaignData: action.payload,
                copyCampaignError: null
            }
        case BRAND_ACTION_TYPES.COPY_CAMPAIGN_ERROR:
            return {
                ...state,
                copyCampaignLoading: false,
                copyCampaignData: null,
                copyCampaignError: action.payload
            }
        case BRAND_ACTION_TYPES.COPY_CAMPAIGN_RESET:
            return {
                ...state,
                copyCampaignLoading: false,
                copyCampaignData: null,
                copyCampaignError: null
            }

        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_REQUEST:
            return {
                ...state,
                massMsgTempSaveLoading: true,
                massMsgTempSaveData: null,
                massMsgTempSaveError: null
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_DATA:
            return {
                ...state,
                massMsgTempSaveLoading: false,
                massMsgTempSaveData: action.payload,
                massMsgTempSaveError: null
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_ERROR:
            return {
                ...state,
                massMsgTempSaveLoading: false,
                massMsgTempSaveData: null,
                massMsgTempSaveError: action.payload
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_SAVE_RESET:
            return {
                ...state,
                massMsgTempSaveLoading: false,
                massMsgTempSaveData: null,
                massMsgTempSaveError: null
            }

        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_LIST_REQUEST:
            return {
                ...state,
                massMsgTempListLoading: true,
                massMsgTempListData: null,
                massMsgTempListError: null
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_LIST_DATA:
            return {
                ...state,
                massMsgTempListLoading: false,
                massMsgTempListData: action.payload,
                massMsgTempListError: null
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_LIST_ERROR:
            return {
                ...state,
                massMsgTempListLoading: false,
                massMsgTempListData: null,
                massMsgTempListError: action.payload
            }

        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_REQUEST:
            return {
                ...state,
                massMsgTempDeleteLoading: true,
                massMsgTempDeleteData: null,
                massMsgTempDeleteError: null
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_DATA:
            return {
                ...state,
                massMsgTempDeleteLoading: false,
                massMsgTempDeleteData: action.payload,
                massMsgTempDeleteError: null
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_ERROR:
            return {
                ...state,
                massMsgTempDeleteLoading: false,
                massMsgTempDeleteData: null,
                massMsgTempDeleteError: action.payload
            }
        case BRAND_ACTION_TYPES.MASS_MSG_TEMP_DELETE_RESET:
            return {
                ...state,
                massMsgTempDeleteLoading: false,
                massMsgTempDeleteData: null,
                massMsgTempDeleteError: null
            }

        case BRAND_ACTION_TYPES.MARK_AS_READ_REQUEST:
            return {
                ...state,
                markAsReadLoading: true,
                markAsReadData: null,
                markAsReadError: null
            }
        case BRAND_ACTION_TYPES.MARK_AS_READ_DATA:
            return {
                ...state,
                markAsReadLoading: false,
                markAsReadData: action.payload,
                markAsReadError: null
            }
        case BRAND_ACTION_TYPES.MARK_AS_READ_ERROR:
            return {
                ...state,
                markAsReadLoading: false,
                markAsReadData: null,
                markAsReadError: action.payload
            }
        case BRAND_ACTION_TYPES.MARK_AS_READ_RESET:
            return {
                ...state,
                markAsReadLoading: false,
                markAsReadData: null,
                markAsReadError: null
            }

        case BRAND_ACTION_TYPES.CAMPAIGN_TYPE_REQUEST:
            return {
                ...state,
                campaignTypesLoading: true,
                campaignTypesData: null,
                campaignTypesError: null
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_TYPE_DATA:
            return {
                ...state,
                campaignTypesLoading: false,
                campaignTypesData: action.payload,
                campaignTypesError: null
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_TYPE_ERROR:
            return {
                ...state,
                campaignTypesLoading: false,
                campaignTypesData: null,
                campaignTypesError: action.payload
            }

        case BRAND_ACTION_TYPES.CAMPAIGN_LISTING_REQUEST:
            return {
                ...state,
                allCampaignsLoading: true,
                allCampaignsData: null,
                allCampaignsError: null
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_LISTING_DATA:
            return {
                ...state,
                allCampaignsLoading: false,
                allCampaignsData: action.payload,
                allCampaignsError: null
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_LISTING_ERROR:
            return {
                ...state,
                allCampaignsLoading: false,
                allCampaignsData: null,
                allCampaignsError: action.payload
            }

        case BRAND_ACTION_TYPES.CAMPAIGN_SAVE_REQUEST:
            return {
                ...state,
                campaignSaveLoading: true,
                campaignSaveData: null,
                campaignSaveError: null
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_SAVE_DATA:
            return {
                ...state,
                campaignSaveLoading: false,
                campaignSaveData: action.payload,
                campaignSaveError: null
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_SAVE_ERROR:
            return {
                ...state,
                campaignSaveLoading: false,
                campaignSaveData: null,
                campaignSaveError: action.payload
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_SAVE_RESET:
            return {
                ...state,
                campaignSaveLoading: false,
                campaignSaveData: null,
                campaignSaveError: null
            }

        case BRAND_ACTION_TYPES.CAMPAIGN_VIEW_REQUEST:
            return {
                ...state,
                campaignViewLoading: true,
                campaignViewData: null,
                campaignViewError: null
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_VIEW_DATA:
            return {
                ...state,
                campaignViewLoading: false,
                campaignViewData: action.payload,
                campaignViewError: null
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_VIEW_ERROR:
            return {
                ...state,
                campaignViewLoading: false,
                campaignViewData: null,
                campaignViewError: action.payload
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_VIEW_RESET:
            return {
                ...state,
                campaignViewLoading: false,
                campaignViewData: null,
                campaignViewError: null
            }

        case BRAND_ACTION_TYPES.REC_RD_CAMPAIGN_REQUEST:
            return {
                ...state,
                recRDCampaignLoading: true,
                recRDCampaignData: null,
                recRDCampaignError: null
            }
        case BRAND_ACTION_TYPES.REC_RD_CAMPAIGN_DATA:
            return {
                ...state,
                recRDCampaignLoading: false,
                recRDCampaignData: action.payload,
                recRDCampaignError: null
            }
        case BRAND_ACTION_TYPES.REC_RD_CAMPAIGN_ERROR:
            return {
                ...state,
                recRDCampaignLoading: false,
                recRDCampaignData: null,
                recRDCampaignError: action.payload
            }
        case BRAND_ACTION_TYPES.REC_RD_CAMPAIGN_RESET:
            return {
                ...state,
                recRDCampaignLoading: false,
                recRDCampaignData: null,
                recRDCampaignError: null
            }

        case BRAND_ACTION_TYPES.SEC_PUB_CAMPAIGN_REQUEST:
            return {
                ...state,
                secPubCampaignLoading: true,
                secPubCampaignData: null,
                secPubCampaignError: null
            }
        case BRAND_ACTION_TYPES.SEC_PUB_CAMPAIGN_DATA:
            return {
                ...state,
                secPubCampaignLoading: false,
                secPubCampaignData: action.payload,
                secPubCampaignError: null
            }
        case BRAND_ACTION_TYPES.SEC_PUB_CAMPAIGN_ERROR:
            return {
                ...state,
                secPubCampaignLoading: false,
                secPubCampaignData: null,
                secPubCampaignError: action.payload
            }
        case BRAND_ACTION_TYPES.SEC_PUB_CAMPAIGN_RESET:
            return {
                ...state,
                secPubCampaignLoading: false,
                secPubCampaignData: null,
                secPubCampaignError: null
            }

        case BRAND_ACTION_TYPES.CAMPAIGN_APP_REQUEST:
            return {
                ...state,
                campaignAppLoading: true,
                campaignAppData: null,
                campaignAppError: null
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_APP_DATA:
            return {
                ...state,
                campaignAppLoading: false,
                campaignAppData: action.payload,
                campaignAppError: null
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_APP_ERROR:
            return {
                ...state,
                campaignAppLoading: false,
                campaignAppData: null,
                campaignAppError: action.payload
            }

        case BRAND_ACTION_TYPES.COPY_CAMPAIGN_V2_REQUEST:
            return {
                ...state,
                copyCampaignV2Loading: true,
                copyCampaignV2Data: null,
                copyCampaignV2Error: null
            }
        case BRAND_ACTION_TYPES.COPY_CAMPAIGN_V2_DATA:
            return {
                ...state,
                copyCampaignV2Loading: false,
                copyCampaignV2Data: action.payload,
                copyCampaignV2Error: null
            }
        case BRAND_ACTION_TYPES.COPY_CAMPAIGN_V2_ERROR:
            return {
                ...state,
                copyCampaignV2Loading: false,
                copyCampaignV2Data: null,
                copyCampaignV2Error: action.payload
            }
        case BRAND_ACTION_TYPES.COPY_CAMPAIGN_V2_RESET:
            return {
                ...state,
                copyCampaignV2Loading: false,
                copyCampaignV2Data: null,
                copyCampaignV2Error: null
            }

        case BRAND_ACTION_TYPES.CAMPAIGN_STATUS_REQUEST:
            return {
                ...state,
                campaignStatusLoading: true,
                campaignStatusData: null,
                campaignStatusError: null
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_STATUS_DATA:
            return {
                ...state,
                campaignStatusLoading: false,
                campaignStatusData: action.payload,
                campaignStatusError: null
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_STATUS_ERROR:
            return {
                ...state,
                campaignStatusLoading: false,
                campaignStatusData: null,
                campaignStatusError: action.payload
            }
        case BRAND_ACTION_TYPES.CAMPAIGN_STATUS_RESET:
            return {
                ...state,
                campaignStatusLoading: false,
                campaignStatusData: null,
                campaignStatusError: null
            }

        case BRAND_ACTION_TYPES.DELETE_CAMPAIGN_REQUEST:
            return {
                ...state,
                deleteCampaignLoading: true,
                deleteCampaignData: null,
                deleteCampaignError: null
            }
        case BRAND_ACTION_TYPES.DELETE_CAMPAIGN_DATA:
            return {
                ...state,
                deleteCampaignLoading: false,
                deleteCampaignData: action.payload,
                deleteCampaignError: null
            }
        case BRAND_ACTION_TYPES.DELETE_CAMPAIGN_ERROR:
            return {
                ...state,
                deleteCampaignLoading: false,
                deleteCampaignData: null,
                deleteCampaignError: action.payload
            }
        case BRAND_ACTION_TYPES.DELETE_CAMPAIGN_RESET:
            return {
                ...state,
                deleteCampaignLoading: false,
                deleteCampaignData: null,
                deleteCampaignError: null
            }

        case BRAND_ACTION_TYPES.GET_FAV_FOLDER_REQUEST:
            return {
                ...state,
                getFavFolderLoading: true,
                getFavFolderData: null,
                getFavFolderError: null
            }
        case BRAND_ACTION_TYPES.GET_FAV_FOLDER_DATA:
            return {
                ...state,
                getFavFolderLoading: false,
                getFavFolderData: action.payload,
                getFavFolderError: null
            }
        case BRAND_ACTION_TYPES.GET_FAV_FOLDER_ERROR:
            return {
                ...state,
                getFavFolderLoading: false,
                getFavFolderData: null,
                getFavFolderError: action.payload
            }
        case BRAND_ACTION_TYPES.GET_FAV_FOLDER_RESET:
            return {
                ...state,
                getFavFolderLoading: false,
                getFavFolderData: null,
                getFavFolderError: null
            }

        case BRAND_ACTION_TYPES.GET_FAV_PROFILE_REQUEST:
            return {
                ...state,
                getFavProfileLoading: true,
                getFavProfileData: null,
                getFavProfileError: null
            }
        case BRAND_ACTION_TYPES.GET_FAV_PROFILE_DATA:
            return {
                ...state,
                getFavProfileLoading: false,
                getFavProfileData: action.payload,
                getFavProfileError: null
            }
        case BRAND_ACTION_TYPES.GET_FAV_PROFILE_ERROR:
            return {
                ...state,
                getFavProfileLoading: false,
                getFavProfileData: null,
                getFavProfileError: action.payload
            }
        case BRAND_ACTION_TYPES.GET_FAV_PROFILE_RESET:
            return {
                ...state,
                getFavProfileLoading: false,
                getFavProfileData: null,
                getFavProfileError: null
            }

        case BRAND_ACTION_TYPES.MY_RDS_REQUEST:
            return {
                ...state,
                myRDsLoading: true,
                myRDsData: null,
                myRDsError: null
            }
        case BRAND_ACTION_TYPES.MY_RDS_DATA:
            return {
                ...state,
                myRDsLoading: false,
                myRDsData: action.payload,
                myRDsError: null
            }
        case BRAND_ACTION_TYPES.MY_RDS_ERROR:
            return {
                ...state,
                myRDsLoading: false,
                myRDsData: null,
                myRDsError: action.payload
            }
        case BRAND_ACTION_TYPES.MY_RDS_RESET:
            return {
                ...state,
                myRDsLoading: false,
                myRDsData: null,
                myRDsError: null
            }

        default:
            return state
    }
}
export default brandReducer