import React, {useImperativeHandle, useState, forwardRef, useEffect} from "react"
import {
    Input,
    FormGroup,
    Row,
    Col,
    Form,
    CustomInput,
    Button
} from "reactstrap"
import {Editor} from "react-draft-wysiwyg"
import {
    EditorState,
    convertToRaw,
    ContentState
} from "draft-js"
import draftToHtml from "draftjs-to-html"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "@styles/react/libs/editor/editor.scss"
import "@styles/base/plugins/forms/form-quill-editor.scss"
import {default as ReactSelect, components} from "react-select"
import {useFormik} from "formik"
import classnames from "classnames"
import {useSelector} from "react-redux"
import htmlToDraft from "html-to-draftjs"
import Flatpickr from "react-flatpickr"
import {
    EXPIRY_DATE_OPTIONS,
    FORM_INITIAL_VALUES,
    VALIDATION_SCHEMA,
    EDITOR_TOOLBAR,
    getReactSelectCustomStyles,
    getAttributes,
    handlePastedText
} from "./Controller"
import CustomLabel from "./CustomLabel"
import DefaultBanners from "./DefaultBanners"

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <div className="d-flex align-items-center font-inter inter-regular">
                    <CustomInput type="checkbox"
                                 checked={props.isSelected}
                                 onChange={() => null}
                                 name={`option-${Math.random()}`}
                                 id={`option-${Math.random()}`}
                                 label={props.label}
                    />
                </div>
            </components.Option>
        </div>
    )
}

const Step2 = forwardRef(({submitted, setData, getPartialData}, ref) => {
    const [image, setImage] = useState(null)
    const [selectAll, setSelectAll] = useState(false)
    const [descriptionEditorState, setDescriptionEditorState] = useState(EditorState.createEmpty())
    const [selectDefaultBanner, setSelectDefaultBanner] = useState(false)
    const [isUploadedImage, setIsUploadedImage] = useState(false)
    const [statesData, setStatesData] = useState([])

    const {brandGoalsSelectValues} = useSelector(state => state.auth)

    const {
        countries,
        states
    } = useSelector((store) => store.brandsOppoprunities)

    const options = getAttributes(brandGoalsSelectValues)

    const form = useFormik({
        initialValues: FORM_INITIAL_VALUES.step2,
        validationSchema: VALIDATION_SCHEMA.default,
        onSubmit: (values) => {
            submitted(values)
        }
    })

    useImperativeHandle(ref, () => ({
        submitThisForm() {
            form.submitForm().then(r => r)
        },
        resetThisForm() {
            form.resetForm()
        },
        getPartialValues() {
            getPartialData(form.values)
        }
    }))

    const handleImageChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            form.setFieldValue('banner', file)
            form.setFieldValue('defaultBanner', '')
            const reader = new FileReader()
            reader.onloadend = () => {
                setImage(reader.result)
                setIsUploadedImage(true)
            }
            reader.readAsDataURL(file)
        }
    }

    useEffect(() => {
        let reader = null
        form.setFieldValue('type', {id: 0, label: 'ANY_CAMPAIGN_TYPE', value: 0, type: 'ANY'})
        if (setData) {
            form.setFieldValue('title', setData.title)
            form.setFieldValue('rdTypes', setData.rdTypes)
            form.setFieldValue('isLocation', setData.isLocation)
            form.setFieldValue('countries', setData.countries)
            form.setFieldValue('states', setData.states)
            form.setFieldValue('description', setData.description)
            form.setFieldValue('expiry', setData.expiry)
            form.setFieldValue('isExpiry', setData.isExpiry)
            form.setFieldValue('applicants', setData.applicants)
            form.setFieldValue('noCap', setData.noCap)
            form.setFieldValue('banner', setData.banner)
            if (setData?.defaultBanner) {
                form.setFieldValue('defaultBanner', setData.defaultBanner)
                setImage(setData.defaultBanner)
                setIsUploadedImage(false)
                setSelectDefaultBanner(false)
            }

            setDescriptionEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(setData.description))))
            if (setData.banner) {
                reader = new FileReader()
                reader.onloadend = () => {
                    setImage(reader.result)
                }
                reader.readAsDataURL(setData.banner)
            }
        }
        return () => {
            if (reader) {
                reader.onloadend = null
                reader.abort()
            }
        }
    }, [setData])

    const selectAllOptions = () => {
        const flattenedOptions = options.reduce((acc, item) => {
            if (item.id === 0) {
                acc.push(item)
            }
            if (item.options) {
                acc.push(...item.options)
            }
            return acc
        }, [])
        form.setFieldValue('rdTypes', flattenedOptions)
    }

    const handleDietitiansTypes = (e) => {
        const hasAll = e.some(item => item.value === "all")
        const typesLength = e.length
        if (hasAll && typesLength === 1) {
            selectAllOptions()
        } else {
            form.setFieldValue('rdTypes', e)
        }
    }

    const flattenedOptions = options.flatMap(group => group.options || [])

    const handleSelectAll = (checked) => {
        setSelectAll(checked)
        if (checked) {
            handleDietitiansTypes(flattenedOptions)
        } else {
            handleDietitiansTypes([])
        }
    }

    const handleChange = (selectedOptions) => {
        handleDietitiansTypes(selectedOptions)
        setSelectAll(selectedOptions.length === flattenedOptions.length)
    }

    const handleEditorChange = (editorState) => {
        setDescriptionEditorState(editorState)
        const contentState = editorState.getCurrentContent()
        if (contentState.hasText()) {
            const rawContent = convertToRaw(contentState)
            const htmlContent = draftToHtml(rawContent)
            form.setFieldValue("description", htmlContent)
        } else {
            form.setFieldValue("description", "")
        }
    }

    const closeModal = () => {
        setSelectDefaultBanner(false)
    }

    const handleCountries = (e) => {
        form.setFieldValue('countries', e)
        form.setFieldValue('states', [])
        const countryIds = e?.map(c => c.id)
        if (states?.length > 0 && e.length > 0) {
            const filteredStates = states?.filter(x => countryIds.includes(x.country))
            setStatesData(filteredStates)
        } else {
            setStatesData([])
        }
    }

    return (
        <div>
            <Form onSubmit={form.handleSubmit}>
                <div className="text-center pb-2 pt-1 font-inter inter-medium outreach-details">
                    Outreach Details
                </div>
                <div className="uploaderContainer mb-2">
                    <label htmlFor="file-input" className="imageWrapper">
                        {image ? (
                            <img
                                src={image}
                                alt="Uploaded"
                                className="uploadedImage"
                                style={{objectFit: isUploadedImage ? "cover" : ""}}
                            />
                        ) : (
                            <div className="placeholder-img">
                                <span></span>
                            </div>
                        )}
                    </label>
                    <div className="d-flex justify-content-between">
                        <div className="textContainer">
                            <h3 className={classnames('title font-inter inter-medium f_size', {
                                'is-invalid': form.touched.banner && form.errors.banner
                            })}
                            >
                                Upload Banner <span className='font-small-2 text-muted inter-medium f_size'>(Optional)</span>
                            </h3>
                            {/* <p className="description font-inter inter-regular">Add a outreach background picture here</p> */}
                            <p className="description font-inter inter-regular">1000/200px dimensions</p>
                            <div className="d-flex align-items-center">
                                <label htmlFor="file-input" className="uploadButton font-inter inter-regular f_size">
                                Upload Image
                                </label>
                                <input id="file-input"
                                       type="file"
                                       accept="image/*"
                                       onChange={handleImageChange}
                                       className="d-none"
                                />
                                <div className="validation-err">{form.touched.banner && form.errors.banner}</div>
                                <Button.Ripple className="float-right font-inter ml-1"
                                               color="primary"
                                               outline={true}
                                               onClick={() => setSelectDefaultBanner(true)}
                                >
                                    Select From Templates
                                </Button.Ripple>
                            </div>
                        </div>
                        <label className='form-label text-danger text-underline text-right font-inter inter-medium'>
                            Max Size: 2MB (Only: PNG, JPG, JPEG)
                        </label>
                    </div>
                </div>
                <FormGroup>
                    <CustomLabel formik={form}
                                 id='title'
                                 title='Title'
                                 msg='Max 100 Characters'
                    />
                    <Input id="title"
                           name="title"
                           placeholder="Share a catchy title for this opportunity."
                           type="text"
                           value={form.values.title}
                           onBlur={form.handleBlur}
                           onChange={form.handleChange}
                           className={classnames({'is-invalid font-inter inter-regular': form.touched.title && form.errors.title})}
                    />
                    <div className="validation-err font-inter inter-regular">{form.touched.title && form.errors.title}</div>
                </FormGroup>
                <FormGroup>
                    <CustomLabel formik={form}
                                 id='description'
                                 title='Tell us about the opportunity and what you’re offering dietitians.'
                                 msg='Min 100 Characters'
                    />
                    <Editor
                        editorState={descriptionEditorState}
                        onEditorStateChange={handleEditorChange}
                        handlePastedText={handlePastedText}
                        toolbar={EDITOR_TOOLBAR}
                        style={{fontFamily: 'Inter, sans-serif'}}
                        stripPastedStyles={false}
                    />
                    <div className="validation-err font-inter inter-regular">{form.touched.description && form.errors.description}</div>
                </FormGroup>
                <Row>
                    <Col md="6" className='d-flex align-items-center'>
                        <CustomLabel formik={form}
                                     id='rdTypes'
                                     title='What types of dietitians would you like to reach?'
                        />
                    </Col>
                    <Col md="6">
                        <div className="d-flex flex-column">
                            <FormGroup check inline className="mt-50 d-flex align-items-center pl-0 font-inter inter-medium label_color">
                                <CustomInput type="checkbox"
                                             checked={selectAll}
                                             onChange={(e) => handleSelectAll(e.target.checked)}
                                             id='select-all'
                                             name='select-all'
                                             label='Select All'
                                />
                            </FormGroup>
                            <p className="txt font-inter inter-regular">
                                You can select all options
                            </p>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="react-select-container">
                            <ReactSelect options={options}
                                         styles={getReactSelectCustomStyles(form, 'rdTypes')}
                                         isMulti
                                         closeMenuOnSelect={false}
                                         hideSelectedOptions={false}
                                         components={{
                                             Option
                                         }}
                                         onChange={(e) => handleChange(e)}
                                         value={form.values.rdTypes}
                            />
                        </div>
                        <div className="validation-err font-inter inter-regular">{form.touched.rdTypes && form.errors.rdTypes}</div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" className='d-flex align-items-center'>
                        <CustomLabel formik={form}
                                     id='isLocation'
                                     title='Would you like to connect with RDs by location?'
                                     isRequired={false}
                        />
                    </Col>
                    <Col md="6">
                        <div className="d-flex flex-column">
                            <FormGroup check inline className="mt-50 d-flex align-items-center pl-0 font-inter inter-medium label_color">
                                <CustomInput type="checkbox"
                                             checked={form.values.isLocation}
                                             onChange={(e) => {
                                                 form.setFieldValue('isLocation', e.target.checked)
                                                 form.setFieldValue('countries', [])
                                                 form.setFieldValue('states', [])
                                                 setStatesData([])
                                             }}
                                             id='isLocation'
                                             name='isLocation'
                                             label='Yes'
                                />
                            </FormGroup>
                            <p className="txt font-inter inter-regular">
                                You can select multiple countries and states
                            </p>
                        </div>
                    </Col>
                    {form.values.isLocation && (
                        <>
                            <Col md="6">
                                <div className="react-select-container">
                                    <ReactSelect options={countries}
                                                 styles={getReactSelectCustomStyles(form, 'countries')}
                                                 isMulti
                                                 closeMenuOnSelect={true}
                                                 hideSelectedOptions={false}
                                                 onChange={(e) => handleCountries(e)}
                                                 value={form.values.countries}
                                                 placeholder='Country...'
                                                 components={{
                                                     Option
                                                 }}
                                    />
                                </div>
                                <div className="validation-err font-inter inter-regular">{form.touched.countries && form.errors.countries}</div>
                            </Col>
                            <Col md="6">
                                <div className="react-select-container">
                                    <ReactSelect options={statesData}
                                                 styles={getReactSelectCustomStyles(form, 'states')}
                                                 isMulti
                                                 closeMenuOnSelect={true}
                                                 hideSelectedOptions={false}
                                                 onChange={(e) => form.setFieldValue('states', e)}
                                                 value={form.values.states}
                                                 placeholder='State...'
                                                 components={{
                                                     Option
                                                 }}
                                    />
                                </div>
                                <div className="validation-err font-inter inter-regular">{form.touched.states && form.errors.states}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row className="my-2">
                    <Col md="12" sm="12">
                        <CustomLabel formik={form}
                                     id='expiry'
                                     title='When would you like to close this opportunity?'
                                     isRequired={false}
                        />
                    </Col>
                    <Col md="6" sm="12">
                        <Flatpickr id="expiry"
                                   style={{fontFamily: 'Inter, sans-serif'}}
                                   value={form.values.expiry}
                                   className={classnames('form-control cursor-pointer', {
                                       'is-invalid': form.touched.expiry && form.errors.expiry,
                                       'full-disabled': form.values.isExpiry,
                                       'full-enabled': !form.values.isExpiry
                                   })}
                                   options={EXPIRY_DATE_OPTIONS}
                                   onChange={(e) => {
                                       form.setFieldValue('expiry', e)
                                   }}
                                   placeholder="Choose End Date"
                                   disabled={form.values.isExpiry}
                        />
                        <div className="validation-err font-inter inter-regular">{form.touched.expiry && form.errors.expiry}</div>
                    </Col>
                    <Col md="6" sm="12">
                        <FormGroup check className="d-flex align-items-center pl-0 font-inter inter-medium label_color">
                            <CustomInput type="checkbox"
                                         checked={form.values.isExpiry}
                                         name="isExpiry"
                                         id="isExpiry"
                                         onChange={(e) => {
                                             form.setFieldValue('expiry', '')
                                             form.setFieldValue('isExpiry', e.target.checked)
                                         }}
                                         label='No specific end date'
                            />
                        </FormGroup>
                        <p className="txt font-inter inter-regular">
                        All Outreach Posts are set to expire/renew after 3 months
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" sm="12">
                        <CustomLabel formik={form}
                                     id='applicants'
                                     title='Would you like to cap the number of applicants?'
                                     isRequired={false}
                        />
                    </Col>
                    <Col md="6" sm="12">
                        <Input name="applicants"
                               placeholder="Add Limit"
                               type="text"
                               id="applicants"
                               value={form.values.applicants}
                               onBlur={form.handleBlur}
                               onChange={form.handleChange}
                               className={classnames({'is-invalid font-inter': form.touched.applicants && form.errors.applicants})}
                               disabled={form.values.noCap}
                        />
                        <div className="validation-err font-inter inter-regular">{form.touched.applicants && form.errors.applicants}</div>
                    </Col>
                    <Col md="6" sm="12">
                        <FormGroup check className="d-flex align-items-center pl-0 font-inter inter-medium label_color">
                            <CustomInput type="checkbox"
                                         checked={form.values.noCap}
                                         name="noCap"
                                         id="noCap"
                                         onChange={(e) => {
                                             form.setFieldValue('applicants', '')
                                             form.setFieldValue('noCap', e.target.checked)
                                         }}
                                         label='No Cap'
                            />
                        </FormGroup>
                        <p className="txt font-inter inter-regular">This will allow unlimited RDs to apply</p>
                    </Col>
                </Row>
            </Form>

            {/* --- Default Banner Modal --- */}
            <DefaultBanners openModal={selectDefaultBanner}
                            closeModal={closeModal}
                            onClick={(image) => {
                                setImage(image)
                                setIsUploadedImage(false)
                                setSelectDefaultBanner(false)
                                form.setFieldValue('banner', '')
                                form.setFieldValue('defaultBanner', image)
                            }}
            />
        </div>
    )
})

export default Step2
