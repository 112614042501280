import {Suspense} from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {store} from './redux/storeConfig/store'
import ability from './configs/acl/ability'
import {ToastContainer} from 'react-toastify'
import {AbilityContext} from './utility/context/Can'
import {SocketContext} from '@src/utility/context/Socket'
import {ThemeContext} from './utility/context/ThemeColors'
import Spinner from './@core/components/spinner/Fallback-spinner'
import './@core/components/ripple-button'
import 'react-perfect-scrollbar/dist/css/styles.css'
import '@styles/react/libs/toastify/toastify.scss'
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import io from "socket.io-client"
import CacheBuster from 'react-cache-buster'
import {version} from '../package.json'
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3"

const ENDPOINT = process.env.REACT_APP_CHAT_ENDPOINT
const CAPTCHA = process.env.REACT_APP_CAPTCHA_KEY
const SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY
const SENTRY_DOMAIN = process.env.REACT_APP_SENTRY_DOMAIN
const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV
const socket = io(ENDPOINT, {transport: ['websocket']})
const BUSTER = process.env.REACT_APP_ENV

import App from './App'
import * as Sentry from "@sentry/react"

Sentry.init({
    dsn: SENTRY_ENV === 'production' ? SENTRY_KEY : '',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration()
    ],

    tracesSampleRate: 1.0,
    tracePropagationTargets: [SENTRY_DOMAIN],
    profilesSampleRate: 0.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
})

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Spinner/>}>
            <AbilityContext.Provider value={ability}>
                <SocketContext.Provider value={socket}>
                    <ThemeContext>
                        <CacheBuster currentVersion={version}
                                     isEnabled={BUSTER === 'production'}
                                     isVerboseMode={false}
                                     loadingComponent={<div className="mt-5 text-center"><h2>Checking for Updates...</h2></div>}>
                            <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA}
                                                     container={{element: 'captcha-v3-renderer', parameters: {badge: 'bottomleft'}}}>
                                <>
                                    <App/>
                                    <div id='captcha-v3-renderer' className='captcha-v3-renderer'></div>
                                </>
                            </GoogleReCaptchaProvider>
                        </CacheBuster>
                        <ToastContainer newestOnTop/>
                    </ThemeContext>
                </SocketContext.Provider>
            </AbilityContext.Provider>
        </Suspense>
    </Provider>,
    document.getElementById('root')
)
