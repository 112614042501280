import CampaignListing from './CampaignListing'

const CampaignPage = () => {

    return (
        <>
            <h4 className="brandSlidesHeading font-weight-bolder mb-3">
                Brand Outreach Posts
            </h4>
            <div className="brandSlides mb-3">
                <CampaignListing/>
            </div>
        </>
    )
}

export default CampaignPage
