import {useEffect, useState, Fragment} from 'react'
import DataTable from "react-data-table-component"
import {useDispatch, useSelector} from 'react-redux'
import {Formik} from "formik"
import * as yup from "yup"
import classnames from 'classnames'
import Cleave from 'cleave.js/react'
import {ChevronDown, CheckCircle, Delete} from "react-feather"
import {
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormGroup,
    Input,
    Form,
    Row,
    Col,
    Alert,
    CardText,
    CardHeader,
    CardTitle,
    Spinner, Badge,
    UncontrolledTooltip, ModalFooter
} from 'reactstrap'
import {createNewCard, getCardsListing, setCardAsDefault, deleteCard, nullResponse} from './store/actions'
import {NULL} from './store/actionType'
import {useHistory} from 'react-router'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

const CardForm = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const [error, setError] = useState('')
    const [successMsg, setSuccessMsg] = useState('')
    const [visible, setVisible] = useState(false)
    const [showDefaultCardErrorMsg, setShowDefaultCardErrorMsg] = useState('')
    const [showDefaultCardError, setShowDefaultCardError] = useState(false)
    const [isTableLoading, setIsTableLoading] = useState(false)

    const [selectedCard, setSelectedCard] = useState(null)
    const [cardDefaultModal, setCardDefaultModal] = useState(false)
    const [cardDeleteModal, setCardDeleteModal] = useState(false)
    const [isCardSubmitting, setIsCardSubmitting] = useState(false)
    const [isCardAdding, setIsCardAdding] = useState(false)
    const [formModal, setFormModal] = useState(false)

    const [captchaValue, setCaptchaValue] = useState(null)
    const [captchaError, setCaptchaError] = useState(false)

    const sweetAlert = withReactContent(Swal)

    const cardsSelector = useSelector(store => store.manageCards)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (!!cardsSelector?.setCardAsDefaultRes) {
            setIsCardSubmitting(false)
            dispatch(nullResponse(NULL.SET_CARD_AS_DEFAULT))
            if (cardsSelector?.setCardAsDefaultRes.status) {
                setIsTableLoading(true)
                dispatch(getCardsListing())
                setCardDefaultModal(false)
            }
            if (!cardsSelector?.setCardAsDefaultRes.status) {
                setShowDefaultCardErrorMsg(cardsSelector?.setCardAsDefaultRes.msg)
            }
        }

        if (!!cardsSelector?.deleteCardRes) {
            setIsCardSubmitting(false)
            dispatch(nullResponse(NULL.DELETE_CARD))
            setIsTableLoading(true)
            dispatch(getCardsListing())
            setCardDeleteModal(false)
        }

        if (!!cardsSelector?.cardsListingData) {
            setIsTableLoading(false)
        }

        const createBankCard =  cardsSelector?.createBankCard ?? null
        if (createBankCard) {
            const {msg, status} = createBankCard
            setVisible(true)
            setIsCardAdding(false)
            dispatch(nullResponse(NULL.CREATE_NEW_CARD))
            if (!status) {
                setError(msg)
                const timer = setTimeout(() => {
                    setVisible(false)
                    clearTimeout(timer)
                }, 5000)
            } else {
                setSuccessMsg(msg)
                setFormModal(!formModal)
                setIsTableLoading(true)
                dispatch(getCardsListing())

                const timer = setTimeout(() => {
                    setVisible(false)
                    clearTimeout(timer)
                }, 5000)
            }
        }

        if (cardsSelector?.error?.response) {
            setIsCardSubmitting(false)
            setIsCardAdding(false)
            setError(cardsSelector?.error?.response?.data?.msg)
            setVisible(true)
            const timer = setTimeout(() => {
                setVisible(false)
                clearTimeout(timer)
            }, 5000)

        }
    }, [cardsSelector])

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        if (!!user && user?.subscriptionexp) history.push('/subscription-plans')

        setIsTableLoading(true)
        dispatch(getCardsListing())
    }, [])

    const addCard = async (values) => {
        setIsCardAdding(true)
        const token = await executeRecaptcha('brand_manage_card')
        if (token) {
            const month = parseInt(values?.expiry?.split("/")[0])
            const year = parseInt(values?.expiry?.split("/")[1])
            const cardNumber = parseInt(values.card_number.replace(/\s+/g, ''))
            const params = {
                cvc: values.cvc,
                owner_name: values.owner_name,
                card_number: cardNumber,
                expiry_year: year,
                expiry_month: month,
                re_captcha_token: token
            }
            dispatch(createNewCard(params))
        } else {
            sweetAlert.fire({
                title: 'Captcha Error',
                icon: 'error',
                html: '<p>Execute recaptcha not yet available.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
    }

    const dataToRender = () => {
        if (cardsSelector?.cardsListingData?.data?.length) {
            return cardsSelector?.cardsListingData?.data
        }
    }

    const handleSetDefaultCard = () => {
        setIsCardSubmitting(true)
        dispatch(setCardAsDefault(selectedCard.id))
    }

    const handleDeleteCard = () => {
        setIsCardSubmitting(true)
        dispatch(deleteCard(selectedCard.id))
    }

    const onCaptchaChange = (value) => {
        setCaptchaError(false)
        setCaptchaValue(value)
    }

    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: "Type",
            selector: "brand",
            sortable: true,
            minWidth: "100px"
        },
        {
            name: "Card Number",
            selector: "last4",
            sortable: true,
            minWidth: "200px",
            cell: (row) => {
                return (
                    <div className="d-flex pl-2">{`********${row.last4}`}</div>
                )
            }
        },
        {
            name: "Month",
            selector: "exp_month",
            sortable: true,
            minWidth: "70px"
        },
        {
            name: "Year",
            selector: "exp_year",
            sortable: true,
            minWidth: "120px"
        },
        {
            name: "Default Card",
            selector: "is_default",
            sortable: true,
            minWidth: "190px",
            cell: (row) => {
                return (
                    <div className="">
                        {row.is_default && <Badge color='light-success'>Yes</Badge>}
                        {!row.is_default && <Badge color='light-danger'>No</Badge>}
                    </div>
                )
            }
        },
        {
            name: "Action",
            selector: "action",
            sortable: false,
            minWidth: "200px",
            cell: (row) => {
                return (
                    <div className="">
                        <Button.Ripple color='primary' outline
                                       id={`set-as-default-${row.id}`}
                                       disabled={row.is_default}
                                       onClick={() => {
                                           setCardDefaultModal(true)
                                           setSelectedCard(row)
                                       }}>
                            <CheckCircle size={16}/>
                        </Button.Ripple>
                        <UncontrolledTooltip placement='top' target={`set-as-default-${row.id}`}>
                            Set as default
                        </UncontrolledTooltip>
                        <Button.Ripple className="ml-1" color='danger'
                                       onClick={() => {
                                           setCardDeleteModal(true)
                                           setSelectedCard(row)
                                       }}>
                            <Delete size={16}/>
                        </Button.Ripple>
                    </div>
                )
            }
        }
    ])

    return (
        <Fragment>
            <h3 className="mb-2">MANAGE CARD</h3>
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Credit Card List</CardTitle>
                    <Button.Ripple color='primary' outline onClick={() => setFormModal(!formModal)} className="float-right">
                        Add Card
                    </Button.Ripple>
                    <Modal isOpen={formModal} toggle={() => setFormModal(!formModal)} className='modal-dialog-centered'>
                        <ModalHeader toggle={() => setFormModal(!formModal)}>Add Card</ModalHeader>
                        <ModalBody>
                            <CardText>
                                <Alert color={!!error ? 'danger' : 'success'} isOpen={visible} toggle={() => setVisible(false)}>
                                    <div className='alert-body'>
                                        {error || successMsg}
                                    </div>
                                </Alert>
                            </CardText>
                            <Formik
                                initialValues={{
                                    owner_name: "",
                                    card_number: "",
                                    expiry: "",
                                    cvc: ""
                                }}
                                validationSchema={yup.object().shape({
                                    owner_name: yup.string().required("This field is required"),
                                    card_number: yup.string()
                                        .min(14, 'Must be 14, 15 or 16 digits')
                                        .max(16, "Must be 14, 15 or 16 digits")
                                        .required("This field is required"),
                                    expiry: yup.string().required("This field is required"),
                                    cvc: yup.string()
                                        .matches(/^[0-9]+$/, "Must be only digits")
                                        .min(3, "Must be 3 or 4 digits")
                                        .max(4, "Must be 3 or 4 digits")
                                        .required("This field is required")
                                })}
                                onSubmit={(values) => {
                                    addCard(values)
                                }}
                            >
                                {({
                                      errors,
                                      handleBlur,
                                      handleChange,
                                      handleSubmit,
                                      touched,
                                      values
                                  }) => (
                                    <Form className='auth-reset-password-form mt-2' onSubmit={handleSubmit}>
                                        <Row>
                                            <FormGroup tag={Col} md="12">
                                                <Label className={classnames({
                                                    'is-invalid': touched.owner_name && errors.owner_name,
                                                    'form-label': true
                                                })} htmlFor={`owner_name`}>
                                                    Owner Name<span className="is-invalid">*</span>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.owner_name}
                                                    name={`owner_name`}
                                                    id={`owner_name`}
                                                    className={classnames({'is-invalid': touched.owner_name && errors.owner_name})}
                                                />
                                                <div className="validation-err">{touched.owner_name && errors.owner_name}</div>
                                            </FormGroup>
                                            <FormGroup tag={Col} className='mb-2' md="12">
                                                <Label className={classnames({
                                                    'is-invalid': touched.card_number && errors.card_number,
                                                    'form-label': true
                                                })} htmlFor={`card_number`}>
                                                    Card Number<span className="is-invalid">*</span>
                                                </Label>
                                                {/* <Cleave
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='2133 3244 4567 8921'
                          options={{ creditCard: true }}
                          value={values.card_number}
                          name={`card_number`}
                          id='payment-card-number'
                          className={classnames('form-control', { 'is-invalid': touched.card_number && errors.card_number })}
                        /> */}
                                                <Input
                                                    type='text'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder='2133324445678921'
                                                    value={values.card_number}
                                                    name={`card_number`}
                                                    id='payment-card-number'
                                                    className={classnames('form-control', {'is-invalid': touched.card_number && errors.card_number})}
                                                />
                                                <div className="validation-err">{touched.card_number && errors.card_number}</div>
                                            </FormGroup>
                                            <Col sm="6">
                                                <FormGroup className='mb-2'>
                                                    <Label className={classnames({
                                                        'is-invalid': touched.expiry && errors.expiry,
                                                        'form-label': true
                                                    })} htmlFor={`expiry`}>
                                                        Expiry<span className="is-invalid">*</span>
                                                    </Label>
                                                    <Cleave
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        placeholder='MM/YY'
                                                        value={values.expiry}
                                                        name="expiry"
                                                        options={{date: true, delimiter: '/', datePattern: ['m', 'y']}}
                                                        id='payment-expiry'
                                                        className={classnames('form-control', {'is-invalid': touched.expiry && errors.expiry})}
                                                    />
                                                    <div className="validation-err">{touched.expiry && errors.expiry}</div>
                                                </FormGroup>
                                            </Col>
                                            <Col sm='6'>
                                                <FormGroup className='mb-2'>
                                                    <Label className={classnames({
                                                        'is-invalid': touched.cvc && errors.cvc,
                                                        'form-label': true
                                                    })} htmlFor={`cvc`}>
                                                        CVV / CVC<span className="is-invalid">*</span>
                                                    </Label>
                                                    <Input
                                                        type='text'
                                                        placeholder='123'
                                                        id='payment-cvv'
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.cvc}
                                                        name={`cvc`}
                                                        className={classnames({'is-invalid': touched.cvc && errors.cvc})}
                                                    />
                                                    <div className="validation-err">{touched.cvc && errors.cvc}</div>
                                                </FormGroup>
                                            </Col>
                                            <FormGroup tag={Col} md="12">
                                                {/* <Button.Ripple type='submit' color='primary' className='mt-2 mx-auto d-flex float-left' disabled={isSubmitting}>
                        {isSubmitting ? 'Saving...' : 'Save'}
                    </Button.Ripple> */}
                                                {/* onClick={() => setFormModal(!formModal)} */}
                                                <Button.Ripple type='submit' color='primary' disabled={isCardAdding} className='mx-auto d-flex float-left'>
                                                    {isCardAdding ? 'Adding...' : 'Add Card'}
                                                </Button.Ripple>
                                            </FormGroup>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </ModalBody>
                    </Modal>
                </CardHeader>
                <CardBody className="p-0">
                    <Row>
                        <Col md="12" sm="12">
                            {isTableLoading && (
                                <div className="w-100 my-4 text-center">
                                    <Spinner color="primary"/>
                                </div>
                            )}
                            {!isTableLoading && (
                                <Fragment>
                                    <DataTable
                                        noHeader
                                        columns={advSearchColumns}
                                        className="react-dataTable"
                                        sortIcon={<ChevronDown size={10}/>}
                                        data={dataToRender()}
                                    />
                                </Fragment>
                            )}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Modal isOpen={cardDefaultModal} className='modal-dialog-centered'>
                <ModalHeader>Set your card as default</ModalHeader>
                <ModalBody>
                    <Alert color='danger' isOpen={showDefaultCardError} toggle={() => setShowDefaultCardError(false)}>
                        <div className='alert-body'>
                            {showDefaultCardErrorMsg}
                        </div>
                    </Alert>
                    <CardText>Are you sure? want to set your card as default.</CardText>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-end">
                        {!isCardSubmitting && <Button.Ripple color='danger' outline onClick={() => setCardDefaultModal(false)}>
                            Cancel
                        </Button.Ripple>}
                        <Button.Ripple className="ml-1" color='primary' disabled={isCardSubmitting} onClick={handleSetDefaultCard}>
                            {isCardSubmitting ? 'Loading...' : 'Yes'}
                        </Button.Ripple>
                    </div>
                </ModalFooter>
            </Modal>

            <Modal isOpen={cardDeleteModal} className='modal-dialog-centered'>
                <ModalHeader>Delete Card</ModalHeader>
                <ModalBody>
                    <CardText>Are you sure you want to delete card?</CardText>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-end">
                        {!isCardSubmitting && <Button.Ripple color='primary' outline onClick={() => setCardDeleteModal(false)}>
                            No
                        </Button.Ripple>}
                        <Button.Ripple className="ml-1" color='danger' disabled={isCardSubmitting} onClick={handleDeleteCard}>
                            {isCardSubmitting ? 'Loading...' : 'Yes'}
                        </Button.Ripple>
                    </div>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}
export default CardForm
