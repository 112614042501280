import {RD_ACTION_TYPES} from "@store/actionTypes/RDActionTypes"

const initialState = {
    getProfileLoading: false,
    getProfileData: null,
    getProfileError: null,

    updateProfileLoading: false,
    updateProfileData: null,
    updateProfileError: null,

    deleteAccountLoading: false,
    deleteAccountData: null,
    deleteAccountError: null,

    facebookLoginLoading: false,
    facebookLoginData: null,
    facebookLoginError: null,

    facebookTokenLoading: false,
    facebookTokenData: null,
    facebookTokenError: null,

    facebookIgIdLoading: false,
    facebookIgIdData: null,
    facebookIgIdError: null,

    facebookIgDataLoading: false,
    facebookIgDataData: null,
    facebookIgDataError: null,

    facebookIgLogoutLoading: false,
    facebookIgLogoutData: null,
    facebookIgLogoutError: null,

    markAsReadLoading: false,
    markAsReadData: null,
    markAsReadError: null,

    getNewEventsLoading: false,
    getNewEventsData: null,
    getNewEventsError: null,

    deleteEventLoading: false,
    deleteEventData: null,
    deleteEventError: null,

    getPastEventsLoading: false,
    getPastEventsData: null,
    getPastEventsError: null,

    getEventBrandsLoading: false,
    getEventBrandsData: null,
    getEventBrandsError: null,

    copyEventLoading: false,
    copyEventData: null,
    copyEventError: null,

    campaignViewLoading: false,
    campaignViewData: null,
    campaignViewError: null,

    applyOnCampaignLoading: false,
    applyOnCampaignData: null,
    applyOnCampaignError: null,

    campaignListingLoading: false,
    campaignListingData: null,
    campaignListingError: null
}

const rdReducer = (state = initialState, action) => {
    switch (action.type) {
        case RD_ACTION_TYPES.GET_PROFILE_REQUEST:
            return {
                ...state,
                getProfileLoading: true,
                getProfileData: null,
                getProfileError: null
            }
        case RD_ACTION_TYPES.GET_PROFILE_DATA:
            return {
                ...state,
                getProfileLoading: false,
                getProfileData: action.payload,
                getProfileError: null
            }
        case RD_ACTION_TYPES.GET_PROFILE_ERROR:
            return {
                ...state,
                getProfileLoading: false,
                getProfileData: null,
                getProfileError: action.payload
            }
        case RD_ACTION_TYPES.GET_PROFILE_RESET:
            return {
                ...state,
                getProfileLoading: false,
                getProfileData: null,
                getProfileError: null
            }

        case RD_ACTION_TYPES.UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                updateProfileLoading: true,
                updateProfileData: null,
                updateProfileError: null
            }
        case RD_ACTION_TYPES.UPDATE_PROFILE_DATA:
            return {
                ...state,
                updateProfileLoading: false,
                updateProfileData: action.payload,
                updateProfileError: null
            }
        case RD_ACTION_TYPES.UPDATE_PROFILE_ERROR:
            return {
                ...state,
                updateProfileLoading: false,
                updateProfileData: null,
                updateProfileError: action.payload
            }
        case RD_ACTION_TYPES.UPDATE_PROFILE_RESET:
            return {
                ...state,
                updateProfileLoading: false,
                updateProfileData: null,
                updateProfileError: null
            }

        case RD_ACTION_TYPES.DELETE_ACCOUNT_REQUEST:
            return {
                ...state,
                deleteAccountLoading: true,
                deleteAccountData: null,
                deleteAccountError: null
            }
        case RD_ACTION_TYPES.DELETE_ACCOUNT_DATA:
            return {
                ...state,
                deleteAccountLoading: false,
                deleteAccountData: action.payload,
                deleteAccountError: null
            }
        case RD_ACTION_TYPES.DELETE_ACCOUNT_ERROR:
            return {
                ...state,
                deleteAccountLoading: false,
                deleteAccountData: null,
                deleteAccountError: action.payload
            }
        case RD_ACTION_TYPES.DELETE_ACCOUNT_RESET:
            return {
                ...state,
                deleteAccountLoading: false,
                deleteAccountData: null,
                deleteAccountError: null
            }

        case RD_ACTION_TYPES.FACEBOOK_LOGIN_REQUEST:
            return {
                ...state,
                facebookLoginLoading: true,
                facebookLoginData: null,
                facebookLoginError: null
            }
        case RD_ACTION_TYPES.FACEBOOK_LOGIN_DATA:
            return {
                ...state,
                facebookLoginLoading: false,
                facebookLoginData: action.payload,
                facebookLoginError: null
            }
        case RD_ACTION_TYPES.FACEBOOK_LOGIN_ERROR:
            return {
                ...state,
                facebookLoginLoading: false,
                facebookLoginData: null,
                facebookLoginError: action.payload
            }
        case RD_ACTION_TYPES.FACEBOOK_LOGIN_RESET:
            return {
                ...state,
                facebookLoginLoading: false,
                facebookLoginData: null,
                facebookLoginError: null
            }

        case RD_ACTION_TYPES.FACEBOOK_TOKEN_REQUEST:
            return {
                ...state,
                facebookTokenLoading: true,
                facebookTokenData: null,
                facebookTokenError: null
            }
        case RD_ACTION_TYPES.FACEBOOK_TOKEN_DATA:
            return {
                ...state,
                facebookTokenLoading: false,
                facebookTokenData: action.payload,
                facebookTokenError: null
            }
        case RD_ACTION_TYPES.FACEBOOK_TOKEN_ERROR:
            return {
                ...state,
                facebookTokenLoading: false,
                facebookTokenData: null,
                facebookTokenError: action.payload
            }
        case RD_ACTION_TYPES.FACEBOOK_TOKEN_RESET:
            return {
                ...state,
                facebookTokenLoading: false,
                facebookTokenData: null,
                facebookTokenError: null
            }

        case RD_ACTION_TYPES.FACEBOOK_IGID_REQUEST:
            return {
                ...state,
                facebookIgIdLoading: true,
                facebookIgIdData: null,
                facebookIgIdError: null
            }
        case RD_ACTION_TYPES.FACEBOOK_IGID_DATA:
            return {
                ...state,
                facebookIgIdLoading: false,
                facebookIgIdData: action.payload,
                facebookIgIdError: null
            }
        case RD_ACTION_TYPES.FACEBOOK_IGID_ERROR:
            return {
                ...state,
                facebookIgIdLoading: false,
                facebookIgIdData: null,
                facebookIgIdError: action.payload
            }
        case RD_ACTION_TYPES.FACEBOOK_IGID_RESET:
            return {
                ...state,
                facebookIgIdLoading: false,
                facebookIgIdData: null,
                facebookIgIdError: null
            }

        case RD_ACTION_TYPES.FACEBOOK_IG_DATA_REQUEST:
            return {
                ...state,
                facebookIgDataLoading: true,
                facebookIgDataData: null,
                facebookIgDataError: null
            }
        case RD_ACTION_TYPES.FACEBOOK_IG_DATA_DATA:
            return {
                ...state,
                facebookIgDataLoading: false,
                facebookIgDataData: action.payload,
                facebookIgDataError: null
            }
        case RD_ACTION_TYPES.FACEBOOK_IG_DATA_ERROR:
            return {
                ...state,
                facebookIgDataLoading: false,
                facebookIgDataData: null,
                facebookIgDataError: action.payload
            }
        case RD_ACTION_TYPES.FACEBOOK_IG_DATA_RESET:
            return {
                ...state,
                facebookIgDataLoading: false,
                facebookIgDataData: null,
                facebookIgDataError: null
            }

        case RD_ACTION_TYPES.FACEBOOK_IG_LOGOUT_REQUEST:
            return {
                ...state,
                facebookIgLogoutLoading: true,
                facebookIgLogoutData: null,
                facebookIgLogoutError: null
            }
        case RD_ACTION_TYPES.FACEBOOK_IG_LOGOUT_DATA:
            return {
                ...state,
                facebookIgLogoutLoading: false,
                facebookIgLogoutData: action.payload,
                facebookIgLogoutError: null
            }
        case RD_ACTION_TYPES.FACEBOOK_IG_LOGOUT_ERROR:
            return {
                ...state,
                facebookIgLogoutLoading: false,
                facebookIgLogoutData: null,
                facebookIgLogoutError: action.payload
            }
        case RD_ACTION_TYPES.FACEBOOK_IG_LOGOUT_RESET:
            return {
                ...state,
                facebookIgLogoutLoading: false,
                facebookIgLogoutData: null,
                facebookIgLogoutError: null
            }

        case RD_ACTION_TYPES.MARK_AS_READ_REQUEST:
            return {
                ...state,
                markAsReadLoading: true,
                markAsReadData: null,
                markAsReadError: null
            }
        case RD_ACTION_TYPES.MARK_AS_READ_DATA:
            return {
                ...state,
                markAsReadLoading: false,
                markAsReadData: action.payload,
                markAsReadError: null
            }
        case RD_ACTION_TYPES.MARK_AS_READ_ERROR:
            return {
                ...state,
                markAsReadLoading: false,
                markAsReadData: null,
                markAsReadError: action.payload
            }
        case RD_ACTION_TYPES.MARK_AS_READ_RESET:
            return {
                ...state,
                markAsReadLoading: false,
                markAsReadData: null,
                markAsReadError: null
            }

        case RD_ACTION_TYPES.GET_NEW_EVENTS_REQUEST:
            return {
                ...state,
                getNewEventsLoading: true,
                getNewEventsData: null,
                getNewEventsError: null
            }
        case RD_ACTION_TYPES.GET_NEW_EVENTS_DATA:
            return {
                ...state,
                getNewEventsLoading: false,
                getNewEventsData: action.payload,
                getNewEventsError: null
            }
        case RD_ACTION_TYPES.GET_NEW_EVENTS_ERROR:
            return {
                ...state,
                getNewEventsLoading: false,
                getNewEventsData: null,
                getNewEventsError: action.payload
            }

        case RD_ACTION_TYPES.DELETE_EVENT_REQUEST:
            return {
                ...state,
                deleteEventLoading: true,
                deleteEventData: null,
                deleteEventError: null
            }
        case RD_ACTION_TYPES.DELETE_EVENT_DATA:
            return {
                ...state,
                deleteEventLoading: false,
                deleteEventData: action.payload,
                deleteEventError: null
            }
        case RD_ACTION_TYPES.DELETE_EVENT_ERROR:
            return {
                ...state,
                deleteEventLoading: false,
                deleteEventData: null,
                deleteEventError: action.payload
            }
        case RD_ACTION_TYPES.DELETE_EVENT_RESET:
            return {
                ...state,
                deleteEventLoading: false,
                deleteEventData: null,
                deleteEventError: null
            }

        case RD_ACTION_TYPES.GET_PAST_EVENTS_REQUEST:
            return {
                ...state,
                getPastEventsLoading: true,
                getPastEventsData: null,
                getPastEventsError: null
            }
        case RD_ACTION_TYPES.GET_PAST_EVENTS_DATA:
            return {
                ...state,
                getPastEventsLoading: false,
                getPastEventsData: action.payload,
                getPastEventsError: null
            }
        case RD_ACTION_TYPES.GET_PAST_EVENTS_ERROR:
            return {
                ...state,
                getPastEventsLoading: false,
                getPastEventsData: null,
                getPastEventsError: action.payload
            }

        case RD_ACTION_TYPES.GET_EVENT_BRANDS_REQUEST:
            return {
                ...state,
                getEventBrandsLoading: true,
                getEventBrandsData: null,
                getEventBrandsError: null
            }
        case RD_ACTION_TYPES.GET_EVENT_BRANDS_DATA:
            return {
                ...state,
                getEventBrandsLoading: false,
                getEventBrandsData: action.payload,
                getEventBrandsError: null
            }
        case RD_ACTION_TYPES.GET_EVENT_BRANDS_ERROR:
            return {
                ...state,
                getEventBrandsLoading: false,
                getEventBrandsData: null,
                getEventBrandsError: action.payload
            }

        case RD_ACTION_TYPES.COPY_EVENT_REQUEST:
            return {
                ...state,
                copyEventLoading: true,
                copyEventData: null,
                copyEventError: null
            }
        case RD_ACTION_TYPES.COPY_EVENT_DATA:
            return {
                ...state,
                copyEventLoading: false,
                copyEventData: action.payload,
                copyEventError: null
            }
        case RD_ACTION_TYPES.COPY_EVENT_ERROR:
            return {
                ...state,
                copyEventLoading: false,
                copyEventData: null,
                copyEventError: action.payload
            }
        case RD_ACTION_TYPES.COPY_EVENT_RESET:
            return {
                ...state,
                copyEventLoading: false,
                copyEventData: null,
                copyEventError: null
            }

        case RD_ACTION_TYPES.CAMPAIGN_VIEW_REQUEST:
            return {
                ...state,
                campaignViewLoading: true,
                campaignViewData: null,
                campaignViewError: null
            }
        case RD_ACTION_TYPES.CAMPAIGN_VIEW_DATA:
            return {
                ...state,
                campaignViewLoading: false,
                campaignViewData: action.payload,
                campaignViewError: null
            }
        case RD_ACTION_TYPES.CAMPAIGN_VIEW_ERROR:
            return {
                ...state,
                campaignViewLoading: false,
                campaignViewData: null,
                campaignViewError: action.payload
            }

        case RD_ACTION_TYPES.APPLY_ON_CAMPAIGN_REQUEST:
            return {
                ...state,
                applyOnCampaignLoading: true,
                applyOnCampaignData: null,
                applyOnCampaignError: null
            }
        case RD_ACTION_TYPES.APPLY_ON_CAMPAIGN_DATA:
            return {
                ...state,
                applyOnCampaignLoading: false,
                applyOnCampaignData: action.payload,
                applyOnCampaignError: null
            }
        case RD_ACTION_TYPES.APPLY_ON_CAMPAIGN_ERROR:
            return {
                ...state,
                applyOnCampaignLoading: false,
                applyOnCampaignData: null,
                applyOnCampaignError: action.payload
            }
        case RD_ACTION_TYPES.APPLY_ON_CAMPAIGN_RESET:
            return {
                ...state,
                applyOnCampaignLoading: false,
                applyOnCampaignData: null,
                applyOnCampaignError: null
            }

        case RD_ACTION_TYPES.CAMPAIGN_LISTING_REQUEST:
            return {
                ...state,
                campaignListingLoading: true,
                campaignListingData: null,
                campaignListingError: null
            }
        case RD_ACTION_TYPES.CAMPAIGN_LISTING_DATA:
            return {
                ...state,
                campaignListingLoading: false,
                campaignListingData: action.payload,
                campaignListingError: null
            }
        case RD_ACTION_TYPES.CAMPAIGN_LISTING_ERROR:
            return {
                ...state,
                campaignListingLoading: false,
                campaignListingData: null,
                campaignListingError: action.payload
            }

        default:
            return state
    }
}
export default rdReducer