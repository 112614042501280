import {Fragment, useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import classnames from 'classnames'
import {
    Filter,
    Folder,
    X
} from 'react-feather'
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CustomInput,
    FormGroup,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Input
} from 'reactstrap'
import Select from 'react-select'
import {
    getCountries,
    getRegionsByCountryId,
    getStatesByRegionId,
    getCitiesByStateId,
    getMyBrandsOnRD,
    searchMyBrandsOnRD,
    getBrandProductAttributes,
    getFavoriteFolders,
    createFavoriteFolder,
    addToFavorite,
    nullResponse
} from '@src/redux/actions/rdDashboard'
import {NULL} from '@src/redux/actionTypes'
import ProductCards from './ProductCards'
import ProductsHeader from './ProductsHeader'
import ProductsSearchbar from './ProductsSearchbar'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import {PropTypes} from "prop-types"

const ProductsPage = props => {
    const [isSelectFoldersModalOpen, setIsSelectFoldersModalOpen] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [selectedFolder, setSelectedFolder] = useState(null)
    const [selectedProfile, setSelectedProfile] = useState(null)
    const [folderNameError, setFolderNameError] = useState(false)
    const [isFolderCreating, setIsFolderCreating] = useState(false)

    const [isPageLoading, setIsPageLoading] = useState(false)
    const [isListLoading, setIsListLoading] = useState(false)
    const [isFoldersLoading, setIsFoldersLoading] = useState(false)
    const [isAddingToFavorite, setIsAddingToFavorite] = useState(false)

    const [showFilters, setShowFilters] = useState(false)
    const [sortCategory, setSortCategory] = useState('partnerships')
    const [searchValue, setSearchValue] = useState('')
    const [specialDietValues, setSpecialDietValues] = useState([])
    const [categoryValues, setCategoryValues] = useState([])
    const [filterValues, setFilterValues] = useState([])

    const [paginationInfo, setPaginationInfo] = useState(null)
    const [brandProductAttributes, setBrandProductAttributes] = useState(null)

    const [countryOptions, setCountryOptions] = useState(null)
    const [regionOptions, setRegionOptions] = useState(null)
    const [stateOptions, setStateOptions] = useState(null)
    const [cityOptions, setCityOptions] = useState(null)

    const [isRegionsLoading, setIsRegionsLoading] = useState(false)
    const [isStatesLoading, setIsStatesLoading] = useState(false)
    const [isCitiesLoading, setIsCitiesLoading] = useState(false)

    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedRegion, setSelectedRegion] = useState(null)
    const [selectedState, setSelectedState] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)

    const {
        activeView,
        setActiveView,
        store,
        sidebarOpen,
        setSidebarOpen
    } = props

    const rdDashboardStoreSlice = useSelector(store => store.rd)
    const dispatch = useDispatch()

    useEffect(() => {

        if (!!rdDashboardStoreSlice?.addToFavroiteRes) {
            setIsAddingToFavorite(false)
            setIsListLoading(true)
            setIsSelectFoldersModalOpen(false)
            dispatch(nullResponse(NULL.RD_MY_BRANDS))
            dispatch(getMyBrandsOnRD(sortCategory))
            dispatch(nullResponse(NULL.RD_ADD_TO_FAVORITE))
        }

        if (!!rdDashboardStoreSlice?.favoriteFoldersRes) {
            setIsFoldersLoading(false)
        }

        if (!!rdDashboardStoreSlice?.createfavoriteFolderRes) {
            setFolderName('')
            setIsFolderCreating(false)
            dispatch(nullResponse(NULL.RD_GET_FOLDERS))
            dispatch(nullResponse(NULL.RD_CREATE_FOLDER))
            dispatch(getFavoriteFolders(false))
            setIsFoldersLoading(true)
        }

        if (!!rdDashboardStoreSlice?.countries) {
            setCountryOptions(rdDashboardStoreSlice.countries)
        }

        if (!!rdDashboardStoreSlice?.regionsByCountryID) {
            setRegionOptions(rdDashboardStoreSlice?.regionsByCountryID)
            setIsRegionsLoading(false)
        }

        if (!!rdDashboardStoreSlice?.statesByRegionID) {
            setStateOptions(rdDashboardStoreSlice?.statesByRegionID)
            setIsStatesLoading(false)
        }

        if (!!rdDashboardStoreSlice?.citiesByStateID) {
            setCityOptions(rdDashboardStoreSlice?.citiesByStateID)
            setIsCitiesLoading(false)
        }

        if (!!rdDashboardStoreSlice?.brandProductAttributesRes) {
            setBrandProductAttributes(rdDashboardStoreSlice.brandProductAttributesRes)
        }

        const myBrands = rdDashboardStoreSlice?.myBrands ?? null
        if (myBrands) {
            setIsListLoading(false)
            const {links, meta, pages} = myBrands
            setPaginationInfo({...links, ...meta, pages})
        }

    }, [rdDashboardStoreSlice])

    useEffect(() => {
        setIsListLoading(true)
        dispatch(getMyBrandsOnRD(sortCategory))
        dispatch(getBrandProductAttributes())
        dispatch(getFavoriteFolders(false))
        dispatch(getCountries())
        dispatch(nullResponse(NULL.RD_REGIONS_BY_COUNTRY_ID))
        dispatch(nullResponse(NULL.RD_STATES_BY_REGIONS_ID))
        dispatch(nullResponse(NULL.RD_CITIES_BY_STATES_ID))
    }, [])

    const handleChange = (filterName, name, value) => {
        switch (filterName) {
            case 'ValueChange':
                let values1 = [...filterValues]
                if (value) values1.push(parseInt(name))
                if (!value) values1 = values1.filter(x => x !== parseInt(name))
                setFilterValues(values1)
                break
            case 'CategoryChange':
                let values2 = [...categoryValues]
                if (value) values2.push(parseInt(name))
                if (!value) values2 = values2.filter(x => x !== parseInt(name))
                setCategoryValues(values2)
                break
            case 'SpecialDietChange':
                let values3 = [...specialDietValues]
                if (value) values3.push(parseInt(name))
                if (!value) values3 = values3.filter(x => x !== parseInt(name))
                setSpecialDietValues(values3)
                break
        }
    }

    const updateLocation = (location, locationValue) => {
        switch (location) {
            case 'Regions':
                dispatch(getRegionsByCountryId(locationValue))
                break
            case 'States':
                dispatch(getStatesByRegionId(locationValue))
                break
            case 'Cities':
                dispatch(getCitiesByStateId(locationValue))
                break
        }
    }

    const handleSelect = (select, value) => {
        switch (select) {
            case 'CountrySelect':
                setIsRegionsLoading(true)
                updateLocation('Regions', value)
                setSelectedCountry(value)
                break
            case 'RegionSelect':
                setIsStatesLoading(true)
                updateLocation('States', value)
                setSelectedRegion(value)
                break
            case 'StateSelect':
                setIsCitiesLoading(true)
                updateLocation('Cities', value)
                setSelectedState(value)
                break
            case 'CitySelect':
                setSelectedCity(value)
                break
        }
    }

    const handleSearchByName = (searchValue) => {
        setIsListLoading(true)
        setSearchValue(searchValue)
        dispatch(searchMyBrandsOnRD(false, sortCategory, {
            filters: [],
            country: null,
            region: null,
            state: [],
            city: [],
            type: sortCategory,
            name: searchValue
        }))
    }

    const handleFiltersSearch = () => {
        setIsListLoading(true)
        dispatch(searchMyBrandsOnRD(false, sortCategory, {
            filters: [...filterValues, ...categoryValues, ...specialDietValues],
            country: selectedCountry?.id || null,
            region: selectedRegion?.id || null,
            state: selectedState === null ? selectedState : [selectedState?.id],
            city: selectedCity === null ? selectedCity : [selectedCity?.id],
            type: sortCategory,
            name: ''
        }))
    }

    const handleResetSearch = () => {
        setIsListLoading(true)
        setSearchValue('')
        setSelectedCountry(null)
        setSelectedRegion(null)
        setSelectedState(null)
        setSelectedCity(null)
        document.querySelectorAll("input[type=checkbox]").forEach(checkbox => {
            checkbox.checked = false
        })
        dispatch(getMyBrandsOnRD(sortCategory))
    }

    const handleChangeTypeFilter = (value) => {
        setIsListLoading(true)
        setSortCategory(value)
        dispatch(getMyBrandsOnRD(value))
    }

    const handleCustomPagination = pageUrl => {
        setIsListLoading(true)
        dispatch(searchMyBrandsOnRD(true, sortCategory, {
            filters: [],
            country: null,
            region: null,
            state: [],
            city: [],
            type: sortCategory,
            name: searchValue
        }, pageUrl))
    }

    const handleCreateFolder = () => {
        if (!folderName) {
            setFolderNameError(true)
            return
        }
        setFolderNameError(false)
        setIsFolderCreating(true)
        dispatch(createFavoriteFolder({folderName}))
    }

    const handleSelectFolder = (folder) => {
        setSelectedFolder(folder)
    }

    const getAddToFavoriteProfile = (profile) => {
        setSelectedProfile(profile)
        if (!profile.is_favorite) setIsSelectFoldersModalOpen(true)
        if (profile.is_favorite) {
            setIsListLoading(true)
            dispatch(addToFavorite({id: profile.user_id, folder_id: profile.folder_id, type: 'profile'}))
        }
    }

    const handleAddToFavorite = () => {
        setIsAddingToFavorite(true)
        dispatch(addToFavorite({id: selectedProfile.user_id, folder_id: selectedFolder.id, type: 'profile'}))
    }

    return (
        <Fragment>
            <div className='content-body'>
                <div className="d-flex align-items-center justify-content-between">
                    <h3 className='filter-heading'>My Brands</h3>
                    <div className="d-flex align-items-center justify-content-between">
                        <ProductsHeader
                            store={store}
                            dispatch={dispatch}
                            activeView={activeView}
                            setActiveView={setActiveView}
                            setSidebarOpen={setSidebarOpen}
                            handleChangeTypeFilter={handleChangeTypeFilter}
                        />
                        <Button.Ripple className='btn-icon ml-1' color='primary' onClick={() => setShowFilters(!showFilters)}>
                            <Filter size={16}/>
                        </Button.Ripple>
                    </div>
                </div>
                {showFilters && <Card className='my-0 filters-right-sidebar'>
                    <CardHeader className="card-header-c">
                        <h3 className='filter-heading my-0'>Filters</h3>
                        <Button.Ripple className='btn-icon' color='danger' onClick={() => setShowFilters(false)}>
                            <X size={16}/>
                        </Button.Ripple>
                    </CardHeader>
                    <CardBody className="pt-2 overflow-y-auto">
                        <h5 className='filter-title mt-0 mb-2'>Values</h5>
                        <div className='d-flex flex-wrap' onChange={e => handleChange('ValueChange', e.target.name, e.target.checked)}>
                            {brandProductAttributes?.values?.map((val, i) => {
                                return (
                                    <CustomInput
                                        key={val.label}
                                        type='checkbox'
                                        className="mx-2 mb-1"
                                        name={val.id}
                                        id={val.id}
                                        label={val.label + val.id}
                                    />)
                            })}
                        </div>
                        <h5 className='filter-title mt-2 mb-2'>Categories</h5>
                        <div className='d-flex flex-wrap' onChange={(e) => handleChange('CategoryChange', e.target.name, e.target.checked)}>
                            {brandProductAttributes?.category?.map(cate => {
                                return (
                                    <CustomInput
                                        key={cate.label}
                                        type='checkbox'
                                        className="mx-2 mb-1"
                                        id={cate.label}
                                        name={cate.id}
                                        label={cate.label + cate.id}
                                    />)
                            })}
                        </div>
                        <h5 className='filter-title mt-2 mb-2'>Special Diets</h5>
                        <div className='d-flex flex-wrap' onChange={e => handleChange('SpecialDietChange', e.target.name, e.target.checked)}>
                            {brandProductAttributes?.special_diet?.map(sp_diet => {
                                return (
                                    <CustomInput
                                        key={sp_diet.title}
                                        type='checkbox'
                                        className="mx-2 mb-1"
                                        id={sp_diet.label}
                                        name={sp_diet.id}
                                        label={sp_diet.label + sp_diet.id}
                                    />)
                            })}
                        </div>
                        <FormGroup>
                            <h6 className='filter-title mt-1'>Country</h6>
                            <Select
                                options={countryOptions}
                                className='react-select'
                                classNamePrefix='select'
                                value={selectedCountry}
                                onChange={(value) => handleSelect("CountrySelect", value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <h6 className='filter-title mt-1'>Region</h6>
                            <div className="overlay-select-parent">
                                {isRegionsLoading && <div className="overlay-select"></div>}
                                <Select
                                    options={regionOptions}
                                    className='react-select'
                                    classNamePrefix='select'
                                    value={selectedRegion}
                                    onChange={(value) => handleSelect("RegionSelect", value)}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <h6 className='filter-title mt-1'>State</h6>
                            <div className="overlay-select-parent">
                                {isStatesLoading && <div className="overlay-select"></div>}
                                <Select
                                    options={stateOptions}
                                    className='react-select'
                                    classNamePrefix='select'
                                    value={selectedState}
                                    onChange={(value) => handleSelect("StateSelect", value)}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <h6 className='filter-title mt-1'>City</h6>
                            <div className="overlay-select-parent">
                                {isCitiesLoading && <div className="overlay-select"></div>}
                                <Select
                                    options={cityOptions}
                                    className='react-select'
                                    classNamePrefix='select'
                                    value={selectedCity}
                                    onChange={(value) => handleSelect("CitySelect", value)}
                                />
                            </div>
                        </FormGroup>
                    </CardBody>
                    <CardFooter>
                        <Button.Ripple color='primary' className="mb-1" onClick={handleFiltersSearch} block>
                            Apply Filters
                        </Button.Ripple>
                        <Button.Ripple onClick={handleResetSearch} color='danger' outline block>
                            Clear Filters
                        </Button.Ripple>
                    </CardFooter>
                </Card>}
                <div className={classnames('body-content-overlay', {
                    show: sidebarOpen
                })}>
                    <button className='btn p-0' onClick={() => setSidebarOpen(false)}></button>
                </div>
                <ProductsSearchbar handleSearch={handleSearchByName} handleReset={handleResetSearch} searchValue={searchValue}/>
                {isListLoading && (
                    <div className="w-100 my-4 text-center">
                        <Spinner color='primary'/>
                    </div>)}
                {!isListLoading && rdDashboardStoreSlice?.myBrands?.data?.length !== 0 && (
                    <Fragment>
                        <ProductCards
                            activeView={activeView}
                            myBrands={rdDashboardStoreSlice?.myBrands?.data}
                            handleAddToFavorite={getAddToFavoriteProfile}
                        />
                        <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                    </Fragment>)}
                {!isListLoading && rdDashboardStoreSlice?.myBrands?.data?.length === 0 && (
                    <div className='d-flex justify-content-center mt-2'>
                        <p>Record doesn't exists</p>
                    </div>)}
            </div>

            <Modal scrollable isOpen={isSelectFoldersModalOpen} className='modal-dialog-centered'>
                <ModalHeader>Select Folder</ModalHeader>
                <ModalBody className="py-2" style={{maxHeight: '50vh', overflowY: 'auto'}}>
                    {isFoldersLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                    {!isFoldersLoading && (
                        <Fragment>
                            {rdDashboardStoreSlice?.favoriteFoldersRes?.data.map((item, i) => (
                                <Card className={classnames({
                                    'ecommerce-card': true,
                                    'mb-1': true,
                                    'border-1px-white': selectedFolder?.id !== item.id,
                                    'cursor-pointer': true,
                                    'border-1px-purple': selectedFolder?.id === item.id
                                })} key={item.folder_name + i} onClick={() => handleSelectFolder(item)}>
                                    <CardBody className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <Folder
                                                className='mr-1 text-secondary'
                                                size={24}
                                            />
                                            <h5 className='mb-0'>{item.folder_name}</h5>
                                        </div>
                                    </CardBody>
                                </Card>
                            ))}
                        </Fragment>
                    )}

                    <p className="text-center">or</p>
                    <div className='form-group'>
                        <Label className={classnames({
                            'form-label': true
                        })} htmlFor={`folderName`}>
                            Folder Name<span className="is-invalid">*</span>
                        </Label>
                        <Input
                            type='text'
                            onChange={(e) => {
                                setFolderName(e.target.value)
                                setFolderNameError(false)
                            }}
                            value={folderName}
                            name={`folderName`}
                            id={`folderName`}
                            className={classnames({'is-invalid': folderNameError})}
                        />
                        {folderNameError && <div className="validation-err">This field is required</div>}
                    </div>
                    <div className="text-center">
                        <Button color='primary' outline disabled={isFolderCreating} onClick={handleCreateFolder}>
                            {isFolderCreating ? 'Creating...' : 'Create Folder'}
                        </Button>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' hidden={isAddingToFavorite} onClick={() => setIsSelectFoldersModalOpen(!isSelectFoldersModalOpen)}>
                        Cancel
                    </Button>
                    <Button color='primary' disabled={isAddingToFavorite} onClick={() => handleAddToFavorite()}>
                        {isAddingToFavorite ? 'Adding...' : 'Add to Favorite'}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

ProductsPage.propTypes = {
    activeView: PropTypes.string.isRequired,
    setActiveView: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired,
    sidebarOpen: PropTypes.bool.isRequired,
    setSidebarOpen: PropTypes.func.isRequired
}

export default ProductsPage
