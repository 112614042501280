import {useState} from 'react'
import {
    Row,
    Col,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu
} from 'reactstrap'
import {PropTypes} from "prop-types"

const ProductsHeader = props => {
    const {
        handleChangeTypeFilter
    } = props

    const [activeSortFilter, setActiveSortFilter] = useState('Past Partners')

    const handleFilter = (title, value) => {
        handleChangeTypeFilter(value)
        setActiveSortFilter(title)
    }

    return (
        <div className='ecommerce-header'>
            <Row>
                <Col sm='12'>
                    <div className='ecommerce-header-items'>
                        <div className='view-options d-flex ml-auto'>
                            <UncontrolledButtonDropdown className='dropdown-sort'>
                                <DropdownToggle className='text-capitalize' color='primary' outline caret>
                                    {activeSortFilter}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem
                                        className='w-100'
                                        onClick={() => handleFilter('My Favorites', 'favorite')}
                                    >
                                        My Favorites
                                    </DropdownItem>
                                    <DropdownItem
                                        className='w-100'
                                        onClick={() => handleFilter('Past Partners', 'partnerships')}
                                    >
                                        Past Partners
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}


ProductsHeader.propTypes = {
    handleChangeTypeFilter: PropTypes.func.isRequired
}

export default ProductsHeader
