import {useState} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Pagination, Navigation} from 'swiper'
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button
} from "reactstrap"
import style from './style.module.css'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/navigation/navigation.scss'
import QM from '../../../assets/images/QuestionMark.png'
import {PropTypes} from "prop-types"

SwiperCore.use([Pagination, Navigation])

const FAQ = ({faqsInfo}) => {
    const [isReadMoreModal, setIsReadMoreModal] = useState(false)
    const [selectedFaq, setSelectedFaq] = useState(null)

    const handleReadMore = (obj) => {
        setSelectedFaq(obj)
        setIsReadMoreModal(true)
    }

    return (
        <div className={style.faqWrap}>
            <div className={style.innerWrap}>
                <div className={style.heading}>Frequently Asked Questions</div>
                <Swiper
                    spaceBetween={20}
                    slidesPerView={3}
                    centeredSlides={true}
                    loop={true}
                    navigation={true}
                    pagination={{clickable: true}}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        }
                    }}
                >
                    {faqsInfo?.map((obj, index) => (
                        <SwiperSlide key={index}>
                            <div className={style.qoutewrap}>
                                <div className={style.Qwrap}>
                                    <div className={style.que}>{obj.question}</div>
                                    <img src={QM} alt='qm'/>
                                </div>
                                <div className={style.AnsWrap}>
                                    <div className={style.Ans}>{obj.answer}</div>
                                    <button onClick={() => handleReadMore(obj)} className='btn btn-link p-0'>Read More</button>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <Modal isOpen={isReadMoreModal} className='modal-dialog-centered modal-lg'>
                <ModalHeader>FAQ</ModalHeader>
                <ModalBody>
                    <h4 className="my-1 bg-light p-1">{selectedFaq?.question}</h4>
                    <p className="text-break text-justify p-1 line-height-condensed bg-light">{selectedFaq?.answer}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={() => setIsReadMoreModal(false)}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

FAQ.propTypes = {
    faqsInfo: PropTypes.arrayOf(
        PropTypes.shape({
            question: PropTypes.string.isRequired,
            answer: PropTypes.string.isRequired
        })
    )
}

export default FAQ
