import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { User} from "react-feather"
import styled from "styled-components"
import {
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap"
import defaultAvatar from "@src/assets/images/avatars/Ellipse.png"
import { useSelector, useDispatch } from "react-redux"
import {
  getPastEvents,
  getBrandsOfEvent
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import CustomPagination from "@src/views/components/pagination/CustomPagination"

const TabsBasic = () => {
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isBrandsListLoading, setIsBrandsListLoading] = useState(false)
  const [paginationInfo, setPaginationInfo] = useState(null)
  const [participateModal, setParticipateModal] = useState(false)
  const [eventId, setEventId] = useState('')
  const [brandId, setBrandId] = useState('')
  const pastEventsSlice = useSelector((store) => store?.brandsOppoprunities)
  const dispatch = useDispatch()

  useEffect(() => {
    const pastEvents = pastEventsSlice?.pastEvents ?? null
    if (pastEvents) {
      const { links, meta, pages } = pastEvents
      setPaginationInfo({ ...links, ...meta, pages })
      setIsTableLoading(false)
      setIsBrandsListLoading(false)
    }
  }, [pastEventsSlice])

  useEffect(() => {
    setIsTableLoading(true)
    dispatch(getPastEvents())
  }, [])

  // console.log("pastEventsSlice :", pastEventsSlice?.eventBrands)
  const handleCustomPagination = (pageUrl) => {
    setIsTableLoading(true)
    dispatch(getPastEvents(true, pageUrl))
  }

  const getBrands = (id) => {
    setEventId(id)
    setIsBrandsListLoading(true)
    dispatch(getBrandsOfEvent(id))
    setParticipateModal(true)
  }

  const UpcomingOppoWrapper = styled.div`
    ul {
      list-style: none
      padding: 0
    }
    li {
      font-weight: 600
      span {
        display: inline-block
        margin-right: 25px
        font-weight: 400
        width: 6rem
        white-space: nowrap
      }
    }
  `
  return (
    <React.Fragment>
      <h4 className="mb-2">Provide Partnerships Feedback</h4>
      {isTableLoading && (
        <div className="w-100 my-4 text-center">
          <Spinner color="primary" />
        </div>
      )}
      {!isTableLoading && (
        <Row>
          {pastEventsSlice?.pastEvents?.data &&
            pastEventsSlice?.pastEvents?.data?.map((pastEve) => (
              <Col sm="6" md="4" lg="4">
                <Card>
                  <CardHeader>
                    <CardTitle>{pastEve?.title}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <UpcomingOppoWrapper>
                      <Row className="mb-2">
                        <Col lg="3">Location:</Col>
                        <Col lg="9">{pastEve?.venue}</Col>
                        <Col lg="3">End Date:</Col>
                        <Col lg="9">{pastEve?.end_datetime}</Col>
                        <Col lg="3">Type:</Col>
                        <Col lg="9">{pastEve?.event_type}</Col>
                      </Row>
                      <Button.Ripple
                        color="secondary"
                        className="round m-25"
                        onClick={() => getBrands(pastEve?.id)}
                      >
                        <User size={14} />
                        <span className="align-middle ml-50">Feedback</span>
                      </Button.Ripple>
                    </UpcomingOppoWrapper>
                  </CardBody>
                </Card>
              </Col>
            ))}
          {pastEventsSlice?.pastEvents?.data.length === 0 && (
              <Col sm="12" md="12" lg="12"><span>Record does not exist.</span></Col>
          )}
          <CustomPagination
            pageLinks={paginationInfo?.pages}
            onPaginationClick={handleCustomPagination}
          />
        </Row>
      )}

      <Modal
        isOpen={participateModal}
        toggle={() => setParticipateModal(!participateModal)}
        className="modal-lg modal-dialog-centered"
      >
        <ModalHeader toggle={() => setParticipateModal(!participateModal)}>
          Select Brand
        </ModalHeader>
        <ModalBody>
            <Row>
              {isBrandsListLoading && (
                <div className="w-100 my-4 text-center">
                  <Spinner color="primary" />
                </div>
              )}

              {!isBrandsListLoading && (
                  pastEventsSlice?.eventBrands?.data?.length ? (
                      pastEventsSlice?.eventBrands?.data &&
                      pastEventsSlice?.eventBrands?.data?.map((brand) => (
                          <Col lg="4">
                            <Link to={{
                              pathname: `/dashboard/rd/partnerships/${eventId}/provide-event-feedback/${brand?.user_id}`,
                              query: {brandId: brand.id}
                            }}>
                              <div className="text-center border mb-1">
                                <img
                                    className="img-fluid"
                                    src={brand?.brand_logo ?? defaultAvatar}
                                    alt={brand?.brand_logo ?? defaultAvatar}
                                    height="120"
                                    width="120"
                                />
                                <h5 className="text-truncate p-1 font-weight-bold">{brand?.brand_name}</h5>
                              </div>
                            </Link>
                          </Col>
                      ))) : (
                          <div className="p-1 font-weight-bold">
                            Sorry! No brand found...
                          </div>
                  ))}
            </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
export default TabsBasic
