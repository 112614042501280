import React from 'react'
import {
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap"
import {
    DEFAULT_BANNERS
} from "./Controller"

const DefaultBanners = ({openModal, closeModal, onClick}) => {
    return (
        <Modal size="lg" isOpen={openModal} className="modal-dialog-centered">
            <ModalHeader toggle={closeModal}>
                <div className="font-inter f_size inter-medium txt_color modal_head">
                    Select Default Banner
                </div>
            </ModalHeader>
            <ModalBody className="px-2 pt-3">
                <div>
                    <Row>
                        {DEFAULT_BANNERS.map((image, index) => (
                            <Col md="6" sm="12" key={index}>
                                <div className={`mb-2 campaign-banner`} onClick={() => onClick(image)}>
                                    <img src={image} alt={`bg${index}`} width="100%"/>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default DefaultBanners