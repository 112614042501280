import {Fragment, useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import classnames from 'classnames'
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CustomInput,
    FormGroup,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input
} from 'reactstrap'
import Select from 'react-select'
import {ArrowDownCircle, Folder, PlusCircle, X} from 'react-feather'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import {
    getGoalsAttributes
} from '@src/views/brands/nutritionExperts/store/actions'
import {
    getNutritionExperts,
    searchNutritionExperts,
    getFavoriteFolders,
    nullResponse,
    createFavoriteFolder,
    addToFavorite,
    getCountries,
    getRegionsByCountryId,
    getStatesByRegionId,
    getCitiesByStateId
} from '@src/redux/actions/brandsDashboard'
import {NULL} from '@src/redux/actionTypes'
import ProductCards from './ProductCards'
import ProductsSearchbar from './ProductsSearchbar'
import queryString from 'query-string'

const NutritionExperts = () => {
    const history = useHistory()

    const [isSelectFoldersModalOpen, setIsSelectFoldersModalOpen] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [selectedFolder, setSelectedFolder] = useState(null)
    const [selectedProfile, setSelectedProfile] = useState(null)
    const [folderNameError, setFolderNameError] = useState(false)
    const [isFolderCreating, setIsFolderCreating] = useState(false)

    const [isPageLoading, setIsPageLoading] = useState(false)
    const [isListLoading, setIsListLoading] = useState(false)
    const [isFoldersLoading, setIsFoldersLoading] = useState(false)
    const [isAddingToFavorite, setIsAddingToFavorite] = useState(false)

    const [showFilters, setShowFilters] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [industryValue, setIndustryValue] = useState('')
    const [specialityValues, setSpecialityValues] = useState([])
    const [personalAttrValues, setPersonalAttrValues] = useState([])
    const [paginationInfo, setPaginationInfo] = useState(null)
    const [goalsAttributes, setGoalsAttrbutes] = useState(null)

    const [countryOptions, setCountryOptions] = useState(null)
    const [regionOptions, setRegionOptions] = useState(null)
    const [stateOptions, setStateOptions] = useState(null)
    const [cityOptions, setCityOptions] = useState(null)

    const [isRegionsLoading, setIsRegionsLoading] = useState(false)
    const [isStatesLoading, setIsStatesLoading] = useState(false)
    const [isCitiesLoading, setIsCitiesLoading] = useState(false)

    const [selectedFollowers, setSelectedFollowers] = useState(null)

    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedRegion, setSelectedRegion] = useState(null)
    const [selectedState, setSelectedState] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)
    const [filterBy, setFilterBy] = useState({value: "All", label: "All"})
    const [rdGender, setRdGender] = useState("")

    const [queryParams, setQueryParams] = useState([])
    const brandsDashboardStoreSlice = useSelector(store => store.brandsDashboardSlice)

    const dispatch = useDispatch()

    useEffect(() => {
        const parsed = queryString.parseUrl(window.location.href)
        let path = `${process.env.REACT_APP_BASEURL}brand/nutrition-expert`
        if (parsed.query) {
            const obj = {}
            setQueryParams([])
            if (parsed.query?.sep) {
                obj.speciality = parsed.query.sep?.split(",")
                setQueryParams(prevState => [...prevState, `sep=${parsed.query.sep}`])
            }
            if (parsed.query.ind) {
                obj.industry = [parsed.query.ind]
                setIndustryValue(parsed.query.ind)
                setQueryParams(prevState => [...prevState, `ind=${parsed.query.ind}`])
            }
            if (parsed.query.pc) {
                obj.personal_attr = parsed.query.pc?.split(",")
                setQueryParams(prevState => [...prevState, `pc=${parsed.query.pc}`])
            }

            if (parsed.query.cou) {
                obj.country = parsed.query.cou
                setQueryParams(prevState => [...prevState, `cou=${parsed.query.cou}`])
            }
            if (parsed.query.reg) {
                obj.region = parsed.query.reg
                setQueryParams(prevState => [...prevState, `reg=${parsed.query.reg}`])
            }
            if (parsed.query.st) {
                obj.state = parsed.query.st
                setQueryParams(prevState => [...prevState, `st=${parsed.query.st}`])
            }
            if (parsed.query.ct) {
                obj.city = parsed.query.ct
                setQueryParams(prevState => [...prevState, `ct=${parsed.query.ct}`])
            }
            if (parsed.query.q) {
                obj.name = parsed.query.q
                setQueryParams(prevState => [...prevState, `q=${parsed.query.q}`])
                setSearchValue(parsed.query.q)
            }
            if (parsed.query?.type) {
                obj.type = parsed.query.type
                setFilterBy({value: parsed.query.type, label: parsed.query.type})
                setQueryParams(prevState => [...prevState, `type=${parsed.query.type}`])
            }
            if (parsed.query.page) {
                path = `${path}?page=${parsed.query.page}`
                setQueryParams(prevState => [...prevState, `page=${parsed.query.page}`])
            }
            if (parsed.query?.g) {
                obj.gender = parsed.query.g
                setRdGender({value: parsed.query.g, label: parsed.query.g})
                setQueryParams(prevState => [...prevState, `type=${parsed.query.g}`])
            }
            dispatch(searchNutritionExperts(true, obj, path))
        } else {
            dispatch(getNutritionExperts(false))
        }
        setIsPageLoading(true)
        setIsListLoading(true)
        dispatch(getGoalsAttributes())
        dispatch(getCountries())
        dispatch(getFavoriteFolders(false))
        dispatch(nullResponse(NULL.BRANDS_REGIONS_BY_COUNTRY_ID))
        dispatch(nullResponse(NULL.BRANDS_STATES_BY_REGIONS_ID))
        dispatch(nullResponse(NULL.BRANDS_CITIES_BY_STATES_ID))
        dispatch({type: 'CLEAR_ALL_RDS'})
    }, [])

    useEffect(() => {
        if (!!brandsDashboardStoreSlice?.addToFavroiteRes) {
            dispatch(nullResponse(NULL.BRANDS_NUTRITION_EXPERTS))
            setIsAddingToFavorite(false)
            setIsListLoading(true)
            setIsSelectFoldersModalOpen(false)
            dispatch(nullResponse(NULL.BRANDS_ADD_TO_FAVORITE))
            setIsPageLoading(true)
            const parsed = queryString.parseUrl(window.location.href)
            let path = `${process.env.REACT_APP_BASEURL}brand/nutrition-expert`
            if (parsed.query) {
                const obj = {}
                setQueryParams([])
                if (parsed.query?.sep) {
                    obj.speciality = parsed.query.sep?.split(",")
                    setQueryParams(prevState => [...prevState, `sep=${parsed.query.sep}`])
                }
                if (parsed.query.ind) {
                    obj.industry = [parsed.query.ind]
                    setIndustryValue(parsed.query.ind)
                    setQueryParams(prevState => [...prevState, `ind=${parsed.query.ind}`])
                }
                if (parsed.query.pc) {
                    obj.personal_attr = parsed.query.pc?.split(",")
                    setQueryParams(prevState => [...prevState, `pc=${parsed.query.pc}`])
                }

                if (parsed.query.cou) {
                    obj.country = parsed.query.cou
                    setQueryParams(prevState => [...prevState, `cou=${parsed.query.cou}`])
                }
                if (parsed.query.reg) {
                    obj.region = parsed.query.reg
                    setQueryParams(prevState => [...prevState, `reg=${parsed.query.reg}`])
                }
                if (parsed.query.st) {
                    obj.state = parsed.query.st
                    setQueryParams(prevState => [...prevState, `st=${parsed.query.st}`])
                }
                if (parsed.query.ct) {
                    obj.city = parsed.query.ct
                    setQueryParams(prevState => [...prevState, `ct=${parsed.query.ct}`])
                }
                if (parsed.query.q) {
                    obj.name = parsed.query.q
                    setQueryParams(prevState => [...prevState, `q=${parsed.query.q}`])
                    setSearchValue(parsed.query.q)
                }
                if (parsed.query?.type) {
                    obj.type = parsed.query.type
                    setFilterBy({value: parsed.query.type, label: parsed.query.type})
                    setQueryParams(prevState => [...prevState, `type=${parsed.query.type}`])
                }
                if (parsed.query.page) {
                    path = `${path}?page=${parsed.query.page}`
                    setQueryParams(prevState => [...prevState, `page=${parsed.query.page}`])
                }
                if (parsed.query?.g) {
                    obj.gender = parsed.query.g
                    setRdGender({value: parsed.query.g, label: parsed.query.g})
                    setQueryParams(prevState => [...prevState, `type=${parsed.query.g}`])
                }
                dispatch(searchNutritionExperts(true, obj, path))
            } else {
                dispatch(getNutritionExperts(false))
            }
        }
        if (!!brandsDashboardStoreSlice?.favoriteFoldersRes) {
            setIsFoldersLoading(false)
        }
        if (!!brandsDashboardStoreSlice?.createfavoriteFolderRes) {
            setFolderName('')
            setIsFolderCreating(false)
            dispatch(nullResponse(NULL.BRANDS_GET_FOLDERS))
            dispatch(nullResponse(NULL.BRANDS_CREATE_FOLDER))
            dispatch(getFavoriteFolders(false))
            setIsFoldersLoading(true)
        }

        const parsed = queryString.parseUrl(window.location.href)

        if (!!brandsDashboardStoreSlice?.countries) {
            setCountryOptions(brandsDashboardStoreSlice.countries)
        }

        if (!!brandsDashboardStoreSlice?.regionsByCountryID) {
            setRegionOptions(brandsDashboardStoreSlice?.regionsByCountryID)
            setIsRegionsLoading(false)
        }

        if (!!brandsDashboardStoreSlice?.statesByRegionID) {
            setStateOptions(brandsDashboardStoreSlice?.statesByRegionID)
            setIsStatesLoading(false)
        }

        if (!!brandsDashboardStoreSlice?.citiesByStateID) {
            setCityOptions(brandsDashboardStoreSlice?.citiesByStateID)
            setIsCitiesLoading(false)
        }

        if (!!brandsDashboardStoreSlice?.goalsAttributes) {
            setGoalsAttrbutes(brandsDashboardStoreSlice.goalsAttributes)
            if (parsed.query.sep) {
                const sep = parsed.query.sep?.split(",")
                const dataArr = brandsDashboardStoreSlice.goalsAttributes.speciality.filter(item => sep.some(val => item.id === parseInt(val))).map(i => i.id)
                setSpecialityValues(dataArr)
            }
            if (parsed.query.pc) {
                const pc = parsed.query.pc?.split(",")
                const dataArr = brandsDashboardStoreSlice.goalsAttributes.personal_attributes.filter(item => pc.some(val => item.id === parseInt(val))).map(i => i.id)
                setPersonalAttrValues(dataArr)
            }
        }

        const nutritionExperts = brandsDashboardStoreSlice?.nutritionExperts ?? null
        if (nutritionExperts) {
            setIsPageLoading(false)
            setIsListLoading(false)
            const {links, meta, pages} = nutritionExperts
            setPaginationInfo({...links, ...meta, pages})
        }
        if (!nutritionExperts) {
            setIsPageLoading(true)
            setIsListLoading(true)
        }
    }, [brandsDashboardStoreSlice])

    const handleCustomPagination = pageUrl => {
        setIsPageLoading(true)
        dispatch(searchNutritionExperts(true, {
            speciality: specialityValues,
            industry: industryValue ? [industryValue] : [],
            personal_attr: personalAttrValues,
            gender: '',
            country: selectedCountry?.id || null,
            region: selectedRegion?.id || null,
            state: selectedState?.id || null,
            city: selectedCity?.id || null,
            name: searchValue,
            type: filterBy?.value || null
        }, pageUrl))

        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        const prevStat = queryParams.filter(item => !item.startsWith("page="))
        setQueryParams([...prevStat, `page=${pageNo}`])
    }

    const handleChange = (filterName, name, value) => {
        switch (filterName) {
            case 'IndustryChange':
                setIndustryValue(parseInt(value))
                break
            case 'SpecialityChange':
                let values = [...specialityValues]
                if (value) values.push(parseInt(name))
                if (!value) values = values.filter(x => x !== parseInt(name))
                setSpecialityValues(values)
                break
            case 'PersonalAttrChange':
                let values1 = [...personalAttrValues]
                if (value) values1.push(parseInt(name))
                if (!value) values1 = values1.filter(x => x !== parseInt(name))
                setPersonalAttrValues(values1)
                break
        }
    }

    const updateLocation = (location, locationValue) => {
        switch (location) {
            case 'Regions':
                dispatch(getRegionsByCountryId(locationValue))
                break
            case 'States':
                dispatch(getStatesByRegionId(locationValue))
                break
            case 'Cities':
                dispatch(getCitiesByStateId(locationValue))
                break
        }
    }

    const handleSelect = (select, value) => {
        switch (select) {
            case 'CountrySelect':
                setIsRegionsLoading(true)
                updateLocation('Regions', value)
                console.log("value", value)
                setSelectedCountry(value)
                setSelectedRegion(null)
                setSelectedState(null)
                setSelectedCity(null)
                break
            case 'RegionSelect':
                setIsStatesLoading(true)
                updateLocation('States', value)
                setSelectedRegion(value)
                setSelectedState(null)
                setSelectedCity(null)
                break
            case 'StateSelect':
                setIsCitiesLoading(true)
                updateLocation('Cities', value)
                setSelectedState(value)
                setSelectedCity(null)
                break
            case 'CitySelect':
                setSelectedCity(value)
                break
            case 'genderSelect':
                setRdGender(value)
                break
            case 'FollowersSelect':
                setSelectedFollowers(0)
                break
        }
    }

    const handleFiltersSearch = () => {
        setQueryParams([])
        setIsListLoading(true)
        dispatch(searchNutritionExperts(false, {
            speciality: specialityValues,
            industry: industryValue ? [industryValue] : [],
            personal_attr: personalAttrValues,
            gender: rdGender?.value ?? "",
            country: selectedCountry?.id || null,
            region: selectedRegion?.id || null,
            state: selectedState?.id || null,
            city: selectedCity?.id || null,
            name: searchValue,
            followers: selectedFollowers?.value || null,
            type: filterBy?.value
        }))
        if (specialityValues.length > 0) {
            setQueryParams(prevState => [...prevState, `sep=${specialityValues.join(",")}`])
        }
        if (industryValue) {
            setQueryParams(prevState => [...prevState, `ind=${industryValue}`])
        }
        if (personalAttrValues.length > 0) {
            setQueryParams(prevState => [...prevState, `pc=${personalAttrValues.join(",")}`])
        }

        if (selectedCountry?.id) {
            setQueryParams(prevState => [...prevState, `cou=${selectedCountry?.id}`])
        }
        if (selectedRegion?.id) {
            setQueryParams(prevState => [...prevState, `reg=${selectedRegion?.id}`])
        }
        if (selectedState?.id) {
            setQueryParams(prevState => [...prevState, `st=${selectedState?.id}`])
        }
        if (selectedCity?.id) {
            setQueryParams(prevState => [...prevState, `ct=${selectedCity?.id}`])
        }
        if (searchValue) {
            setQueryParams(prevState => [...prevState, `q=${searchValue}`])
        }
        if (rdGender?.value) {
            setQueryParams(prevState => [...prevState, `g=${rdGender?.value}`])
        }
        if (filterBy?.value) {
            setQueryParams(prevState => [...prevState, `type=${filterBy.value}`])
        }
    }

    useEffect(() => {
        history.push({
            pathname: window.location.pathname,
            search: `?${queryParams.join("&")}`
        })
    }, [queryParams])

    const handleSearchByName = (searchValue) => {
        setFilterBy(null)
        setQueryParams([])
        setIsListLoading(true)
        setSearchValue(searchValue)
        dispatch(searchNutritionExperts(false, {
            speciality: [],
            industry: [],
            personal_attr: [],
            gender: null,
            country: null,
            region: null,
            state: null,
            city: null,
            name: searchValue
        }))
        setQueryParams([`q=${searchValue}`])
        setSpecialityValues([])
        setIndustryValue(null)
        setPersonalAttrValues([])
        setSelectedCountry(null)
        setSelectedRegion(null)
        setSelectedState(null)
        setSelectedCity(null)
        setSelectedFollowers(null)
        setRdGender("")
    }

    const handleResetSearch = () => {
        setFilterBy(null)
        setIsListLoading(true)
        setSearchValue('')
        document.querySelectorAll("input[type=radio]").forEach(radio => {
            radio.checked = false
        })
        document.querySelectorAll("input[type=checkbox]").forEach(checkbox => {
            checkbox.checked = false
        })
        setSelectedCountry(null)
        setSelectedRegion(null)
        setSelectedState(null)
        setSelectedCity(null)
        setSelectedFollowers(null)
        setIndustryValue(null)
        setSpecialityValues([])
        setPersonalAttrValues([])
        setRdGender("")
        setFilterBy({id: null, value: "All", label: "All"})
        dispatch(getNutritionExperts(false))
        setQueryParams([])
        history.push({
            pathname: window.location.pathname
        })
    }

    const handleCreateFolder = () => {
        if (!folderName) {
            setFolderNameError(true)
            return
        }
        setFolderNameError(false)
        setIsFolderCreating(true)
        dispatch(createFavoriteFolder({folderName}))
    }

    const handleSelectFolder = (folder) => {
        setSelectedFolder(folder)
    }

    const getAddToFavoriteProfile = (profile) => {
        setSelectedProfile(profile)
        if (!profile.is_favorite) setIsSelectFoldersModalOpen(true)
        if (profile.is_favorite) {
            setIsListLoading(true)
            dispatch(addToFavorite({id: profile.id, folder_id: profile.folder_id, type: 'profile'}))
        }
    }

    const handleAddToFavorite = () => {
        setIsAddingToFavorite(true)
        dispatch(addToFavorite({id: selectedProfile.id, folder_id: selectedFolder.id, type: 'profile'}))
    }

    const genderOption = [
        {value: 'Male', label: 'Male'},
        {value: 'Female', label: 'Female'},
        {value: 'Non-Binary', label: 'Non-Binary'},
        {value: 'Transgender', label: 'Transgender'}
    ]

    return (
        <>
            {isPageLoading ? (
                <div className="w-100 my-4 text-center"><Spinner color='primary'/></div>
            ) : (
                <div className='content-detached'>
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className='filter-heading'>All Nutrition Experts</h4>
                    </div>
                    {showFilters && (
                        <Card className='my-0 filters-right-sidebar'>
                            <CardHeader className="card-header-c">
                                <h3 className='filter-heading my-0'>Filters</h3>
                                <Button.Ripple className='btn-icon' color='danger' onClick={() => setShowFilters(false)}>
                                    <X size={16}/>
                                </Button.Ripple>
                            </CardHeader>
                            <CardBody className="overflow-y-auto pt-1">
                                <FormGroup>
                                    <h6 className='filter-title bg-light p-1 font-weight-bold'>View By:</h6>
                                    <Select options={[{value: "New", label: "New"}, {value: "All", label: "All"}]}
                                            className='react-select'
                                            classNamePrefix='select'
                                            placeholder="---Select---"
                                            value={filterBy}
                                            onChange={(value) => {
                                                setFilterBy(value)
                                            }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title bg-light p-1 font-weight-bold'>Speciality:</h6>
                                    {goalsAttributes?.speciality?.map((brand, index) => (
                                        <CustomInput key={index}
                                                     type='checkbox'
                                                     className="mx-2 mb-1"
                                                     name={brand.id}
                                                     id={`speciality-${index}`}
                                                     label={brand.label}
                                                     checked={specialityValues?.includes(brand.id)}
                                                     onChange={e => handleChange('SpecialityChange', e.target.name, e.target.checked)}
                                        />
                                    ))}
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title bg-light p-1 font-weight-bold'>Industry:</h6>
                                    {goalsAttributes?.industry?.map((brand, index) => (
                                        <CustomInput key={index}
                                                     type='radio'
                                                     className="mx-2 mb-1"
                                                     id={`industry-${index}`}
                                                     value={brand.id}
                                                     name="industry"
                                                     label={brand.label}
                                                     checked={parseInt(industryValue) === brand.id}
                                                     onChange={(e) => handleChange('IndustryChange', e.target.name, e.target.value)}
                                        />
                                    ))}
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title bg-light p-1 font-weight-bold'>Personal Characteristics:</h6>
                                    {goalsAttributes?.personal_attributes?.map((brand, index) => (
                                        <CustomInput key={index}
                                                     className="mx-2 mb-1"
                                                     type='checkbox'
                                                     id={`personal-characteristics-${index}`}
                                                     name={brand.id}
                                                     label={brand.label}
                                                     checked={personalAttrValues?.includes(brand.id)}
                                                     onChange={e => handleChange('PersonalAttrChange', e.target.name, e.target.checked)}
                                        />
                                    ))}
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title bg-light p-1 font-weight-bold'>Gender:</h6>
                                    <Select options={genderOption}
                                            className='react-select'
                                            classNamePrefix='select'
                                            value={rdGender}
                                            onChange={(value) => handleSelect("genderSelect", value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title bg-light p-1 font-weight-bold'>Country:</h6>
                                    <Select options={countryOptions}
                                            className='react-select'
                                            classNamePrefix='select'
                                            value={selectedCountry}
                                            onChange={(value) => handleSelect("CountrySelect", value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title bg-light p-1 font-weight-bold'>Region:</h6>
                                    <div className="overlay-select-parent">
                                        {isRegionsLoading && <div className="overlay-select"></div>}
                                        <Select options={regionOptions}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={selectedRegion}
                                                onChange={(value) => handleSelect("RegionSelect", value)}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title bg-light p-1 font-weight-bold'>State:</h6>
                                    <div className="overlay-select-parent">
                                        {isStatesLoading && <div className="overlay-select"></div>}
                                        <Select options={stateOptions}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={selectedState}
                                                onChange={(value) => handleSelect("StateSelect", value)}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title bg-light p-1 font-weight-bold'>City:</h6>
                                    <div className="overlay-select-parent">
                                        {isCitiesLoading && <div className="overlay-select"></div>}
                                        <Select options={cityOptions}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={selectedCity}
                                                onChange={(value) => handleSelect("CitySelect", value)}
                                        />
                                    </div>
                                </FormGroup>
                            </CardBody>
                            <CardFooter>
                                <Button.Ripple color='primary' className="mb-1" onClick={handleFiltersSearch} block={true}>
                                    Apply Filters
                                </Button.Ripple>
                                <Button.Ripple color='danger' outline onClick={handleResetSearch} block={true}>
                                    Clear All Filters
                                </Button.Ripple>
                            </CardFooter>
                        </Card>
                    )}
                    <div className='content-body'>
                        <ProductsSearchbar handleSearch={handleSearchByName} handleReset={handleResetSearch} searchValue={searchValue}/>
                        {isListLoading ? (
                            <div className="w-100 my-4 text-center"><Spinner color='primary'/></div>
                        ) : (brandsDashboardStoreSlice?.nutritionExperts?.data?.length > 0 ? (
                            <>
                                <div className="d-flex justify-content-between">
                                    <div className="mt-1 font-medium-3">
                                        {paginationInfo?.total > 0 && (
                                            <span>{paginationInfo?.total} RDs found</span>
                                        )}
                                    </div>
                                    <div>
                                        <Button.Ripple className='btn-icon' outline color='primary' onClick={() => setShowFilters(!showFilters)}>
                                            Filter By…
                                        </Button.Ripple>
                                    </div>
                                </div>
                                <ProductCards activeView='grid'
                                              nutritionExperts={brandsDashboardStoreSlice?.nutritionExperts?.data}
                                              handleAddToFavorite={getAddToFavoriteProfile}
                                />
                                <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                            </>
                        ) : (
                            <div className='d-flex justify-content-center mt-2'>
                                <p>Record doesn't exists</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <Modal scrollable isOpen={isSelectFoldersModalOpen} className='modal-dialog-centered'>
                <ModalHeader>Add to favorite</ModalHeader>
                <ModalBody style={{maxHeight: '70vh', overflowY: 'auto'}}>
                    <p className="font-weight-bold bg-light p-1 text-primary">
                        <PlusCircle size={16}/> Create New Folder
                    </p>
                    <div className='form-group d-flex'>
                        <Input type='text'
                               onChange={(e) => {
                                   setFolderName(e.target.value)
                                   setFolderNameError(false)
                               }}
                               value={folderName}
                               name={`folderName`}
                               id={`folderName`}
                               className={classnames({'is-invalid': folderNameError})}
                               placeholder='Folder Name'
                        />
                        <Button color='primary' disabled={isFolderCreating} onClick={handleCreateFolder} className='ml-1'>
                            Create
                        </Button>
                    </div>
                    {isFoldersLoading ? (
                        <div className="w-100 my-4 text-center"><Spinner color='primary'/></div>
                    ) : (
                        brandsDashboardStoreSlice?.favoriteFoldersRes?.data?.length > 0 && (
                            <>
                                <p className="font-weight-bold bg-light p-1 text-primary">
                                    <ArrowDownCircle size={16}/> Select Existing Folder
                                </p>
                                {brandsDashboardStoreSlice?.favoriteFoldersRes?.data?.map(item => (
                                    <Card className={classnames({
                                        'ecommerce-card': true,
                                        'mb-1': true,
                                        'border-1px-white': selectedFolder?.id !== item.id,
                                        'cursor-pointer': true,
                                        'border-1px-purple': selectedFolder?.id === item.id
                                    })} key={item.folder_name} onClick={() => handleSelectFolder(item)}>
                                        <CardBody className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <Folder className='mr-1 text-secondary' size={24}/>
                                                <h5 className='mb-0'>{item.folder_name}</h5>
                                            </div>
                                        </CardBody>
                                    </Card>
                                ))}
                            </>
                        )
                    )}
                </ModalBody>
                <ModalFooter className='d-flex justify-content-between'>
                    <Button color='danger' disabled={isAddingToFavorite} onClick={() => {
                        setIsSelectFoldersModalOpen(false)
                        setSelectedFolder(null)
                        setFolderName(null)
                        setFolderNameError(null)
                    }}>
                        Cancel
                    </Button>
                    <Button color='primary' disabled={isAddingToFavorite || !selectedFolder} onClick={() => handleAddToFavorite()}>
                        Add
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default NutritionExperts
