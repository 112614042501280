import {useState, Fragment, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {
    getBrandsData,
    brandsSearchData,
    deleteBrandsRDsData,
    updateManageBrandStatus,
    exportBrandsData,
    nullSearchData,
    assignPlanToBrand,
    getSecondaryUsers,
    makePrimaryUser,
    makePrimaryUserReset,
    profileViews,
    campaignData,
    profileLinks,
    recommendationViews,
    onlyActiveBrands,
    brandsTotalImpression,
    brandsMassMessages
} from '@src/views/tables/data-tables-admin-brands/store/actions'
import {NULL} from '@src/views/tables/data-tables-admin-brands/store/actionTypes'
import {
    Search,
    RefreshCcw,
    FileText,
    MoreHorizontal,
    Eye,
    Trash,
    Users,
    Activity,
    CheckCircle,
    List,
    Info,
    Unlock,
    RefreshCw,
    XCircle
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CustomInput,
    UncontrolledDropdown,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Alert,
    Table,
    Jumbotron,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, Form
} from 'reactstrap'
import classnames from "classnames"
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import Select from "react-select"
import {
    brandCancelPlanAction,
    brandCancelPlanResetAction,
    brandsDirectMessagesAction
} from '@store/actions/AdminActions'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Flatpickr from 'react-flatpickr'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import moment from "moment"

const ManageBrands = () => {

    const [campaignUserID, setCampaignUserID] = useState('')
    const [durationValue, setDurationValue] = useState(null)
    const [statusValue, setStatusValue] = useState(null)

    const sweetAlert = withReactContent(Swal)

    const dispatch = useDispatch()

    const profileViewsFromDateForm = useFormik({
        initialValues: {
            startDate: '',
            endDate: ''
        },
        validationSchema: Yup.object().shape({
            startDate: Yup.array().required('Start date is required.'),
            endDate: Yup.array().required('End date is required.')
        }),
        onSubmit: (values) => {
            setDurationValue(null)
            const startDate = moment(values.startDate[0]).format('yyyy-MM-DD')
            const endDate = moment(values.endDate[0]).format('yyyy-MM-DD')
            dispatch(profileViews(`?startDate=${startDate}&endDate=${endDate}&s=${statusValue?.value ?? ''}`))
        }
    })

    const linksClickedFromDateForm = useFormik({
        initialValues: {
            startDate: '',
            endDate: ''
        },
        validationSchema: Yup.object().shape({
            startDate: Yup.array().required('Start date is required.'),
            endDate: Yup.array().required('End date is required.')
        }),
        onSubmit: (values) => {
            setDurationValue(null)
            const startDate = moment(values.startDate[0]).format('yyyy-MM-DD')
            const endDate = moment(values.endDate[0]).format('yyyy-MM-DD')
            dispatch(profileLinks(`?startDate=${startDate}&endDate=${endDate}&s=${statusValue?.value ?? ''}`))
        }
    })

    const campaignsFromDateForm = useFormik({
        initialValues: {
            startDate: '',
            endDate: ''
        },
        validationSchema: Yup.object().shape({
            startDate: Yup.array().required('Start date is required.'),
            endDate: Yup.array().required('End date is required.')
        }),
        onSubmit: (values) => {
            setDurationValue(null)
            const startDate = moment(values.startDate[0]).format('yyyy-MM-DD')
            const endDate = moment(values.endDate[0]).format('yyyy-MM-DD')
            dispatch(campaignData({
                id: campaignUserID,
                startDate,
                endDate
            }))
        }
    })

    const recommendedFromDateForm = useFormik({
        initialValues: {
            startDate: '',
            endDate: ''
        },
        validationSchema: Yup.object().shape({
            startDate: Yup.array().required('Start date is required.'),
            endDate: Yup.array().required('End date is required.')
        }),
        onSubmit: (values) => {
            setDurationValue(null)
            const startDate = moment(values.startDate[0]).format('yyyy-MM-DD')
            const endDate = moment(values.endDate[0]).format('yyyy-MM-DD')
            dispatch(recommendationViews(`?startDate=${startDate}&endDate=${endDate}&s=${statusValue?.value ?? ''}`))
        }
    })

    const massMessageFromDateForm = useFormik({
        initialValues: {
            startDate: '',
            endDate: ''
        },
        validationSchema: Yup.object().shape({
            startDate: Yup.array().required('Start date is required.'),
            endDate: Yup.array().required('End date is required.')
        }),
        onSubmit: (values) => {
            setDurationValue(null)
            const startDate = moment(values.startDate[0]).format('yyyy-MM-DD')
            const endDate = moment(values.endDate[0]).format('yyyy-MM-DD')
            dispatch(brandsMassMessages(`?startDate=${startDate}&endDate=${endDate}&s=${statusValue?.value ?? ''}`))
        }
    })

    const directMessageFromDateForm = useFormik({
        initialValues: {
            startDate: '',
            endDate: ''
        },
        validationSchema: Yup.object().shape({
            startDate: Yup.array().required('Start date is required.'),
            endDate: Yup.array().required('End date is required.')
        }),
        onSubmit: (values) => {
            setDurationValue(null)
            const startDate = moment(values.startDate[0]).format('yyyy-MM-DD')
            const endDate = moment(values.endDate[0]).format('yyyy-MM-DD')
            dispatch(brandsDirectMessagesAction(`?startDate=${startDate}&endDate=${endDate}&s=${statusValue?.value ?? ''}`))
        }
    })

    const {
        brandCancelPlanLoading,
        brandCancelPlanData,
        brandCancelPlanError,

        brandDirectMessagesLoading,
        brandDirectMessagesData,
        brandDirectMessagesError
    } = useSelector(store => store.adminReducer)

    const brandsSelector = useSelector(store => store.dataTablesAdminBrands)

    const {
        secondaryUserRequest,
        secondaryUserData,
        secondaryUserFail,

        makePrimaryRequest,
        makePrimaryData,
        makePrimaryFail,

        profileViewsRequest,
        profileViewsData,
        profileViewsFail,

        campaignDataRequest,
        campaignDataData,
        campaignDataFail,

        profileLinksRequest,
        profileLinksData,
        profileLinksFail,

        recoViewsRequest,
        recoViewsData,
        recoViewsFail,

        activeBrandsRequest,
        activeBrandsData,
        activeBrandsFail,

        brandTotalImpRequest,
        brandTotalImpData,
        brandTotalImpFail,

        brandMassMsgRequest,
        brandMassMsgData,
        brandMassMsgFail
    } = useSelector(store => store.dataTablesAdminBrands)

    const [searchName, setSearchName] = useState('')
    const [searchCompanyName, setSearchCompanyName] = useState('')
    const [searchStatus, setSearchStatus] = useState('')
    const [searchEmail, setSearchEmail] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [deleteFAQModal, setDeleteFAQModal] = useState(false)
    const [show, setShow] = useState(false)
    const [isPageLoading, setIsPageLoading] = useState(true)
    const [manageBrands, setManageBrands] = useState([])
    const [paginationInfo, setPaginationInfo] = useState(null)
    const [activePaginationUrl, setActivePaginationUrl] = useState('')
    const [successMsg, setSuccessMsg] = useState('')
    const [visible, setVisible] = useState(false)
    const [error, setError] = useState('')
    const [deleteMsg, setDeleteMsg] = useState('')
    const [usersModal, setUsersModal] = useState(false)
    const [primaryModal, setPrimaryModal] = useState(false)
    const [primaryData, setPrimaryData] = useState({})
    const [brandStatsModal, setBrandStatsModal] = useState(false)
    const [brandImpressionModal, setBrandImpressionModal] = useState(false)
    const [activeTab, setActiveTab] = useState('1')

    const [monthFilter, setMonthFilter] = useState('')
    const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false)

    const updateBrandStatus = (id) => {
        const params = {
            id,
            type: "manage"
        }
        dispatch(updateManageBrandStatus(params))
    }

    const assignPlan = (userId, duration) => {
        const params = {
            userId,
            duration: parseInt(duration)
        }
        dispatch(assignPlanToBrand(params))
        setIsPageLoading(true)
    }

    const secondaryUsers = (data) => {
        dispatch(getSecondaryUsers({
            id: data?.id
        }))
    }

    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: 'Company Name',
            selector: 'company',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Contact Name',
            selector: 'name',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Subscription Plan',
            selector: 'plan',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            minWidth: '80px',
            cell: row => {
                return (
                    <CustomInput
                        className='custom-control-success'
                        type='switch'
                        id={`success_${row.id}`}
                        name='success'
                        inline
                        defaultChecked={row.status === 'enabled'}
                        onClick={() => updateBrandStatus(row.id)}
                    />
                )
            }
        },
        {
            name: 'Registration Date',
            selector: 'reg_date',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Actions',
            allowOverflow: true,
            cell: row => {
                return (
                    <div className='d-flex pl-2'>
                        <UncontrolledDropdown>
                            <DropdownToggle className='pr-1 cursor-pointer' tag='span'>
                                <MoreHorizontal size={15}/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem tag={Link} onClick={() => assignPlan(row.id, '30')}>
                                    <Activity size={15}/>
                                    <span className='align-middle ml-50'>Assign free for 1 month</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} onClick={() => assignPlan(row.id, '90')}>
                                    <Activity size={15}/>
                                    <span className='align-middle ml-50'>Assign free for 3 month</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} onClick={() => assignPlan(row.id, '180')}>
                                    <Activity size={15}/>
                                    <span className='align-middle ml-50'>Assign free for 6 month</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} onClick={() => assignPlan(row.id, '365')}>
                                    <Activity size={15}/>
                                    <span className='align-middle ml-50'>Assign free for 1 year</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} to={`/admin/manage-brands/${row.id}`}>
                                    <Eye size={15}/>
                                    <span className='align-middle ml-50'>View</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => {
                                    setDeleteFAQModal(true)
                                    setDeleteId(row.id)
                                }}>
                                    <Trash size={15}/>
                                    <span className='align-middle ml-50'>Delete</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => {
                                    setUsersModal(true)
                                    secondaryUsers(row)
                                }}>
                                    <Users size={15}/>
                                    <span className='align-middle ml-50'>View Secondary User</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => {
                                    setCancelSubscriptionModal(true)
                                    setDeleteId(row?.id)
                                }}>
                                    <Unlock size={15}/>
                                    <span className='align-middle ml-50'>Cancel Subscription</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        }
    ])

    useEffect(() => {
        const brandsData = brandsSelector.brandsData ? brandsSelector.brandsData : null
        if (brandsData) {
            const {links, meta, pages} = brandsData
            const data = brandsData.data ? brandsData.data : []
            setPaginationInfo({...links, ...meta, pages})
            setManageBrands(data)
            if (data) {
                setIsPageLoading(false)
            }
        }

        const assigningPlan = brandsSelector.assigningPlan ? brandsSelector.assigningPlan : null
        if (assigningPlan) {
            const {msg, status} = assigningPlan
            setVisible(true)
            dispatch(nullSearchData(NULL.ADMIN_ASSIGN_PLAN))
            if (!status) {
                setError(msg)
                setTimeout(() => {
                    setError("")
                    setVisible(false)
                }, 4000)
            } else {
                setSuccessMsg(msg)

                const searchParams = {
                    name: searchName,
                    email: searchEmail,
                    status: searchStatus,
                    company: searchCompanyName
                }
                dispatch(brandsSearchData(searchParams, true, activePaginationUrl))

                setTimeout(() => {
                    setSuccessMsg("")
                    setVisible(false)
                }, 4000)
            }
        }

        const deleteBrandDataSuccess = brandsSelector.deleteBrandData?.success ? brandsSelector.deleteBrandData?.success : null
        const deleteBrandDataMessage = brandsSelector.deleteBrandData?.message ? brandsSelector.deleteBrandData?.message : null
        if (deleteBrandDataSuccess) {
            dispatch(nullSearchData(NULL.ADMIN_DELETE_MANAGE_BRAND_GET_DATA))
            const searchParams = {
                name: searchName,
                email: searchEmail,
                status: searchStatus,
                company: searchCompanyName
            }
            dispatch(brandsSearchData(searchParams, false, activePaginationUrl))
            setDeleteMsg(deleteBrandDataMessage)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000)
        }
    }, [brandsSelector])

    useEffect(() => {
        if (makePrimaryData?.status === true) {
            setVisible(true)
            setError(false)
            setSuccessMsg(makePrimaryData.msg)
            setPrimaryModal(false)
            setUsersModal(false)
            setPrimaryData({})
            setIsPageLoading(true)
            dispatch(getBrandsData())
            dispatch(exportBrandsData())
            dispatch(makePrimaryUserReset())
            setTimeout(() => {
                setVisible(false)
                setSuccessMsg('')
            }, 10000)
        }
    }, [makePrimaryData])

    useEffect(() => {
        if (brandCancelPlanData?.status) {
            setCancelSubscriptionModal(false)
            setDeleteId('')
            sweetAlert.fire('Success!', brandCancelPlanData?.msg, 'success')
            dispatch(brandCancelPlanResetAction())
            setIsPageLoading(true)
            dispatch(getBrandsData())
        }
        if (brandCancelPlanError?.response?.status) {
            setCancelSubscriptionModal(false)
            setDeleteId('')
            sweetAlert.fire('Error!', brandCancelPlanError?.response?.data?.msg, 'error')
            dispatch(brandCancelPlanResetAction())
        }
    }, [brandCancelPlanData, brandCancelPlanError])

    useEffect(() => {
        setIsPageLoading(true)
        dispatch(getBrandsData())
        dispatch(exportBrandsData())
    }, [])

    const deleteBrand = () => {
        setDeleteFAQModal(!deleteFAQModal)
        dispatch(deleteBrandsRDsData(deleteId))
        setIsPageLoading(true)
    }

    const searchBrands = () => {
        setIsPageLoading(true)
        const params = {
            name: searchName,
            email: searchEmail,
            status: searchStatus,
            company: searchCompanyName
        }
        dispatch(brandsSearchData(params, false))
    }

    const refreshBrands = () => {
        setSearchName('')
        setSearchEmail('')
        setSearchStatus('')
        setSearchCompanyName('')
        dispatch(nullSearchData('manageBrandsList'))
        setIsPageLoading(true)
        dispatch(getBrandsData())
    }

    function convertArrayOfObjectsToCSV(array) {
        let result = ''
        result += "Company Name, Contact Name, Email, Subscription Plan, Status, Registration Date \n"
        array.forEach(item => {
            result += `${item?.company?.replaceAll(',', '')}, ${item?.name.replaceAll(',', '')}, ${item?.email.replaceAll(',', '')}, ${item?.plan.replaceAll(',', '')}, ${item?.status.replaceAll(',', '')}, ${item?.reg_date.replaceAll(',', '')}`
            result += "\n"
        })
        return result
    }

    function downloadCSV(array) {
        if (array.length > 0) {
            const link = document.createElement('a')
            let csv = convertArrayOfObjectsToCSV(array)
            if (csv === null) return
            const filename = 'Manage-Brands'
            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`
            }
            link.setAttribute('href', encodeURI(csv))
            link.setAttribute('download', filename)
            link.click()
        } else {
            alert('Data is being loaded, Please wait...')
        }
    }

    const dataToRender = () => {
        if (manageBrands?.length) {
            return manageBrands
        }
    }

    const handleCustomPagination = pageUrl => {
        setIsPageLoading(true)
        setActivePaginationUrl(pageUrl)
        const params = {
            name: searchName,
            email: searchEmail,
            status: searchStatus,
            company: searchCompanyName
        }
        dispatch(brandsSearchData(params, true, pageUrl))
    }

    const markPrimary = (data) => {
        setPrimaryData(data)
        setPrimaryModal(true)
    }

    const setAsPrimaryUser = () => {
        dispatch(makePrimaryUser({
            id: primaryData?.id
        }))
    }

    const FilterByOptions = [
        {value: 'all', label: 'All'},
        {value: 'month', label: 'Month'},
        {value: 'quarter', label: 'Quarter'},
        {value: 'year', label: 'Year'}
    ]

    const StatusByOptions = [
        {value: 'all', label: 'All'},
        {value: 'active', label: 'Active'},
        {value: 'inactive', label: 'Inactive'}
    ]

    const downloadProfileViews = () => {
        let result = "Brand Name, Subscribed, Views, Percentage, Average, Unique Views\n"
        profileViewsData?.data.forEach((item) => {
            const subscribed = item?.status ? 'Yes' : 'No'
            result += `${item?.name?.replaceAll(',', '')}, ${subscribed}, ${item?.views}, ${item?.percentage}, ${item?.avg}, ${item?.uniqueViews}\n`
        })
        const csv = `data:text/csv;charset=utf-8,${result}`
        const link = document.createElement('a')
        link.setAttribute('href', encodeURI(csv))
        link.setAttribute('download', 'Stats-Profile-Viewed-By-RDs')
        link.click()
    }

    const downloadLinksClicked = () => {
        let result = "Brand Name, Subscribed, Website, Social, External, Average, Unique Clicks\n"
        profileLinksData?.data.forEach((item) => {
            const subscribed = item?.status ? 'Yes' : 'No'
            result += `${item?.name?.replaceAll(',', '')}, ${subscribed}, ${item?.web}, ${item?.social}, ${item?.external}, ${item?.avg}, ${item?.uniqueViews}\n`
        })
        const csv = `data:text/csv;charset=utf-8,${result}`
        const link = document.createElement('a')
        link.setAttribute('href', encodeURI(csv))
        link.setAttribute('download', 'Stats-Profile-Links-Clicked-By-RDs')
        link.click()
    }

    const downloadCampaignData = () => {
        let result = "Brand Name, Created Date, Publish Date, Deactivate Date, Campaign Title, Status, Applicants, Views, Percentage, Average Views, Unique Views\n"
        campaignDataData?.data.forEach((item) => {
            result += `${item?.brand_name?.replaceAll(',', '')}, ${item?.created_date?.replaceAll(',', '')}, ${item?.publish_date?.replaceAll(',', '')}, ${item?.deactivate_date?.replaceAll(',', '')}, ${item?.campaign_title?.replaceAll(',', '')}, ${item?.campaign_status}, ${item?.applicants}, ${item?.views}, ${item?.percentage}, ${item?.avg}, ${item?.uniqueViews}\n`
        })
        const csv = `data:text/csv;charset=utf-8,${result}`
        const link = document.createElement('a')
        link.setAttribute('href', encodeURI(csv))
        link.setAttribute('download', 'Stats-Campaign-Data')
        link.click()
    }

    const downloadRecommendation = () => {
        let result = "Brand Name, Subscribed, As a New Brand, As a Recommended Brand, Recommended Brand Campaigns\n"
        recoViewsData?.data.forEach((item) => {
            const subscribed = item?.status ? 'Yes' : 'No'
            result += `${item?.name?.replaceAll(',', '')}, ${subscribed}, ${item?.new_brand}, ${item?.recommended_brands}, ${item?.recommended_brands_campaign}\n`
        })
        const csv = `data:text/csv;charset=utf-8,${result}`
        const link = document.createElement('a')
        link.setAttribute('href', encodeURI(csv))
        link.setAttribute('download', 'Stats-Recommendation-Views')
        link.click()
    }

    const downloadMassMsg = () => {
        let result = "Brand Name, Subscribed, Total Mass Messages, Percentage, Average\n"
        brandMassMsgData?.data.forEach((item) => {
            const subscribed = item?.status ? 'Yes' : 'No'
            result += `${item?.name?.replaceAll(',', '')}, ${subscribed}, ${item?.views}, ${item?.percentage}, ${item?.avg}\n`
        })
        const csv = `data:text/csv;charset=utf-8,${result}`
        const link = document.createElement('a')
        link.setAttribute('href', encodeURI(csv))
        link.setAttribute('download', 'Stats-Mass-Messages-Data')
        link.click()
    }

    const downloadImpressions = () => {
        let result = "Brand Name, Profile Views, Links Clicked, Total Campaigns, Campaign Views, Campaign Applicants, New Brand, Recommended Brand, Recommended Campaigns, Total Mass Msg\n"
        brandTotalImpData?.data.forEach((item) => {
            result += `${item?.name?.replaceAll(',', '')}, ${item?.profile_views} - (${item?.profile_views_percent}%), ${item?.links}, ${item?.totalCampaign}, ${item?.campaignViews}, ${item?.campaignApplicants}, ${item?.new_brand}, ${item?.recommended_brands}, ${item?.recommended_brands_campaign}, ${item?.massMsg}\n`
        })
        const csv = `data:text/csv;charset=utf-8,${result}`
        const link = document.createElement('a')
        link.setAttribute('href', encodeURI(csv))
        link.setAttribute('download', 'Total-Impressions')
        link.click()
    }

    const brandSubscriptionCancel = () => {
        dispatch(brandCancelPlanAction({
            user_id: deleteId
        }))
    }

    const downloadDirectMsg = () => {
        let result = "Brand Name, Subscribed, Total Received Messages, Total Sent Messages, Message Open Rate\n"
        brandDirectMessagesData?.data.forEach((item) => {
            const subscribed = item?.status ? 'Yes' : 'No'
            result += `${item?.name?.replaceAll(',', '')}, ${subscribed}, ${item?.total_received}, ${item?.total_sent}, ${item?.open_rate} \n`
        })
        const csv = `data:text/csv;charset=utf-8,${result}`
        const link = document.createElement('a')
        link.setAttribute('href', encodeURI(csv))
        link.setAttribute('download', 'Stats-Direct-Messages-Data')
        link.click()
    }

    const handleFilterBy = (e, form, tab) => {
        setStatusValue(e)
        const startDate = form.values.startDate[0] ? moment(form.values.startDate[0]).format('yyyy-MM-DD') : ''
        const endDate = form.values.endDate[0] ? moment(form.values.endDate[0]).format('yyyy-MM-DD') : ''
        if (tab === 1) {
            dispatch(profileViews(`?s=${e.value}&q=${durationValue?.value ?? ""}&startDate=${startDate}&endDate=${endDate}`))
        } else if (tab === 2) {
            dispatch(profileLinks(`?s=${e.value}&q=${durationValue?.value ?? ""}&startDate=${startDate}&endDate=${endDate}`))
        } else if (tab === 4) {
            dispatch(recommendationViews(`?s=${e.value}&q=${durationValue?.value ?? ""}&startDate=${startDate}&endDate=${endDate}`))
        } else if (tab === 5) {
            dispatch(brandsMassMessages(`?s=${e.value}&q=${durationValue?.value ?? ""}&startDate=${startDate}&endDate=${endDate}`))
        } else if (tab === 6) {
            dispatch(brandsDirectMessagesAction(`?s=${e.value}&q=${durationValue?.value ?? ""}&startDate=${startDate}&endDate=${endDate}`))
        }
    }

    return (
        <Fragment>
            <Card>
                <CardHeader className='d-flex align-items-center justify-content-between flex-wrap border-bottom p-1 px-2'>
                    <CardTitle tag='h4'>Manage Brands</CardTitle>
                    <div>
                        <Button.Ripple className="mr-1" size='sm' color='primary' onClick={() => {
                            setBrandImpressionModal(true)
                            dispatch(brandsTotalImpression())
                        }}>
                            <Info size={15}/> Total impressions
                        </Button.Ripple>
                        <Button.Ripple className="mr-1" size='sm' color='primary' onClick={() => {
                            setBrandStatsModal(true)
                            dispatch(profileViews(""))
                        }}>
                            <List size={15}/> Statistics
                        </Button.Ripple>
                        <Button.Ripple className="my-1" size='sm' color='primary' onClick={() => downloadCSV(brandsSelector?.exportBrandsData)}>
                            <FileText size={15}/> Export (CSV)
                        </Button.Ripple>
                    </div>
                </CardHeader>
                <CardBody>
                    <Row form className='mt-1 mb-50'>
                        <Col lg='2' md='4'>
                            <FormGroup>
                                <Label for='company'>Company</Label>
                                <Input id='company'
                                       placeholder='Company'
                                       value={searchCompanyName}
                                       onChange={(e) => setSearchCompanyName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='contact-name'>Contact Name</Label>
                                <Input id='contact-name'
                                       placeholder='Contact Name'
                                       value={searchName}
                                       onChange={(e) => setSearchName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='email'>Contact Email</Label>
                                <Input type='email'
                                       id='email'
                                       placeholder='Contact Email'
                                       value={searchEmail}
                                       onChange={(e) => setSearchEmail(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='2' md='4'>
                            <FormGroup>
                                <Label for='status'>Status:</Label>
                                <Input type="select"
                                       id='status'
                                       placeholder='Status'
                                       value={searchStatus}
                                       onChange={(e) => setSearchStatus(e.target.value)}
                                >
                                    <option value=''>---Select---</option>
                                    <option value="enabled">Enabled</option>
                                    <option value="disabled">Disabled</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='2' md='4'>
                            <FormGroup>
                                <Label for='status'>Action:</Label>
                                <div>
                                    <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchBrands}>
                                        <Search size={16}/>
                                    </Button.Ripple>
                                    <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshBrands}>
                                        <RefreshCcw size={16}/>
                                    </Button.Ripple>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alert color={!!error ? 'danger' : 'success'} isOpen={visible} toggle={() => setVisible(false)}>
                                <div className='alert-body'>
                                    {error || successMsg}
                                </div>
                            </Alert>
                        </Col>
                    </Row>

                    <Alert color={'success'} isOpen={show} className="mt-1">
                        <div className='alert-body'>
                            {deleteMsg}
                        </div>
                    </Alert>

                </CardBody>
                {isPageLoading ? (
                    <div className="w-100 my-4 text-center">
                        <Spinner color='primary'/>
                    </div>
                ) : (
                    <div>
                        <DataTable
                            noHeader={true}
                            columns={advSearchColumns}
                            subHeader={false}
                            className='react-dataTable'
                            data={dataToRender()}
                            style={{minHeight: '450px'}}
                        />
                        <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                    </div>
                )}
                <Modal isOpen={deleteFAQModal} toggle={() => setDeleteFAQModal(!deleteFAQModal)} className='modal-dialog-centered'>
                    <ModalHeader toggle={() => setDeleteFAQModal(!deleteFAQModal)}>Delete Brand</ModalHeader>
                    <ModalBody>
                        Are you sure want to delete this brand?
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' outline onClick={() => setDeleteFAQModal(!deleteFAQModal)}>
                            No
                        </Button>
                        <Button color='danger' onClick={deleteBrand}>
                            Yes
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* --- Secondary Users Modal --- */}
                <Modal isOpen={usersModal} className='modal-dialog-centered' size="xl">
                    <ModalHeader toggle={() => setUsersModal(false)}>Secondary Users</ModalHeader>
                    <ModalBody>
                        <Table responsive>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {secondaryUserRequest ? (
                                <tr>
                                    <td className="text-center" colSpan="5">
                                        <Spinner color='primary'/>
                                    </td>
                                </tr>
                            ) : (
                                secondaryUserData?.data?.length > 0 ? (
                                    secondaryUserData?.data?.map((item, i) => (
                                        <tr key={i}>
                                            <td><span className='align-middle font-weight-bold'>{++i}</span></td>
                                            <td>{item?.name}</td>
                                            <td>{item?.email}</td>
                                            <td>
                                                {item.status === 'Deleted' ? (
                                                    <span className="badge badge-danger">{item?.status}</span>
                                                ) : (
                                                    item.status === 'Disabled' ? (
                                                        <span className="badge badge-warning">{item?.status}</span>
                                                    ) : (
                                                        <span className="badge badge-success">{item?.status}</span>
                                                    )
                                                )}
                                            </td>
                                            <td>
                                                <Button color='danger' size="sm" onClick={() => markPrimary(item)}>
                                                    <CheckCircle size="14"/> Make Primary
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="text-center" colSpan="5">
                                            Sorry! No users found.
                                        </td>
                                    </tr>
                                )
                            )}
                            </tbody>
                        </Table>
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </Modal>
                {/* --- Mark Primary Confirmation --- */}
                <Modal isOpen={primaryModal} className='modal-dialog-centered' size="lg">
                    <ModalHeader>Confirmation</ModalHeader>
                    <ModalBody>
                        <div>
                            <Jumbotron>
                                <h1 className="display-4 text-danger">Alert</h1>
                                <p className="lead">1- The current primary user will be deleted permanently.</p>
                                <p className="lead">2- Any deleted or disabled account from the secondary user's list will be recovered.</p>
                                <p className="lead">3- You can't revert this after taking action.</p>
                            </Jumbotron>
                            <p className="font-medium-2 font-weight-bold text-center">Are you sure you want to make primary user?</p>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' outline onClick={() => {
                            setPrimaryModal(false)
                            setPrimaryData({})
                        }} disabled={makePrimaryRequest}>
                            No
                        </Button>
                        <Button color='danger' onClick={setAsPrimaryUser} disabled={makePrimaryRequest}>
                            {makePrimaryRequest ? (
                                <Spinner style={{height: '14px', width: '14px'}}/>
                            ) : (
                                "Yes"
                            )}
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* --- Brand Stats --- */}
                <Modal isOpen={brandStatsModal} className='modal-dialog-centered' size="xl">
                    <ModalHeader toggle={() => {
                        setBrandStatsModal(false)
                        setActiveTab('1')
                        setCampaignUserID('')
                    }}>
                        Brand Statistics
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <Nav tabs>
                                <NavItem className="d-flex">
                                    <NavLink className={classnames({active: activeTab === '1'})} onClick={() => {
                                        setActiveTab('1')
                                        setDurationValue(null)
                                        setStatusValue(null)
                                        dispatch(profileViews(``))
                                        profileViewsFromDateForm.resetForm()
                                    }}>
                                        Profile Viewed By RD's
                                    </NavLink>
                                    <NavLink className={classnames({active: activeTab === '2'})} onClick={() => {
                                        setActiveTab('2')
                                        setDurationValue(null)
                                        setStatusValue(null)
                                        dispatch(profileLinks(``))
                                        linksClickedFromDateForm.resetForm()
                                    }}>
                                        Links Clicked By RD's
                                    </NavLink>
                                    <NavLink className={classnames({active: activeTab === '3'})} onClick={() => {
                                        setActiveTab('3')
                                        setDurationValue(null)
                                        setStatusValue(null)
                                        setCampaignUserID('')
                                        dispatch(onlyActiveBrands(``))
                                        dispatch(campaignData({id: ''}))
                                        campaignsFromDateForm.resetForm()
                                    }}>
                                        Brands Campaign Data
                                    </NavLink>
                                    <NavLink className={classnames({active: activeTab === '4'})} onClick={() => {
                                        setActiveTab('4')
                                        setDurationValue(null)
                                        setStatusValue(null)
                                        dispatch(recommendationViews(``))
                                        recommendedFromDateForm.resetForm()
                                    }}>
                                        Brands As Recommended
                                    </NavLink>
                                    <NavLink className={classnames({active: activeTab === '5'})} onClick={() => {
                                        setActiveTab('5')
                                        setDurationValue(null)
                                        setStatusValue(null)
                                        dispatch(brandsMassMessages(``))
                                        massMessageFromDateForm.resetForm()
                                    }}>
                                        Mass Messages
                                    </NavLink>
                                    <NavLink className={classnames({active: activeTab === '6'})} onClick={() => {
                                        setActiveTab('6')
                                        setDurationValue(null)
                                        setStatusValue(null)
                                        dispatch(brandsDirectMessagesAction(``))
                                        directMessageFromDateForm.resetForm()
                                    }}>
                                        Direct Messages
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <hr/>
                                    <Form onSubmit={profileViewsFromDateForm.handleSubmit} autoComplete="off">
                                        <Row>
                                            <Col md='1'>
                                                <FormGroup>
                                                    <Label><strong>Filter By:</strong></Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            value={statusValue}
                                                            options={StatusByOptions}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            placeholder='All'
                                                            onChange={(e) => handleFilterBy(e, profileViewsFromDateForm, 1)}
                                                            isDisabled={profileViewsRequest}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md='1'>
                                                <FormGroup>
                                                    <Label><strong>Duration:</strong></Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            value={durationValue}
                                                            options={FilterByOptions}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            placeholder='All'
                                                            onChange={(e) => {
                                                                setDurationValue(e)
                                                                dispatch(profileViews(`?s=${statusValue?.value ?? ""}&q=${e?.value}`))
                                                                profileViewsFromDateForm.resetForm()
                                                            }}
                                                            isDisabled={profileViewsRequest}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md='2'>
                                                <FormGroup>
                                                    <Label for='start-date'><strong>Start Date:</strong></Label>
                                                    <Flatpickr
                                                        className='form-control'
                                                        id='start-date'
                                                        placeholder='Start Date'
                                                        value={profileViewsFromDateForm.values.startDate}
                                                        options={{dateFormat: 'd M, Y'}}
                                                        onChange={(e) => {
                                                            profileViewsFromDateForm.setFieldValue('startDate', e)
                                                        }}
                                                        disabled={profileViewsRequest}
                                                    />
                                                    {profileViewsFromDateForm.errors.startDate && profileViewsFromDateForm.touched.startDate && (
                                                        <div className='text-danger'>{profileViewsFromDateForm.errors.startDate}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md='2'>
                                                <FormGroup>
                                                    <Label for='end-date'><strong>End Date:</strong></Label>
                                                    <Flatpickr
                                                        className='form-control'
                                                        id='end-date'
                                                        placeholder='End Date'
                                                        value={profileViewsFromDateForm.values.endDate}
                                                        options={{dateFormat: 'd M, Y'}}
                                                        onChange={(e) => {
                                                            profileViewsFromDateForm.setFieldValue('endDate', e)
                                                        }}
                                                        disabled={profileViewsRequest}
                                                    />
                                                    {profileViewsFromDateForm.errors.endDate && profileViewsFromDateForm.touched.endDate && (
                                                        <div className='text-danger'>{profileViewsFromDateForm.errors.endDate}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md='4'>
                                                <Button color='primary' type='submit' disabled={profileViewsRequest} className="mt-2 mr-1">
                                                    <Search size={14}/> Search
                                                </Button>
                                                <Button color='danger' type='button' disabled={profileViewsRequest}
                                                        className="mt-2 mr-1"
                                                        onClick={() => {
                                                            setDurationValue(null)
                                                            setStatusValue(null)
                                                            dispatch(profileViews(``))
                                                            profileViewsFromDateForm.resetForm()
                                                        }}>
                                                    <RefreshCw size={15}/> Clear All
                                                </Button>
                                            </Col>
                                            <Col md='2' className='text-right'>
                                                <FormGroup>
                                                    <Button color='primary' type='button' onClick={downloadProfileViews} disabled={profileViewsRequest} className="mt-2">
                                                        <FileText size={15}/> Export (CSV)
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <hr/>
                                    <Table responsive>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Image</th>
                                            <th>Brand</th>
                                            <th>Subscribed</th>
                                            <th>Views</th>
                                            <th>Percentage</th>
                                            <th>Average</th>
                                            <th>Unique Views</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {profileViewsRequest ? (
                                            <tr>
                                                <td className="text-center" colSpan="8">
                                                    <Spinner color='primary'/>
                                                </td>
                                            </tr>
                                        ) : (
                                            profileViewsData?.data?.length > 0 ? (
                                                profileViewsData?.data?.map((item, i) => (
                                                    <tr key={i}>
                                                        <td><span className='align-middle font-weight-bold'>{++i}</span></td>
                                                        <td><img src={item?.image} alt={item?.image} height="80"/></td>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            {item?.status ? <span className="badge badge-success">Yes</span> : <span className="badge badge-danger">No</span>}
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-primary">{item?.views}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-success">{item?.percentage}%</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-danger">{item?.avg}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-warning">{item?.uniqueViews}</span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td className="text-center" colSpan="8">
                                                        Sorry! No views found.
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                        </tbody>
                                    </Table>
                                </TabPane>
                                <TabPane tabId="2">
                                    <hr/>
                                    <Form onSubmit={linksClickedFromDateForm.handleSubmit} autoComplete="off">
                                        <Row>
                                            <Col md='1'>
                                                <FormGroup>
                                                    <Label><strong>Filter By:</strong></Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            value={statusValue}
                                                            options={StatusByOptions}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            placeholder='All'
                                                            onChange={(e) => handleFilterBy(e, linksClickedFromDateForm, 2)}
                                                            isDisabled={profileLinksRequest}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md='1'>
                                                <FormGroup>
                                                    <Label><strong>Duration:</strong></Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            value={durationValue}
                                                            options={FilterByOptions}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            placeholder='All'
                                                            onChange={(e) => {
                                                                setDurationValue(e)
                                                                dispatch(profileLinks(`?s=${e.value}&q=${durationValue?.value ?? ""}`))
                                                                linksClickedFromDateForm.resetForm()
                                                            }}
                                                            isDisabled={profileLinksRequest}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col md='2'>
                                                <FormGroup>
                                                    <Label for='start-date'><strong>Start Date:</strong></Label>
                                                    <Flatpickr
                                                        className='form-control'
                                                        id='start-date'
                                                        placeholder='Start Date'
                                                        value={linksClickedFromDateForm.values.startDate}
                                                        options={{dateFormat: 'd M, Y'}}
                                                        onChange={(e) => {
                                                            linksClickedFromDateForm.setFieldValue('startDate', e)
                                                        }}
                                                        disabled={profileLinksRequest}
                                                    />
                                                    {linksClickedFromDateForm.errors.startDate && linksClickedFromDateForm.touched.startDate && (
                                                        <div className='text-danger'>{linksClickedFromDateForm.errors.startDate}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md='2'>
                                                <FormGroup>
                                                    <Label for='end-date'><strong>End Date:</strong></Label>
                                                    <Flatpickr
                                                        className='form-control'
                                                        id='end-date'
                                                        placeholder='End Date'
                                                        value={linksClickedFromDateForm.values.endDate}
                                                        options={{dateFormat: 'd M, Y'}}
                                                        onChange={(e) => {
                                                            linksClickedFromDateForm.setFieldValue('endDate', e)
                                                        }}
                                                        disabled={profileLinksRequest}
                                                    />
                                                    {linksClickedFromDateForm.errors.endDate && linksClickedFromDateForm.touched.endDate && (
                                                        <div className='text-danger'>{linksClickedFromDateForm.errors.endDate}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md='4'>
                                                <Button color='primary' type='submit' disabled={profileLinksRequest} className="mt-2 mr-1">
                                                    <Search size={14}/> Search
                                                </Button>
                                                <Button color='danger' type='button' disabled={profileLinksRequest}
                                                        className="mt-2 mr-1"
                                                        onClick={() => {
                                                            setDurationValue(null)
                                                            setStatusValue(null)
                                                            dispatch(profileLinks(``))
                                                            linksClickedFromDateForm.resetForm()
                                                        }}>
                                                    <RefreshCw size={15}/> Clear All
                                                </Button>
                                            </Col>
                                            <Col md='2' className='text-right'>
                                                <FormGroup>
                                                    <Button color='primary' type='button' onClick={downloadLinksClicked} disabled={profileLinksRequest} className='mt-2'>
                                                        <FileText size={15}/> Export (CSV)
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <hr/>
                                    <Table responsive>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Image</th>
                                            <th>Brand</th>
                                            <th>Subscribed</th>
                                            <th>Website</th>
                                            <th>Social</th>
                                            <th>External</th>
                                            <th>Average</th>
                                            <th>Unique Clicks</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {profileLinksRequest ? (
                                            <tr>
                                                <td className="text-center" colSpan="9">
                                                    <Spinner color='primary'/>
                                                </td>
                                            </tr>
                                        ) : (
                                            profileLinksData?.data?.length > 0 ? (
                                                profileLinksData?.data?.map((item, i) => (
                                                    <tr key={i}>
                                                        <td><span className='align-middle font-weight-bold'>{++i}</span></td>
                                                        <td><img src={item?.image} alt={item?.image} height="80"/></td>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            {item?.status ? <span className="badge badge-success">Yes</span> : <span className="badge badge-danger">No</span>}
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-primary">{item?.web}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-success">{item?.social}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-danger">{item?.external}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-warning">{item?.avg}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-primary">{item?.uniqueViews}</span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td className="text-center" colSpan="9">
                                                        Sorry! No views found.
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                        </tbody>
                                    </Table>
                                </TabPane>
                                <TabPane tabId="3">
                                    <hr/>
                                    <Form onSubmit={campaignsFromDateForm.handleSubmit} autoComplete="off">
                                        <Row>
                                            <Col md='1'>
                                                <FormGroup>
                                                    <Label><strong>Filter By:</strong></Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            value={statusValue}
                                                            options={StatusByOptions}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            placeholder='All'
                                                            onChange={(e) => {
                                                                setStatusValue(e)
                                                                campaignsFromDateForm.resetForm()
                                                                dispatch(onlyActiveBrands(`?s=${e.value}`))
                                                            }}
                                                            isDisabled={campaignDataRequest || activeBrandsRequest}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md='1'>
                                                <FormGroup>
                                                    <Label><strong>Duration:</strong></Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            value={durationValue}
                                                            options={FilterByOptions}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            placeholder='All'
                                                            onChange={(e) => {
                                                                setDurationValue(e)
                                                                campaignsFromDateForm.resetForm()
                                                                dispatch(campaignData({
                                                                    id: campaignUserID,
                                                                    q: e.value
                                                                }))
                                                                setMonthFilter(e.value)
                                                            }}
                                                            isDisabled={campaignDataRequest || !campaignUserID || activeBrandsRequest}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md='2'>
                                                <FormGroup>
                                                    <Label for='start-date'><strong>Start Date:</strong></Label>
                                                    <Flatpickr
                                                        className='form-control'
                                                        id='start-date'
                                                        placeholder='Start Date'
                                                        value={campaignsFromDateForm.values.startDate}
                                                        options={{dateFormat: 'd M, Y'}}
                                                        onChange={(e) => {
                                                            campaignsFromDateForm.setFieldValue('startDate', e)
                                                        }}
                                                        disabled={campaignDataRequest || !campaignUserID || activeBrandsRequest}
                                                    />
                                                    {campaignsFromDateForm.errors.startDate && campaignsFromDateForm.touched.startDate && (
                                                        <div className='text-danger'>{campaignsFromDateForm.errors.startDate}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md='2'>
                                                <FormGroup>
                                                    <Label for='end-date'><strong>End Date:</strong></Label>
                                                    <Flatpickr
                                                        className='form-control'
                                                        id='end-date'
                                                        placeholder='End Date'
                                                        value={campaignsFromDateForm.values.endDate}
                                                        options={{dateFormat: 'd M, Y'}}
                                                        onChange={(e) => {
                                                            campaignsFromDateForm.setFieldValue('endDate', e)
                                                        }}
                                                        disabled={campaignDataRequest || !campaignUserID || activeBrandsRequest}
                                                    />
                                                    {campaignsFromDateForm.errors.endDate && campaignsFromDateForm.touched.endDate && (
                                                        <div className='text-danger'>{campaignsFromDateForm.errors.endDate}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md='4'>
                                                <Button color='primary' type='submit' disabled={campaignDataRequest || !campaignUserID || activeBrandsRequest}
                                                        className="mt-2 mr-1">
                                                    <Search size={14}/> Search
                                                </Button>
                                                <Button color='danger' type='button' disabled={campaignDataRequest || !campaignUserID || activeBrandsRequest}
                                                        className="mt-2 mr-1"
                                                        onClick={() => {
                                                            setDurationValue(null)
                                                            setStatusValue(null)
                                                            setCampaignUserID('')
                                                            dispatch(onlyActiveBrands(``))
                                                            dispatch(campaignData({id: ''}))
                                                            campaignsFromDateForm.resetForm()
                                                        }}>
                                                    <RefreshCw size={15}/> Clear All
                                                </Button>
                                            </Col>
                                            <Col md='2' className='text-right'>
                                                <FormGroup>
                                                    <Button color='primary' type='button' onClick={downloadCampaignData}
                                                            disabled={campaignDataRequest || !campaignUserID || activeBrandsRequest} className='mt-2'>
                                                        <FileText size={15}/> Export (CSV)
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <hr/>
                                    <Row className="mt-1 mb-1 bg-light pt-1">
                                        {activeBrandsRequest ? (
                                            <Col md="12" className='text-center pb-1'>
                                                <Spinner color='primary'/>
                                            </Col>
                                        ) : (
                                            activeBrandsData?.data?.map((item, index) => (
                                                <Col key={index} md="3">
                                                    <FormGroup>
                                                        <CustomInput type="radio"
                                                                     id={`brand-${index}`}
                                                                     name="brands-campaign-data"
                                                                     label={
                                                                         <span>
                                                                             {item?.status ? (
                                                                                 <span className={`text-success`} title={`Subscribed`}><CheckCircle size={16}/></span>
                                                                             ) : (
                                                                                 <span className={`text-danger`} title={`Not Subscribed`}><XCircle size={16}/></span>
                                                                             )}
                                                                             {` `}
                                                                             {item?.name}
                                                                         </span>}
                                                                     value={item?.id}
                                                                     onChange={(e) => {
                                                                         dispatch(campaignData({
                                                                             id: e.target.value,
                                                                             q: monthFilter
                                                                         }))
                                                                         setCampaignUserID(e.target.value)
                                                                     }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            ))
                                        )}
                                    </Row>
                                    <Table responsive={true} className='word-break-normal'>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Created Date</th>
                                            <th>Publish Date</th>
                                            <th>Deactivate Date</th>
                                            <th>Campaign Title</th>
                                            <th>Status</th>
                                            <th>Applicants</th>
                                            <th>Views</th>
                                            <th>Percentage</th>
                                            <th>Average Views</th>
                                            <th>Unique Views</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {campaignDataRequest ? (
                                            <tr>
                                                <td className="text-center" colSpan="11">
                                                    <Spinner color='primary'/>
                                                </td>
                                            </tr>
                                        ) : (
                                            campaignDataData?.data?.length > 0 ? (
                                                campaignDataData?.data?.map((item, i) => (
                                                    <tr key={i}>
                                                        <td><span className='align-middle font-weight-bold'>{++i}</span></td>
                                                        <td>{item?.created_date}</td>
                                                        <td>{item?.publish_date}</td>
                                                        <td>{item?.deactivate_date}</td>
                                                        <td>{item?.campaign_title}</td>
                                                        <td><strong>{item?.campaign_status}</strong></td>
                                                        <td>
                                                            <span className="badge badge-success">{item?.applicants}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-primary">{item?.views}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-warning">{item?.percentage}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-warning">{item?.avg}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-primary">{item?.uniqueViews}</span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td className="text-center" colSpan="11">
                                                        Sorry! No data found.
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                        </tbody>
                                    </Table>
                                </TabPane>
                                <TabPane tabId="4">
                                    <hr/>
                                    <Form onSubmit={recommendedFromDateForm.handleSubmit} autoComplete="off">
                                        <Row>
                                            <Col md='1'>
                                                <FormGroup>
                                                    <Label><strong>Filter By:</strong></Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            value={statusValue}
                                                            options={StatusByOptions}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            placeholder='All'
                                                            onChange={(e) => handleFilterBy(e, recommendedFromDateForm, 4)}
                                                            isDisabled={recoViewsRequest}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md='1'>
                                                <FormGroup>
                                                    <Label><strong>Duration:</strong></Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            value={durationValue}
                                                            options={FilterByOptions}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            placeholder='All'
                                                            onChange={(e) => {
                                                                setDurationValue(e)
                                                                dispatch(recommendationViews(`?s=${statusValue?.value ?? ""}&q=${e?.value}`))
                                                                recommendedFromDateForm.resetForm()
                                                            }}
                                                            isDisabled={recoViewsRequest}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md='2'>
                                                <FormGroup>
                                                    <Label for='start-date'><strong>Start Date:</strong></Label>
                                                    <Flatpickr
                                                        className='form-control'
                                                        id='start-date'
                                                        placeholder='Start Date'
                                                        value={recommendedFromDateForm.values.startDate}
                                                        options={{dateFormat: 'd M, Y'}}
                                                        onChange={(e) => {
                                                            recommendedFromDateForm.setFieldValue('startDate', e)
                                                        }}
                                                        disabled={recoViewsRequest}
                                                    />
                                                    {recommendedFromDateForm.errors.startDate && recommendedFromDateForm.touched.startDate && (
                                                        <div className='text-danger'>{recommendedFromDateForm.errors.startDate}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md='2'>
                                                <FormGroup>
                                                    <Label for='end-date'><strong>End Date:</strong></Label>
                                                    <Flatpickr
                                                        className='form-control'
                                                        id='end-date'
                                                        placeholder='End Date'
                                                        value={recommendedFromDateForm.values.endDate}
                                                        options={{dateFormat: 'd M, Y'}}
                                                        onChange={(e) => {
                                                            recommendedFromDateForm.setFieldValue('endDate', e)
                                                        }}
                                                        disabled={recoViewsRequest}
                                                    />
                                                    {recommendedFromDateForm.errors.endDate && recommendedFromDateForm.touched.endDate && (
                                                        <div className='text-danger'>{recommendedFromDateForm.errors.endDate}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md='4'>
                                                <Button color='primary' type='submit' disabled={recoViewsRequest} className="mt-2 mr-1">
                                                    <Search size={14}/> Search
                                                </Button>
                                                <Button color='danger' type='button' disabled={recoViewsRequest}
                                                        className="mt-2 mr-1"
                                                        onClick={() => {
                                                            setDurationValue(null)
                                                            setStatusValue(null)
                                                            dispatch(recommendationViews(``))
                                                            recommendedFromDateForm.resetForm()
                                                        }}>
                                                    <RefreshCw size={15}/> Clear All
                                                </Button>
                                            </Col>
                                            <Col md='2' className='text-right'>
                                                <FormGroup>
                                                    <Button color='primary' type='button' onClick={downloadRecommendation} disabled={recoViewsRequest} className='mt-2'>
                                                        <FileText size={15}/> Export (CSV)
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <hr/>
                                    <Table responsive>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Image</th>
                                            <th>Brand</th>
                                            <th>Subscribed</th>
                                            <th>As a New Brand</th>
                                            <th>As a Recommended Brand</th>
                                            <th>Recommended Brand Outreach</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {recoViewsRequest ? (
                                            <tr>
                                                <td className="text-center" colSpan="7">
                                                    <Spinner color='primary'/>
                                                </td>
                                            </tr>
                                        ) : (
                                            recoViewsData?.data?.length > 0 ? (
                                                recoViewsData?.data?.map((item, i) => (
                                                    <tr key={i}>
                                                        <td><span className='align-middle font-weight-bold'>{++i}</span></td>
                                                        <td><img src={item?.image} alt={item?.image} height="80"/></td>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            {item?.status ? <span className="badge badge-success">Yes</span> : <span className="badge badge-danger">No</span>}
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-primary">{item?.new_brand}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-primary">{item?.recommended_brands}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-primary">{item?.recommended_brands_campaign}</span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td className="text-center" colSpan="7">
                                                        Sorry! No views found.
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                        </tbody>
                                    </Table>
                                </TabPane>
                                <TabPane tabId="5">
                                    <hr/>
                                    <Form onSubmit={massMessageFromDateForm.handleSubmit} autoComplete="off">
                                        <Row>
                                            <Col md='1'>
                                                <FormGroup>
                                                    <Label><strong>Filter By:</strong></Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            value={statusValue}
                                                            options={StatusByOptions}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            placeholder='All'
                                                            onChange={(e) => handleFilterBy(e, massMessageFromDateForm, 5)}
                                                            isDisabled={brandMassMsgRequest}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md='1'>
                                                <FormGroup>
                                                    <Label><strong>Duration:</strong></Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            value={durationValue}
                                                            options={FilterByOptions}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            placeholder='All'
                                                            onChange={(e) => {
                                                                setDurationValue(e)
                                                                dispatch(brandsMassMessages(`?s=${statusValue?.value ?? ""}&q=${e?.value}`))
                                                                massMessageFromDateForm.resetForm()
                                                            }}
                                                            isDisabled={brandMassMsgRequest}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md='2'>
                                                <FormGroup>
                                                    <Label for='start-date'><strong>Start Date:</strong></Label>
                                                    <Flatpickr
                                                        className='form-control'
                                                        id='start-date'
                                                        placeholder='Start Date'
                                                        value={massMessageFromDateForm.values.startDate}
                                                        options={{dateFormat: 'd M, Y'}}
                                                        onChange={(e) => {
                                                            massMessageFromDateForm.setFieldValue('startDate', e)
                                                        }}
                                                        disabled={recoViewsRequest}
                                                    />
                                                    {massMessageFromDateForm.errors.startDate && massMessageFromDateForm.touched.startDate && (
                                                        <div className='text-danger'>{massMessageFromDateForm.errors.startDate}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md='2'>
                                                <FormGroup>
                                                    <Label for='end-date'><strong>End Date:</strong></Label>
                                                    <Flatpickr
                                                        className='form-control'
                                                        id='end-date'
                                                        placeholder='End Date'
                                                        value={massMessageFromDateForm.values.endDate}
                                                        options={{dateFormat: 'd M, Y'}}
                                                        onChange={(e) => {
                                                            massMessageFromDateForm.setFieldValue('endDate', e)
                                                        }}
                                                        disabled={recoViewsRequest}
                                                    />
                                                    {massMessageFromDateForm.errors.endDate && massMessageFromDateForm.touched.endDate && (
                                                        <div className='text-danger'>{massMessageFromDateForm.errors.endDate}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md='4'>
                                                <Button color='primary' type='submit' disabled={brandMassMsgRequest} className="mt-2 mr-1">
                                                    <Search size={14}/> Search
                                                </Button>
                                                <Button color='danger' type='button' disabled={brandMassMsgRequest}
                                                        className="mt-2 mr-1"
                                                        onClick={() => {
                                                            setDurationValue(null)
                                                            setStatusValue(null)
                                                            dispatch(brandsMassMessages(``))
                                                            massMessageFromDateForm.resetForm()
                                                        }}>
                                                    <RefreshCw size={15}/> Clear All
                                                </Button>
                                            </Col>
                                            <Col md='2' className='text-right'>
                                                <FormGroup>
                                                    <Button color='primary' type='button' onClick={downloadMassMsg} disabled={brandMassMsgRequest} className='mt-2'>
                                                        <FileText size={15}/> Export (CSV)
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <hr/>
                                    <Table responsive>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Image</th>
                                            <th>Brand</th>
                                            <th>Subscribed</th>
                                            <th>Total Mass Messages</th>
                                            <th>Percentage</th>
                                            <th>Average</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {brandMassMsgRequest ? (
                                            <tr>
                                                <td className="text-center" colSpan="7">
                                                    <Spinner color='primary'/>
                                                </td>
                                            </tr>
                                        ) : (
                                            brandMassMsgData?.data?.length > 0 ? (
                                                brandMassMsgData?.data?.map((item, i) => (
                                                    <tr key={i}>
                                                        <td><span className='align-middle font-weight-bold'>{++i}</span></td>
                                                        <td><img src={item?.image} alt={item?.image} height="80"/></td>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            {item?.status ? <span className="badge badge-success">Yes</span> : <span className="badge badge-danger">No</span>}
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-primary">{item?.views}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-success">{item?.percentage}%</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-danger">{item?.avg}</span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td className="text-center" colSpan="7">
                                                        Sorry! No views found.
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                        </tbody>
                                    </Table>
                                </TabPane>
                                <TabPane tabId="6">
                                    <hr/>
                                    <Form onSubmit={directMessageFromDateForm.handleSubmit} autoComplete="off">
                                        <Row>
                                            <Col md='1'>
                                                <FormGroup>
                                                    <Label><strong>Filter By:</strong></Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            value={statusValue}
                                                            options={StatusByOptions}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            placeholder='All'
                                                            onChange={(e) => handleFilterBy(e, directMessageFromDateForm, 6)}
                                                            isDisabled={brandDirectMessagesLoading}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md='1'>
                                                <FormGroup>
                                                    <Label><strong>Duration:</strong></Label>
                                                    <Select className='react-select'
                                                            classNamePrefix='select'
                                                            value={durationValue}
                                                            options={FilterByOptions}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            placeholder='All'
                                                            onChange={(e) => {
                                                                setDurationValue(e)
                                                                dispatch(brandsDirectMessagesAction(`?s=${statusValue?.value ?? ""}&q=${e?.value}`))
                                                                directMessageFromDateForm.resetForm()
                                                            }}
                                                            isDisabled={brandDirectMessagesLoading}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md='2'>
                                                <FormGroup>
                                                    <Label for='start-date'><strong>Start Date:</strong></Label>
                                                    <Flatpickr
                                                        className='form-control'
                                                        id='start-date'
                                                        placeholder='Start Date'
                                                        value={directMessageFromDateForm.values.startDate}
                                                        options={{dateFormat: 'd M, Y'}}
                                                        onChange={(e) => {
                                                            directMessageFromDateForm.setFieldValue('startDate', e)
                                                        }}
                                                        disabled={recoViewsRequest || brandDirectMessagesLoading}
                                                    />
                                                    {directMessageFromDateForm.errors.startDate && directMessageFromDateForm.touched.startDate && (
                                                        <div className='text-danger'>{directMessageFromDateForm.errors.startDate}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md='2'>
                                                <FormGroup>
                                                    <Label for='end-date'><strong>End Date:</strong></Label>
                                                    <Flatpickr
                                                        className='form-control'
                                                        id='end-date'
                                                        placeholder='End Date'
                                                        value={directMessageFromDateForm.values.endDate}
                                                        options={{dateFormat: 'd M, Y'}}
                                                        onChange={(e) => {
                                                            directMessageFromDateForm.setFieldValue('endDate', e)
                                                        }}
                                                        disabled={recoViewsRequest || brandDirectMessagesLoading}
                                                    />
                                                    {directMessageFromDateForm.errors.endDate && directMessageFromDateForm.touched.endDate && (
                                                        <div className='text-danger'>{directMessageFromDateForm.errors.endDate}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md='4'>
                                                <Button color='primary' type='submit' disabled={brandDirectMessagesLoading} className="mt-2 mr-1">
                                                    <Search size={14}/> Search
                                                </Button>
                                                <Button color='danger' type='button' disabled={brandDirectMessagesLoading}
                                                        className="mt-2 mr-1"
                                                        onClick={() => {
                                                            setDurationValue(null)
                                                            setStatusValue(null)
                                                            dispatch(brandsDirectMessagesAction(``))
                                                            directMessageFromDateForm.resetForm()
                                                        }}>
                                                    <RefreshCw size={15}/> Clear All
                                                </Button>
                                            </Col>
                                            <Col md='2' className='text-right'>
                                                <FormGroup>
                                                    <Button color='primary' type='button' onClick={downloadDirectMsg} disabled={brandDirectMessagesLoading} className='mt-2'>
                                                        <FileText size={15}/> Export (CSV)
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <hr/>
                                    <Table responsive={true} className='word-break-normal'>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Image</th>
                                            <th>Brand</th>
                                            <th>Subscribed</th>
                                            <th>Total Received Messages</th>
                                            <th>Total Sent Messages</th>
                                            <th title='# of Messages opened by RD / # Messages sent to RDs' className='cursor-pointer'>Message Open Rate By RD's</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {brandDirectMessagesLoading ? (
                                            <tr>
                                                <td className="text-center" colSpan="7">
                                                    <Spinner color='primary'/>
                                                </td>
                                            </tr>
                                        ) : (
                                            brandDirectMessagesData?.data?.length > 0 ? (
                                                brandDirectMessagesData?.data?.map((item, i) => (
                                                    <tr key={i}>
                                                        <td><span className='align-middle font-weight-bold'>{++i}</span></td>
                                                        <td><img src={item?.image} alt={item?.image} height="80"/></td>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            {item?.status ? <span className="badge badge-success">Yes</span> : <span className="badge badge-danger">No</span>}
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-primary">{item?.total_received}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-primary">{item?.total_sent}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-primary">{item?.open_rate}%</span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td className="text-center" colSpan="7">
                                                        Sorry! No messages found.
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                        </tbody>
                                    </Table>
                                </TabPane>
                            </TabContent>
                        </div>
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </Modal>
                {/* --- Brands Impression --- */}
                <Modal isOpen={brandImpressionModal} className='modal-dialog-centered' size="xl">
                    <ModalHeader toggle={() => {
                        setBrandImpressionModal(false)
                    }}>
                        Total Impressions
                    </ModalHeader>
                    <ModalBody className="word-break-normal overflow-x-scroll">
                        <div className='d-flex justify-content-end'>
                            <Button.Ripple className="my-1" color='primary' onClick={downloadImpressions} disabled={brandTotalImpRequest}>
                                <FileText size={15}/> Export (CSV)
                            </Button.Ripple>
                        </div>
                        <Table>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Image</th>
                                <th>Brand</th>
                                <th>Profile Views</th>
                                <th>Links Clicked</th>
                                <th>Total Campaigns</th>
                                <th>Campaign Views</th>
                                <th>Campaign Applicants</th>
                                <th>New Brand</th>
                                <th>Recommended Brand</th>
                                <th>Recommended Campaigns</th>
                                <th>Total Mass Msg</th>
                            </tr>
                            </thead>
                            <tbody>
                            {brandTotalImpRequest ? (
                                <tr>
                                    <td className="text-center" colSpan="12">
                                        <Spinner color='primary'/>
                                    </td>
                                </tr>
                            ) : (
                                brandTotalImpData?.data?.length > 0 ? (
                                    brandTotalImpData?.data?.map((item, i) => (
                                        <tr key={i}>
                                            <td><span className='align-middle font-weight-bold'>{++i}</span></td>
                                            <td><img src={item?.image} alt={item?.image} height="80"/></td>
                                            <td>{item?.name}</td>
                                            <td>
                                                <span className="badge badge-primary">{item?.profile_views} - ({item?.profile_views_percent}%)</span>
                                            </td>
                                            <td>
                                                <span className="badge badge-success">{item?.links}</span>
                                            </td>
                                            <td>
                                                <span className="badge badge-primary">{item?.totalCampaign}</span>
                                            </td>
                                            <td>
                                                <span className="badge badge-success">{item?.campaignViews}</span>
                                            </td>
                                            <td>
                                                <span className="badge badge-primary">{item?.campaignApplicants}</span>
                                            </td>
                                            <td>
                                                <span className="badge badge-success">{item?.new_brand}</span>
                                            </td>
                                            <td>
                                                <span className="badge badge-primary">{item?.recommended_brands}</span>
                                            </td>
                                            <td>
                                                <span className="badge badge-success">{item?.recommended_brands_campaign}</span>
                                            </td>
                                            <td>
                                                <span className="badge badge-primary">{item?.massMsg}</span>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="text-center" colSpan="12">
                                            Sorry! No views found.
                                        </td>
                                    </tr>
                                )
                            )}
                            </tbody>
                        </Table>
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </Modal>
                {/* --- Cancel Subscription --- */}
                <Modal isOpen={cancelSubscriptionModal} className='modal-dialog-centered'>
                    <ModalHeader>Cancel Subscription</ModalHeader>
                    <ModalBody>
                        Are you sure want to cancel the subscription?
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' outline onClick={() => {
                            setCancelSubscriptionModal(false)
                            setDeleteId('')
                        }} disabled={brandCancelPlanLoading}>
                            No
                        </Button>
                        <Button color='danger' onClick={brandSubscriptionCancel} disabled={brandCancelPlanLoading}>
                            {brandCancelPlanLoading ? (
                                <span><Spinner style={{height: '15px', width: '15px'}}/> Yes</span>
                            ) : (
                                <span>Yes</span>
                            )}
                        </Button>
                    </ModalFooter>
                </Modal>
            </Card>
        </Fragment>
    )
}

export default ManageBrands
