export const RD_ACTION_TYPES = {
    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_DATA: 'GET_PROFILE_DATA',
    GET_PROFILE_ERROR: 'GET_PROFILE_ERROR',
    GET_PROFILE_RESET: 'GET_PROFILE_RESET',

    UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_DATA: 'UPDATE_PROFILE_DATA',
    UPDATE_PROFILE_ERROR: 'UPDATE_PROFILE_ERROR',
    UPDATE_PROFILE_RESET: 'UPDATE_PROFILE_RESET',

    DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
    DELETE_ACCOUNT_DATA: 'DELETE_ACCOUNT_DATA',
    DELETE_ACCOUNT_ERROR: 'DELETE_ACCOUNT_ERROR',
    DELETE_ACCOUNT_RESET: 'DELETE_ACCOUNT_RESET',

    FACEBOOK_LOGIN_REQUEST: 'FACEBOOK_LOGIN_REQUEST',
    FACEBOOK_LOGIN_DATA: 'FACEBOOK_LOGIN_DATA',
    FACEBOOK_LOGIN_ERROR: 'FACEBOOK_LOGIN_ERROR',
    FACEBOOK_LOGIN_RESET: 'FACEBOOK_LOGIN_RESET',

    FACEBOOK_TOKEN_REQUEST: 'FACEBOOK_TOKEN_REQUEST',
    FACEBOOK_TOKEN_DATA: 'FACEBOOK_TOKEN_DATA',
    FACEBOOK_TOKEN_ERROR: 'FACEBOOK_TOKEN_ERROR',
    FACEBOOK_TOKEN_RESET: 'FACEBOOK_TOKEN_RESET',

    FACEBOOK_IGID_REQUEST: 'FACEBOOK_IGID_REQUEST',
    FACEBOOK_IGID_DATA: 'FACEBOOK_IGID_DATA',
    FACEBOOK_IGID_ERROR: 'FACEBOOK_IGID_ERROR',
    FACEBOOK_IGID_RESET: 'FACEBOOK_IGID_RESET',

    FACEBOOK_IG_DATA_REQUEST: 'FACEBOOK_IG_DATA_REQUEST',
    FACEBOOK_IG_DATA_DATA: 'FACEBOOK_IG_DATA_DATA',
    FACEBOOK_IG_DATA_ERROR: 'FACEBOOK_IG_DATA_ERROR',
    FACEBOOK_IG_DATA_RESET: 'FACEBOOK_IG_DATA_RESET',

    FACEBOOK_IG_LOGOUT_REQUEST: 'FACEBOOK_IG_LOGOUT_REQUEST',
    FACEBOOK_IG_LOGOUT_DATA: 'FACEBOOK_IG_LOGOUT_DATA',
    FACEBOOK_IG_LOGOUT_ERROR: 'FACEBOOK_IG_LOGOUT_ERROR',
    FACEBOOK_IG_LOGOUT_RESET: 'FACEBOOK_IG_LOGOUT_RESET',

    MARK_AS_READ_REQUEST: 'MARK_AS_READ_REQUEST',
    MARK_AS_READ_DATA: 'MARK_AS_READ_DATA',
    MARK_AS_READ_ERROR: 'MARK_AS_READ_ERROR',
    MARK_AS_READ_RESET: 'MARK_AS_READ_RESET',

    GET_NEW_EVENTS_REQUEST: 'GET_NEW_EVENTS_REQUEST',
    GET_NEW_EVENTS_DATA: 'GET_NEW_EVENTS_DATA',
    GET_NEW_EVENTS_ERROR: 'GET_NEW_EVENTS_ERROR',

    DELETE_EVENT_REQUEST: 'DELETE_EVENT_REQUEST',
    DELETE_EVENT_DATA: 'DELETE_EVENT_DATA',
    DELETE_EVENT_ERROR: 'DELETE_EVENT_ERROR',
    DELETE_EVENT_RESET: 'DELETE_EVENT_RESET',

    GET_PAST_EVENTS_REQUEST: 'GET_PAST_EVENTS_REQUEST',
    GET_PAST_EVENTS_DATA: 'GET_PAST_EVENTS_DATA',
    GET_PAST_EVENTS_ERROR: 'GET_PAST_EVENTS_ERROR',

    GET_EVENT_BRANDS_REQUEST: 'GET_EVENT_BRANDS_REQUEST',
    GET_EVENT_BRANDS_DATA: 'GET_EVENT_BRANDS_DATA',
    GET_EVENT_BRANDS_ERROR: 'GET_EVENT_BRANDS_ERROR',

    COPY_EVENT_REQUEST: 'COPY_EVENT_REQUEST',
    COPY_EVENT_DATA: 'COPY_EVENT_DATA',
    COPY_EVENT_ERROR: 'COPY_EVENT_ERROR',
    COPY_EVENT_RESET: 'COPY_EVENT_RESET',

    CAMPAIGN_VIEW_REQUEST: 'CAMPAIGN_VIEW_REQUEST',
    CAMPAIGN_VIEW_DATA: 'CAMPAIGN_VIEW_DATA',
    CAMPAIGN_VIEW_ERROR: 'CAMPAIGN_VIEW_ERROR',

    APPLY_ON_CAMPAIGN_REQUEST: 'APPLY_ON_CAMPAIGN_REQUEST',
    APPLY_ON_CAMPAIGN_DATA: 'APPLY_ON_CAMPAIGN_DATA',
    APPLY_ON_CAMPAIGN_ERROR: 'APPLY_ON_CAMPAIGN_ERROR',
    APPLY_ON_CAMPAIGN_RESET: 'APPLY_ON_CAMPAIGN_RESET',

    CAMPAIGN_LISTING_REQUEST: 'CAMPAIGN_LISTING_REQUEST',
    CAMPAIGN_LISTING_DATA: 'CAMPAIGN_LISTING_DATA',
    CAMPAIGN_LISTING_ERROR: 'CAMPAIGN_LISTING_ERROR'
}