import {Fragment} from 'react'
import {Pagination, PaginationItem, PaginationLink} from 'reactstrap'
import {PropTypes} from "prop-types"

const CustomPagination = (props) => {
    const {pageLinks, onPaginationClick} = props

    const handleCustomPagination = (url) => {
        if (!!url) onPaginationClick(url)
    }

    return (
        <Pagination className='d-flex w-100 my-2 justify-content-end align-items-center'>
            {pageLinks && pageLinks?.map((page, i) => (
                <Fragment key={page + i}>
                    {page.label === 'Previous' && (
                        <PaginationItem disabled={!page.url} className='prev-item' onClick={() => handleCustomPagination(page.url)}>
                            <PaginationLink disabled={!page.url}></PaginationLink>
                        </PaginationItem>
                    )}
                    {page.label !== 'Previous' && page.label !== 'Next' && (
                        <PaginationItem active={page.active} onClick={() => handleCustomPagination(page.url)}>
                            <PaginationLink>{page.label}</PaginationLink>
                        </PaginationItem>
                    )}
                    {page.label === 'Next' && (
                        <PaginationItem disabled={!page.url} className='next-item' onClick={() => handleCustomPagination(page.url)}>
                            <PaginationLink disabled={!page.url}></PaginationLink>
                        </PaginationItem>
                    )}
                </Fragment>
            ))}
        </Pagination>
    )
}

CustomPagination.propTypes = {
    pageLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any,
            url: PropTypes.string,
            active: PropTypes.bool
        })
    ),
    onPaginationClick: PropTypes.func
}

export default CustomPagination
