import Timeline from '@components/timeline'
import {Card, CardHeader, CardTitle, CardBody} from 'reactstrap'
import {PropTypes} from "prop-types"

const SubscriptionHistory = (props) => {
    return (
        <Card className='card-user-timeline'>
            <CardHeader>
                <CardTitle tag='h4'>Subscription History</CardTitle>
            </CardHeader>
            <CardBody>
                <Timeline className='ml-50 mb-0' data={props?.subscriptionHistory}/>
            </CardBody>
        </Card>
    )
}


SubscriptionHistory.propTypes = {
    subscriptionHistory: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            date: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string,
            status: PropTypes.string
        })
    ).isRequired
}

export default SubscriptionHistory
