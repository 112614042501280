import {Link} from 'react-router-dom'
import {useHistory} from 'react-router'
import classnames from 'classnames'
import {Heart, Mail, User} from 'react-feather'
import {Card, CardBody, Button} from 'reactstrap'
import BlankLogo from "@src/assets/images/avatars/Blank-logo.jpg"
import Avatar from "@components/avatar"
import {PropTypes} from "prop-types"

const ProductCards = ({activeView, myBrands, handleAddToFavorite}) => {
    const history = useHistory()

    const handleFavorite = (profile) => {
        handleAddToFavorite(profile)
    }

    const handleRedirectMessage = (user) => {
        localStorage.setItem('selectedChatBrand', JSON.stringify(user))
        history.push(`/rd/apps/chat/chatRd/${user.user_id}`)
    }

    const renderProducts = () => {
        return myBrands?.map((item, i) => {
            return (
                <Card className='ecommerce-card' key={item.user_id + i}>
                    <div className="row align-items-center justify-content-between p-1">
                        {item.is_new && <div className="col-auto"><small className="border border-1 px-75 py-25 border-muted text-muted">New</small></div>}
                        <div className="col-auto ml-auto">
                            {item.is_favorite && <Heart
                                className='text-muted cursor-pointer'
                                color="red"
                                size={14}
                                onClick={() => handleFavorite(item)}
                            />}
                            {!item.is_favorite && <Heart
                                className='text-muted cursor-pointer'
                                size={14}
                                onClick={() => handleFavorite(item)}
                            />}
                        </div>
                    </div>
                    <div className="item-img text-center mx-auto">
                        <Avatar img={item.brand_logo || item.company_logo || BlankLogo} imgHeight="120" imgWidth="120"/>
                    </div>
                    <CardBody>
                        <h5 className='item-name text-center'>{item.brand_name}</h5>
                    </CardBody>
                    <div className='item-options text-center'>
                        <Button tag={Link} to={`/dashboard/rd/my-brands/${item.id}`} color='light' className="btn-wishlist">
                            <User className="mr-50" size={14}/>
                            <span>View Profile</span>
                        </Button>
                        <Button onClick={() => handleRedirectMessage(item)} color='primary' className="btn-cart move-cart">
                            <Mail className="mr-50" size={14}/>
                            <span>Send Message</span>
                        </Button>
                    </div>
                </Card>
            )
        })
    }

    return (
        <div
            className={classnames({
                'grid-view brands-nutritions': activeView === 'grid',
                'list-view': activeView === 'list'
            })}
        >
            {renderProducts()}
        </div>
    )
}

ProductCards.propTypes = {
    activeView: PropTypes.oneOf(['grid', 'list']).isRequired,
    myBrands: PropTypes.arrayOf(
        PropTypes.shape({
            user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            is_new: PropTypes.bool,
            is_favorite: PropTypes.bool,
            brand_logo: PropTypes.string,
            company_logo: PropTypes.string,
            brand_name: PropTypes.string.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        })
    ).isRequired,
    handleAddToFavorite: PropTypes.func.isRequired
}

export default ProductCards
