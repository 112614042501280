const RD = {
    CHAT_ADD_CONTACT: '[RD][CHAT] ADD_CONTACT',
    CHAT_GET_USER_PROFILE_FROM_SERVER: '[RD][CHAT] ADD_CONTACT GET_USER_PROFILE_FROM_SERVER',
    CHAT_CONTACTS: '[RD][CHAT] GET_CONTACTS',
    CHAT_GET_MESSAGE_TEMPLETES: '[RD][CHAT] GET_MESSAGE_TEMPLETES',
    SELECTED_USER_CHAT: '[RD][CHAT] SELECTED_USER_CHAT',
    LOAD_SELECTED_USER_CHAT: '[RD] [CHAT] LOAD_SELECTED_USER_CHAT',
    NEW_MESSAGE: '[RD][CHAT] NEW_MESSAGE',
    CHAT_SELECTED_CONTACT: '[RD][CHAT] SELECTED_CONTACT'
}

const NULL = {
    LOAD_SELECTED_RD_USER_CHAT: '[NULL] [RD] [CHAT] LOAD_SELECTED_RD_USER_CHAT'
}

export { RD, NULL }
