import {useEffect, useMemo} from "react"
import {Link, useHistory} from "react-router-dom"
import Avatar from "@components/avatar"
import {
    List,
    CheckCircle,
    XCircle
} from "react-feather"
import {
    Button,
    Row,
    Col,
    Spinner
} from "reactstrap"
import img4 from "@src/assets/images/avatars/avatar-blank.png"
import {useSelector, useDispatch} from "react-redux"
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import queryString from "query-string"
import {campaignListing} from "@store/actions/RDActions"

const CampaignListing = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const {
        campaignListingLoading,
        campaignListingData,
        campaignListingError
    } = useSelector((store) => store.rdReducer)

    const {data, pages} = useMemo(() => {
        return {
            data: campaignListingData?.data ?? null,
            pages: campaignListingData?.pages ?? null
        }
    }, [campaignListingData])

    useEffect(() => {
        const parsed = queryString.parseUrl(window.location.href)
        const pageNo = parsed?.query?.page
        let params = ''
        if (pageNo) {
            params = `?page=${pageNo}`
        }
        dispatch(campaignListing(params))
    }, [])

    const handleCustomPagination = (pageUrl) => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        const params = `?page=${pageNo}`
        dispatch(campaignListing(params))
        history.push({
            pathname: window.location.pathname,
            search: `?page=${pageNo}`
        })
    }

    const getAppliedCampaignData = (data) => {
        history.push(`/rd/apply-on-campaign/${data?.id}/view`)
    }

    return (
        campaignListingLoading ? (
            <div className='bg-primary text-white text-center p-1'>
                <Spinner style={{width: '18px', height: '18px'}}/> Please wait...
            </div>
        ) : (data ? (
            <>
                <Row>
                    {data?.map((campaign, index) => (
                        <Col md="4" className="mb-4" key={`campaign-${index}`}>
                            <div className="brandContent">
                                <Avatar img={campaign?.brand_img || img4}
                                        imgHeight="80"
                                        imgWidth="80"
                                />
                                <h4 className="mt-1 text-break font-weight-bold"
                                    title={campaign?.campaign_title}
                                    style={{height: "50px"}}
                                >
                                    {campaign?.campaign_title.length > 40 ? `${campaign?.campaign_title.substring(0, 40)}...` : campaign?.campaign_title}
                                </h4>
                                <div className="healthcare" style={{backgroundColor: "#f8f8f8"}}>
                                    <p className="general-wellness cursor-pointer" style={{color: "#161d31"}}>
                                        {campaign?.brand_name}
                                    </p>
                                </div>
                                {!campaign?.is_applied && campaign?.can_apply && (
                                    <Button.Ripple tag={Link}
                                                   to={`/rd/apply-on-campaign/${campaign?.id}`}
                                                   color="secondary"
                                                   className="m-25 healthcare"
                                    >
                                        <CheckCircle size={14}/> Learn More &amp; Outreach to Apply
                                    </Button.Ripple>
                                )}
                                {campaign?.is_applied ? (
                                    <Button.Ripple onClick={() => getAppliedCampaignData(campaign)}
                                                   color="secondary"
                                                   className="m-25 healthcare"
                                    >
                                        <List size={14}/> Applied
                                    </Button.Ripple>
                                ) : campaign?.can_apply === false ? (
                                    <Button.Ripple color="danger"
                                                   className="m-25 healthcare"
                                                   disabled={true}
                                    >
                                        <XCircle size={14}/> Limit exceeded.
                                    </Button.Ripple>
                                ) : null}
                            </div>
                        </Col>
                    ))}
                </Row>
                <Row className="pt-4 justify-content-centered">
                    <Col md="12">
                        <CustomPagination pageLinks={pages}
                                          onPaginationClick={handleCustomPagination}
                        />
                    </Col>
                </Row>
            </>
        ) : (
            <div className='font-weight-bold text-center'>
                <div className='mb-1'>Sorry! Campaign not found.</div>
                <Link className='text-primary' to='/dashboard/rd/campaigns'>Go Back</Link>
            </div>
        )))
}

export default CampaignListing
