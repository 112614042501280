import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap'
import {PropTypes} from "prop-types"

const DeleteFolderModal = ({handleData, isOpen, isLoading}) => {
    const handleDeleteFolder = (obj) => {
        handleData(obj)
    }

    return (
        <div className='vertically-centered-modal'>
            <Modal isOpen={isOpen} className='modal-dialog-centered'>
                <ModalHeader>Delete Folder</ModalHeader>
                <ModalBody className="py-2">
                    Are you sure you want to delete this folder.
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={() => handleDeleteFolder({isDelete: false})}>
                        No
                    </Button>
                    <Button color='danger' disabled={isLoading} onClick={() => handleDeleteFolder({isDelete: true})}>
                        {isLoading ? 'Deleting...' : 'Yes'}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

DeleteFolderModal.propTypes = {
    handleData: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
}

export default DeleteFolderModal
