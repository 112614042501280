import React, {useState, useEffect, Fragment} from 'react'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Folder, FolderPlus, Edit, Trash2} from 'react-feather'
import {InputGroup, InputGroupAddon, Input, Button, Card, CardBody, Row, Col, Spinner} from 'reactstrap'
import CreateUpdateFolderModal from './favoritesModals/createFolderModal'
import DeleteFolderModal from './favoritesModals/deleteFolderModal'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import {NULL} from '@src/redux/actionTypes'
import {
    getFavoriteFolders, createFavoriteFolder, updateFavoriteFolder,
    deleteFavoriteFolder, searchFavoriteFolders, nullResponse
} from '@src/redux/actions/rdDashboard'
import classnames from 'classnames'

const Favourite = () => {
    const [isPageLoading, setIsPageLoading] = useState(false)
    const [isCreateAction, setIsCreateAction] = useState(false)
    const [isUpdateAction, setIsUpdateAction] = useState(false)

    const [isDeleting, setIsDeleting] = useState(false)
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
    const [isCreateOrUpdating, setIsCreateOrUpdating] = useState(false)

    const [isCreateErrorShow, setIsCreateErrorShow] = useState(false)
    const [createErrorMessage, setCreateErrorMessage] = useState('')

    const [isOpenCreateUpdateModal, setIsOpenCreateUpdateModal] = useState(false)
    const [createUpdateModalHeaderTitle, setCreateUpdateModalHeaderTitle] = useState('')
    const [createUpdateModalButtonTitle, setCreateUpdateModalButtonTitle] = useState('')
    const [activeFolder, setActiveFolder] = useState(null)
    const [searchFolderValue, setSearchFolderValue] = useState('')
    const [paginationInfo, setPaginationInfo] = useState(null)
    const [activePage, setActivePage] = useState('Favorites')

    const rdDashboardStoreSlice = useSelector(store => store.rd)
    const dispatch = useDispatch()

    useEffect(() => {
        const favoriteFoldersRes = rdDashboardStoreSlice?.favoriteFoldersRes ?? null
        if (favoriteFoldersRes) {
            const {links, meta, pages} = favoriteFoldersRes
            setPaginationInfo({...links, ...meta, pages})
            setIsPageLoading(false)
        }

        if (!!rdDashboardStoreSlice?.createfavoriteFolderRes) {

            if (!rdDashboardStoreSlice?.createfavoriteFolderRes.status) {
                setIsCreateOrUpdating(false)
                setIsCreateErrorShow(true)
                setCreateErrorMessage(rdDashboardStoreSlice?.createfavoriteFolderRes.msg)
            }

            if (rdDashboardStoreSlice?.createfavoriteFolderRes.status) {
                setIsCreateOrUpdating(false)
                setIsOpenCreateUpdateModal(false)
                dispatch(nullResponse(NULL.RD_GET_FOLDERS))
                dispatch(nullResponse(NULL.RD_CREATE_FOLDER))
                dispatch(getFavoriteFolders(false))
                setIsPageLoading(true)
            }

        }

        if (!!rdDashboardStoreSlice?.updatefavoriteFolderRes) {
            setIsCreateOrUpdating(false)
            setIsOpenCreateUpdateModal(false)
            setIsPageLoading(true)
            dispatch(nullResponse(NULL.RD_GET_FOLDERS))
            dispatch(nullResponse(NULL.RD_CREATE_FOLDER))
            dispatch(nullResponse(NULL.RD_UPDATE_FOLDER))
            dispatch(getFavoriteFolders(false))
        }

        if (!!rdDashboardStoreSlice?.deletefavoriteFolderRes) {
            setIsDeleting(false)
            setIsOpenDeleteModal(false)
            setIsPageLoading(true)
            dispatch(nullResponse(NULL.RD_GET_FOLDERS))
            dispatch(nullResponse(NULL.RD_CREATE_FOLDER))
            dispatch(nullResponse(NULL.RD_UPDATE_FOLDER))
            dispatch(nullResponse(NULL.RD_DELETE_FOLDER))
            dispatch(getFavoriteFolders(false))
        }
    }, [rdDashboardStoreSlice])

    useEffect(() => {
        setIsPageLoading(true)
        dispatch(getFavoriteFolders(false))
    }, [])

    const onCreateFolder = (folder) => {
        setIsCreateErrorShow(false)
        setIsOpenCreateUpdateModal(true)
        setCreateUpdateModalHeaderTitle('Create Folder')
        setCreateUpdateModalButtonTitle('Create')
        setActiveFolder(folder)
        setIsCreateAction(true)
        setIsUpdateAction(false)
    }

    const onUpdateFolder = (folder) => {
        setIsCreateErrorShow(false)
        setIsOpenCreateUpdateModal(true)
        setCreateUpdateModalHeaderTitle('Update Folder')
        setCreateUpdateModalButtonTitle('Update')
        setActiveFolder(folder)
        setIsUpdateAction(true)
        setIsCreateAction(false)
    }

    const onDeleteFolder = (folder) => {
        setIsOpenDeleteModal(true)
        setActiveFolder(folder)
    }

    const HandleCreateUpdateFolder = ({isCancel, folderName, isCreateAction, isUpdateAction}) => {
        if (isCancel) {
            setIsOpenCreateUpdateModal(false)
            return
        }

        setIsCreateOrUpdating(true)
        if (isCreateAction) {
            setIsCreateErrorShow(false)
            dispatch(createFavoriteFolder({folderName}))
        }

        if (isUpdateAction) {
            const {id, folder_name} = activeFolder
            dispatch(updateFavoriteFolder({id, folderName}))
        }
    }

    const HandleDeleteFolder = ({isDelete}) => {
        if (!isDelete) {
            setIsOpenDeleteModal(false)
            return
        }
        setIsDeleting(true)
        const {id} = activeFolder
        dispatch(deleteFavoriteFolder({id}))
    }

    const handeSearchFolders = () => {
        setIsPageLoading(true)
        dispatch(searchFavoriteFolders({searchFolderValue}))
    }

    const handeResetSearch = () => {
        setSearchFolderValue('')
        setIsPageLoading(true)
        dispatch(getFavoriteFolders())
    }

    const handleCustomPagination = pageUrl => {
        setIsPageLoading(true)
        dispatch(getFavoriteFolders(true, pageUrl))
    }

    const onEnterSearch = (e) => {
        if (e.key === 'Enter') {
            handeSearchFolders()
        }
    }

    return (
        <Fragment>
            <div className="d-flex align-items-center justify-content-between mb-2 flex-wrap">
                <h3 className="mr-2">Favorites </h3>
                <Button.Ripple className='' color='primary' onClick={() => onCreateFolder()}>
                    <FolderPlus size={16}/>
                    <span> Create New Folder</span>
                </Button.Ripple>
            </div>
            <Card>
                <CardBody className="">
                    <Row>
                        <Col md="6">
                            <InputGroup size='lg' className="input-group-with-tabs-c">
                                <InputGroupAddon addonType='append'>
                                    <Button
                                        className={classnames({
                                            'active-tab': activePage === 'All'
                                        })}
                                        onClick={() => setActivePage('All')}
                                        tag={Link}
                                        to={'/dashboard/rd/brands'}
                                        color='primary'
                                        outline>
                                        All
                                    </Button>
                                </InputGroupAddon>
                                <InputGroupAddon addonType='append'>
                                    <Button
                                        className={classnames({
                                            'active-tab': activePage === 'My Brands'
                                        })}
                                        onClick={() => setActivePage('My Brands')}
                                        tag={Link}
                                        to={'/dashboard/rd/my-brands'}
                                        color='primary' outline>
                                        My Brands
                                    </Button>
                                </InputGroupAddon>
                                <InputGroupAddon addonType='append'>
                                    <Button
                                        className={classnames({
                                            'active-tab': activePage === 'Favorites'
                                        })}
                                        onClick={() => setActivePage('Favorites')}
                                        tag={Link}
                                        to={'/dashboard/rd/favorite-folders'}
                                        color='primary'
                                        outline>
                                        Favorites
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                        <Col md="6">
                            <InputGroup size='lg' className="mt-2 mt-md-0">
                                <Input placeholder='Search By Name' value={searchFolderValue} onChange={(e) => setSearchFolderValue(e.target.value)} onKeyDown={onEnterSearch}/>
                                <InputGroupAddon addonType='append'>
                                    <Button color='primary' onClick={handeSearchFolders} outline>
                                        Search
                                    </Button>
                                </InputGroupAddon>
                                <InputGroupAddon addonType='append'>
                                    <Button color='primary' onClick={handeResetSearch} outline>
                                        Reset
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            {isPageLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
            {!isPageLoading && (
                <div className="ecommerce-application">
                    <Row>
                        {rdDashboardStoreSlice?.favoriteFoldersRes?.data?.length === 0 && <div className="w-100 my-4 text-center"><h4>Record doesn't exists</h4></div>}
                        {rdDashboardStoreSlice?.favoriteFoldersRes?.data.map((item, i) => (
                            <Col sm="6" md="4" key={i}>
                                <Card className='ecommerce-card'>
                                    <CardBody className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <Folder
                                                className='mr-1 text-secondary'
                                                size={24}
                                            />
                                            <h5 className='mb-0'><Link to={`/dashboard/rd/favorite-folders/${item.id}/list`}>{item.folder_name}</Link></h5>
                                        </div>
                                        <div className="min-60px">
                                            <Edit
                                                className='cursor-pointer primary'
                                                size={20}
                                                onClick={() => onUpdateFolder(item)}
                                            />
                                            <Trash2
                                                className='ml-1 cursor-pointer danger'
                                                size={20}
                                                onClick={() => onDeleteFolder(item)}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                </div>)}
            <CreateUpdateFolderModal
                headerTitle={createUpdateModalHeaderTitle}
                buttonTitle={createUpdateModalButtonTitle}
                handleData={HandleCreateUpdateFolder}
                isOpen={isOpenCreateUpdateModal}
                isLoading={isCreateOrUpdating}
                isCreateAction={isCreateAction}
                isUpdateAction={isUpdateAction}
                editFolderInfo={activeFolder}
                isCreateErrorShow={isCreateErrorShow}
                createErrorMessage={createErrorMessage}
            />
            <DeleteFolderModal
                handleData={HandleDeleteFolder}
                isOpen={isOpenDeleteModal}
                isLoading={isDeleting}
            />
        </Fragment>
    )
}

export default Favourite
