import React from "react"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

const CircularProgress = ({ percentage }) => {  // Ensure 'percentage' is received as a prop
  const getColor = (value) => {
    if (value >= 80) return "#3AC977" // Green
    if (value >= 50) return "#00A0B6" // Blue
    return "#f44336" // Red
  }

  return (
    <div style={{ width: 60, height: 60 }}> {/* Fixed container size */}
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        strokeWidth={18}
        styles={buildStyles({
          pathColor: getColor(percentage),
          trailColor: "#EFF2F4",
          textColor: "#585858",
          textSize: "22px",
          fontWeight: "bold",
          strokeLinecap: "butt"
        })}
      />
    </div>
  )
}

export default CircularProgress
