import RDSignup from '../../views/pages/rd/RDSignup'
import BrandSignup from '../../views/pages/rd/BrandSignup'
import SetPassword from '../../views/pages/misc/SetPassword'
import Login from '../../views/pages/authentication/Login'
import AboutUs from '../../views/outerPages/AboutUs'
import TermsAndConditions from '../../views/outerPages/TermsAndConditions'
import Contact from '../../views/outerPages/Contact'
import PrivacyPolicy from '../../views/outerPages/PrivacyPolicy'
import Pricing from '../../views/outerPages/Pricing'
import Features from '../../views/outerPages/Features'
import Blog from '../../views/outerPages/Blog'
import DetailBlog from '../../views/outerPages/DetailBlog'
import ForgotPassword from '../../views/pages/authentication/ForgotPassword'
import ResetPasswordV2 from '../../views/pages/authentication/ResetPasswordV2'
import NotAuthorized from '../../views/pages/misc/NotAuthorized'
import ErrorPage from '../../views/pages/misc/Error'
import CreateLoginrd from '../../views/pages/rd/CreateLoginrd'
import Unsubscribe from '../../views/pages/misc/Unsubscribe'


const PagesRoutes = [
    {
        path: '/about',
        component: AboutUs,
        layout: 'BlankLayout',
        meta: {
            authRoute: false,
            publicRoute: true
        }
    },
    {
        path: '/terms-and-conditions',
        component: TermsAndConditions,
        layout: 'BlankLayout',
        meta: {
            authRoute: false,
            publicRoute: true
        }
    },
    {
        path: '/contact',
        component: Contact,
        layout: 'BlankLayout',
        meta: {
            authRoute: false,
            publicRoute: true
        }
    },
    {
        path: '/privacy-policy',
        component: PrivacyPolicy,
        layout: 'BlankLayout',
        meta: {
            authRoute: false,
            publicRoute: true
        }
    },
    {
        path: '/pricing',
        component: Pricing,
        layout: 'BlankLayout',
        meta: {
            authRoute: false,
            publicRoute: true
        }
    },
    {
        path: '/features',
        component: Features,
        layout: 'BlankLayout',
        meta: {
            authRoute: false,
            publicRoute: true
        }
    },
    {
        path: '/blog',
        component: Blog,
        layout: 'BlankLayout',
        exact: true,
        meta: {
            authRoute: false,
            publicRoute: true
        }
    },
    {
        path: '/blog/:id',
        component: DetailBlog,
        layout: 'BlankLayout',
        meta: {
            authRoute: false,
            publicRoute: true
        }
    },
    {
        path: '/login',
        component: Login,
        layout: 'BlankLayout',
        meta: {
            authRoute: true
        }
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        layout: 'BlankLayout',
        meta: {
            authRoute: true
        }
    },
    {
        path: '/reset/password/:token',
        component: ResetPasswordV2,
        layout: 'BlankLayout',
        meta: {
            authRoute: true
        }
    },
    {
        path: '/misc/not-authorized',
        component: NotAuthorized,
        layout: 'BlankLayout',
        meta: {
            publicRoute: true
        }
    },
    {
        path: '/misc/error',
        component: ErrorPage,
        layout: 'BlankLayout',
        meta: {
            publicRoute: true
        }
    },
    {
        path: '/createloginrd',
        component: CreateLoginrd,
        layout: 'BlankLayout',
        meta: {
            publicRoute: true
        }
    },
    {
        path: '/register-brand',
        component: BrandSignup,
        layout: 'BlankLayout',
        meta: {
            publicRoute: true
        }
    },
    {
        path: '/register-rd',
        component: RDSignup,
        layout: 'BlankLayout',
        meta: {
            publicRoute: true
        }
    },
    {
        path: '/unsubscribe/:token',
        component: Unsubscribe,
        layout: 'BlankLayout',
        meta: {
            authRoute: false,
            publicRoute: true
        }
    },
    {
        path: '/admin/set-password/:token',
        component: SetPassword,
        layout: 'BlankLayout',
        meta: {
            authRoute: false,
            publicRoute: true
        }
    }
]

export default PagesRoutes
