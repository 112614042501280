import {useState, useEffect} from 'react'
import {
    Search,
    RefreshCcw,
    ArrowRight
} from 'react-feather'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Spinner,
    Form,
    Table
} from 'reactstrap'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import {useSelector, useDispatch} from 'react-redux'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import * as ICON from 'react-feather'
import classnames from "classnames"
import Select from "react-select"
import {useFormik} from "formik"
import * as yup from "yup"
import {
    adminCouponListAction,
    adminCouponDeleteAction,
    adminCouponDeleteResetAction,
    adminCouponCreateAction,
    adminCouponCreateResetAction
} from "@store/actions/AdminActions"
import queryString from "query-string"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

const ManageCoupons = () => {
    const sweetAlert = withReactContent(Swal)

    const [addCouponModal, setAddCouponModal] = useState(false)

    const dispatch = useDispatch()

    const couponForm = useFormik({
        initialValues: {
            couponType: '',
            couponCode: '',
            couponValue: ''
        },
        validationSchema: yup.object().shape({
            couponType: yup.object().required("This field is required"),
            couponCode: yup.string().trim().min(4, 'Minimum 4 characters').max(10, 'Maximum 10 characters').required("This field is required"),
            couponValue: yup.number().required("This field is required").typeError('Only Numbers')
        }),
        onSubmit: (values) => {
            dispatch(adminCouponCreateAction({
                coupon_type: values.couponType.value,
                coupon_code: values.couponCode,
                value: values.couponValue
            }))
        }
    })

    const couponSearchForm = useFormik({
        initialValues: {
            coupon: ''
        },
        validationSchema: yup.object().shape({
            coupon: yup.string()
        }),
        onSubmit: (values) => {
            dispatch(adminCouponListAction(`?coupon_code=${values.coupon}`))
        }
    })

    const {
        adminCouponListLoading,
        adminCouponListData,
        adminCouponListError,

        adminCouponDeleteLoading,
        adminCouponDeleteData,
        adminCouponDeleteError,

        adminCouponCreateLoading,
        adminCouponCreateData,
        adminCouponCreateError
    } = useSelector(store => store.adminReducer)

    useEffect(() => {
        if (adminCouponDeleteError?.response?.data) {
            sweetAlert.fire({
                title: 'Error',
                icon: 'error',
                html: `<p>${adminCouponDeleteError?.response?.data?.msg}</p>`,
                confirmButtonText: 'OK'
            })
            dispatch(adminCouponDeleteResetAction())
        }
        if (adminCouponListError?.response?.data) {
            sweetAlert.fire({
                title: 'Error',
                icon: 'error',
                html: `<p>Error in coupon listing</p>`,
                confirmButtonText: 'OK'
            })
        }
        if (adminCouponCreateError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (adminCouponCreateError?.response?.data?.coupon_type) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminCouponCreateError?.response?.data?.coupon_type[0]}</li>`)
            }
            if (adminCouponCreateError?.response?.data?.coupon_code) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminCouponCreateError?.response?.data?.coupon_code[0]}</li>`)
            }
            if (adminCouponCreateError?.response?.data?.value) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminCouponCreateError?.response?.data?.value[0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(adminCouponCreateResetAction())
        }
        if (adminCouponCreateData?.status) {
            sweetAlert.fire({
                title: 'Congratulations',
                icon: 'success',
                html: `<p>${adminCouponCreateData?.msg}</p>`,
                confirmButtonText: 'OK'
            })
            dispatch(adminCouponCreateResetAction())
            dispatch(adminCouponListAction(''))
            setAddCouponModal(false)
        }
        if (adminCouponDeleteData?.status) {
            sweetAlert.fire({
                title: 'Congratulations',
                icon: 'success',
                html: `<p>${adminCouponDeleteData?.msg}</p>`,
                confirmButtonText: 'OK'
            })
            dispatch(adminCouponDeleteResetAction())
            dispatch(adminCouponListAction(''))
        }
    }, [
        adminCouponDeleteData,
        adminCouponDeleteError,
        adminCouponListError,
        adminCouponCreateData,
        adminCouponCreateError
    ])

    useEffect(() => {
        dispatch(adminCouponListAction(''))
    }, [])

    const refreshSearchForm = () => {
        couponSearchForm.resetForm()
        dispatch(adminCouponListAction(''))
    }

    const deleteCoupon = (item) => {
        sweetAlert.fire({
            title: `"${item.coupon_code}"`,
            icon: 'warning',
            html: '<p>Do you really want to delete?</p>',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showCancelButton: true,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(adminCouponDeleteAction({
                    id: item.id
                }))
            }
        })
    }

    const handleCustomPagination = (pageUrl) => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        dispatch(adminCouponListAction(`?page=${pageNo}&coupon_code=${couponSearchForm.values.coupon}`))
    }

    const couponOption = [
        {value: 'amount_off', label: 'Dollar Amount'},
        {value: 'percent_off', label: 'Percent Off'}
    ]

    return (
        <Card>
            <CardHeader className='d-flex align-items-center justify-content-between flex-wrap border-bottom p-1 px-2'>
                <CardTitle className="my-1" tag='h4'>
                    <ICON.List size={20}/> Manage Coupons
                </CardTitle>

                <Button.Ripple className="my-1" color='primary' onClick={() => setAddCouponModal(!addCouponModal)}>
                    <ICON.PlusSquare size={14}/> Add New Coupon
                </Button.Ripple>
            </CardHeader>
            <CardBody>
                <Form onSubmit={couponSearchForm.handleSubmit} autoComplete="off">
                    <Row form className='mt-1 mb-50'>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='coupon'>Coupon Code</Label>
                                <Input type='text'
                                       onBlur={couponSearchForm.handleBlur}
                                       onChange={couponSearchForm.handleChange}
                                       value={couponSearchForm.values.coupon}
                                       name='coupon'
                                       id='coupon'
                                       placeholder='Coupon Code'
                                       autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <div className="admin-filter-actions inline-direction">
                                <Button.Ripple className='btn-icon mr-10px' color='primary' type='submit' disabled={adminCouponListLoading || adminCouponDeleteLoading}>
                                    <Search size={16}/>
                                </Button.Ripple>
                                <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshSearchForm}
                                               disabled={adminCouponListLoading || adminCouponDeleteLoading}>
                                    <RefreshCcw size={16}/>
                                </Button.Ripple>
                            </div>
                        </Col>
                    </Row>
                </Form>

                <Table responsive>
                    <thead>
                    <tr>
                        <th>Coupon Code</th>
                        <th>Coupon Type</th>
                        <th>Coupon Value</th>
                        <th>Date</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {adminCouponListLoading ? (
                        <tr>
                            <td colSpan={5} align='center'>
                                <Spinner color='primary'/>
                            </td>
                        </tr>
                    ) : (
                        adminCouponListData?.data.length > 0 ? adminCouponListData?.data?.map((item) => (
                            <tr key={item.id}>
                                <td>{item.coupon_code}</td>
                                <td>{item.coupon_type}</td>
                                <td>
                                    {item.coupon_type === 'PERCENT OFF' ? `${item.value}%` : `$${item.value}`}
                                </td>
                                <td>{item.date}</td>
                                <td>
                                    <Button.Ripple color='danger' size='sm' onClick={() => deleteCoupon(item)} disabled={adminCouponDeleteLoading}>
                                        <ICON.Trash size={14}/> Delete
                                    </Button.Ripple>
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td colSpan={5} align='center'>
                                    Sorry! No record found.
                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </Table>
                <CustomPagination pageLinks={adminCouponListData?.pages} onPaginationClick={handleCustomPagination}/>
            </CardBody>

            {/* --- Add New Coupon ---*/}
            <Modal isOpen={addCouponModal} className='modal-dialog-centered'>
                <ModalHeader toggle={() => {
                    setAddCouponModal(false)
                    couponForm.resetForm()
                }}>
                    <ICON.PlusSquare size={14}/> Add New Coupon
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={couponForm.handleSubmit} autoComplete="off">
                        <Row>
                            <FormGroup tag={Col} md='12'>
                                <Label htmlFor='coupon-type'
                                       className={classnames({
                                           'is-invalid': couponForm.touched.couponType && couponForm.errors.couponType,
                                           'form-label': true,
                                           'font-weight-bold': true
                                       })}
                                >
                                    Coupon Type <span className="is-invalid">*</span>
                                </Label>
                                <Select options={couponOption}
                                        className='react-select'
                                        classNamePrefix='select'
                                        placeholder="Please Select..."
                                        value={couponForm.values.couponType}
                                        onChange={(e) => couponForm.setFieldValue('couponType', e)}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderColor: couponForm.touched.couponType && couponForm.errors.couponType ? 'red' : baseStyles.borderColor
                                            })
                                        }}
                                        isDisabled={adminCouponCreateLoading}
                                />
                                <div className="validation-err">{couponForm.touched.couponType && couponForm.errors.couponType}</div>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup tag={Col} md='12'>
                                <Label htmlFor='couponCode'
                                       className={classnames({
                                           'is-invalid': couponForm.touched.couponCode && couponForm.errors.couponCode,
                                           'form-label': true,
                                           'font-weight-bold': true
                                       })}
                                >
                                    Coupon Code <span className="is-invalid">*</span>
                                </Label>
                                <Input type='text'
                                       onBlur={couponForm.handleBlur}
                                       onChange={couponForm.handleChange}
                                       value={couponForm.values.couponCode}
                                       name='couponCode'
                                       id='couponCode'
                                       placeholder='Coupon Code'
                                       className={classnames({'is-invalid': couponForm.touched.couponCode && couponForm.errors.couponCode})}
                                       autoComplete="off"
                                       disabled={adminCouponCreateLoading}
                                />
                                <div className="validation-err">{couponForm.touched.couponCode && couponForm.errors.couponCode}</div>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup tag={Col} md='12'>
                                <Label htmlFor='couponValue'
                                       className={classnames({
                                           'is-invalid': couponForm.touched.couponValue && couponForm.errors.couponValue,
                                           'form-label': true,
                                           'font-weight-bold': true
                                       })}
                                >
                                    Coupon Value <span className="is-invalid">*</span>
                                </Label>
                                <Input type='text'
                                       onBlur={couponForm.handleBlur}
                                       onChange={couponForm.handleChange}
                                       value={couponForm.values.email}
                                       name='couponValue'
                                       id='couponValue'
                                       placeholder='Coupon Value'
                                       className={classnames({'is-invalid': couponForm.touched.couponValue && couponForm.errors.couponValue})}
                                       autoComplete="off"
                                       disabled={adminCouponCreateLoading}
                                />
                                <div className="validation-err">{couponForm.touched.couponValue && couponForm.errors.couponValue}</div>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup tag={Col} md='12' className="align-items-center d-flex justify-content-end">
                                <div>
                                    <Button.Ripple type='submit' color='primary' disabled={adminCouponCreateLoading}>
                                        {adminCouponCreateLoading ? (
                                            <span>
                                            Submit <Spinner style={{width: '16px', height: '16px'}}/>
                                        </span>
                                        ) : (
                                            <span>
                                            Submit <ArrowRight size="18"/>
                                        </span>
                                        )}
                                    </Button.Ripple>
                                </div>
                            </FormGroup>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </Card>
    )
}

export default ManageCoupons
