import React, {useEffect, useMemo} from "react"
import "@styles/react/libs/editor/editor.scss"
import "@styles/base/plugins/forms/form-quill-editor.scss"
import {Link, useHistory, useParams} from "react-router-dom"
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardText,
    Button,
    Spinner,
    FormGroup,
    Label,
    Input,
    Form,
    CustomInput
} from "reactstrap"
import {useDispatch, useSelector} from "react-redux"
import {
    campaignView,
    applyOnCampaign,
    applyOnCampaignReset
} from "@store/actions/RDActions"
import {useFormik} from "formik"
import * as Yup from "yup"
import classnames from "classnames"
import BlankLogo from "@src/assets/images/avatars/Blank-logo.jpg"
import Dots from "../../../assets/images/dots.svg"
import HomeIcon from "@src/assets/images/home.svg"
import BgImg from "@src/assets/images/bg.svg"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ArrowLeft from "@src/assets/images/arrowLeft.svg"

const ApplyOnCampaign = () => {
    const history = useHistory()

    const sweetAlert = withReactContent(Swal)

    const dispatch = useDispatch()

    const {id, mode} = useParams()

    const {
        campaignViewLoading,
        campaignViewData,
        campaignViewError,

        applyOnCampaignLoading,
        applyOnCampaignData,
        applyOnCampaignError
    } = useSelector((store) => store.rdReducer)

    const {data} = useMemo(() => {
        return {
            data: campaignViewData?.data ?? null
        }
    }, [campaignViewData])

    const getValidationSchema = () => {
        const baseSchema = {}
        if (data?.sample_provided_for_participants === 1 || data?.ship_to_canada_po_boxes_ap_boxes === 1) {
            baseSchema.first_name = Yup.string().trim().required("This field is required.")
            baseSchema.last_name = Yup.string().trim().required("This field is required.")
            baseSchema.street_address_1 = Yup.string().trim().required("This field is required.")
            baseSchema.city = Yup.string().trim().required("This field is required.")
            baseSchema.state = Yup.string().trim().required("This field is required.")
            baseSchema.zipcode = Yup.string().trim().required("This field is required.")
        }

        if (data?.campaign_questions?.length > 0) {
            baseSchema.campaign_form_questions = Yup.array().of(Yup.object().shape({
                    id: Yup.number().required(),
                    campaign_id: Yup.number().required(),
                    question: Yup.string().required(),
                    type: Yup.string().required(),
                    campaign_question_options: Yup.array().of(
                        Yup.object().shape({
                            id: Yup.number().required(),
                            question_id: Yup.number().required(),
                            answer: Yup.string().required()
                        })
                    ),
                    user_ans: Yup.string().required('This field is required')
                })
            ).required('At least one question is required').min(1, 'At least one question is required')
        }
        return Yup.object().shape(baseSchema)
    }

    const form = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            street_address_1: "",
            street_address_2: "",
            city: "",
            state: "",
            zipcode: "",
            campaign_form_questions: []
        },
        validationSchema: getValidationSchema(),
        onSubmit: (values) => {
            const result = new FormData()
            const {
                campaign_form_questions,
                first_name,
                last_name,
                street_address_1,
                street_address_2,
                city,
                state,
                zipcode
            } = values
            result.append('campaign_id', id)
            result.append('first_name', first_name)
            result.append('last_name', last_name)
            result.append('street_address_1', street_address_1)
            result.append('street_address_2', street_address_2)
            result.append('city', city)
            result.append('state', state)
            result.append('zipcode', zipcode)
            if (campaign_form_questions) {
                campaign_form_questions.forEach((item) => {
                    if (item.type === "textField") {
                        result.append(`text_answers[${item?.id}]`, item.user_ans)
                    } else if (item.type === "multiple") {
                        result.append(`answers_selected[]`, item.user_ans)
                    }
                })
                sweetAlert.fire({
                    title: 'Apply?',
                    icon: 'warning',
                    html: 'Do you really want to apply?',
                    confirmButtonText: 'Yes',
                    allowOutsideClick: false,
                    showCancelButton: true
                }).then((e) => {
                    if (e.isConfirmed) {
                        dispatch(applyOnCampaign(result))
                    }
                })
            }
        }
    })

    useEffect(() => {
        if (data?.campaign_questions) {
            const dataFormatted = data?.campaign_questions?.map((item) => {
                if (item.type === 'textField') {
                    return {...item, user_ans: item?.get_user_ans?.answer_text ?? ''}
                } else if (item.type === 'multiple') {
                    return {...item, user_ans: item?.get_user_ans?.answer_id ?? ''}
                } else {
                    return {...item, user_ans: ''}
                }
            })
            form.setFieldValue('campaign_form_questions', dataFormatted)
        }

        if (data?.get_mailing_address) {
            form.setFieldValue('first_name', data?.get_mailing_address.first_name ?? '')
            form.setFieldValue('last_name', data?.get_mailing_address.last_name ?? '')
            form.setFieldValue('street_address_1', data?.get_mailing_address.street_address_1 ?? '')
            form.setFieldValue('street_address_2', data?.get_mailing_address.street_address_2 ?? '')
            form.setFieldValue('city', data?.get_mailing_address.city ?? '')
            form.setFieldValue('state', data?.get_mailing_address.state ?? '')
            form.setFieldValue('zipcode', data?.get_mailing_address.zipcode ?? '')
        }
    }, [data])

    useEffect(() => {
        if (applyOnCampaignData?.status) {
            sweetAlert.fire({
                title: 'Congratulations',
                icon: 'success',
                html: applyOnCampaignData?.msg
            })
            dispatch(applyOnCampaignReset())
            history.push('/dashboard/rd/campaigns')
        }

        if (applyOnCampaignError?.response?.status) {
            sweetAlert.fire({
                title: 'Error',
                icon: 'error',
                html: applyOnCampaignError?.response?.data?.msg
            })
            dispatch(applyOnCampaignReset())
        }
    }, [
        applyOnCampaignData,
        applyOnCampaignError
    ])

    useEffect(() => {
        form.resetForm()
        dispatch(campaignView(`?campaign_id=${id}`))
    }, [])

    return (
        campaignViewLoading ? (
            <div className='bg-primary text-white text-center p-1'>
                <Spinner style={{width: '18px', height: '18px'}}/> Please wait...
            </div>
        ) : (data ? (
                <>
                    <div className="d-flex align-items-center justify-content-between pb-2">
                         <div className="d-flex align-items-center pb-1">
                            <Link to='/dashboard/rd/campaigns' className='btn p-0'>
                                <img className="pr-1" src={ArrowLeft} alt="Arrow Left"/>
                            </Link>
                            <h2 className="m-0 font-inter inter-medium" style={{color: '#000'}}>
                                Apply
                            </h2>
                        </div>
                        <div className="d-flex align-items-center">
                            <Link to='/dashboard/rd' className='btn p-0'>
                                <img src={HomeIcon} alt="Home"/>
                            </Link>
                            <div className="px-1">/</div>
                            <Link to='/dashboard/rd/campaigns' className='btn p-0'>
                                <div>Brand Outreach Posts</div>
                            </Link>
                        </div>
                    </div>

                    <Card className="p-2 rdViewCard">
                        <Row className="position-relative">
                            <Col md={12} className="position-relative">
                                <img src={data?.banner_image || BgImg} alt="Banner" className="img-fluid rounded w-100 bgImg"/>
                            </Col>
                        </Row>
                        <Row className="mt-3 position-relative">
                            <Col md={2} className="text-center position-relative">
                                <img src={`${data?.get_user?.get_brand_profile?.brand_logo_url || BlankLogo}`} alt="Brand Logo" className="rounded-circle img-fluid profileImg"/>
                            </Col>
                            <Col md={10}>
                                <div className="name-description">
                                    <CardTitle className="name" tag="h4">
                                        {data?.campaign_title}
                                    </CardTitle>
                                    <CardText className="description">
                                        {data?.campaign_type?.title}
                                    </CardText>
                                </div>
                            </Col>
                        </Row>
                        <CardBody>
                            <h5>Description</h5>
                            <div>
                                <div dangerouslySetInnerHTML={{__html: data?.campaign_details}}/>
                            </div>
                            <h6 className="mt-3">Application Deadline</h6>
                            <CardText>{data?.expire_after ? data?.expire_after : 'No specific end date'}</CardText>
                        </CardBody>
                    </Card>

                    <Form onSubmit={form.handleSubmit}>
                        {data?.campaign_type?.id === 3 && (
                            <Card>
                                <CardBody>
                                    <h5 className="f_size bold_weight text-dark font-inter inter-medium ml-1 mb-2">
                                        RD Requirements
                                    </h5>
                                    <h6 className="f_size bold_weight font-inter inter-regular ml-1 text-line-height"
                                        dangerouslySetInnerHTML={{
                                            __html: data?.rd_requirements?.replace(/\r\n/g, "<br />")
                                        }}
                                    ></h6>
                                </CardBody>
                                <CardBody>
                                    <h5 className="f_size bold_weight text-dark font-inter inter-medium ml-1 mb-2">
                                        Partnership Requirements & Guidelines
                                    </h5>
                                    <h6 className="f_size bold_weight font-inter inter-regular ml-1 text-line-height"
                                        dangerouslySetInnerHTML={{
                                            __html: data?.partnership_requirements_and_guidelines?.replace(/\r\n/g, "<br />")
                                        }}
                                    ></h6>
                                </CardBody>
                                <CardBody>
                                    <h5 className="f_size bold_weight text-dark font-inter inter-medium ml-1 mb-2">
                                        Compensation
                                    </h5>
                                    <h6 className="f_size bold_weight font-inter inter-regular ml-1">
                                        {data?.budget_per_rd}
                                    </h6>
                                </CardBody>
                            </Card>
                        )}

                        {data?.campaign_type?.id === 1 && data?.platform_external_link && (
                            <Card>
                                <CardBody>
                                    <CardTitle className="name" tag="h4">
                                        Submission Form
                                    </CardTitle>
                                    <div>
                                        <strong>Sign-up link.</strong>
                                        <div>Please click on this link to sign up:</div>
                                        <button className="btn btn-link p-0 text-underline" onClick={() => {
                                            window.open(data?.platform_external_link, '_blank')
                                        }}>
                                            Sign up Here
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        )}

                        {data?.campaign_type?.id !== 1 && data?.campaign_questions?.length > 0 && (
                            <Card>
                                <CardBody>
                                    <CardTitle className="name" tag="h4">
                                       Submission Form
                                    </CardTitle>
                                    {form.values.campaign_form_questions.map((field, index) => (
                                        <div key={`campaign-form-${index}`}>
                                            <div className="d-flex align-items-start mb-1">
                                                <img className="pt-25 pr-2" src={Dots} alt="icon"/>
                                                <div className="w-100">
                                                    <FormGroup className="mb-0">
                                                        <div className={classnames({
                                                            'is-invalid': form.touched.campaign_form_questions?.[index]?.user_ans && form.errors.campaign_form_questions?.[index]?.user_ans,
                                                            'mb-50': true
                                                        })} dangerouslySetInnerHTML={{__html: field.question?.replace(/\r\n/g, "<br />")}}></div>
                                                        {field.type === 'multiple' && (
                                                            field?.campaign_question_options?.map((option, i) => (
                                                                <FormGroup check={true} key={`campaign-form-${index}-${i}`} className="d-flex align-items-center mb-50 p-0">
                                                                    <CustomInput name={`option-${index}`}
                                                                           id={`option-${index}-${i}`}
                                                                           type="radio"
                                                                           value={option?.id}
                                                                           onChange={() => {
                                                                               form.setFieldValue(`campaign_form_questions[${index}].user_ans`, option?.id)
                                                                           }}
                                                                           checked={form.values.campaign_form_questions[index].user_ans === option?.id}
                                                                           className={classnames({
                                                                               'is-invalid': form.touched.campaign_form_questions?.[index]?.user_ans && form.errors.campaign_form_questions?.[index]?.user_ans
                                                                           })}
                                                                           disabled={applyOnCampaignLoading || mode}
                                                                    />
                                                                    {" "}
                                                                    <Label className={classnames({
                                                                        'is-invalid': form.touched.campaign_form_questions?.[index]?.user_ans && form.errors.campaign_form_questions?.[index]?.user_ans
                                                                    })}>
                                                                        {option?.answer}
                                                                    </Label>
                                                                </FormGroup>
                                                            ))
                                                        )}
                                                        {field.type !== 'multiple' && (
                                                            <FormGroup>
                                                                <Input placeholder="Your Answer..."
                                                                       id={`que-${index}`}
                                                                       type="textarea"
                                                                       rows={3}
                                                                       className={classnames({
                                                                           'is-invalid': form.touched.campaign_form_questions?.[index]?.user_ans && form.errors.campaign_form_questions?.[index]?.user_ans
                                                                       })}
                                                                       name={`campaign_form_questions[${index}].user_ans`}
                                                                       value={form.values.campaign_form_questions[index].user_ans}
                                                                       onChange={form.handleChange}
                                                                       onBlur={form.handleBlur}
                                                                       disabled={applyOnCampaignLoading || mode}
                                                                />
                                                            </FormGroup>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </CardBody>
                            </Card>
                        )}

                        {((data?.sample_provided_for_participants === 1) || (data?.ship_to_canada_po_boxes_ap_boxes === 1)) && (
                            <Card>
                                <CardBody>
                                    <CardTitle className="name text-dark" tag="h4">
                                        To receive a welcome package or samples, please share your mailing address.
                                    </CardTitle>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label className={classnames('formlabel', {
                                                    'is-invalid': form.touched.first_name && form.errors.first_name,
                                                    'form-label': true
                                                })}
                                                       htmlFor='first_name'
                                                >
                                                    First Name <span className="is-invalid ml-25">*</span>
                                                </Label>
                                                <Input placeholder="First Name"
                                                       type="text"
                                                       name='first_name'
                                                       id='first_name'
                                                       value={form.values.first_name}
                                                       onBlur={form.handleBlur}
                                                       onChange={form.handleChange}
                                                       className={classnames({'is-invalid': form.touched.first_name && form.errors.first_name})}
                                                       disabled={applyOnCampaignLoading || mode}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label className={classnames('formlabel', {
                                                    'is-invalid': form.touched.last_name && form.errors.last_name,
                                                    'form-label': true
                                                })}
                                                       htmlFor='last_name'
                                                >
                                                    Last Name <span className="is-invalid ml-25">*</span>
                                                </Label>
                                                <Input placeholder="Last Name"
                                                       type="text"
                                                       name='last_name'
                                                       id='last_name'
                                                       value={form.values.last_name}
                                                       onBlur={form.handleBlur}
                                                       onChange={form.handleChange}
                                                       className={classnames({'is-invalid': form.touched.last_name && form.errors.last_name})}
                                                       disabled={applyOnCampaignLoading || mode}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <Label className={classnames('formlabel', {
                                                    'is-invalid': form.touched.street_address_1 && form.errors.street_address_1,
                                                    'form-label': true
                                                })}
                                                       htmlFor='street_address'
                                                >
                                                    Street Address 1 <span className="is-invalid ml-25">*</span>
                                                </Label>
                                                <Input placeholder="Street Address 1"
                                                       type="text"
                                                       name='street_address_1'
                                                       id='street_address_1'
                                                       value={form.values.street_address_1}
                                                       onBlur={form.handleBlur}
                                                       onChange={form.handleChange}
                                                       className={classnames({'is-invalid': form.touched.street_address_1 && form.errors.street_address_1})}
                                                       disabled={applyOnCampaignLoading || mode}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <Label className={classnames('formlabel', {
                                                    'is-invalid': form.touched.street_address_2 && form.errors.street_address_2,
                                                    'form-label': true
                                                })}
                                                       htmlFor='street_address_2'
                                                >
                                                    Street Address 2
                                                </Label>
                                                <Input placeholder="Street Address 2"
                                                       type="text"
                                                       name='street_address_2'
                                                       id='street_address_2'
                                                       value={form.values.street_address_2}
                                                       onBlur={form.handleBlur}
                                                       onChange={form.handleChange}
                                                       className={classnames({'is-invalid': form.touched.street_address_2 && form.errors.street_address_2})}
                                                       disabled={applyOnCampaignLoading || mode}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label className={classnames('formlabel', {
                                                    'is-invalid': form.touched.city && form.errors.city,
                                                    'form-label': true
                                                })}
                                                       htmlFor='city'
                                                >
                                                    City <span className="is-invalid ml-25">*</span>
                                                </Label>
                                                <Input placeholder="City"
                                                       type="text"
                                                       name='city'
                                                       id='city'
                                                       value={form.values.city}
                                                       onBlur={form.handleBlur}
                                                       onChange={form.handleChange}
                                                       className={classnames({'is-invalid': form.touched.city && form.errors.city})}
                                                       disabled={applyOnCampaignLoading || mode}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label className={classnames('formlabel', {
                                                    'is-invalid': form.touched.state && form.errors.state,
                                                    'form-label': true
                                                })}
                                                       htmlFor='state'
                                                >
                                                    State <span className="is-invalid ml-25">*</span>
                                                </Label>
                                                <Input placeholder="State"
                                                       type="text"
                                                       name='state'
                                                       id='state'
                                                       value={form.values.state}
                                                       onBlur={form.handleBlur}
                                                       onChange={form.handleChange}
                                                       className={classnames({'is-invalid': form.touched.state && form.errors.state})}
                                                       disabled={applyOnCampaignLoading || mode}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <Label className={classnames('formlabel', {
                                                    'is-invalid': form.touched.zipcode && form.errors.zipcode,
                                                    'form-label': true
                                                })}
                                                       htmlFor='zipcode'
                                                >
                                                    Zip Code <span className="is-invalid ml-25">*</span>
                                                </Label>
                                                <Input placeholder="Zip Code"
                                                       type="text"
                                                       name='zipcode'
                                                       id='zipcode'
                                                       value={form.values.zipcode}
                                                       onBlur={form.handleBlur}
                                                       onChange={form.handleChange}
                                                       className={classnames({'is-invalid': form.touched.zipcode && form.errors.zipcode})}
                                                       disabled={applyOnCampaignLoading || mode}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        )}

                        {data?.campaign_type?.id === 2 && data?.ship_to_canada_po_boxes_ap_boxes !== 1 && (
                            <Card>
                                <CardBody>
                                    <CardTitle className="name text-dark font-inter inter-medium" tag="h4">
                                        Please note we cannot ship to Canada, xxx…
                                    </CardTitle>
                                </CardBody>
                            </Card>
                        )}
                    </Form>

                    <Card>
                        <CardBody>
                            <div className="d-flex align-items-center justify-content-between">
                                <Button color="primary" type='button' onClick={() => form.submitForm()} disabled={applyOnCampaignLoading || mode}>
                                    {applyOnCampaignLoading ? 'Please wait...' : 'Submit'}
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </>
            ) : (
                <div className='font-weight-bold text-center'>
                    <div className='mb-1'>Sorry! Campaign not found.</div>
                    <Link className='text-primary' to='/dashboard/rd/campaigns'>Go Back</Link>
                </div>
            )
        )
    )
}

export default ApplyOnCampaign
