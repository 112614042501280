import React, {useEffect, useState} from "react"
import {Button, Col, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row, Spinner} from "reactstrap"
import * as Icon from "react-feather"
import {useFormik} from "formik"
import * as Yup from "yup"
import Resumable from 'resumablejs'
import {PropTypes} from "prop-types"

const Uploader = ({show, hide, onSuccess, onError}) => {

    const FILE_SIZE = 1 * 1024 * 1024 * 1024

    const UPLOAD_FORMATS = ["image/png", "image/jpeg", "image/jpg", "application/pdf", "video/mp4"]

    const [uploader, setUploader] = useState(null)
    const [progress, setProgress] = useState(null)
    const [loader, setLoader] = useState(false)

    const serverURL = `${process.env.REACT_APP_BASEURL}file-uploader`

    useEffect(() => {
        const upload = new Resumable({
            target: serverURL,
            chunkSize: 0.5 * 1024 * 1024,
            headers: {
                Accept: 'application/json'
            },
            testChunks: false,
            throttleProgressCallbacks: 1
        })

        upload.on('fileAdded', (file) => {
            upload.upload()
            setLoader(true)
        })

        upload.on('fileProgress', (file) => {
            const progress = Math.floor(file.progress() * 100)
            setProgress(progress === 0 ? 1 : progress)
        })

        upload.on('fileSuccess', (file, response) => {
            upload.removeFile(file)
            setLoader(false)
            onSuccess(JSON.parse(response))
        })

        upload.on('fileError', (file, response) => {
            upload.removeFile(file)
            setLoader(false)
            onError(JSON.parse(response))
        })

        setUploader(upload)
    }, [])

    const form = useFormik({
        initialValues: {
            file: ''
        },
        validationSchema: Yup.object().shape({
            file: Yup.mixed().required("File is required.")
                .test(
                    "fileFormat",
                    "File is empty or invalid.",
                    value => !value || (value && UPLOAD_FORMATS.includes(value.type))
                )
                .test(
                    "fileSize",
                    "File size exceeds the limit of 1 GB.",
                    value => !value || (value && value.size <= FILE_SIZE)
                )
        }),
        onSubmit: (values) => {
            uploader.addFile(values.file)
        }
    })

    useEffect(() => {
        if (show) {
            form.resetForm()
            setProgress(null)
            setLoader(null)
        }
    }, [show])

    return (
        <Modal isOpen={show} className='modal-dialog-centered' size="lg">
            <ModalHeader toggle={() => {
                form.resetForm()
                hide()
                uploader.cancel()
            }}>
                Upload File
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={form.handleSubmit}>
                    <Row>
                        <FormGroup tag={Col} md='12' className='border-bottom'>
                            <Label htmlFor="file" className="d-flex justify-content-between form-label font-weight-bold mb-1">
                                <span>Upload File</span>
                                <span className='text-dark'>Allowed Only (PNG, JPEG, JPG, PDF, MP4) (Max Size: 1GB)</span>
                            </Label>
                        </FormGroup>
                        <FormGroup tag={Col} md='8' className='mt-1'>
                            <CustomInput type="file"
                                         name="file"
                                         id="file"
                                         onChange={(e) => {
                                             form.setFieldValue('file', e.target.files[0])
                                         }}
                                         onBlur={form.handleBlur}
                                         accept="video/*,image/*,application/pdf"
                                         disabled={false}
                            />
                            <div className="validation-err">
                                {form.touched.file && form.errors.file}
                            </div>
                        </FormGroup>
                        <FormGroup tag={Col} md='4' className='text-right mt-1'>
                            <Button.Ripple type="submit" color="primary" disabled={loader}>
                                {loader ? (
                                    <>
                                        <Spinner style={{width: '16px', height: '16px'}}/> Start Uploading
                                    </>
                                ) : (
                                    <>
                                        <Icon.Upload size='16'/> Start Uploading
                                    </>
                                )}
                            </Button.Ripple>
                        </FormGroup>
                    </Row>
                    {(progress) && (
                        <Row>
                            <FormGroup tag={Col} md='12'>
                                <h6 className='bg-light p-1 text-dark rounded'>An upload of your file is underway.</h6>
                                <Progress className="mt-2" value={progress} color='success'>
                                    {progress}%
                                </Progress>
                            </FormGroup>
                            <FormGroup tag={Col} md='12' className='text-right text-danger font-small-4'>
                                <strong><Icon.Eye size='14'/> Keep this window open until the file uploads.</strong>
                            </FormGroup>
                        </Row>
                    )}
                </Form>
            </ModalBody>
        </Modal>
    )
}

Uploader.propTypes = {
    show: PropTypes.bool,
    hide: PropTypes.func,
    onSuccess: PropTypes.func,
    onError: PropTypes.func
}

export default Uploader
