import React from 'react'
import classnames from "classnames"
import {Label} from "reactstrap"

const CustomLabel = ({formik, id, title, isRequired = true, msg = ''}) => {
    return (
        <Label htmlFor={id}
               className={classnames('d-flex justify-content-between', {
                   'is-invalid': formik.touched[id] && formik.errors[id],
                   'form-label label_color': true,
                   'font-weight-bold': true
               })}
        >
            <span className="f_size mb-25 d-inline-block font-inter inter-medium">
                {title}{isRequired && <span className="is-invalid ml-25">*</span>}
            </span>
            {msg && <span className='text-danger text-underline font-inter inter-medium'>{msg}</span>}
        </Label>
    )
}

export default CustomLabel