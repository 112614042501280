import React, {useState, Fragment, useEffect, useMemo} from "react"
import {Link, useHistory, useParams} from "react-router-dom"
import BlankLogo from "@src/assets/images/avatars/Blank-logo.jpg"
import {
    Card,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    CardText,
    Form,
    Spinner,
    CustomInput
} from "reactstrap"
import HomeIcon from "@src/assets/images/home.svg"
import {useSelector, useDispatch} from "react-redux"
import "@styles/react/libs/flatpickr/flatpickr.scss"
import ArrowLeft from "@src/assets/images/arrowLeft.svg"
import {
    viewCampaign,
    viewCampaignReset
} from "@src/redux/actions/BrandActions"
import "@styles/react/libs/editor/editor.scss"
import "@styles/base/plugins/forms/form-quill-editor.scss"
import Dots from "@src/assets/images/dots.svg"
import PublishSchedule from "./PublishSchedule"
import EditIcon from "@src/assets/images/editIcon.svg"
import BgImg from "@src/assets/images/bg.svg"
import {
    showOutreachPage,
    showHomePage,
    showEditOutreachPage,
    successAlert
} from "./Controller"

const RDView = () => {
    const history = useHistory()

    const {id, mode} = useParams()

    const dispatch = useDispatch()

    const [openPubSecModal, setOpenPubSecModal] = useState(false)
    const [openSecModal, setOpenSecModal] = useState(false)
    const [action, setAction] = useState(false)

    const {
        campaignViewLoading,
        campaignViewData,
        campaignViewError
    } = useSelector(state => state.brandReducer)

    const {data} = useMemo(() => {
        return {
            data: campaignViewData?.data ?? null
        }
    }, [campaignViewData])

    const reload = () => {
        dispatch(viewCampaign(`?campaign_id=${id}`))
    }

    useEffect(() => {
        reload()
    }, [])

    const saveAsDraftHandle = () => {
        successAlert('Campaign has saved as draft')
        history.push(showOutreachPage())
    }

    const onSuccess = (msg) => {
        successAlert(msg)
        setOpenPubSecModal(false)
        setOpenSecModal(false)
        history.push(showOutreachPage())
    }

    const goBack = () => {
        dispatch(viewCampaignReset())
        history.push(showOutreachPage())
    }

    const goHome = () => {
        dispatch(viewCampaignReset())
        history.push(showHomePage())
    }

    const goEdit = () => {
        dispatch(viewCampaignReset())
        history.push(showEditOutreachPage(id))
    }

    return (
        campaignViewLoading ? (
            <div className='bg-primary text-white text-center p-1'>
                <Spinner className="loader-md" /> Please wait...
            </div>
        ) : (data ? (
                <>
                    <div className="d-flex align-items-center justify-content-between pb-2">
                        <div className="d-flex align-items-center">
                            <button className='btn p-0' onClick={goBack}>
                                <img className="pr-1" src={ArrowLeft} alt="Arrow Left"/>
                            </button>
                            <h2 className="m-0 font-inter inter-medium text-dark">Review Outreach</h2>
                        </div>
                        <div className="d-flex align-items-center">
                            <button className='btn p-0' onClick={goHome}>
                                <img src={HomeIcon} alt="Home"/>
                            </button>
                            <div className="px-1">/</div>
                            <div>
                                <button className='btn p-0 font-inter inter-regular' onClick={goBack}>
                                    My Outreach
                                </button>
                            </div>
                            <div className="px-1">/</div>
                            <div>
                                <button className='btn p-0 font-inter inter-regular' onClick={reload}>
                                    Review Outreach
                                </button>
                            </div>
                        </div>
                    </div>

                    <Card className="p-2 rdViewCard">
                        <Row>
                            <Col md={12}>
                                {data?.banner_image ? (
                                    <img src={data?.banner_image} alt="banner" className="img-fluid rounded w-100 bgImg"/>

                                ) : (
                                    <img src={BgImg} alt="banner" className="img-fluid rounded w-100 bgImg"/>
                                )}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={2} sm={2} xs={12} className="text-center">
                                <img src={`${data?.get_user?.get_brand_profile?.brand_logo_url || BlankLogo}`} alt="Brand Logo"
                                     className="rounded-circle img-fluid profileImg"/>
                            </Col>
                            <Col md={10} sm={10} xs={12}>
                                <div className="name-description">
                                    <CardTitle className="name font-inter inter-medium" tag="h4">
                                        {data?.campaign_title}
                                    </CardTitle>
                                    <CardText className="description font-inter inter-regular">
                                        {data?.campaign_type?.title}
                                    </CardText>
                                </div>
                            </Col>
                        </Row>
                        <CardBody>
                            <h5 className="f_size bold_weight text-dark font-inter inter-medium">Description</h5>
                            <div className="font-inter inter-regular" dangerouslySetInnerHTML={{__html: campaignViewData?.data?.campaign_details}}></div>
                            <h6 className="mt-3 f_size bold_weight text-dark font-inter inter-medium">Application Deadline</h6>
                            <CardText className="font-inter inter-regular">{data?.deadline ? data?.deadline : 'No specific end date'}</CardText>
                        </CardBody>
                    </Card>

                    {data?.campaign_type?.id === 3 && (
                        <Card>
                            <CardBody>
                                <h5 className="f_size bold_weight text-dark font-inter inter-medium ml-1 mb-2">
                                    RD Requirements
                                </h5>
                                <h6 className="f_size bold_weight font-inter inter-regular ml-1 text-line-height"
                                    dangerouslySetInnerHTML={{
                                        __html: data?.rd_requirements?.replace(/\r\n/g, "<br />")
                                    }}
                                ></h6>
                            </CardBody>
                            <CardBody>
                                <h5 className="f_size bold_weight text-dark font-inter inter-medium ml-1 mb-2">
                                    Partnership Requirements & Guidelines
                                </h5>
                                <h6 className="f_size bold_weight font-inter inter-regular ml-1 text-line-height"
                                    dangerouslySetInnerHTML={{
                                        __html: data?.partnership_requirements_and_guidelines?.replace(/\r\n/g, "<br />")
                                    }}
                                ></h6>
                            </CardBody>
                            <CardBody>
                                <h5 className="f_size bold_weight text-dark font-inter inter-medium ml-1 mb-2">
                                    Compensation
                                </h5>
                                <h6 className="f_size bold_weight font-inter inter-regular ml-1">
                                    {data?.budget_per_rd}
                                </h6>
                            </CardBody>
                        </Card>
                    )}

                    {data?.campaign_type?.id === 1 && data?.platform_external_link && (
                        <Card>
                            <CardBody>
                                <CardTitle className="name text-dark font-inter inter-medium" tag="h4">
                                    Submission Form
                                </CardTitle>
                                <CardText className="font-inter">
                                    <strong className="font-inter inter-regular">Sign-up link</strong>
                                    <div className="font-inter inter-regular">Please click on this link to sign up:</div>
                                    <button className="btn btn-link p-0 text-underline font-inter inter-medium" onClick={() => {
                                        window.open(data?.platform_external_link, '_blank')
                                    }}>
                                        Sign up Here
                                    </button>
                                </CardText>
                            </CardBody>
                        </Card>
                    )}

                    {data?.campaign_type?.id !== 1 && data?.campaign_questions?.length > 0 && (
                        <Card>
                            <CardBody>
                                <CardTitle className="name text-dark font-inter inter-medium" tag="h4">
                                    Submission Form
                                </CardTitle>
                                <Form>
                                    {data?.campaign_questions.map((field, index) => (
                                        <CardText key={`questions-${index}`} tag='div'>
                                            <div className="d-flex align-items-start">
                                                <img className="pt-50 pr-2" src={Dots} alt="icon"/>
                                                <div className="w-100">
                                                    <FormGroup className="mb-0">
                                                    <div className="f_size bold_weight text-dark font-inter inter-medium mb-50"
                                                        dangerouslySetInnerHTML={{
                                                            __html: field.question?.replace(/\r\n/g, "<br />")
                                                        }}
                                                    ></div>
                                                        {field.type === 'multiple' && (
                                                            field?.campaign_question_options?.map((option, i) => (
                                                                <FormGroup check key={`options-${index}-${i}`} className="d-flex align-items-center pl-0 mb-1">
                                                                    <CustomInput name={`option-${index}`}
                                                                                 id={`option-${index}-${i}`}
                                                                                 type="radio"
                                                                                 value={option?.id}
                                                                                 label={option?.answer}
                                                                                 disabled={true}
                                                                    />
                                                                </FormGroup>
                                                            ))
                                                        )}
                                                        {field.type !== 'multiple' && (
                                                            <FormGroup>
                                                                <Input className="font-inter inter-regular" placeholder="Your Answer..."
                                                                       type="textarea"
                                                                       rows={3}
                                                                       disabled={true}
                                                                />
                                                            </FormGroup>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </CardText>
                                    ))}
                                </Form>

                            </CardBody>
                        </Card>
                    )}

                    {((data?.sample_provided_for_participants === 1) || (data?.ship_to_canada_po_boxes_ap_boxes === 1)) && (
                        <Card>
                            <CardBody>
                                <CardTitle className="name text-dark font-inter inter-medium" tag="h4">
                                    To receive a welcome package or samples, please share your mailing address.
                                </CardTitle>
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label className='formlabel font-inter inter-medium'>
                                                First Name <span className="is-invalid ml-25">*</span>
                                            </Label>
                                            <Input className="font-inter inter-regular"  placeholder="First Name" type="text" disabled={true}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label className='formlabel font-inter inter-medium'>
                                                Last Name <span className="is-invalid ml-25">*</span>
                                            </Label>
                                            <Input className="font-inter inter-regular" placeholder="Last Name" type="text" disabled={true}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup>
                                            <Label className='formlabel font-inter inter-medium'>
                                                Street Address 1 <span className="is-invalid ml-25">*</span>
                                            </Label>
                                            <Input className="font-inter inter-regular" placeholder="Street Address 1" type="text" disabled={true}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup>
                                            <Label className='formlabel font-inter inter-medium'>
                                                Street Address 2
                                            </Label>
                                            <Input className="font-inter inter-regular" placeholder="Street Address 2" type="text" disabled={true}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label className='formlabel font-inter inter-medium'>
                                                City <span className="is-invalid ml-25">*</span>
                                            </Label>
                                            <Input className="font-inter inter-regular" placeholder="City" type="text" disabled={true}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label className='formlabel font-inter inter-medium'>
                                                State <span className="is-invalid ml-25">*</span>
                                            </Label>
                                            <Input className="font-inter inter-regular" placeholder="State" type="text" disabled={true}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup>
                                            <Label className='formlabel font-inter inter-medium'>
                                                Zip Code <span className="is-invalid ml-25">*</span>
                                            </Label>
                                            <Input className="font-inter inter-regular" placeholder="Zip Code" type="text" disabled={true}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    )}

                    {data?.campaign_type?.id === 2 && data?.ship_to_canada_po_boxes_ap_boxes !== 1 && (
                        <Card>
                            <CardBody>
                                <CardTitle className="name text-dark font-inter inter-medium" tag="h4">
                                    Please note we cannot ship to Canada, xxx…
                                </CardTitle>
                            </CardBody>
                        </Card>
                    )}

                    {data?.is_live !== 1 && (
                        <Card>
                            <CardBody>
                                <div className="d-flex align-items-center justify-content-end footer-btn">
                                    <div>
                                        <button className="btn btn-light mr-1 font-inter inter-medium btn_color" onClick={goEdit}>
                                            <img height="18" src={EditIcon} alt="Edit" className="mr-50"/>
                                            Edit
                                        </button>
                                        {!mode && (
                                            <>
                                                <Button outline color="primary" className="mr-1 outline-Btn font-inter inter-medium" onClick={saveAsDraftHandle}>
                                                    Save as Draft
                                                </Button>
                                                <Button outline color="primary" className="mr-1 outline-Btn font-inter inter-medium" onClick={() => {
                                                    setAction('schedule')
                                                    setOpenPubSecModal(true)
                                                }}>
                                                    Schedule Publish
                                                </Button>
                                                <Button color="primary" className="font-inter inter-medium" onClick={() => {
                                                    setAction('publish')
                                                    setOpenPubSecModal(true)
                                                }}>
                                                    Publish Outreach
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    )}

                    <PublishSchedule openPubSecModal={openPubSecModal}
                                     setOpenPubSecModal={(status) => setOpenPubSecModal(status)}
                                     openSecModal={openSecModal}
                                     setOpenSecModal={(status) => setOpenSecModal(status)}
                                     data={campaignViewData?.data}
                                     onSuccess={(res) => {
                                         onSuccess(res)
                                     }}
                                     id={id}
                                     action={action}
                    />
                </>
            ) : (
                <div className='font-weight-bold text-center'>
                    <div className='mb-1 font-inter inter-regular'>Sorry! Outreach not found.</div>
                    <Link className='text-primary font-inter inter-regular' to='/brands/outreach-posts'>Go Back</Link>
                </div>
            )
        )
    )
}

export default RDView
