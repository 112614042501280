import {useState, useEffect} from 'react'
import classnames from 'classnames'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Label, Input} from 'reactstrap'
import {AlertCircle} from 'react-feather'
import {PropTypes} from "prop-types"

const CreateUpdateFolderModal = ({editFolderInfo, headerTitle, buttonTitle, handleData, isOpen, isLoading, isCreateAction, isUpdateAction, isCreateErrorShow, createErrorMessage}) => {
    const [folderName, setFolderName] = useState('')
    const [folderNameError, setFolderNameError] = useState(false)

    useEffect(() => {
        setFolderName(editFolderInfo?.folder_name)
    }, [editFolderInfo])

    useEffect(() => {
        if (!isOpen) setFolderName('')
    }, [isOpen])

    const handleCreateorUpdateFolder = ({isCancel}) => {
        if (!folderName && !isCancel) {
            setFolderNameError(true)
            return
        }
        if (folderName?.replace(/ /g, "").length === 0 && !isCancel) {
            setFolderNameError(true)
            return
        }
        setFolderNameError(false)
        handleData({isCancel, folderName, isCreateAction, isUpdateAction})

    }

    return (
        <div className='vertically-centered-modal'>
            <Modal isOpen={isOpen} className='modal-dialog-centered'>
                <ModalHeader>{headerTitle}</ModalHeader>
                <ModalBody>
                    <Alert color='danger' isOpen={isCreateErrorShow}>
                        <div className='alert-body'>
                            <AlertCircle size={15}/>{' '}
                            <span className='ml-1'>
                                {createErrorMessage}
                            </span>
                        </div>
                    </Alert>
                    <div className='form-group form-password-toggle'>
                        <Label className={classnames({
                            'form-label': true
                        })} htmlFor={`folderName`}>
                            Folder Name<span className="is-invalid">*</span>
                        </Label>
                        <Input
                            type='text'
                            onChange={(e) => {
                                setFolderName(e.target.value)
                                setFolderNameError(false)
                            }}
                            value={folderName}
                            name={`folderName`}
                            id={`folderName`}
                            className={classnames({'is-invalid': folderNameError})}
                        />
                        {folderNameError && <div className="validation-err">This field is required</div>}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' onClick={() => handleCreateorUpdateFolder({isCancel: true})}>
                        Cancel
                    </Button>
                    <Button color='primary' disabled={isLoading} onClick={() => handleCreateorUpdateFolder({isCancel: false})}>
                        {isLoading ? 'Loading...' : buttonTitle}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

CreateUpdateFolderModal.propTypes = {
    editFolderInfo: PropTypes.shape({
        folder_name: PropTypes.string
    }),
    headerTitle: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    handleData: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isCreateAction: PropTypes.bool.isRequired,
    isUpdateAction: PropTypes.bool.isRequired,
    isCreateErrorShow: PropTypes.bool.isRequired,
    createErrorMessage: PropTypes.string
}

export default CreateUpdateFolderModal
